<template>
  <v-app>
    <v-main>
      <NotificationDialog ref="notificationDialog"></NotificationDialog>
      <ImageDialog ref="imageDialog"></ImageDialog>
      <RecoverAgentPasswordDialog ref="recoverAgentPasswordDialog"></RecoverAgentPasswordDialog>
      
      <ProductDialog ref="productDialog"></ProductDialog>
      <InsertMainProductDialog ref="insertMainProductDialog"></InsertMainProductDialog>
      <InsertProductDialog ref="insertProductDialog"></InsertProductDialog>
      
      <InsertProductSearchParameterDialog ref="insertProductSearchParameterDialog"></InsertProductSearchParameterDialog>
      
      <ProductStockDialog ref="productStockDialog"></ProductStockDialog>
      <ProductImageDialog ref="productImageDialog"></ProductImageDialog>
      <InsertProductTransferDialog ref="insertProductTransferDialog"></InsertProductTransferDialog>
      <UpdateProductTransferDialog ref="updateProductTransferDialog"></UpdateProductTransferDialog>

      <BillDialog ref="billDialog"></BillDialog>
      <BillCancelationDialog ref="billCancelationDialog"></BillCancelationDialog>
      <PrintableBillDialog ref="printableBillDialog"></PrintableBillDialog>
      <PrintablePDFExcelDocumentDialog ref="printablePDFExcelDocumentDialog"></PrintablePDFExcelDocumentDialog>
      <InsertAgentDialog ref="insertAgentDialog"></InsertAgentDialog>
      <InsertLocalityDialog ref="insertLocalityDialog"></InsertLocalityDialog>

      <InsertProductChangeDialog ref="insertProductChangeDialog"></InsertProductChangeDialog>
      <InsertProductInAndOutDialog ref="insertProductInAndOutDialog"></InsertProductInAndOutDialog>
      <InsertLocalityExpenseDialog ref="insertLocalityExpenseDialog"></InsertLocalityExpenseDialog>
      <InsertClientDialog ref="insertClientDialog"></InsertClientDialog>
      <AgentDialog ref="agentDialog"></AgentDialog>
      <AccessCredentialDialog ref="accessCredentialDialog"></AccessCredentialDialog>
      <InsertTokenDialog ref="insertTokenDialog"></InsertTokenDialog>

      <ProductChangeDialog ref="productChangeDialog"></ProductChangeDialog>

      <ProductTransferDialog ref="productTransferDialog"></ProductTransferDialog>
      <ProductInAndOutDialog ref="productInAndOutDialog"></ProductInAndOutDialog>

      <InsertProformaDialog ref="insertProformaDialog"></InsertProformaDialog>
      <ContactDialog ref="contactDialog"></ContactDialog>

      <InsertDebtPaymentDialog ref="insertDebtPaymentDialog"></InsertDebtPaymentDialog>

      <ClientBillDialog ref="clientBillDialog"></ClientBillDialog>
      <DebtPaymentDialog ref="debtPaymentDialog"></DebtPaymentDialog>


      <router-view/>
    </v-main>
  </v-app>
</template>

<style>
:root {
  --warningFontColor: #B00020;
}
</style>


<script>
import NotificationDialog from './components/NotificationDialog.vue';
import ImageDialog from './components/ImageDialog.vue';
import RecoverAgentPasswordDialog from './components/RecoverAgentPasswordDialog.vue';

import ProductDialog from './components/ProductDialog.vue';
import InsertMainProductDialog from './components/InsertMainProductDialog.vue';
import InsertProductDialog from './components/InsertProductDialog.vue';

import InsertProductSearchParameterDialog from './components/InsertProductSearchParameterDialog.vue';
import ProductStockDialog from './components/ProductStockDialog.vue';
import ProductImageDialog from './components/ProductImageDialog.vue';
import InsertProductTransferDialog from './components/InsertProductTransferDialog.vue';
import UpdateProductTransferDialog from './components/UpdateProductTransferDialog.vue';
import BillDialog from './components/BillDialog.vue';
import BillCancelationDialog from './components/BillCancelationDialog.vue';
import PrintableBillDialog from './components/PrintableBillDialog.vue';
import PrintablePDFExcelDocumentDialog from './components/PrintablePDFExcelDocumentDialog.vue';
import InsertAgentDialog from './components/InsertAgentDialog.vue';
import InsertLocalityDialog from './components/InsertLocalityDialog.vue';
import InsertProductChangeDialog from './components/InsertProductChangeDialog.vue';

import InsertProductInAndOutDialog from './components/InsertProductInAndOutDialog.vue';
import InsertLocalityExpenseDialog from './components/InsertLocalityExpenseDialog.vue';
import InsertClientDialog from './components/InsertClientDialog.vue';
import AgentDialog from './components/AgentDialog.vue';
import AccessCredentialDialog from './components/AccessCredentialDialog.vue';
import InsertTokenDialog from './components/InsertTokenDialog.vue';



import InsertProformaDialog from './components/InsertProformaDialog.vue';
import ContactDialog from './components/ContactDialog.vue';
import InsertDebtPaymentDialog from './components/InsertDebtPaymentDialog.vue';


import ClientBillDialog from './components/ClientBillDialog.vue';
import ProductTransferDialog from './components/ProductTransferDialog.vue';
import ProductInAndOutDialog from './components/ProductInAndOutDialog.vue';


import DebtPaymentDialog from './components/DebtPaymentDialog.vue';
import ProductChangeDialog from './components/ProductChangeDialog.vue';

export default {
  name: 'App',

  components: 
  {
    NotificationDialog,
    ImageDialog,
    RecoverAgentPasswordDialog,

    ProductDialog,
    InsertMainProductDialog,
    InsertProductDialog,
    InsertProductSearchParameterDialog,

    ProductStockDialog,
    ProductImageDialog,
    InsertProductTransferDialog,
    UpdateProductTransferDialog,
    BillDialog,
    BillCancelationDialog,
    PrintableBillDialog,
    PrintablePDFExcelDocumentDialog,
    InsertAgentDialog,
    InsertLocalityDialog,

    InsertProductChangeDialog,
    InsertProductInAndOutDialog,
    InsertLocalityExpenseDialog,
    InsertClientDialog,
    AgentDialog,
    AccessCredentialDialog,
    InsertTokenDialog,


    ProductTransferDialog,
    ProductInAndOutDialog,
    

    InsertProformaDialog,
    ContactDialog,
    InsertDebtPaymentDialog,

    ClientBillDialog,
    DebtPaymentDialog,
    ProductChangeDialog


  },

  data: () => ({}),

  mounted(){
    this.$root.sessionManager = this.$refs.sessionManager;
    this.$root.notificationDialog = this.$refs.notificationDialog;
    this.$root.imageDialog = this.$refs.imageDialog;
    this.$root.recoverAgentPasswordDialog = this.$refs.recoverAgentPasswordDialog;

    this.$root.productDialog = this.$refs.productDialog;
    this.$root.insertMainProductDialog = this.$refs.insertMainProductDialog;
    this.$root.insertProductDialog = this.$refs.insertProductDialog;
    this.$root.insertProductSearchParameterDialog = this.$refs.insertProductSearchParameterDialog;


    this.$root.productStockDialog = this.$refs.productStockDialog;
    this.$root.productImageDialog = this.$refs.productImageDialog;
    this.$root.insertProductTransferDialog = this.$refs.insertProductTransferDialog;
    this.$root.updateProductTransferDialog = this.$refs.updateProductTransferDialog;
    this.$root.billDialog = this.$refs.billDialog;
    this.$root.billCancelationDialog = this.$refs.billCancelationDialog;
    this.$root.printableBillDialog = this.$refs.printableBillDialog;
    this.$root.printablePDFExcelDocumentDialog = this.$refs.printablePDFExcelDocumentDialog;

    this.$root.insertAgentDialog = this.$refs.insertAgentDialog;
    this.$root.insertLocalityDialog = this.$refs.insertLocalityDialog;

    this.$root.insertProductChangeDialog = this.$refs.insertProductChangeDialog;

    this.$root.insertProductInAndOutDialog = this.$refs.insertProductInAndOutDialog;
    this.$root.insertLocalityExpenseDialog = this.$refs.insertLocalityExpenseDialog;
    this.$root.insertClientDialog = this.$refs.insertClientDialog;
    this.$root.agentDialog = this.$refs.agentDialog;
    this.$root.accessCredentialDialog = this.$refs.accessCredentialDialog;
    this.$root.insertTokenDialog = this.$refs.insertTokenDialog;

    this.$root.productChangeDialog = this.$refs.productChangeDialog;
    this.$root.productTransferDialog = this.$refs.productTransferDialog;
    this.$root.productInAndOutDialog = this.$refs.productInAndOutDialog;


    this.$root.insertProformaDialog = this.$refs.insertProformaDialog;
    this.$root.contactDialog = this.$refs.contactDialog;
    this.$root.insertDebtPaymentDialog = this.$refs.insertDebtPaymentDialog;

    this.$root.clientBillDialog = this.$refs.clientBillDialog;
    this.$root.debtPaymentDialog = this.$refs.debtPaymentDialog;

  }
}
</script>
