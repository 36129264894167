<template>
  <v-dialog v-model="navigation.insertDebtPaymentDialogIsVisible" :persistent="true" width="auto">
    <v-card width="1200" style="padding: 20px;">
      <div v-if="loader.insertDebtPayment" style="text-align: center;">
        <v-progress-circular color="info" indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <div style="display: flex;">
          <div style="width: 80%;">
            <div style="display: flex;">
              <v-text-field v-model="input.debtPaymentTotalAmount" append-inner-icon="mdi-cash" label="Monto total del abono" type="text" hide-spin-buttons variant="solo-filled"></v-text-field>
              <v-switch v-if="display.billRows.length > 1" v-model="input.selectedMode" :label="input.selectedMode" :true-value="'Última factura'" :false-value="'Distribuir entre facturas'" color="indigo" variant="solo-filled" style="margin-top: 5px; margin-left: 30px;"></v-switch>
            </div>
            <v-textarea v-model="input.debtPaymentNote" append-inner-icon="mdi-text-box-outline" label="Nota del abono (opcional)" rows="3" variant="solo-filled"></v-textarea>  
          </div>
          <div style="width: 18%; margin-left: 2%; text-align: center;">
            <input @change="processImage()" type="file" accept="image/*" ref="debtPaymentImage" id="debtPaymentImage" style="display: none;">  
            <img v-if="input.debtPaymentImage == null" @click="uploadImage()" :src="assets.placeholderImage" style="cursor: pointer; width: 80%; margin-top: 20px;">
            <img v-else @click="uploadImage()" :src="input.debtPaymentImage" style="cursor: pointer; width: 50%;">
          </div>
        </div>
        <v-select v-model="input.debtPaymentType" :items="display.debtPaymentTypeOptions" append-inner-icon="mdi-cash-multiple" label="Método de pago" variant="solo-filled"></v-select>
        <br><br>
        <div style="padding: 20px; background-color: #e9e8e8;">        
          <v-data-table-virtual :headers="display.billHeaders" :items="display.billRows">
            <template v-slot:item.billCreationDatetime="{ item }">
              {{ item.billCreationDatetime }}
              <p>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.billCreationDatetime) }}</p>
            </template>
            <template v-slot:item.billAmount="{ item }">
              <p>₡ {{ item.billAmount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
            </template>
            <template v-slot:item.billDebt="{ item }">
              <p>₡ {{ item.billDebt.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
            </template>
            <template v-slot:item.debtPaymentAmount="{ item }">
              <v-number-input v-model="item.debtPaymentAmount" :min="0" :max="item.billDebt" :readonly="input.selectedMode == 'Última factura'" variant="solo-filled" density="dense" style="margin-top: 20px;"></v-number-input>
            </template>
          </v-data-table-virtual>
        </div>
        <br><br><br>
        <v-btn @click="insertDebtPayment()" dark block height="38" color="success">
          <h3>REGISTRAR ABONO</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-cash</v-icon>
        </v-btn>
        <br>
        <v-btn @click="setDefaultValues()" dark block height="38" color="error">
          <h3>CANCELAR</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
    
<style scoped>
</style>

<script>
import { viewMethodCaller } from '../viewMethodCaller.js';

export default {
  name: 'InsertDebtPaymentDialog',
  inject: ['$generalFunctions'],

  data: () => ({

    input:
    {
      debtPaymentTotalAmount: null,
      selectedMode: 'Última factura',
      debtPaymentNote: null,
      debtPaymentImage: null,
      debtPaymentType: null
    },

    loader: 
    {
      insertDebtPayment: false
    },
    
    navigation: 
    {
      insertDebtPaymentDialogIsVisible: false
    },

    display:
    {
      debtPaymentTypeOptions: ['Efectivo', 'Tarjeta', 'SINPE', 'Transferencia o depósito'],
      billRows: [],
      billHeaders: 
      [
        {key: 'billID', title: 'ID'},
        {key: 'billCreationDatetime', title: 'FECHA'},
        {key: 'billAmount', title: 'MONTO DE LA FACTURA'},
        {key: 'billDebt', title: 'DEUDA A LA FECHA'},
        {key: 'debtPaymentAmount', title: 'ABONADO ASIGNADA A ESTA FACTURA'}
      ]
    },

    localStorage:
    {
      agentID: null
    },

    assets:
    {
      placeholderImage: null
    }

  }),

  watch: { 
    'input.debtPaymentTotalAmount'(){
      if (this.input.debtPaymentTotalAmount && this.input.selectedMode == 'Última factura'){
        this.display.billRows[this.display.billRows.length - 1].debtPaymentAmount = this.input.debtPaymentTotalAmount;
      }
    },
    'input.selectedMode'(){
      this.input.debtPaymentTotalAmount = null;
      this.display.billRows.forEach(billRow => {
        billRow.debtPaymentAmount = 0;
      });
    }
  },


  methods: {
    async insertDebtPayment(){
      this.loader.insertDebtPayment = true;
      const insertDebtPaymentRequestQuery = 
      {
        'debtPayments': this.display.billRows.filter(billRow => billRow.debtPaymentAmount != 0),
        'debtPaymentAgentID': this.localStorage.agentID,
        'debtPaymentType': this.input.debtPaymentType,
        'debtPaymentFile': this.input.debtPaymentImage,
        'debtPaymentNote': this.input.debtPaymentNote
      };
      const insertDebtPaymentResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/contact/functions/insertDebtPayment', insertDebtPaymentRequestQuery);
      if (insertDebtPaymentResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'Pago registrado exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        await this.setDefaultValues();
        viewMethodCaller.emit('ContactView/methods/selectContactTransactionFromContactView()');
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al registrar el abono, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.insertDebtPayment = false;
    },

    uploadImage(){
      document.getElementById('debtPaymentImage').click();
    },

    processImage(){
      const previousImage = this.input.debtPaymentImage;
      try {
        this.input.debtPaymentImage = this.$refs.debtPaymentImage.files[0];
        const imageFileReader = new FileReader();
        imageFileReader.readAsDataURL(this.input.debtPaymentImage);
        imageFileReader.onload = () => {
          this.input.debtPaymentImage = imageFileReader.result;
        };
        imageFileReader.onerror = function () {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al cargar la imagen del abono de la factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
          throw new Error('Error when charging the image');
        };
      } catch {
        this.input.debtPaymentImage = previousImage;
      }
    },

    async setDefaultValues(){
      this.input.debtPaymentTotalAmount = null;
      this.input.selectedMode = 'Última factura';
      this.input.debtPaymentNote = null;
      this.input.debtPaymentImage = null;
      this.input.debtPaymentType = null;
      this.loader.insertDebtPayment = false;
      this.display.billRows.length = 0;
      this.navigation.insertDebtPaymentDialogIsVisible = false;
    },

    async getLocalStorageData(){
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.assets.placeholderImage = this.$generalFunctions.default.data.placeholderImage;
    },

    async openInsertDebtPaymentDialog(billRows){
      await this.getLocalStorageData();
      this.display.billRows = billRows;
      this.navigation.insertDebtPaymentDialogIsVisible = true;
    }
  },

  mounted(){
    
  }
}
</script>