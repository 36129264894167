<template>
  <div class="closeViewContainer">
      <v-card v-if="loader.selectBillOnCloseView || (display.billsRows.length > 0)" class="closeViewInformationContainer" elevation="3">
      <div v-if="loader.selectBillOnCloseView" style="text-align: center;">
        <br>
        <v-progress-circular color="info" indeterminate></v-progress-circular>
        <br><br>
      </div>
      <div v-else class="closeViewInformationMainContainer">
        <div class="closeViewInformationTableContainer">
          <v-data-table-virtual :headers="display.billsHeaders" :items="display.billsRows">
            <template v-slot:item.billState="{ item }">
              <v-chip :color="localStorage.billStateColors[item.billState].billStateColor" variant="flat">
                <p :style="{color: localStorage.billStateColors[item.billState].billStateFontColor}">{{ item.billState }}</p>
              </v-chip>
            </template>
            <template v-slot:item.billAgentName="{ item }">
              <v-chip :color="item.billAgentColor" variant="flat">
                <p :style="{color: item.billAgentFontColor}">{{ item.billAgentName }}</p>
              </v-chip>
            </template>
            <template v-slot:item.clientName="{ item }">
              {{ item.clientName || item.contactName || 'No registrado' }}
            </template>
            <template v-slot:item.billCreationDatetime="{ item }">
              {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.billCreationDatetime) }}
            </template>
            <template v-slot:item.billAmount="{ item }">
              <div v-if="item.billPaymentType == 'Mixto'" style="cursor: pointer;">
                <v-tooltip text="Tooltip">
                  <template v-slot:activator="{ props }">
                    <p v-bind="props">{{ item.billCurrency == '₡' ? `${item.billCurrency}${$generalFunctions.default.methods.parseAmountToDisplay(item.billAmount)}` : `${$generalFunctions.default.methods.parseAmountToDisplay(item.billAmount)}${item.billCurrency}` }}</p>
                  </template>
                  <p v-if="item.billMixedPaymentCashAmount"><strong>Efectivo:</strong> {{ item.billCurrency == '₡' ? `${item.billCurrency}${$generalFunctions.default.methods.parseAmountToDisplay(item.billMixedPaymentCashAmount)}` : `${$generalFunctions.default.methods.parseAmountToDisplay(item.billMixedPaymentCashAmount)}${item.billCurrency}` }}</p>
                  <p v-if="item.billMixedPaymentCardAmount"><strong>Tarjeta:</strong> {{ item.billCurrency == '₡' ? `${item.billCurrency}${$generalFunctions.default.methods.parseAmountToDisplay(item.billMixedPaymentCardAmount)}` : `${$generalFunctions.default.methods.parseAmountToDisplay(item.billMixedPaymentCardAmount)}${item.billCurrency}` }}</p>
                  <p v-if="item.billMixedPaymentSINPEAmount"><strong>SINPE:</strong> {{ item.billCurrency == '₡' ? `${item.billCurrency}${$generalFunctions.default.methods.parseAmountToDisplay(item.billMixedPaymentSINPEAmount)}` : `${$generalFunctions.default.methods.parseAmountToDisplay(item.billMixedPaymentSINPEAmount)}${item.billCurrency}` }}</p>
                  <p v-if="item.billMixedPaymentDepositAmount"><strong>Transferencia o depósito:</strong> {{ item.billCurrency == '₡' ? `${item.billCurrency}${$generalFunctions.default.methods.parseAmountToDisplay(item.billMixedPaymentDepositAmount)}` : `${$generalFunctions.default.methods.parseAmountToDisplay(item.billMixedPaymentSINPEAmount)}${item.billCurrency}` }}</p>
                </v-tooltip>
              </div>
              <div v-else>
                {{ item.billCurrency == '₡' ? `${item.billCurrency} ${$generalFunctions.default.methods.parseAmountToDisplay(item.billAmount)}` : `${$generalFunctions.default.methods.parseAmountToDisplay(item.billAmount)} ${item.billCurrency}` }}
              </div>
            </template>
            <template v-slot:item.billAction="{ item }">
              <div v-if="item.loader" style="text-align: center;">
                <v-progress-circular color="success" indeterminate></v-progress-circular>
              </div>
              <div v-else style="display: flex;">
                <v-icon @click="openBillDialog(item)" color="info" dark right>mdi-magnify</v-icon>
                <v-icon @click="openPrintableBillDialog(item)" style="margin-left: 10px;" color="success" dark right>mdi-printer</v-icon>
              </div>
            </template>
          </v-data-table-virtual>
        </div>
        <div class="closeViewInformationResultContainer">
          <div class="closeViewInformationResultLabelContainer">
            <div style="display: flex; width: 100%;">
              <div style="width: 50%; text-align: left;">
                <p class="closeViewInformationLabel"><strong>Efectivo:</strong></p>
                <p class="closeViewInformationLabel"><strong>Tarjeta:</strong></p>
                <p class="closeViewInformationLabel"><strong>SINPE:</strong></p>
                <p class="closeViewInformationLabel"><strong>Transferencia:</strong></p>
                <p class="closeViewInformationLabel"><strong>Página web:</strong></p>
                <p class="closeViewInformationLabel"><strong>Rappi:</strong></p>
                <p class="closeViewInformationLabel"><strong>Uber:</strong></p>

                <br><br>
                <p class="closeViewInformationTotalLabel"><strong>TOTAL:</strong></p>
              </div>
              <div style="width: 50%; text-align: right;">
                <p class="closeViewInformationLabel">{{ `₡ ${$generalFunctions.default.methods.parseAmountToDisplay(getBillPaymentTypeAmount('Efectivo', 'billMixedPaymentCashAmount'))}` }}</p>
                <p class="closeViewInformationLabel">{{ `₡ ${$generalFunctions.default.methods.parseAmountToDisplay(getBillPaymentTypeAmount('Tarjeta', 'billMixedPaymentCardAmount'))}` }}</p>
                <p class="closeViewInformationLabel">{{ `₡ ${$generalFunctions.default.methods.parseAmountToDisplay(getBillPaymentTypeAmount('SINPE', 'billMixedPaymentSINPEAmount'))}` }}</p>
                <p class="closeViewInformationLabel">{{ `₡ ${$generalFunctions.default.methods.parseAmountToDisplay(getBillPaymentTypeAmount('Transferencia o depósito', 'billMixedPaymentDepositAmount'))}` }}</p>
                <p class="closeViewInformationLabel">{{ `₡ ${$generalFunctions.default.methods.parseAmountToDisplay(getBillPaymentTypeAmount('Página web', null))}` }}</p>
                <p class="closeViewInformationLabel">{{ `₡ ${$generalFunctions.default.methods.parseAmountToDisplay(getBillPaymentTypeAmount('Rappi', null))}` }}</p>
                <p class="closeViewInformationLabel">{{ `₡ ${$generalFunctions.default.methods.parseAmountToDisplay(getBillPaymentTypeAmount('Uber', null))}` }}</p>
                <br><br>
                <p class="closeViewInformationTotalLabel">{{ `₡ ${$generalFunctions.default.methods.parseAmountToDisplay(getBillsTotalAmount())}` }}</p>
              </div>
            </div>
          </div>
          <br>
          <div class="closeViewInformationResultLabelContainer">
            <v-text-field @wheel.prevent v-model="input.closingCashAmount" type="number" hide-spin-buttons prepend-inner-icon="mdi-cash" label="Cantidad en efectivo" variant="solo-filled"></v-text-field>
            <v-text-field @wheel.prevent v-model="input.closingCardAmount" type="number" hide-spin-buttons prepend-inner-icon="mdi-credit-card" label="Cantidad en tarjeta" variant="solo-filled"></v-text-field>
            <v-text-field @wheel.prevent v-model="input.closingSINPEAmount" type="number" hide-spin-buttons prepend-inner-icon="mdi-cellphone" label="Cantidad en SINPE" variant="solo-filled"></v-text-field>
            <v-text-field @wheel.prevent v-model="input.closingDepositAmount" type="number" hide-spin-buttons prepend-inner-icon="mdi-bank" label="Cantidad en depósito o transferencia" variant="solo-filled"></v-text-field>
            <v-text-field @wheel.prevent v-model="input.closingWebpageAmount" type="number" hide-spin-buttons prepend-inner-icon="mdi-web" label="Cantidad en página web" variant="solo-filled"></v-text-field>
            <v-text-field @wheel.prevent v-model="input.closingRappiAmount" type="number" hide-spin-buttons prepend-inner-icon="mdi-alpha" label="Cantidad en Rappi" variant="solo-filled"></v-text-field>
            <v-text-field @wheel.prevent v-model="input.closingUberAmount" type="number" hide-spin-buttons prepend-inner-icon="mdi-beta" label="Cantidad en Uber" variant="solo-filled"></v-text-field>
            <v-text-field @wheel.prevent v-model="input.closingExpenseAmount" type="number" hide-spin-buttons prepend-inner-icon="mdi-store" label="Cantidad de gastos" variant="solo-filled"></v-text-field>
            <br>
            <div style="display: flex; width: 100%;">
              <div style="width: 50%; text-align: left;">
                <p style="font-size: 30px;"><strong>Total reportado:</strong></p>
              </div>
              <div style="width: 50%; text-align: right;">
                <div v-if="isNaN(getBillsReportedTotalAmount()) == false">
                  <p style="font-size: 30px;">{{ `₡ ${$generalFunctions.default.methods.parseAmountToDisplay(getBillsReportedTotalAmount())}` }}</p>
                </div>
                <div v-else>
                  <p style="font-size: 30px;">Error</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br><br>
      <v-btn @click="generateClose()" dark block height="38" color="success">
        <h3>GENERAR CIERRE</h3>
        <v-icon style="margin-left: 20px;" dark right>mdi-check-circle</v-icon>
      </v-btn>
    </v-card>
    <br><br>
  </div>
</template>

<style scoped>
  .closeViewContainer {
    margin: 2% 3% 0% 3%;
  }

  .closeViewInputsContainer {
    padding: 1.5%;
    background-color: #e9e8e8;
  }

  .selectTodayChip {
    margin: 0% 5% 5% 5%;
    text-align: center;
    cursor: pointer;
  }

  .closeViewInformationContainer {
    padding: 1%;
    background-color: #e9e8e8;
  }

  .closeViewInformationMainContainer {
    display: flex;
  }

  .closeViewInformationTableContainer {
    width: 64.5%;
    margin-right: 0.5%;
    min-height: 650px;
    overflow-y: auto;
    padding: 1.5%;
    background-color: #c3c3c3;

  }

  .closeViewInformationResultContainer {
    width: 34.5%;
    margin-left: 0.5%;
  }

  .closeViewInformationLabel strong {
    display: inline-block;
    text-align:left;
  }

  .closeViewInformationResultLabelContainer {
    background-color: #c3c3c3;
    padding: 2%;
  }

  .closeViewInformationLabel {
    font-size: 28px;
  }

  .closeViewInformationTotalLabel {
    font-size: 35px;
  }

  .coinIcon {
    width: 50px; 
    height: 50px;
  }

  .billIcon {
    width: 90px; 
    height: 50px;
  }
  
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CloseView',
  inject: ['$generalFunctions'],

  
  data: () => ({
    input: 
    {
      closingCashAmount: 0,
      closingCardAmount: 0,
      closingSINPEAmount: 0,
      closingDepositAmount: 0,
      closingWebpageAmount: 0,
      closingRappiAmount: 0,
      closingUberAmount: 0,
      closingExpenseAmount: 0
    },

    navigation: 
    {
      startDatePickerIsVisible: false
    },

    loader: 
    {
      selectBillOnCloseView: false,
      generateClose: false
    },


    display:
    {
      startDateFormatted: null,
      billsRows: [],
      billsHeaders: 
      [
        {key: 'billID', title: 'ID'},
        {key: 'billState', title: 'ESTADO'},
        {key: 'billAgentName', title: 'FACTURADOR'},
        {key: 'clientName', title: 'CLIENTE'},
        {key: 'billCreationDatetime', title: 'FECHA'},
        {key: 'billType', title: 'TIPO'},
        {key: 'billPaymentType', title: 'MÉTODO DE PAGO'},
        {key: 'billAmount', title: 'MONTO'},
        {key: 'billAction', title: null}        
      ],
      dolarExchangeRate: null,
      localityPaymentTerminals: [],
      localitySINPEAccounts: [],
      localityDepositAccounts: []
    },

    localStorage: 
    {
      localityID: null,
      agentID: null,
      billStateColors: null
    },

    accessCredential: {}

  }),

  watch: {
    'input.startDate': async function() {
      this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB');
      this.navigation.startDatePickerIsVisible = false;
    }
  },

  methods: {

    openBillDialog(bill){
      const billDialogRequestQuery = 
      {
        'billID': bill.billID
      };
      this.$root.billDialog.openBillDialog(billDialogRequestQuery);
    },

    async openPrintableBillDialog(bill){
      bill.loader = true;
      const createBillPDFDocumentRequestQuery = 
      {
        'billID': bill.billID
      };
      const createBillPDFDocumentResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/functions/createBillPDFDocument', createBillPDFDocumentRequestQuery);
      if (createBillPDFDocumentResult.success){
        this.$root.printableBillDialog.showPrintableBillDialog(createBillPDFDocumentResult.result);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al imprimir la factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      bill.loader = false;
    },

    async generateClose(){
      this.loader.generateClose = true;

      const generateCloseRequestQuery = 
      {
        'closingLocalityID': this.localStorage.localityID,
        'closingAgentID': this.localStorage.agentID,
        'closingRequiredAmount': this.getBillsTotalAmount(),
        'closingRequiredCashAmount': this.getBillPaymentTypeAmount('Efectivo', 'billMixedPaymentCashAmount'),
        'closingCashAmount': this.input.closingCashAmount,
        'closingRequiredCardAmount': this.getBillPaymentTypeAmount('Tarjeta', 'billMixedPaymentCardAmount'),
        'closingCardAmount': this.input.closingCardAmount,
        'closingRequiredSINPEAmount': this.getBillPaymentTypeAmount('SINPE', 'billMixedPaymentSINPEAmount'),
        'closingSINPEAmount': this.input.closingSINPEAmount,
        'closingRequiredDepositAmount': this.getBillPaymentTypeAmount('Transferencia o depósito', 'billMixedPaymentDepositAmount'),
        'closingDepositAmount': this.input.closingDepositAmount,
        'closingRequiredWebpageAmount': this.getBillPaymentTypeAmount('Página web', null),
        'closingWebpageAmount': this.input.closingWebpageAmount,
        'closingRequiredRappiAmount': this.getBillPaymentTypeAmount('Rappi', null),
        'closingRappiAmount': this.input.closingRappiAmount,
        'closingRequiredUberAmount': this.getBillPaymentTypeAmount('Uber', null),
        'closingUberAmount': this.input.closingUberAmount,
        'closingExpenseAmount': this.input.closingExpenseAmount
      };
      const generateCloseResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/functions/generateClose', generateCloseRequestQuery);
      if (generateCloseResult.success){
        
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'Se ha generado el cierre exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': generateCloseResult.result.documentFile.data,
          'printablePDFDocumentName': generateCloseResult.result.documentName,
          'printableExcelDocumentFile': null,
          'printableExcelDocumentName': null
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
        await this.selectBillOnCloseView();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el cierre, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.generateClose = false;
    },
    
    async selectBillOnCloseView(){
      this.loader.selectBillOnCloseView = true;
      const selectBillOnCloseViewRequestQuery = 
      {
        'billLocalityID': this.localStorage.localityID
      };
      const selectBillOnCloseViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/functions/selectBillOnCloseView', selectBillOnCloseViewRequestQuery);
      if (selectBillOnCloseViewResult.success){
        this.display.billsRows = selectBillOnCloseViewResult.result;
        if (this.display.billsRows.length == 0){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': '',
            'notificationDialogBody': 'No se ha encontrado ninguna factura con las características especificadas, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'info',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las facturas, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectBillOnCloseView = false;
    },


    async selectLocalityPaymentTerminalByLocalityPaymentTerminalLocalityID(){
      this.loader.selectLocalityPaymentTerminalByLocalityPaymentTerminalLocalityID = true;
      const selectLocalityPaymentTerminalByLocalityPaymentTerminalLocalityIDRequestQuery = 
      {
        'localityPaymentTerminalLocalityID': this.localStorage.localityID
      };
      const selectLocalityPaymentTerminalByLocalityPaymentTerminalLocalityIDResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/localityPaymentTerminal/select/byLocalityPaymentTerminalLocalityID', selectLocalityPaymentTerminalByLocalityPaymentTerminalLocalityIDRequestQuery);
      if (selectLocalityPaymentTerminalByLocalityPaymentTerminalLocalityIDResult.success){
        this.display.localityPaymentTerminals = selectLocalityPaymentTerminalByLocalityPaymentTerminalLocalityIDResult.result.map(localityPaymentTerminal => 
        ({
          ...localityPaymentTerminal, 
          localityPaymentTerminalInput: null 
        }));
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las terminales de cobro por tarjeta, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectLocalityPaymentTerminalByLocalityPaymentTerminalLocalityID = false;
    },


    async selectLocalitySINPEAccountByLocalitySINPEAccountLocalityID(){
      this.loader.selectLocalitySINPEAccountByLocalitySINPEAccountLocalityID = true;
      const selectLocalitySINPEAccountByLocalitySINPEAccountLocalityIDRequestQuery = 
      {
        'localitySINPEAccountLocalityID': this.localStorage.localityID
      };
      const selectLocalitySINPEAccountByLocalitySINPEAccountLocalityIDResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/localitySINPEAccount/select/byLocalitySINPEAccountLocalityID', selectLocalitySINPEAccountByLocalitySINPEAccountLocalityIDRequestQuery);
      if (selectLocalitySINPEAccountByLocalitySINPEAccountLocalityIDResult.success){
        this.display.localitySINPEAccounts = selectLocalitySINPEAccountByLocalitySINPEAccountLocalityIDResult.result.map(localitySINPEAccount => 
        ({
          ...localitySINPEAccount, 
          localitySINPEAccountInput: null 
        }));
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las cuentas de cobro por SINPE, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectLocalitySINPEAccountByLocalitySINPEAccountLocalityID = false;
    },

    
    async selectLocalityDepositAccountByLocalityDepositAccountLocalityID(){
      this.loader.selectLocalityDepositAccountByLocalityDepositAccountLocalityID = true;
      const selectLocalityDepositAccountByLocalityDepositAccountLocalityIDRequestQuery = 
      {
        'localityDepositAccountLocalityID': this.localStorage.localityID
      };
      const selectLocalityDepositAccountByLocalityDepositAccountLocalityIDResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/localityDepositAccount/select/byLocalityDepositAccountLocalityID', selectLocalityDepositAccountByLocalityDepositAccountLocalityIDRequestQuery);
      if (selectLocalityDepositAccountByLocalityDepositAccountLocalityIDResult.success){
        this.display.localityDepositAccounts = selectLocalityDepositAccountByLocalityDepositAccountLocalityIDResult.result.map(localityDepositAccount => 
        ({
          ...localityDepositAccount, 
          localityDepositAccountInput: null 
        }));
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las cuentas de cobro por depósito o transferencia, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectLocalityDepositAccountByLocalityDepositAccountLocalityID = false;
    },

    async selectDolarExchangeRate(){
      this.display.dolarExchangeRate = await this.$generalFunctions.default.methods.selectDolarExchangeRate();
    },

    getBillPaymentTypeAmount(billPaymentType, billMixedPaymentType){
      var billPaymentTypeAmount = 0;
      for (var billIndex in this.display.billsRows){
        const billRow = this.display.billsRows[billIndex];
        if (billRow.billPaymentType == billPaymentType){
          billPaymentTypeAmount = billPaymentTypeAmount + billRow.billAmount;
        } else if (billRow.billPaymentType == 'Mixto'){
          if (billRow[billMixedPaymentType]){
            billPaymentTypeAmount = billPaymentTypeAmount + billRow[billMixedPaymentType];
          }
        }
      }
      return billPaymentTypeAmount;
    },

    getBillsTotalAmount(){
      var billsTotalAmount = 0;
      for (var billIndex in this.display.billsRows){
        const billRow = this.display.billsRows[billIndex];
        billsTotalAmount = billsTotalAmount + billRow.billAmount;
      }
      return billsTotalAmount;
    },

   
    getBillsReportedTotalAmount(){
      const sumAmounts = (...amounts) => amounts.reduce((total, amount) => total + (isNaN(amount) ? 0 : parseFloat(amount)), 0);

      const total = sumAmounts(
          this.input.closingCashAmount,
          this.input.closingCardAmount,
          this.input.closingSINPEAmount,
          this.input.closingDepositAmount,
          this.input.closingWebpageAmount,
          this.input.closingRappiAmount,
          this.input.closingUberAmount
      );
      return total;
    },

    getMinimumDate(){
      const offsetDays = (this.accessCredential['/close/maxOffsetDays'] + 1) || 2;
      const minimumDate = new Date();
      minimumDate.setDate(minimumDate.getDate() - offsetDays);
      return minimumDate;
    },
    

    setDefaultValues(){
      this.input.startDate = new Date();
      this.navigation.startDatePickerIsVisible = false;
      this.loader.selectBillOnCloseView = false;
      this.loader.selectLocalityPaymentTerminalByLocalityPaymentTerminalLocalityID = false;
      this.loader.selectLocalitySINPEAccountByLocalitySINPEAccountLocalityID = false;
      this.loader.selectLocalityDepositAccountByLocalityDepositAccountLocalityID = false;
      this.display.startDateFormatted = null;
      this.display.billsRows = [];
      this.display.dolarExchangeRate = null;
      this.display.localityPaymentTerminals = [];
      this.display.localitySINPEAccounts = [];
      this.display.localityDepositAccounts = [];
      this.localStorage.localityID = null;
      this.localStorage.billStateColors = null;
    },

    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.localStorage.billStateColors = this.$generalFunctions.default.data.billStateColors;
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);

    },
  },

  async mounted(){
    this.setDefaultValues();
    await this.getLocalStorageData();
    await this.selectBillOnCloseView();
    await this.selectDolarExchangeRate();
  }

});

</script>
