<template>
  <v-dialog v-model="navigation.clientBillDialogIsVisible" width="auto">
    <v-card width="1000" style="padding: 20px 20px 0px 20px;">
      <v-autocomplete v-model="input.clientID" :items="display.clientOptions" :loading="loader.selectClientForClientSearch" :disabled="loader.selectClientForClientSearch" item-title="ac" item-value="id" append-inner-icon="mdi-account" label="SSN o nombre del cliente" variant="solo-filled"></v-autocomplete>
      <div v-if="loader.selectClientFromClientBillDialog" style="text-align: center;">
        <br>
        <v-progress-circular color="info" indeterminate></v-progress-circular>
        <br><br>
      </div>
      <div v-else>
        <div v-if="display.clientBillRows.length != 0">
          <br>
          <div style="padding: 20px; background-color: #e8e8e8">
            <v-data-table-virtual :headers="display.clientBillHeaders" :items="display.clientBillRows">
              <template v-slot:item.billCreationDatetime="{ item }">
                {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.billCreationDatetime) }}
              </template>
              <template v-slot:item.localityName="{ item }">
                <v-chip :color="item.localityColor" variant="flat">
                  <p :style="{color: item.localityFontColor}">{{ item.localityName }}</p>
                </v-chip>
              </template>
              <template v-slot:item.agentName="{ item }">
                <v-chip :color="item.agentColor" variant="flat">
                  <p :style="{color: item.agentFontColor}">{{ item.agentName }}</p>
                </v-chip>
              </template>
              <template v-slot:item.billProducts="{ item }">
                <v-icon @click="openBillProductsDialog(item.billProducts)" color="info" dark right>mdi-cart</v-icon>
              </template>
            </v-data-table-virtual>
          </div>
          <br>
        </div>
      </div>
    </v-card>
  </v-dialog>

  <v-dialog v-model="navigation.billProductsDialogIsVisible" width="auto">
    <v-card width="700" style="padding: 20px 20px 20px 20px;">
      <div style="padding: 20px; background-color: #e8e8e8">
        <v-data-table-virtual :headers="display.billProductHeaders" :items="display.billProducts">
        </v-data-table-virtual>
      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped>
</style>

<script>

export default {
  name: "ClientBillDialog",
  inject: ['$generalFunctions'],

  data: () => ({

    input:
    {
      clientID: null
    },

    display: 
    {
      clientOptions: [],
      clientBillHeaders: 
      [
        {key: 'billID', title: 'ID'},
        {key: 'billCreationDatetime', title: 'FECHA'},
        {key: 'localityName', title: 'LOCALIDAD'},
        {key: 'agentName', title: 'VENDEDOR'},
        {key: 'billProducts', title: 'PRODUCTOS'}
      ],
      clientBillRows: [],
      billProductHeaders:
      [
        {key: 'productSKU', title: 'SKU'},
        {key: 'productName', title: 'NOMBRE'},
        {key: 'billProductAmount', title: 'CANTIDAD'}
      ],
      billProducts: []
    },

    navigation: 
    {
      clientBillDialogIsVisible: false,
      billProductsDialogIsVisible: false
    },

    loader:
    {
      selectClientForClientSearch: false,
      selectClientFromClientBillDialog: false
    },

    assets:
    {
    },

    localStorage: 
    {
    },

    accessCredential: {}

  }),

  watch: {
    async 'input.clientID'(){
      await this.selectClientFromClientBillDialog();
    }
  },

  methods: {
    
    async selectClientFromClientBillDialog(){
      this.loader.selectClientFromClientBillDialog = true;
      this.display.clientBillRows.length = 0;
      const selectClientFromClientBillDialogRequestQuery = 
      {
        'clientID': this.input.clientID
      };
      const selectClientFromClientBillDialogResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/client/functions/selectClientFromClientBillDialog', selectClientFromClientBillDialogRequestQuery);
      if (selectClientFromClientBillDialogResult.success){
        this.display.clientBillRows = selectClientFromClientBillDialogResult.result;
        if (this.display.clientBillRows.length == 0){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': null,
            'notificationDialogBody': 'El cliente no tiene compras registradas',
            'notificationDialogColor': 'info',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar el historial del cliente, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectClientFromClientBillDialog = false;
    },

    async selectClientForClientSearch(){
      this.loader.selectClientForClientSearch = true;
      const selectClientForClientSearchResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/client/functions/selectClientForClientSearch');
      if (selectClientForClientSearchResult.success){
        this.display.clientOptions = selectClientForClientSearchResult.result;
        this.loader.selectClientForClientSearch = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de clientes, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    openBillProductsDialog(billProducts){
      this.display.billProducts = billProducts;
      this.navigation.billProductsDialogIsVisible = true;
    },

    async setDefaultValues() {
      this.input.clientID = null;
      this.display.clientBillRows.length = 0;
      this.navigation.billProductsDialogIsVisible = false;
    },

    async openClientBillDialog() {
      await this.setDefaultValues();
      await this.selectClientForClientSearch();
      this.navigation.clientBillDialogIsVisible = true;
    }
  },

  mounted(){
  }
}
</script>