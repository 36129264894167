import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '../views/LoginView.vue';
import BillView from '../views/BillView.vue';

import BillsView from '../views/BillsView.vue';
import CloseView from '../views/CloseView.vue';
import ClosesView from '../views/ClosesView.vue';

import TopNavigationBarView from '../views/TopNavigationBarView.vue';

import ProductsView from '@/views/ProductsView.vue';
import ProductInAndOutView from '../views/ProductInAndOutView.vue';
import ProductTransferView from '../views/ProductTransferView.vue';
import ProductDailySaleView from '../views/ProductDailySaleView.vue';
import ProductTendencySaleView from '../views/ProductTendencySaleView.vue';
import ProductStockView from '../views/ProductStockView.vue';
import ProductLowStockView from '../views/ProductLowStockView.vue';

import ProductHistoryView from '@/views/ProductHistoryView.vue';
import ProductCompareView from '@/views/ProductCompareView.vue';

import ProductChangeView from '@/views/ProductChangeView.vue';

import AgentView from '../views/AgentView.vue';

import LocalityView from '@/views/LocalityView.vue';
import LocalityExpenseView from '../views/LocalityExpenseView.vue';
import OperationalExpenseView from '../views/OperationalExpenseView.vue';


import TokenView from '@/views/TokenView.vue';


import SessionExpiredView from '../views/SessionExpiredView.vue';
import PageNotFoundView from '../views/PageNotFoundView.vue';
import CallcenterView from '@/views/CallcenterView.vue';
import BillPaymentValidationView from '@/views/BillPaymentValidationView.vue';

import MasterDashboardView from '@/views/MasterDashboardView.vue';
import OperationDashboardView from '@/views/OperationDashboardView.vue';
import StoreAdminDashboardView from '@/views/StoreAdminDashboardView.vue';
import WarehouseDashboardView from '@/views/WarehouseDashboardView.vue';
import WholesaleDashboardView from '@/views/WholesaleDashboardView.vue';
import OfficeDashboardView from '@/views/OfficeDashboardVue.vue';



import ProductView from '@/views/ProductView.vue';


import ProformasView from '../views/ProformasView.vue';
import ContactsView from '../views/ContactsView.vue';
import PendingPaymentsView from '@/views/PendingPaymentsView.vue';
import ContactView from '../views/ContactView.vue';
import AgentSaleDashboardView from '@/views/AgentSaleDashboardView.vue';
import ProductSaleView from '@/views/ProductSaleView.vue';

import ProductProviderView from '@/views/ProductProviderView.vue';


const routes = [
  {
    path: '/',
    name: 'loginView',
    component: LoginView
  },
  {
    path: '/sessionExpired',
    name: 'sessionExpired',
    component: SessionExpiredView
  },
  {
    path: '/',
    name: 'topNavigationBarView',
    component: TopNavigationBarView,
    children: 
    [
      {path: '/bill/:parameters?', component: BillView},

      {path: '/bills/:subroute*/:parameters?', component: BillsView},
      {path: '/callCenter', component: CallcenterView},
      {path: '/billPaymentValidation', component: BillPaymentValidationView},
      
      {path: '/close', component: CloseView},
      {path: '/closes', component: ClosesView},

      {path: '/products', component: ProductsView},
      {path: '/productInAndOut', component: ProductInAndOutView},
      {path: '/productTransfer', component: ProductTransferView},
      {path: '/productSale', component: ProductSaleView},

      {path: '/productDailySale', component: ProductDailySaleView},

      {path: '/productTendencySale/:subroute*', component: ProductTendencySaleView},
      {path: '/productStock', component: ProductStockView},
      {path: '/productLowStock', component: ProductLowStockView},

      {path: '/productHistory/:subroute*', component: ProductHistoryView},
      {path: '/productCompare', component: ProductCompareView},

      {path: '/productChange', component: ProductChangeView},
      {path: '/product/:subroute*', component: ProductView},

      {path: '/agent', component: AgentView},
      {path: '/locality', component: LocalityView},
      {path: '/localityExpense/:subroute*', component: LocalityExpenseView},
      {path: '/operationalExpense', component: OperationalExpenseView},



      {path: '/dashboard/master', component: MasterDashboardView},
      {path: '/dashboard/operation', component: OperationDashboardView},
      {path: '/dashboard/agentSale', component: AgentSaleDashboardView},

      {path: '/dashboard/storeAdmin', component: StoreAdminDashboardView},
      {path: '/dashboard/warehouse', component: WarehouseDashboardView},
      {path: '/dashboard/wholesale', component: WholesaleDashboardView},
      {path: '/dashboard/office', component: OfficeDashboardView},


      {path: '/token', component: TokenView},

      {path: '/proformas', component: ProformasView},
    
      {path: '/contacts', component: ContactsView},
      {path: '/pendingPayments', component: PendingPaymentsView},

      {path: '/contact/:subroute+', component: ContactView},


      {path: '/productProvider', component: ProductProviderView},

    ]
  },
  {
    path: '/:catchAll(.*)',
    name: 'pageNotFoundView',
    component: PageNotFoundView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
