<template>
  <div style="margin: 2% 3% 0% 3%;">
    <v-card style="padding: 20px; background-color: #e9e8e8;" elevation="3">
      <v-autocomplete v-model="input.selectedLocalityID" :items="display.localityOptions" :loading="loader.selectLocalityIDAndLocalityName" item-title="localityName" item-value="localityID" append-inner-icon="mdi-store" label="Localidad involucrada" variant="solo-filled"></v-autocomplete>

      <v-text-field v-model="display.startDateFormatted" @click="navigation.startDatePickerIsVisible = true" readonly label="Filtrar por fecha inicial" append-inner-icon="mdi-calendar" style="cursor: pointer;" variant="solo-filled"></v-text-field>
      <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
        <v-card style="display: flex;">
          <v-date-picker v-model="input.startDate" hide-header color="primary" width="100%"></v-date-picker>
          <v-chip @click="input.startDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
        </v-card>
      </v-dialog>
      <v-text-field v-model="display.endDateFormatted" @click="navigation.endDatePickerIsVisible = true" readonly label="Filtrar por fecha final" append-inner-icon="mdi-calendar" style="cursor: pointer;" variant="solo-filled"></v-text-field>
      <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
        <v-card>
          <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
          <v-chip @click="input.endDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
        </v-card>
      </v-dialog>
      <v-select v-model="input.selectedProductTransferState" :items="display.productTransferStateOptions" label="Filtrar por estado del traslado" append-inner-icon="mdi-clock" variant="solo-filled"></v-select>
      <br>
      <div v-if="loader.selectProductTransferOnProductTransferView" style="text-align: center;">
        <v-progress-circular color="info" indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <v-btn @click="selectProductTransferOnProductTransferView()" block dark height="38" color="primary">
          <h3>BUSCAR</h3>
          <v-icon style="margin-left: 20px;" dark right>mdi-magnify</v-icon>
        </v-btn>
      </div>
    </v-card>
    <div v-if="display.productTransferRows.length > 0">
      <br><br>
      <v-card style="padding: 20px; background-color: #e9e8e8;" elevation="3">
        <v-data-table-virtual :headers="display.productTransferHeaders" :items="display.productTransferRows">
          <template v-slot:item.productTransferState="{ item }">
            <v-chip :color="localStorage.productTransferStateColors[item.productTransferState].productTransferStateColor" variant="flat">
              <p :style="{color: localStorage.productTransferStateColors[item.productTransferState].productTransferStateFontColor}">{{ item.productTransferState }}</p>
            </v-chip>
          </template>
          <template v-slot:item.sendLocalityName="{ item }">
            <v-chip :color="item.sendLocalityColor" variant="flat">
              <p :style="{color: item.sendLocalityFontColor}">{{ item.sendLocalityName }}</p>
            </v-chip>
          </template>
          <template v-slot:item.receiveLocalityName="{ item }">
            <v-chip :color="item.receiveLocalityColor" variant="flat">
              <p :style="{color: item.receiveLocalityFontColor}">{{ item.receiveLocalityName }}</p>
            </v-chip>
          </template>
          <template v-slot:item.productTransferSendDatetime="{ item }">
            {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.productTransferSendDatetime) }}
          </template>
          <template v-slot:item.sendAgentName="{ item }">
            <v-chip :color="item.sendAgentColor" variant="flat">
              <p :style="{color: item.sendAgentFontColor}">{{ item.sendAgentName }}</p>
            </v-chip>
          </template>
          <template v-slot:item.productTransferSendNote="{ item }">
            {{ item.productTransferSendNote || 'Sin nota al enviar' }}
          </template>
          <template v-slot:item.transporterAgentName="{ item }">
            <v-chip :color="item.transporterAgentColor" variant="flat">
              <p :style="{color: item.transporterAgentFontColor}">{{ item.transporterAgentName }}</p>
            </v-chip>
          </template>
          <template v-slot:item.receiveAgentName="{ item }">
            {{ item.receiveAgentName || 'El traslado no ha sido recibido' }}
          </template>
          <template v-slot:item.productTransferReceiveDatetime="{ item }">
            {{ item.productTransferReceiveDatetime ? $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.productTransferReceiveDatetime) : 'El traslado no ha sido recibido' }}
          </template>
          <template v-slot:item.productTransferReceiveNote="{ item }">
            {{ item.receiveAgentName ? (item.productTransferReceiveNote || 'Sin nota al recibir') : 'El traslado no ha sido recibido' }}
          </template>
          <template v-slot:item.productTransferUpdateAction="{ item }">
            <div v-if="item.productTransferState != 'Traslado aprobado' && item.productTransferState != 'Traslado rechazado'" style="display: flex;">
              <v-btn @click="openUpdateProductTransferDialog(item, 'Aceptar')" dark height="38" color="success">
                <h3>ACEPTAR</h3>
              </v-btn>
              <v-btn @click="openUpdateProductTransferDialog(item, 'Rechazar')" dark height="38" color="error" style="margin-left: 10px;">
                <h3>RECHAZAR</h3>
              </v-btn>
            </div>
            <div v-else>
              <p>El traslado ha sido {{ item.productTransferResult == 'Aceptar' ? 'aprobado' : 'rechazado' }}</p>
            </div>
          </template>
          <template v-slot:item.productTransferPrintAction="{ item }">
            <div v-if="item.loader" style="text-align: center;">
              <v-progress-circular color="success" indeterminate></v-progress-circular>
            </div>
            <div v-else style="display: flex;">
              
              <v-icon @click="openProductTransferDialog(item.productTransferID)" color="info">mdi-magnify</v-icon>
              <v-icon @click="createPDFDocumentForProductTransfer(item)" color="success" style="margin-left: 10px;">mdi-printer</v-icon>
            </div>
          </template>
        </v-data-table-virtual>
      </v-card>
    </div>
    <br><br>
  </div>
</template>

<style scoped>
</style>

<script>
import { defineComponent } from 'vue';
import { viewMethodCaller } from '../viewMethodCaller.js';

export default defineComponent({
  name: 'ProductTransferView',
  inject: ['$generalFunctions'],

  data: () => ({

    input: 
    {
      selectedLocalityID: null,
      startDate: null,
      endDate: null,
      selectedProductTransferState: null,
    },

    display: 
    {
      startDateFormatted: '',
      endDateFormatted: '',
      productTransferStateOptions: 
      [
        {value: null, title: 'Todos los estados'},
        {value: 'Traslado solicitado', title: 'Traslado solicitado'},
        {value: 'Traslado aprobado', title: 'Traslado aprobado'},
        {value: 'Traslado rechazado', title: 'Traslado rechazado'}      
      ],
      productTransferRows: [],
      productTransferHeaders: 
      [
        {key: 'productTransferState', title: 'ESTADO'},
        {key: 'sendLocalityName', title: 'ENVIADO DESDE'},
        {key: 'receiveLocalityName', title: 'ENVIADO A'},
        {key: 'productTransferSendDatetime', title: 'ENVIADO EL'},
        {key: 'sendAgentName', title: 'ENVIADO POR'},
        {key: 'productTransferSendNote', title: 'NOTA AL ENVIAR'},
        {key: 'receiveAgentName', title: 'RECIBIDO POR'},
        {key: 'productTransferReceiveDatetime', title: 'RECIBIDO EL'},
        {key: 'productTransferReceiveNote', title: 'NOTA AL RECIBIR'},
        {key: 'productTransferUpdateAction', title: 'RECIBIR'},
        {key: 'productTransferPrintAction', title: null}
      ],
      productTransferProducts: [],
    },

    loader: 
    {
      selectLocalityIDAndLocalityName: false,
      selectProductTransferOnProductTransferView: false
    },

    navigation: 
    {
      productTransferProductsDialogIsVisible: false
    },

    accessCredential: {},

    localStorage:
    {
      localityID: null,
      agentID: null,
      productTransferStateColors: null
    }

  }),

  watch: {
    'input.startDate': function() {
      this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB');
      this.navigation.startDatePickerIsVisible = false;
      this.display.productTransferRows.length = 0;
    },
    'input.endDate': function() {
      this.display.endDateFormatted = new Date(this.input.endDate).toLocaleDateString('en-GB');
      this.navigation.endDatePickerIsVisible = false;
      this.display.productTransferRows.length = 0;
    }
  },

  methods: {
    async selectProductTransferOnProductTransferView(){
      this.display.productTransferRows.length = 0;
      this.loader.selectProductTransferOnProductTransferView = true;
      const selectProductTransferOnProductTransferViewRequestQuery =
      {
        'productTransferLocalityID': this.input.selectedLocalityID,
        'productTransferState': this.input.selectedProductTransferState,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate
      };
      const selectProductTransferOnProductTransferViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productTransfer/functions/selectProductTransferOnProductTransferView', selectProductTransferOnProductTransferViewRequestQuery);
      if (selectProductTransferOnProductTransferViewResult.success){
        if (selectProductTransferOnProductTransferViewResult.result.length > 0){
          this.display.productTransferRows = selectProductTransferOnProductTransferViewResult.result;
          this.display.productTransferRows.forEach(productTransferRow => {
            productTransferRow.productTransferProducts.forEach(productTransferProduct => {
              productTransferProduct.productTransferProductReceiveAmount = productTransferProduct.productTransferProductSendAmount;
            });
          });
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ATENCIÓN',
            'notificationDialogBody': 'No se ha encontrado ningún traslado con los filtros seleccionados, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los traslados, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectProductTransferOnProductTransferView = false;
    },

    async selectLocalityIDAndLocalityName(){
      this.loader.selectLocalityIDAndLocalityName = true;
      const selectLocalityIDAndLocalityNameResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityName');
      if (selectLocalityIDAndLocalityNameResult.success){
        this.display.localityOptions = selectLocalityIDAndLocalityNameResult.result;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectLocalityIDAndLocalityName = false;
    },

    async createPDFDocumentForProductTransfer(productTransfer){
      productTransfer.loader = true;
      const createPDFDocumentForProductTransferRequestQuery =
      {
        'productTransferID': productTransfer.productTransferID,
        'reprint': true
      };
      const createPDFDocumentForProductTransferResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productTransfer/functions/createPDFDocumentForProductTransfer', createPDFDocumentForProductTransferRequestQuery);
      if (createPDFDocumentForProductTransferResult.success){
        productTransfer.PDFDocumentFile = createPDFDocumentForProductTransferResult.result.documentFile.data;
        productTransfer.PDFDocumentName = createPDFDocumentForProductTransferResult.result.documentName;
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': productTransfer.PDFDocumentFile,
          'printablePDFDocumentName': productTransfer.PDFDocumentName,
          'printableExcelDocumentFile': null,
          'printableExcelDocumentName': null
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el reporte del traslado de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      productTransfer.loader = false;
    },

    openUpdateProductTransferDialog(productTransfer, productTransferResult){
      const updateProductTransferDialogRequestQuery =
      {
        'productTransferResult': productTransferResult,
        'productTransferID': productTransfer.productTransferID,
        'productTransferProducts': productTransfer.productTransferProducts
      };
      this.$root.updateProductTransferDialog.openUpdateProductTransferDialog(updateProductTransferDialogRequestQuery);
    },

    openProductTransferDialog(productTransferID){
      const productTransferDialogRequestQuery =
      {
        'productTransferID': productTransferID
      };
      this.$root.productTransferDialog.openProductTransferDialog(productTransferDialogRequestQuery);
    },

    listenToViewMethodCaller(){
      viewMethodCaller.on('ProductTransferView/methods/selectProductTransferOnProductTransferView()', this.selectProductTransferOnProductTransferView);
    },

    setDefaultValues(){
      this.input.selectedLocalityID = null;
      this.input.startDate = new Date(Date.now());
      this.input.endDate = new Date(Date.now());
    },

    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.localStorage.productTransferStateColors = this.$generalFunctions.default.data.productTransferStateColors;
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    }
  },

  async mounted(){
    this.listenToViewMethodCaller();
    await this.getLocalStorageData();
    await this.selectLocalityIDAndLocalityName();
    this.setDefaultValues();
  }

});

</script>