<template>
  <div style="margin: 2% 3% 0% 3%;">
    <v-card style="padding: 20px; background-color: #e9e8e8;" elevation="3">
      <v-expansion-panels v-model="navigation.searchVisible">
        <v-expansion-panel value="searchVisible">
          <v-expansion-panel-title>
            <p style="font-size: large;">Búsqueda de cierres de caja</p>
            <template v-slot:actions>
              <v-icon icon="mdi-magnify"></v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <br>
            <div style="display: flex;">
              <div style="width: 49%; margin-right: 1%;">
                <v-select v-model="input.localityID" :items="display.localityOptions" :loading="loader.localityOptions" :disabled="loader.localityOptions" item-title="localityName" item-value="localityID" append-inner-icon="mdi-store" label="Filtrar por localidad" variant="solo-filled"></v-select>
              </div>
              <div style="width: 49%; margin-left: 1%;">
                <v-select v-model="input.agentID" :items="display.agentOptions" :loading="loader.agentOptions" :disabled="loader.agentOptions" item-title="agentName" item-value="agentID" append-inner-icon="mdi-account" label="Filtrar por agente generador" variant="solo-filled"></v-select>
              </div>
            </div>
            <div style="display: flex;">
              <div style="width: 49%; margin-right: 1%;">
                <v-text-field v-model="display.startDateFormatted" @click="navigation.startDatePickerIsVisible = true" readonly label="Filtrar por fecha inicial" append-inner-icon="mdi-calendar" variant="solo-filled"></v-text-field>
                <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
                  <v-card style="display: flex;">
                    <v-date-picker v-model="input.startDate" hide-header color="primary" width="100%"></v-date-picker>
                    <v-chip @click="input.startDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
                  </v-card>
                </v-dialog>
              </div>
              <div style="width: 49%; margin-left: 1%;">
                <v-text-field v-model="display.endDateFormatted" @click="navigation.endDatePickerIsVisible = true" readonly label="Filtrar por fecha final" append-inner-icon="mdi-calendar" variant="solo-filled"></v-text-field>
                <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
                  <v-card>
                    <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
                    <v-chip @click="input.endDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
                  </v-card>
                </v-dialog>
              </div>
            </div>
            <br><br>
            <div v-if="loader.selectCloseFromClosesView" style="text-align: center;">
              <br>
              <v-progress-circular color="info" indeterminate></v-progress-circular>
              <br>
            </div>
            <div v-else>
              <v-btn @click="selectCloseFromClosesView()" dark block height="38" color="primary">
                <h3>BUSCAR</h3>
                <v-icon style="margin-left: 20px;" dark right>mdi-magnify</v-icon>
              </v-btn>
              <br>
              <v-btn @click="setDefaultValues()" dark block height="38" color="#ffd978">
                <h3>LIMPIAR</h3>
                <v-icon style="margin-left: 20px;" dark right>mdi-backspace</v-icon>
              </v-btn>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    
    <div v-if="display.closing.length > 0">
      <br><br>
      <v-card style="max-height: 1000px; overflow-y: auto; padding: 20px; background-color: #e9e8e8;" elevation="3">
        <v-data-table-virtual :headers="display.closingHeaders" :items="display.closing">
          <template v-slot:item.localityName="{ item }">
            <v-chip :color="item.localityColor" variant="flat">
              <p :style="{color: item.localityFontColor}">{{ item.localityName }}</p>
            </v-chip>
          </template>
          <template v-slot:item.agentName="{ item }">
            <v-chip :color="item.agentColor" variant="flat">
              <p :style="{color: item.agentFontColor}">{{ item.agentName }}</p>
            </v-chip>
          </template>
          <template v-slot:item.closingDatetime="{ item }">
            {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.closingDatetime) }}
          </template>
          <template v-slot:item.closingAction="{ item }">
            <v-icon @click="createPDFDocumentForClosing(item.closingID)" color="success" style="cursor: pointer;">mdi-printer</v-icon>
          </template>
        </v-data-table-virtual>
      </v-card>
      <br><br>
    </div>
  </div>
</template>

<style scoped>
  
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ClosesView',
  inject: ['$generalFunctions'],

  
  data: () => ({
    input: 
    {
      localityID: null,
      agentID: null,
      startDate: null,
      endDate: null
    },

    navigation: 
    {
      searchVisible: 'searchVisible',
      startDatePickerIsVisible: false,
      endDatePickerIsVisible: false
    },

    loader: 
    {
      localityOptions: false,
      agentOptions: false,
      selectCloseFromClosesView: false
    },


    display:
    {
      localityOptions: [],
      agentOptions: [],
      startDateFormatted: null,
      endDateFormatted: null,
      closing: [],
      closingHeaders: 
      [
        {key: 'closingID', title: 'ID'},
        {key: 'closingDatetime', title: 'FECHA Y HORA'},
        {key: 'localityName', title: 'LOCALIDAD'},
        {key: 'agentName', title: 'GENERADO POR'},
        {key: 'closingAction', title: null}
      ],
    },

    localStorage: 
    {
    },

    accessCredential: {}

  }),

  watch: {
    'input.localityID': async function(){
      await this.selectAgentByAgentLocalityIDAndByAgentIsActive();
    },
    'input.startDate': async function() {
      if (this.input.startDate){
        this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB');
        this.navigation.startDatePickerIsVisible = false;
      } else {
        this.display.startDateFormatted = null;
      }
    },
    'input.endDate': async function() {
      if (this.input.endDate){
        this.display.endDateFormatted = new Date(this.input.endDate).toLocaleDateString('en-GB');
        this.navigation.endDatePickerIsVisible = false;
      } else {
        this.display.endDateFormatted = null;
      }
    },
  },

  methods: {

    async createPDFDocumentForClosing(closingID){
      const createPDFDocumentForClosingRequestQuery = 
      {
        'closingID': closingID
      };
      const createPDFDocumentForClosingResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/functions/createPDFDocumentForClosing', createPDFDocumentForClosingRequestQuery);
      if (createPDFDocumentForClosingResult.success){
       
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': createPDFDocumentForClosingResult.result.documentFile.data,
          'printablePDFDocumentName': createPDFDocumentForClosingResult.result.documentName,
          'printableExcelDocumentFile': null,
          'printableExcelDocumentName': null
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al reimprimir el cierre de caja, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectLocalityIDAndLocalityNameByLocalityIsActive(){
      this.loader.localityOptions = true;
      const selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery = {'localityIsActive': true};
      const selectLocalityIDAndLocalityNameByLocalityIsActiveResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityNameByLocalityIsActive', selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery);
      if (selectLocalityIDAndLocalityNameByLocalityIsActiveResult.success){
        this.display.localityOptions = 
        [
          {'localityID': null, 'localityName': 'Todas las localidades'}, 
          ...selectLocalityIDAndLocalityNameByLocalityIsActiveResult.result
        ];
        this.loader.localityOptions = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectAgentByAgentLocalityIDAndByAgentIsActive(){
      this.loader.agentOptions = true;
      const selectAgentByAgentLocalityIDAndByAgentIsActiveRequestQuery = 
      {
        'agentLocalityID': this.input.localityID, 
        'agentIsActive': true
      };
      const selectAgentByAgentLocalityIDAndByAgentIsActiveResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/select/byAgentLocalityIDAndByAgentIsActive', selectAgentByAgentLocalityIDAndByAgentIsActiveRequestQuery);
      if (selectAgentByAgentLocalityIDAndByAgentIsActiveResult.success){
        this.display.agentOptions = 
        [
          {'agentID': null, 'agentName': 'Todos los agentes'},
          ...selectAgentByAgentLocalityIDAndByAgentIsActiveResult.result
        ];
        if (!this.display.agentOptions.map(agentOption => agentOption.agentID).includes(this.input.agentID)){
          this.input.agentID = null;
        }
        this.loader.agentOptions = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de facturadores, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectCloseFromClosesView(){
      this.loader.selectCloseFromClosesView = true;
      this.display.closing.length = 0;
      const selectCloseFromClosesViewRequestQuery = 
      {
        'closingLocalityID': this.input.localityID, 
        'closingAgentID': this.input.agentID,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate
      };
      const selectCloseFromClosesViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/functions/selectCloseFromClosesView', selectCloseFromClosesViewRequestQuery);
      if (selectCloseFromClosesViewResult.success){
        this.display.closing = selectCloseFromClosesViewResult.result;
        this.navigation.searchVisible = '';
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar el historial de cierres de caja, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectCloseFromClosesView = false;
    },

    async setDefaultValues(){
      this.input.localityID = null;
      this.input.agentID = null;
      this.input.startDate = null;
      this.input.endDate = null;
      this.display.startDateFormatted = null;
      this.display.endDateFormatted = null;
      this.display.closing.length = 0;
      this.navigation.startDatePickerIsVisible = false;
      this.navigation.endDatePickerIsVisible = false;
      this.loader.selectCloseFromClosesView = false;
    }


  },

  async mounted(){
    await this.selectLocalityIDAndLocalityNameByLocalityIsActive();
    await this.selectAgentByAgentLocalityIDAndByAgentIsActive();
  }

});

</script>
