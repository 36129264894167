<template>
  <div style="margin: 2% 3% 0% 3%;">
    <v-card elevation="3" style="padding: 20px; background-color: #e9e8e8;">
      <v-expansion-panels v-model="navigation.searchVisible">
        <v-expansion-panel value="searchVisible">
          <v-expansion-panel-title>
            <p style="font-size: large;">Búsqueda de inventario de productos</p>
            <template v-slot:actions>
              <v-icon icon="mdi-magnify"></v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <br>
            <div v-if="accessCredential['/productStock/selectLocality']" style="display: flex" >
              <div style="width: 90%;">
                <v-select v-model="input.selectedLocalityIDS" :items="display.localityOptions" :loading="loader.selectLocalityIDAndLocalityName" multiple chips item-title="localityName" item-value="localityID" append-inner-icon="mdi-store" label="Localidad" variant="solo-filled"></v-select>
              </div>
              <div style="width: 9%; margin-left: 1%;">
                <v-checkbox @click="selectAllLocality()" v-model="input.allLocalitySelected" color="info" label="Todas las localidades" hide-details></v-checkbox>
              </div>
            </div>
            <v-autocomplete 
              @keydown.enter.prevent="selectProductID" 
              v-model="input.selectedProductID" 
              v-model:search="input.searchedProductID"
              :items="display.productOptions" 
              :loading="loader.selectProductBasicBasicInformationForBillsView" 
              :disabled="loader.selectProductBasicBasicInformationForBillsView" 
              ref="selectedProductIDInput"
              item-title="productAutocompleteTitle" 
              item-value="productID" 
              append-inner-icon="mdi-alpha-a-box-outline" 
              label="Filtrar por producto" 
              variant="solo-filled"
            ></v-autocomplete>
            <div style="display: flex;">
              <div style="width: 49.5%; margin-right: 0.5%;">
                <v-autocomplete v-model="input.selectedProductCategory" :items="display.productCategoryOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productCategoryName" item-value="productCategoryID" append-inner-icon="mdi-cog" label="Filtrar por categoría del producto" variant="solo-filled"></v-autocomplete>
                <v-autocomplete v-model="input.selectedProductBrand" :items="display.productBrandOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productBrandName" item-value="productBrandID" append-inner-icon="mdi-bookshelf" label="Filtrar por marca del producto" variant="solo-filled"></v-autocomplete>
                <v-autocomplete v-model="input.selectedProductModel" :items="display.productModelOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productModelName" item-value="productModelID" append-inner-icon="mdi-source-branch" label="Filtrar por modelo del producto" variant="solo-filled"></v-autocomplete>
              </div>
              <div style="width: 49.5%; margin-left: 0.5%;">
                <v-autocomplete v-model="input.selectedProductFlavor" :items="display.productFlavorOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productFlavorName" item-value="productFlavorID" append-inner-icon="mdi-water" label="Filtrar por sabor del producto" variant="solo-filled"></v-autocomplete>
                <v-autocomplete v-model="input.selectedProductNicotineLevel" :items="display.productNicotineLevelOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productNicotineLevelName" item-value="productNicotineLevelID" append-inner-icon="mdi-weather-cloudy" label="Filtrar por graduación de nicotina del producto" variant="solo-filled"></v-autocomplete>
                <v-autocomplete v-model="input.selectedProductSize" :items="display.productSizeOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productSizeName" item-value="productSizeID" append-inner-icon="mdi-ruler" label="Filtrar por tamaño del producto" variant="solo-filled"></v-autocomplete>
              </div>
            </div>
            <br><br>
            <div v-if="loader.selectProductStockOnProductStockView" style="text-align: center;">
              <v-progress-circular color="info" indeterminate></v-progress-circular>
            </div>
            <div v-else>
              <v-btn @click="selectProductStockOnProductStockView()" :disabled="loader.selectProductStockOnProductStockView" dark block height="38" color="primary">
                <h3>BUSCAR</h3>
                <v-icon style="margin-left: 20px;" dark right>mdi-magnify</v-icon>
              </v-btn>
              <br>
              <v-btn @click="setDefaultValues()" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive || loader.selectAgentByAgentLocalityIDAndByAgentIsActive || loader.selectProductBasicBasicInformationForBillsView || loader.selectProductBasicBasicInformationForBillsView" dark block height="38" color="#ffd978">
                <h3>LIMPIAR</h3>
                <v-icon style="margin-left: 20px;" dark right>mdi-backspace</v-icon>
              </v-btn>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <br><br>
    <v-card v-if="display.productRows.length != 0" elevation="3" style="padding: 20px; background-color: #e9e8e8;">
      <br>
      <div v-if="loader.generateReportOnProductStockView" style="text-align: center;">
        <v-progress-circular color="success" indeterminate></v-progress-circular>
        <br><br>
      </div>
      <div v-else>
        <div v-if="accessCredential['/productStock/generateReport']">
          <v-btn @click="generateReportOnProductStockView()" dark block height="38" color="success">
            <h3>GENERAR REPORTE</h3>
            <v-icon style="margin-left: 20px;" dark right>mdi-printer</v-icon>
          </v-btn>
          <br><br>
          <p style="font-size: x-large;"><strong>Unidades totales: </strong> {{ display.productRows.reduce((productStockTotalAmount, product) => productStockTotalAmount + product.productStockTotal, 0) }} </p>
          <div style="display: flex;">
            <p style="font-size: x-large;"><strong>Precio de costo total: </strong></p>
            <p v-if="navigation.productCostTotalAmountIsVisible" class="fadeOutClass" style="font-size: x-large; margin-left: 8px;"> ₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(display.productRows.reduce((productCostTotalAmount, product) => productCostTotalAmount + (product.productStockTotal * product.productCost), 0)) }} </p>
            <v-icon v-else @click="showProductCostTotalAmount()" size="x-large" style="margin-left: 10px; margin-top: 2px; cursor: pointer;">mdi-eye</v-icon>
          </div>
          <br><br>
        </div>
        <v-data-table-virtual :headers="display.productHeaders" :items="display.productRows" style="max-height: 700px; overflow-y: auto;">
          <template v-slot:item.productSelected="{ item }">
            <v-checkbox v-model="item.productSelected" color="info" hide-details></v-checkbox>
          </template>
          <template v-slot:item.productStock="{ item }">
            <div style="display: flex">
              <div v-for="productStockByLocality in item.productStock" v-bind:key="productStockByLocality">
                <v-tooltip :text="productStockByLocality.localityName" location="top">
                  <template v-slot:activator="{ props }">
                    <v-chip v-bind="props" :color="productStockByLocality.localityColor" variant="flat" style="margin: 0px 5px 0px 5px; cursor: pointer;">
                      <p :style="{color: productStockByLocality.localityFontColor}" style="font-size: x-large;"><strong>{{ productStockByLocality.productStockAmount }}</strong></p>
                    </v-chip>
                  </template>
                </v-tooltip>
              </div>
              <div v-if="input.selectedLocalityIDS.length != 1">
                <v-tooltip :text="(input.selectedLocalityIDS.length != 0 && input.selectedLocalityIDS.length != display.localityOptions.length) ? 'Total (de las localidades seleccionadas)' : 'Total'" location="top">
                  <template v-slot:activator="{ props }">
                    <v-chip v-bind="props" color="black" variant="flat" style="margin: 0px 5px 0px 5px; cursor: pointer;">
                      <p style="color: white; font-size: x-large;"><strong>{{ item.productStockTotal }}</strong></p>
                    </v-chip>
                  </template>
                </v-tooltip>
              </div>
            </div>
          </template>
          <template v-slot:item.productCost="{ item }">
            ₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(item.productCost) }}
          </template>
        </v-data-table-virtual>
      </div>
    </v-card>
    <br><br>
  </div>
</template>

<style scoped>
  .fadeOutClass {
    animation: fadeOut 10s forwards;
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

</style>

<script>
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'ProductStockView',

  inject: ['$generalFunctions'],

  data: () => ({
    input: 
    {
      selectedLocalityIDS: [],
      allLocalitySelected: false,
      selectedProductID: null,
      searchedProductID: null,
      selectedProductCategory: null,
      selectedProductBrand: null,
      selectedProductModel: null,
      selectedProductFlavor: null,
      selectedProductNicotineLevel: null,
      selectedProductSize: null
    },

    display: 
    {
      localityOptions: [],
      productOptions: [],
      productCategoryOptions: [],
      productBrandOptions: [],
      productModelOptions: [],
      productFlavorOptions: [],
      productNicotineLevelOptions: [],
      productSizeOptions: [],
      productHeaders: [],
      productRows: [],
      PDFDocumentFile: null,
      PDFDocumentName: null,
      excelDocumentFile: null,
      excelDocumentName: null
    },

    navigation: 
    {
      searchVisible: 'searchVisible',
      productCostTotalAmountIsVisible: false,
      productCostTotalAmountIsVisibleInterval: null
    },

    loader:
    {
      selectLocalityIDAndLocalityName: false,
      selectProductBasicBasicInformationForBillsView: false,
      selectProductSearchParameters: false,
      selectProductStockOnProductStockView: false,
      generateReportOnProductStockView: false
    },

    localStorage:
    {
      localityID: null,
      agentID: null
    },

    accessCredential: {}
  }),

  watch: {
    'input.selectedLocalityIDS'(){
      this.display.productRows.length = 0;
      this.input.allLocalitySelected = this.input.selectedLocalityIDS.length == this.display.localityOptions.length;
    }
  },

  methods: {
    async generateReportOnProductStockView(){
      this.loader.generateReportOnProductStockView = true;
      var products = JSON.parse(JSON.stringify(this.display.productRows));
      products = products.filter(productRow => productRow.productSelected);
      if (products.length > 0){
        products.forEach(product => {
          delete product['productSelected'];
          product.productStock.forEach(productStockByLocality => {
            delete productStockByLocality['localityColor'];
            delete productStockByLocality['localityFontColor']
          });
        });
        const generateReportOnProductStockViewRequestQuery = 
        {
          'localityID': this.localStorage.localityID,
          'agentID': this.localStorage.agentID,
          'products': products
        };
        const generateReportOnProductStockViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productStock/functions/generateReportOnProductStockView', generateReportOnProductStockViewRequestQuery);
        if (generateReportOnProductStockViewResult.success){
          if (generateReportOnProductStockViewResult.result.PDFDocument.success){
            this.display.PDFDocumentFile = generateReportOnProductStockViewResult.result.PDFDocument.result.documentFile.data;
            this.display.PDFDocumentName = generateReportOnProductStockViewResult.result.PDFDocument.result.documentName;
          } else {
            this.display.PDFDocumentFile = null;
            this.display.PDFDocumentName = null;
            this.$root.notificationDialog.showNotificationDialog({
              'notificationDialogTitle': 'ERROR',
              'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el documento en PDF, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
              'notificationDialogColor': 'error',
              'notificationDialogIsPersistent': false
            });
          }
          const printablePDFExcelDocumentDialogRequestQuery = 
          {
            'printablePDFDocumentFile': this.display.PDFDocumentFile,
            'printablePDFDocumentName': this.display.PDFDocumentName,
            'printableExcelDocumentFile': null,
            'printableExcelDocumentName': null
          };
          this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el reporte, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ATENCIÓN',
          'notificationDialogBody': 'Debe seleccionar al menos un producto para generar el reporte, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.generateReportOnProductStockView = false;
    },

    async selectProductStockOnProductStockView(){
      this.loader.selectProductStockOnProductStockView = true;
      this.display.productRows.length = 0;
      const selectProductStockOnProductStockViewRequestQuery =
      {
        'localityIDS': this.input.selectedLocalityIDS,
        'productID': this.input.selectedProductID,
        'productCategoryID': this.input.selectedProductCategory,
        'productBrandID': this.input.selectedProductBrand,
        'productModelID': this.input.selectedProductModel,
        'productFlavorID': this.input.selectedProductFlavor,
        'productNicotineLevelID': this.input.selectedProductNicotineLevel,
        'productSizeID': this.input.selectedProductSize
      };
      const selectProductStockOnProductStockViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productStock/functions/selectProductStockOnProductStockView', selectProductStockOnProductStockViewRequestQuery);
      if (selectProductStockOnProductStockViewResult.success){
        this.display.productRows = selectProductStockOnProductStockViewResult.result.map(productRow => ({
          ...productRow,
          'productStockTotal': productRow.productStock.reduce((productStockTotal, productStockByLocality) => productStockTotal + productStockByLocality.productStockAmount, 0),
          'productSelected': true
        }));
        this.navigation.searchVisible = '';
        if (this.display.productRows.length == 0) {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ATENCIÓN',
            'notificationDialogBody': 'No se ha encontrado ningún producto con los filtros seleccionados, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectProductStockOnProductStockView = false;
    },

    async selectLocalityIDAndLocalityName(){
      this.loader.selectLocalityIDAndLocalityName = true;
      const selectLocalityIDAndLocalityNameResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityName');
      if (selectLocalityIDAndLocalityNameResult.success){
        this.display.localityOptions = selectLocalityIDAndLocalityNameResult.result;
        this.input.allLocalitySelected = false;
        this.selectAllLocality();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectLocalityIDAndLocalityName = false;
    },

    async selectProductBasicBasicInformationForBillsView(){
      this.loader.selectProductBasicBasicInformationForBillsView = true;
      const selectProductBasicBasicInformationForBillsViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductBasicBasicInformationForBillsView');
      if (selectProductBasicBasicInformationForBillsViewResult.success){
        this.display.productOptions = selectProductBasicBasicInformationForBillsViewResult.result.map(productOption => ({
          'productID': productOption.i,
          'productSKU': productOption.s,
          'productName': productOption.n,
          'productAutocompleteTitle': productOption.s + ' - ' + productOption.n
        }));
        this.loader.selectProductBasicBasicInformationForBillsView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductSearchParameters(){
      this.loader.selectProductSearchParameters = true;
      const selectProductSearchParametersResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductSearchParameters');
      if (selectProductSearchParametersResult.success){
        this.display.productCategoryOptions = [{'productCategoryName': 'Todas las categorías', 'productCategoryID': null}, ...selectProductSearchParametersResult.result.productCategory];
        this.display.productBrandOptions = [{'productBrandName': 'Todas las marcas', 'productBrandID': null}, ...selectProductSearchParametersResult.result.productBrand];
        this.display.productModelOptions = [{'productModelName': 'Todos los modelos', 'productModelID': null}, ...selectProductSearchParametersResult.result.productModel];
        this.display.productFlavorOptions = [{'productFlavorName': 'Todos los sabores', 'productFlavorID': null}, ...selectProductSearchParametersResult.result.productFlavor];
        this.display.productNicotineLevelOptions = [{'productNicotineLevelName': 'Todas las graduaciones de nicotina', 'productNicotineLevelID': null}, ...selectProductSearchParametersResult.result.productNicotineLevel];
        this.display.productSizeOptions = [{'productSizeName': 'Todos los tamaños', 'productSizeID': null}, ...selectProductSearchParametersResult.result.productSize];
        this.loader.selectProductSearchParameters = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de características de los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    showProductCostTotalAmount(){
      this.navigation.productCostTotalAmountIsVisible = true;
      this.navigation.productCostTotalAmountIsVisibleInterval = setInterval(() => {
        this.navigation.productCostTotalAmountIsVisible = false;
      }, 10000);
    },

    selectAllLocality(){
      if (this.input.allLocalitySelected == false){
        this.input.selectedLocalityIDS = this.display.localityOptions.map(localityOption => localityOption.localityID);
      } else {
        this.input.selectedLocalityIDS.length = 0;
      }
    },

    selectProductID(){
      const selectedProduct = this.display.productOptions.find(productOption => productOption.productSKU === this.input.searchedProductID);
      if (selectedProduct){
        const selectedProductID = selectedProduct.productID;
        this.input.selectedProductID = selectedProductID;
        this.input.searchedProductID = selectedProductID
        this.blurInput('selectedProductIDInput');
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': null,
          'notificationDialogBody': 'El producto escaneado no ha sido encontrado, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
      }
      this.input.searchedProductID = null;
    },

    setDefaultValues(){
      this.input.allLocalitySelected = false;
      this.selectAllLocality();
      this.input.selectedProductID = null;
      this.input.selectedProductCategory = null;
      this.input.selectedProductBrand = null;
      this.input.selectedProductModel = null;
      this.input.selectedProductFlavor = null;
      this.input.selectedProductNicotineLevel = null;
      this.input.selectedProductSize = null;
      this.display.productRows.length = 0;
      this.navigation.productCostTotalAmountIsVisible = false;
      this.navigation.productCostTotalAmountIsVisibleInterval = null;
      this.loader.selectLocalityIDAndLocalityName = false;
      this.loader.selectProductStockOnProductStockView = false;
      this.loader.generateReportOnProductStockView = false;
    },
    
    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    },

    prepareBasedOnAccessCredential(){
      this.display.productHeaders = [
        ...(this.accessCredential['/productStock/generateReport'] ? [{key: 'productSelected', title: ''}] : []),
        {key: 'productSKU', title: 'SKU'},
        {key: 'productName', title: 'NOMBRE'},
        ...(this.accessCredential['/productStock/showProductCost'] ? [{key: 'productCost', title: 'PRECIO DE COSTO'}] : []),
        {key: 'productStock', title: 'STOCK'}
      ];
    }
  },

  async mounted(){
    await this.getLocalStorageData();
    this.prepareBasedOnAccessCredential();
    await this.selectLocalityIDAndLocalityName();
    await this.selectProductBasicBasicInformationForBillsView();
    await this.selectProductSearchParameters();
  },

  beforeUnmount(){
    if (this.navigation.productCostTotalAmountIsVisibleInterval){
      clearInterval(this.navigation.productCostTotalAmountIsVisible);
    }
  }
});
</script>
