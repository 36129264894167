<template>
  <div style="margin: 2% 3% 0% 3%;">
    <v-dialog v-model="navigation.whatsappInvoiceProductsDialogIsVisible" width="auto">
      <v-card width="800" style="padding: 30px;">
        <v-data-table-virtual :headers="display.whatsappInvoiceProductHeaders" :items="display.whatsappInvoiceProductRows">      
        </v-data-table-virtual>
      </v-card>
    </v-dialog>
    <v-card elevation="3" style="max-height: 600px; padding: 30px; overflow-y: auto; background-color: #e9e8e8;">
      <v-data-table-virtual :headers="display.whatsappInvoiceHeaders" :items="display.whatsappInvoiceRows">
        <template v-slot:item.whatsappInvoiceAction="{ item }">
          <v-icon @click="openBillView(item)" color="success" center>mdi-invoice-send</v-icon>
        </template>
        <template v-slot:item.whatsappInvoiceProducts="{ item }">
          <v-icon @click="openWhatsappInvoiceProductsDialog(item.whatsappInvoiceProducts)" color="info" center>mdi-cart</v-icon>
        </template>
        <template v-slot:item.whatsappInvoiceLocalityDatetime="{ item }">
          <div style="width: 150px;">
            {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.whatsappInvoiceLocalityDatetime) }}
          </div>
        </template>
        <template v-slot:item.callcenterAgentName="{ item }">
          <v-chip :color="item.callcenterAgentColor" variant="flat">
            <p :style="{color: item.callcenterAgentFontColor}">{{ item.callcenterAgentName }}</p>
          </v-chip>
        </template>
        <template v-slot:item.clientSSN="{ item }">
          <div style="width: 500px;">
            <p><strong>Nombre:</strong> {{ item.clientName }}</p>
            <p><strong>Número de teléfono:</strong> {{ item.clientPhoneNumber }}</p>
          </div>
        </template>
        <template v-slot:item.whatsappInvoiceAmount="{ item }">
          <div style="width: 250px;">
            <p><strong>Monto:</strong> ₡{{ $generalFunctions.default.methods.parseAmountToDisplay(item.whatsappInvoiceAmount) }}</p>
            <p><strong>Método de pago:</strong> {{ item.whatsappInvoicePaymentMethod }}</p>
            <p><strong>Estado del pago:</strong> {{ item.whatsappInvoicePaymentState }}</p>
            <p><strong>Método de envío:</strong> {{ item.whatsappInvoiceShippingMethod }}</p>
          </div>
        </template>
        <template v-slot:item.whatsappInvoiceShippingMethod="{ item }">
          <div style="width: 150px;">
            {{ item.whatsappInvoiceShippingMethod }}
          </div>
        </template>
      </v-data-table-virtual>
    </v-card>
    <br><br>
  </div>
</template>

<style scoped>
</style>


<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CallcenterView',

  inject: ['$generalFunctions'],

  data: () => ({
    input: 
    {
    },

    display: 
    {
      whatsappInvoiceHeaders:
      [
        {key: 'whatsappInvoiceAction', title: 'FACTURAR'},
        {key: 'whatsappInvoiceProducts', title: 'CARRITO'},
        {key: 'whatsappInvoiceID', title: 'ID'},
        {key: 'whatsappInvoiceLocalityDatetime', title: 'FECHA Y HORA'},
        {key: 'callcenterAgentName', title: 'AGENTE'},
        {key: 'clientSSN', title: 'CLIENTE'},
        {key: 'whatsappInvoiceAmount', title: 'PAGO'},
      ],
      whatsappInvoiceRows: 
      [
        {
          'whatsappInvoiceID': 1,
          'callcenterAgentName': 'Diana Jiménez',
          'callcenterAgentColor': '#ffa8b8',
          'callcenterAgentFontColor': '#000000',
          'clientSSN': '117770329',
          'clientName': 'Oscar Fernando Araya Garbanzo (Escazú - Bello Horizonte)',
          'clientPhoneNumber': '50660694075',
          'whatsappInvoiceAmount': 50000,
          'whatsappInvoiceType': 'Factura electrónica',
          'whatsappInvoiceProducts': [ { "billProductSelectedAction": false, "billProductProductID": "1", "billProductName": "NASTY MANGO", "billProductPrice": 1000, "billProductWithoutTax": 870, "billProductTaxMargin": 13, "billProductMaxDiscountMargin": 50, "billProductTax": 130, "billProductDiscountMargin": 0, "billProductDiscount": 0, "billProductSubtotal": 1000, "billProductAmount": 1, "billProductLocalityStockAmount": 9, "billProductTotal": 1000 } ],
          'whatsappInvoiceShippingMethod': 'Envío por motorizado',
          'whatsappInvoicePaymentMethod': 'Tarjeta',
          'whatsappInvoicePaymentState': 'Pendiente',
          'whatsappInvoiceLocalityDatetime': new Date(),
        }
      ],
      whatsappInvoiceProductRows: [],
      whatsappInvoiceProductHeaders:
      [
        {key: 'billProductName', title: 'NOMBRE'},
        {key: 'billProductAmount', title: 'CANTIDAD'},
        {key: 'billProductPrice', title: 'PRECIO'},
      ]
    },

    navigation: 
    {
      whatsappInvoiceProductsDialogIsVisible: false
    },

    loader:
    {
    },

    localStorage: 
    {
      localityID: null,
      agentID: null
    },

    accessCredential: 
    {
    }
  }),

  watch: {
  },

  methods: {
    openWhatsappInvoiceProductsDialog(whatsappInvoiceProducts){
      this.display.whatsappInvoiceProductRows = whatsappInvoiceProducts;
      this.navigation.whatsappInvoiceProductsDialogIsVisible = true;
    },

    openBillView(whatsappInvoice){
      const encodedQuery = encodeURIComponent(JSON.stringify(whatsappInvoice));
      this.$router.push({path: '/bill', query: { 'whatsappInvoice': encodedQuery}});
    }
  },

  async mounted(){
    
  }
});
</script>
