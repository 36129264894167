<template>
  <div class="localityViewContainer">
    <v-card class="localityViewMainContainer" elevation="3">
      <div class="localityViewInputContainer">
        <v-autocomplete 
          v-model="input.selectedLocalityID"
          :items="display.localityOptions" 
          :loading="loader.selectLocalityIDAndLocalityName" 
          item-value="localityID" 
          item-title="localityName" 
          class="selectedLocalityIDInput"
          append-inner-icon="mdi-store" 
          label="Localidad" 
          variant="solo-filled"
        ></v-autocomplete>
        <v-btn @click="openInsertLocalityDialog()" height="38" color="success" style="margin-left: 1.5%; margin-top: 10px;">
          <h3>CREAR LOCALIDAD</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-plus-circle</v-icon>
        </v-btn>
      </div>
    </v-card>

    <br><br>
    <v-card v-if="loader.selectLocalityForLocalityView || display.locality" style="background-color: #d6d6d6; padding: 2%;" elevation="3">
      <div v-if="loader.selectLocalityForLocalityView" style="text-align: center;">
        <v-progress-circular color="info" indeterminate></v-progress-circular>
        <br>
      </div>
      <div v-else>
        <h1>{{ display.originalLocalityName }}:</h1>
        <br>
        <div style="display: flex;">
          <div style="width: 69%; margin-right: 1%;">
            <v-text-field v-model="display.locality.localityID" readonly="true" label="ID de la localidad" variant="solo-filled"></v-text-field>
            <v-text-field v-model="display.locality.localityTaxID" readonly="true" label="ID de facturación de la localidad" variant="solo-filled"></v-text-field>
            <v-text-field v-model="display.locality.localityName" label="Nombre de la localidad" variant="solo-filled"></v-text-field>
          </div>
          <div style="width: 39%; margin-left: 1%;">
            <v-select v-model="display.locality.localityType" :items="display.localityTypeOptions" label="Tipo de localidad" variant="solo-filled"></v-select>
            <v-text-field @click="navigation.localityColorDialogIsVisible = true" v-model="display.locality.localityColor" :bg-color="display.locality.localityColor" readonly="true" prepend-inner-icon="mdi-palette" label="Color de la localidad" variant="solo-filled"></v-text-field>
            <v-dialog v-model="navigation.localityColorDialogIsVisible" width="auto">
              <v-card width="300">
                <v-color-picker v-model="display.locality.localityColor" v-model:mode="navigation.colorMode" hide-inputs></v-color-picker>
              </v-card>
            </v-dialog>
            <v-text-field @click="navigation.localityFontColorDialogIsVisible = true" v-model="display.locality.localityFontColor" :bg-color="display.locality.localityFontColor" readonly="true" prepend-inner-icon="mdi-palette" label="Color de letra de la localidad" variant="solo-filled"></v-text-field>
            <v-dialog v-model="navigation.localityFontColorDialogIsVisible" width="auto">
              <v-card width="300">
                <v-color-picker v-model="display.locality.localityFontColor" v-model:mode="navigation.colorMode" hide-inputs></v-color-picker>
              </v-card>
            </v-dialog>
          </div>
        </div>
        <v-textarea v-model="display.locality.localityDescription" label="Descripción de la localidad" rows="3" variant="solo-filled"></v-textarea>

        <br>
        <div v-if="loader.updateLocalityFromLocalityView" style="text-align: center;">
          <v-progress-circular color="success" indeterminate></v-progress-circular>
          <br><br>
        </div>
        <div v-else>
          <v-btn @click="updateLocalityFromLocalityView()" dark block height="38" color="success" style="margin-bottom: 15px;">
            <h3>ACTUALIZAR LOCALIDAD</h3>
            <v-icon style="margin-left: 10px;" dark right>mdi-content-save</v-icon>
          </v-btn>
        </div>


        <br><br><br>
        <div style="display: flex; width: 100%;">
          <h2>Agentes:</h2>
          <div style="margin-left: auto;">
            <v-btn @click="openInsertAgentDialog()" height="38" color="success">
              <h3>CREAR AGENTE</h3>
              <v-icon style="margin-left: 10px;" dark right>mdi-plus-circle</v-icon>
            </v-btn>
          </div>
        </div>
        <br>
        <v-data-table-virtual :headers="display.agentHeaders" :items="display.locality.agents">
          <template v-slot:item.agentName="{ item }">
            <v-chip :color="item.agentColor" variant="flat">
              <p :style="{color: item.agentFontColor}">{{ item.agentName }}</p>
            </v-chip>
          </template>
          <template v-slot:item.agentIsActive="{ item }">
            <div style="width: 150px;">
              <v-switch v-model="item.agentIsActive" :true-value="1" :false-value="0" :label="item.agentIsActive ? 'Activo' : 'Inactivo'" hide-details color="success"></v-switch>
            </div>
          </template>
        </v-data-table-virtual>
        
        <br><br><br>
        <div style="display: flex; width: 100%;">
          <h2>Terminales de pago:</h2>
          <div style="margin-left: auto;">
            <v-btn @click="null" height="38" color="success">
              <h3>CREAR TERMINAL DE PAGO</h3>
              <v-icon style="margin-left: 10px;" dark right>mdi-plus-circle</v-icon>
            </v-btn>
          </div>
        </div>
        <br>
        <v-data-table-virtual :headers="display.localityPaymentTerminalHeaders" :items="display.locality.localityPaymentTerminals">
          <template v-slot:item.localityPaymentTerminalBank="{ item }">
            <div style="display: flex; width: 150px;">
              <v-select v-model="item.localityPaymentTerminalBank" :items="display.bankOptions" density="dense" variant="solo-filled" style="margin-top: 20px;"></v-select>
            </div>
          </template>
          <template v-slot:item.localityPaymentTerminalIsActive="{ item }">
            <div style="width: 150px;">
              <v-switch v-model="item.localityPaymentTerminalIsActive" :true-value="1" :false-value="0" :label="item.localityPaymentTerminalIsActive ? 'Activa' : 'Inactiva'" hide-details color="success"></v-switch>
            </div>
          </template>
        </v-data-table-virtual>
        
        <br><br><br>
        <div style="display: flex; width: 100%;">
          <h2>Cuentas de SINPE:</h2>
          <div style="margin-left: auto;">
            <v-btn @click="null" height="38" color="success">
              <h3>CREAR CUENTA DE SINPE</h3>
              <v-icon style="margin-left: 10px;" dark right>mdi-plus-circle</v-icon>
            </v-btn>
          </div>
        </div>
        <br>
        <v-data-table-virtual :headers="display.localitySINPEAccountHeaders" :items="display.locality.localitySINPEAccounts">
          <template v-slot:item.localitySINPEAccountBank="{ item }">
            <div style="width: 150px;">
              <v-select v-model="item.localitySINPEAccountBank" :items="display.bankOptions" density="dense" variant="solo-filled" style="margin-top: 20px;"></v-select>
            </div>
          </template>
          <template v-slot:item.localitySINPEAccountIsActive="{ item }">
            <div style="width: 150px;">
              <v-switch v-model="item.localitySINPEAccountIsActive" :true-value="1" :false-value="0" :label="item.localitySINPEAccountIsActive ? 'Activa' : 'Inactiva'" hide-details color="success"></v-switch>
            </div>
          </template>
        </v-data-table-virtual>
        
        <br><br><br>
        <div style="display: flex; width: 100%;">
          <h2>Cuentas bancarias:</h2>
          <div style="margin-left: auto;">
            <v-btn @click="null" height="38" color="success">
              <h3>CREAR CUENTA BANCARIA</h3>
              <v-icon style="margin-left: 10px;" dark right>mdi-plus-circle</v-icon>
            </v-btn>
          </div>
        </div>
        <br>
        <v-data-table-virtual :headers="display.localityDepositAccountHeaders" :items="display.locality.localityDepositAccounts">
          <template v-slot:item.localityDepositAccountBank="{ item }">
            <div style="width: 150px;">
              <v-select v-model="item.localityDepositAccountBank" :items="display.bankOptions" density="dense" variant="solo-filled" style="margin-top: 20px;"></v-select>
            </div>
          </template>
          <template v-slot:item.localityDepositAccountIsActive="{ item }">
            <div style="width: 150px;">
              <v-switch v-model="item.localityDepositAccountIsActive" :true-value="1" :false-value="0" :label="item.localityDepositAccountIsActive ? 'Activa' : 'Inactiva'" hide-details color="success"></v-switch>
            </div>
          </template>
        </v-data-table-virtual>
      </div>
    </v-card>
    <br><br>
  </div>

</template>

<style scoped>
  .localityViewContainer {
    margin: 2% 3% 0% 3%;
  }

  .localityViewMainContainer {
    padding: 1.5%;
    background-color: #e9e8e8;
  }

  .localityViewInputContainer {
    display: flex;
  }

</style>


<script>
import { defineComponent } from 'vue';

import bn from '../assets/bankIcons/bn.png';
import bcr from '../assets/bankIcons/bcr.png';
import bac from '../assets/bankIcons/bac.png';
import promerica from '../assets/bankIcons/promerica.png';

export default defineComponent({
  name: 'LocalityView',

  inject: ['$generalFunctions'],

  data: () => ({
    assets: 
    {
      BN: bn,
      BCR: bcr,
      BAC: bac,
      PRM: promerica
    },

    input: 
    {
      selectedLocalityID: null
    },

    display: 
    {
      locality: null,
      originalLocalityName: null,
      localityOptions: [],
      localityTypeOptions: ['Tienda', 'Bodega'],
      bankOptions: ['BN', 'BCR', 'BAC', 'Promérica', 'Otro'],
      agentHeaders:
      [
        {key: 'agentID', title: 'Identificador interno'},
        {key: 'agentSSN', title: 'Número de cédula'},
        {key: 'agentName', title: 'Nombre'},
        {key: 'agentIsActive', title: 'Estado'}
      ],
      localityPaymentTerminalHeaders:
      [
        {key: 'localityPaymentTerminalID', title: 'Identificador interno'},
        {key: 'localityPaymentTerminalSerialID', title: 'Número de serie'},
        {key: 'localityPaymentTerminalName', title: 'Nombre'},
        {key: 'localityPaymentTerminalDescription', title: 'Descripción'},
        {key: 'localityPaymentTerminalBank', title: 'Banco asociado'},
        {key: 'localityPaymentTerminalIsActive', title: 'Estado'}
      ],
      localitySINPEAccountHeaders:
      [
        {key: 'localitySINPEAccountID', title: 'Identificador interno'},
        {key: 'localitySINPEAccountPhoneNumber', title: 'Número de teléfono asociado'},
        {key: 'localitySINPEAccountName', title: 'Nombre'},
        {key: 'localitySINPEAccountDescription', title: 'Descripción'},
        {key: 'localitySINPEAccountBank', title: 'Banco asociado'},
        {key: 'localitySINPEAccountIsActive', title: 'Estado'}
      ],
      localityDepositAccountHeaders:
      [
        {key: 'localityDepositAccountID', title: 'Identificador interno'},
        {key: 'localityDepositAccountAccountID', title: 'Número de cuenta asociada'},
        {key: 'localityDepositAccountName', title: 'Nombre'},
        {key: 'localityDepositAccountDescription', title: 'Descripción'},
        {key: 'localityDepositAccountBank', title: 'Banco asociado'},
        {key: 'localityDepositAccountIsActive', title: 'Estado'}
      ]
    },

    navigation: 
    {
      localityColorDialogIsVisible: false,
      localityFontColorDialogIsVisible: false,
      colorMode: 'hex'
    },

    loader:
    {
      updateLocalityFromLocalityView: false,
      selectLocalityIDAndLocalityName: false,
      selectLocalityForLocalityView: false
    },

    localStorage:
    {
      localityID: null
    }
  }),

  watch: {
    'input.selectedLocalityID'(){
      this.selectLocalityForLocalityView();
    }
  },


  methods: {
    async updateLocalityFromLocalityView(){
      this.loader.updateLocalityFromLocalityView = true;
      const updateLocalityFromLocalityRequestQuery =
      {
        'localityID': this.input.selectedLocalityID,
        'localityName': this.display.locality.localityName,
        'localityType': this.display.locality.localityType,
        'localityColor': this.display.locality.localityColor,
        'localityFontColor': this.display.locality.localityFontColor
      }
      const updateLocalityFromLocalityViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/functions/updateLocalityFromLocalityView', updateLocalityFromLocalityRequestQuery);
      if (updateLocalityFromLocalityViewResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'Se ha actualizado la localidad exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        await this.selectLocalityIDAndLocalityName();
        await this.selectLocalityForLocalityView();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al actualizar la localidad, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.updateLocalityFromLocalityView = false;
    },

    async selectLocalityIDAndLocalityName(){
      this.loader.selectLocalityIDAndLocalityName = true;
      const selectLocalityIDAndLocalityNameResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityName');
      if (selectLocalityIDAndLocalityNameResult.success){
        this.display.localityOptions = selectLocalityIDAndLocalityNameResult.result;
        this.input.selectedLocalityID = this.localStorage.localityID;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectLocalityIDAndLocalityName = false;
    },

    async selectLocalityForLocalityView(){
      this.loader.selectLocalityForLocalityView = true;
      const selectLocalityForLocalityViewRequestQuery = {'localityID': this.input.selectedLocalityID};
      const selectLocalityForLocalityViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/functions/selectLocalityForLocalityView', selectLocalityForLocalityViewRequestQuery);
      if (selectLocalityForLocalityViewResult.success){
        this.display.locality = selectLocalityForLocalityViewResult.result;
        this.display.originalLocalityName = this.display.locality.localityName;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al la información de la localidad, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectLocalityForLocalityView = false;
    },

    openInsertAgentDialog(){
      const insertAgentDialogRequestQuery = 
      {
        'insertingAgentLocalityID': this.input.selectedLocalityID
      }
      this.$root.insertAgentDialog.openInsertAgentDialog(insertAgentDialogRequestQuery);
    },

    openInsertLocalityDialog(){
      this.$root.insertLocalityDialog.openInsertLocalityDialog();
    },

    setDefaultValues(){
      this.input.selectedLocalityID = null;
      this.display.locality = null;
      this.display.localityOptions = [];
      this.navigation.localityColorDialogIsVisible = false;
      this.navigation.localityFontColorDialogIsVisible = false;
      this.loader.selectLocalityIDAndLocalityName = false;
      this.loader.selectLocalityForLocalityView = false;
      this.localStorage.localityID = null;
    },

    getLocalStorageData(){
      this.localStorage.localityID = parseInt(localStorage.getItem('localityID'));
    }
  },


  async mounted(){
    this.setDefaultValues();
    this.getLocalStorageData();
    await this.selectLocalityIDAndLocalityName();
  }
});
</script>
