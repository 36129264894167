<template>
  <div style="margin: 2% 3% 0% 3%;">
    <h1>Historial de tokens:</h1>
    <br><br>
    <v-card elevation="3" style="padding: 20px; background-color: #f5f5f5; max-height: 700px; overflow-y: auto;">
      <v-data-table-virtual :headers="display.tokenHeaders" :items="display.token">
        <template v-slot:item.agentName="{ item }">
          <v-chip :color="item.agentColor" variant="flat">
            <p :style="{color: item.agentFontColor}">{{ item.agentName }}</p>
          </v-chip>
        </template>

        <template v-slot:item.tokenExpirationDatetime="{ item }">
          {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.tokenExpirationDatetime) }}
        </template>

      </v-data-table-virtual>  
    </v-card>
  </div>
</template>

<style scoped>
</style>


<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TokenView',

  inject: ['$generalFunctions'],

  data: () => ({
    input: 
    {
      startDate: null,
      endDate: null
    },

    display: 
    {
      token: [],
      tokenHeaders: 
      [
        {key: 'tokenID', title: 'ID'},
        {key: 'tokenValue', title: 'VALOR'},
        {key: 'agentName', title: 'GENERADO POR'},
        {key: 'tokenType', title: 'TIPO'},
        {key: 'tokenExpirationDatetime', title: 'EXPIRA EL'},
        {key: 'tokenUseAmount', title: 'USOS RESTANTES'}

      ]
    },

    navigation: 
    {
    },

    loader:
    {
      selectTokenFromTokenView: false
    },

    localStorage: 
    {
    },

    accessCredential: {}
  }),

  watch: {
  }, 

  methods: {
    async selectTokenFromTokenView(){
      const selectTokenFromTokenViewRequetQuery =
      {
        'startDate': this.input.startDate,
        'endDate': this.input.endDate
      };
      const selectTokenFromTokenViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/token/functions/selectTokenFromTokenView', selectTokenFromTokenViewRequetQuery);
      if (selectTokenFromTokenViewResult.success){
        this.display.token = selectTokenFromTokenViewResult.result;
        this.loader.selectTokenFromTokenView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de tokens, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    }
  },

  async mounted(){
    await this.selectTokenFromTokenView();
  }
});
</script>
