<template>
  <v-dialog v-model="navigation.productDialogIsVisible" width="auto">
    <v-card width="700" class="productDialogContainer">
      <div v-if="loader.selectProductForProductDialog" style="text-align: center;">
        <v-progress-circular color="info" indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <v-text-field v-model="display.productID" readonly label="Código interno del producto" type="number" prepend-inner-icon="mdi-cash" variant="solo-filled" hide-spin-buttons></v-text-field>
        <v-text-field v-model="display.productSKU" readonly prepend-inner-icon="mdi-barcode" label="SKU del producto" variant="solo-filled"></v-text-field>
        <v-text-field v-model="display.productName" readonly prepend-inner-icon="mdi-package-variant-closed" label="Nombre del producto" variant="solo-filled"></v-text-field>
        <v-textarea v-model="display.productDescription" readonly rows="6" prepend-inner-icon="mdi-text-box-outline" label="Descripción (página web) del producto" variant="solo-filled"></v-textarea>
        <v-text-field v-model="display.productPrice" readonly label="Precio de venta del producto" type="number" prepend-inner-icon="mdi-cash" variant="solo-filled" hide-spin-buttons></v-text-field>
        <br>
        <div class="productStockContainer">
          <v-data-table-virtual :headers="display.productStockByLocalityHeaders" :items="display.productStockByLocalityRows">
            <template v-slot:item="row">
              <tr>
                <td style="min-width: 65%;">
                  {{ row.item.localityName }}
                </td>
                <td style="min-width: 35%;">
                  <br>
                  <v-text-field v-model="row.item.productStockAmount" readonly type="number" prepend-inner-icon="mdi-text-box-outline" label="Cantidad de stock" variant="solo-filled" hide-spin-buttons></v-text-field>
                </td>
              </tr>
            </template>
          </v-data-table-virtual>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>


<style scoped>
  .productDialogContainer {
    padding: 20px;
  }

  .productStockContainer{
    background-color: rgb(205, 205, 205);
    width: 100%;
    padding: 20px;
  }

</style>


<script>

export default {
  name: "ProductDialog",
  inject: ['$generalFunctions'],

  data: () => ({
    navigation: 
    {
      productDialogIsVisible: false,
      productID: null
    },

    display:
    {
      productID: null,
      productSKU: null,
      productName: null,
      productDescription: null,
      productPrice: null,
      productStockByLocalityRows: [],
      productStockByLocalityHeaders: 
      [
        {key: 'localityName', title: 'Localidad'},
        {key: 'productStockAmount', title: 'Cantidad de stock'},
      ],
    },

    loader: 
    {
      selectProductForProductDialog: false
    }


  }),

  methods: {

    async selectProductForProductDialog(){
      this.loader.selectProductForProductDialog = true;
      const selectProductForProductDialogRequestQuery = {'productID': this.navigation.productID};
      const selectProductForProductDialogResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductForProductDialog', selectProductForProductDialogRequestQuery);
      if (selectProductForProductDialogResult.success){
        this.display.productID = selectProductForProductDialogResult.result.productID;
        this.display.productSKU = selectProductForProductDialogResult.result.productSKU;
        this.display.productName = selectProductForProductDialogResult.result.productName;
        this.display.productDescription = selectProductForProductDialogResult.result.productDescription;
        this.display.productPrice = selectProductForProductDialogResult.result.productPrice;
        this.display.productStockByLocalityRows = selectProductForProductDialogResult.result.productStock;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar el producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });      
      }
      this.loader.selectProductForProductDialog = false;
    },
    
    async showProductDialog(productDialogRequestQuery) {
      this.navigation.productID = productDialogRequestQuery.productID;
      this.navigation.productDialogIsVisible = true;
      await this.selectProductForProductDialog();
    }
  }
}
</script>