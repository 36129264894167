<template>
  <v-dialog v-model="navigation.printableBillDialogIsVisible" persistent width="auto">
    <v-card width="600" class="printableBillDialogContainer">
      <vue-pdf-app ref="printableBillPDFDocument" style="height: 60vh" :pdf="display.printableBillPDFDocumentBlob"/>
      <br><br>
      <v-btn @click="printBill()" dark block height="38" color="info">
        <h3>IMPRIMIR</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-printer</v-icon>
      </v-btn>
      <br>
      <v-btn @click="closePrintableBillDialog()" dark block height="38" color="error">
        <h3>CERRAR</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>


<style scoped>
  .printableBillDialogContainer {
    padding: 20px;
  }

</style>


<script>
import VuePdfApp from "vue3-pdf-app";
import "vue3-pdf-app/dist/icons/main.css";


export default {
  name: "PrintableBillDialog",

  components: {
    VuePdfApp
  },

  data: () => ({
    navigation: 
    {
      printableBillDialogIsVisible: false
    },

    display: 
    {
      printableBillPDFDocumentFile: null,
      printableBillPDFDocumentBlob: null
    }


  }),

  methods: {
    createPrintableBillPDFDocumentBlob(){
      if (this.display.printableBillPDFDocumentFile){
        if (this.display.printableBillPDFDocumentFile.data){
          this.display.printableBillPDFDocumentBlob = new Uint8Array(this.display.printableBillPDFDocumentFile.data);
        }
      }
    },

    printBill(){
      const printBillInterval = setInterval(() => {
        if (this.$refs.printableBillPDFDocument) {
          window.print();
          clearInterval(printBillInterval);
        }
      }, 1);
    },

    closePrintableBillDialog(){
      this.navigation.printableBillDialogIsVisible = false;

    },

    showPrintableBillDialog(printableBillDialogRequestQuery) {
      this.display.printableBillPDFDocumentFile = printableBillDialogRequestQuery.billPDFDocumentFile;
      this.navigation.printableBillDialogIsVisible = true;
      this.createPrintableBillPDFDocumentBlob();
      this.printBill();
    }
  }
}
</script>