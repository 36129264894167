<template>
  <div>
    <div class="loginMainContainer">
      <v-card class="loginFormContainer">
        <v-text-field v-model="input.agentUsername" @keydown.enter.prevent="loginAgent()" prepend-inner-icon="mdi-account" label="Nombre de usuario" variant="solo-filled"></v-text-field>
        <v-text-field 
          v-model="input.agentPassword" 
          :type="navigation.agentPasswordInputType" 
          :append-inner-icon="navigation.agentPasswordInputIcon"
          @keydown.enter.prevent="loginAgent()" 
          @click:append-inner="changeAgentPasswordInputTypeAndIcon()"
          prepend-inner-icon="mdi-lock" 
          label="Contraseña" 
          variant="solo-filled">
        </v-text-field>
        <br><br>
        <div class="loginLoginButtonContainer">
          <v-btn @click="loginAgent()" block color="info" size="x-large" variant="elevated" text="INICIAR SESIÓN"></v-btn>
        </div>
        <br>
        <div class="loginRecoverPasswordButtonContainer">
          <v-btn @click="openRecoverAgentPasswordDialog()" block color="info" size="large"  variant="elevated" text="RECUPERAR CONTRASEÑA"></v-btn>
        </div>
      </v-card>
    </div>
  </div>
</template>

<style scoped>
  .loginMainContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(240, 240, 240);
  }

  .loginFormContainer {
    width: 100%;
    max-width: 800px;
    background-color: gray;
    padding: 30px;
    
  }

  .loginLoginButtonContainer {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }

  .loginRecoverPasswordButtonContainer {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LoginView',
  inject: ['$generalFunctions'],

  data: () => ({
    input:
    {
      agentUsername: '',
      agentPassword: ''
    },

    navigation:
    {
      agentPasswordInputType: 'password',
      agentPasswordInputIcon: 'mdi-eye-off'
    }
  }),

  methods: {
    changeAgentPasswordInputTypeAndIcon(){
      if ((this.navigation.agentPasswordInputType == 'password') && (this.navigation.agentPasswordInputIcon == 'mdi-eye-off')){
        this.navigation.agentPasswordInputType = 'default';
        this.navigation.agentPasswordInputIcon = 'mdi-eye';
      } else {
        this.navigation.agentPasswordInputType = 'password';
        this.navigation.agentPasswordInputIcon = 'mdi-eye-off';
      }
    },

    async loginAgent(){
      const agentLoginRequestQuery = 
      {
        'agentUsername': this.input.agentUsername, 
        'agentPassword': this.input.agentPassword
      };
      const agentLoginResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/functions/login', agentLoginRequestQuery);
      if (agentLoginResult.success){
        if (agentLoginResult.result){
          const agentID = agentLoginResult.result.agentID;
          const agentName = agentLoginResult.result.agentName;
          const localityID = agentLoginResult.result.localityID;
          const agentLandingPage = agentLoginResult.result.agentLandingPage || '/bill';
          const localityName = agentLoginResult.result.localityName;
          const JWTValue = agentLoginResult.result.JWTValue;
          const JWTAgentID = agentLoginResult.result.JWTAgentID;
          localStorage.clear();
          localStorage.setItem('agentID', agentID);
          localStorage.setItem('agentName', agentName);
          localStorage.setItem('localityID', localityID);
          localStorage.setItem('localityName', localityName);
          localStorage.setItem('agentLandingPage', agentLandingPage);
          localStorage.setItem('JWTValue', JWTValue);
          localStorage.setItem('JWTAgentID', JWTAgentID);
          this.$generalFunctions.default.methods.navigateTo(agentLandingPage);
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'No existe ningún usuario que coincida con este nombre de usuario y contraseña, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al iniciar sesión, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    openRecoverAgentPasswordDialog(){
      this.$root.recoverAgentPasswordDialog.openRecoverAgentPasswordDialog();
    }
  },

  mounted(){
    if (localStorage.getItem('agentID') && localStorage.getItem('agentLandingPage')){
      this.$generalFunctions.default.methods.navigateTo(localStorage.getItem('agentLandingPage'));
    }
  }
});
</script>
