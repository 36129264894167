<template>
  <v-dialog v-model="navigation.insertProductTransferDialogIsVisible" :persistent="true" width="auto">
    <v-card width="1200" style="padding: 20px;">
      <v-autocomplete v-model="input.selectedLocalityID" :items="display.localityOptions" :loading="loader.localityOptions" item-title="localityName" item-value="localityID" append-inner-icon="mdi-store" label="Localidad que envía" variant="solo-filled"></v-autocomplete>
      <div style="display: flex;">
        <v-autocomplete v-model="input.selectedLocalityIDS" :items="display.localityOptions" :loading="loader.localityOptions" multiple chips item-title="localityName" item-value="localityID" append-inner-icon="mdi-store" label="Localidades que reciben" variant="solo-filled"></v-autocomplete>
        <v-checkbox @click="selectAllLocality()" v-model="input.allLocalitySelected" color="info" label="Todas las localidades" hide-details style="margin-left: 10px;"></v-checkbox>
      </div>
      <v-card style="padding: 15px; background-color: #e9e8e8; max-height: 500px; overflow-y: auto;">
        {{ input.productIDOrName }}
        <v-autocomplete
          v-model="input.productIDOrName"
          v-model:search="input.searchedProductID"
          @update:model-value="appendToProductToProductTransferProducts()"
          :items="display.productOptionsDisplay"
          :loading="loader.selectProductBasicInformationForBillView"
          :disabled="loader.selectProductBasicInformationForBillView"
          ref="selectedProductInput"
          append-inner-icon="mdi-magnify"
          label="Código o nombre del producto"
          variant="solo-filled"
        ></v-autocomplete>
        <div v-if="loader.generateProductTransfer" style="text-align: center;">
          <v-progress-circular color="info" indeterminate></v-progress-circular>
        </div>
        <div v-else>
          <v-data-table-virtual :headers="display.productTransferProductHeaders" :items="display.productTransferProductRows">
            <template v-for="localityID in input.selectedLocalityIDS" v-bind:key="localityID" v-slot:[`item.productTransferProductSendAmount_${localityID}`]="{ item }">
              <div v-if="display.productTransferProductRows.filter(productTransferProductRow => productTransferProductRow.productTransferProductID != null).length > 0">
                <div v-if="item.productTransferProductID">
                  <v-text-field v-model="item[`productTransferProductSendAmount_${localityID}`]" variant="solo-filled" type="number" density="dense" style="margin-top: 20px; width: 100px;"></v-text-field>
                </div>
                <div v-else>
                  <v-btn @click="generateProductTransfer(localityID)" dark height="38" :color="display.localityOptions.find(localityOption => localityOption.localityID == localityID)?.localityColor || 'gray'" style="margin-top: 20px; margin-bottom: 20px;">
                    <h3>GENERAR TRASLADO</h3>
                    <v-icon style="margin-left: 20px;" dark right>mdi-car-pickup</v-icon>
                  </v-btn>
                </div>
              </div>
            </template>
            <template v-slot:item.productTransferProductDeleteAction="{ item }">
              <v-icon v-if="item.productTransferProductID" @click="deleteProductFromProductTransferProducts(item.productTransferProductID)" color="error">mdi-delete</v-icon>
            </template>
            <template v-slot:item.productTransferProductName="{ item }">
              <p>{{ item.productTransferProductName }}</p>
              <p><strong>{{ item.productTransferProductSKU }}</strong></p>
            </template>
            <template v-slot:item.productTransferProductNote="{ item }">
              <v-textarea v-if="item.productTransferProductID" v-model="item.productTransferProductNote" variant="solo-filled" rows="1" style="margin-top: 20px; width: 300px;"></v-textarea>
            </template>
          </v-data-table-virtual>
        </div>
      </v-card>
      <br><br>
      <v-btn @click="navigation.insertProductTransferDialogIsVisible = false" dark block height="38" color="error">
        <h3>CANCELAR</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-close</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>
  
<style scoped>
</style>

<script>

export default {
  name: 'InsertProductTransferDialog',
  inject: ['$generalFunctions'],

  data: () => ({
    input:
    {
      selectedLocalityID: null,
      searchedProductID: null,
      selectedLocalityIDS: [],
      productIDOrName: null,
      allLocalitySelected: false
    },

    loader: 
    {
      selectProductBasicInformationForBillView: false,
      insertProductTransfer: false,
      generateProductTransfer: false
    },
    
    navigation: 
    {
      insertProductTransferDialogIsVisible: false
    },

    display:
    {
      localityOptions: [],
      productTransferProductRows: [],
      productTransferProductHeaders: 
      [
        {key: 'productTransferProductDeleteAction', title: ''},
        {key: 'productTransferProductName', title: 'PRODUCTO'},
        {key: 'productStockAmount', title: 'STOCK'},
        {key: 'productTransferProductNote', title: 'NOTA (OPCIONAL)'}
      ],
      PDFDocumentFile: null,
      PDFDocumentName: null,
      excelDocumentFile: null,
      excelDocumentName: null
    },

    localStorage:
    {
      localityID: null,
      agentID: null
    }
  }),

  watch: {
    'input.selectedLocalityIDS'(){
      this.input.allLocalitySelected = this.input.selectedLocalityIDS.length == this.display.localityOptions.length;
      this.updateSelectedLocalityIDS();
    }
  },

  methods: {
    async generateProductTransfer(productTransferReceiveLocalityID){
      this.loader.generateProductTransfer = true;
      if (this.display.productTransferProductRows.filter(productTransferProductRow => (productTransferProductRow.productTransferProductID != null) && (parseInt(productTransferProductRow[`productTransferProductSendAmount_${productTransferReceiveLocalityID}`]) > productTransferProductRow.productTransferProductStockAmount)).length == 0){
        const productTransferProducts = [];
        for (var productTransferProductRowIndex in this.display.productTransferProductRows.filter(productTransferProductRow => productTransferProductRow.productTransferProductID != null)){
          const productTransferProductRow = this.display.productTransferProductRows[productTransferProductRowIndex];
          const productTransferProduct = 
          {
            'productTransferProductProductID': productTransferProductRow.productTransferProductID,
            'productTransferProductProductSKU': productTransferProductRow.productTransferProductSKU,
            'productTransferProductProductName': productTransferProductRow.productTransferProductName,
            'productTransferProductSendAmount': productTransferProductRow[`productTransferProductSendAmount_${productTransferReceiveLocalityID}`],
            'productTransferProductNote': productTransferProductRow.productTransferProductNote
          };
          productTransferProducts.push(productTransferProduct);
        }
        const generateProductTransferRequestQuery = 
        {
          'productTransferSendLocalityID': this.input.selectedLocalityID,
          'productTransferSendAgentID': this.localStorage.agentID,
          'productTransferReceiveLocalityID': productTransferReceiveLocalityID,
          'productTransferProducts': productTransferProducts
        };
        const generateProductTransferResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productTransfer/functions/generateProductTransfer', generateProductTransferRequestQuery);
        if (generateProductTransferResult.success){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ÉXITO',
            'notificationDialogBody': 'El traslado de productos se ha generado exitosamente',
            'notificationDialogColor': 'success',
            'notificationDialogIsPersistent': false
          });
          await this.updateProductTransferProductStockAmount();
          this.display.productTransferProductHeaders = this.display.productTransferProductHeaders.filter(productTransferProductHeader => productTransferProductHeader.key != `productTransferProductSendAmount_${productTransferReceiveLocalityID}`);
          if (generateProductTransferResult.result.PDFDocument.success){
            this.display.PDFDocumentFile = generateProductTransferResult.result.PDFDocument.result.documentFile.data;
            this.display.PDFDocumentName = generateProductTransferResult.result.PDFDocument.result.documentName;
          } else {
            this.$root.notificationDialog.showNotificationDialog({
              'notificationDialogTitle': 'ERROR',
              'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el documento en PDF, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
              'notificationDialogColor': 'error',
              'notificationDialogIsPersistent': false
            });
          }
          const printablePDFExcelDocumentDialogRequestQuery = 
          {
            'printablePDFDocumentFile': this.display.PDFDocumentFile,
            'printablePDFDocumentName': this.display.PDFDocumentName,
            'printableExcelDocumentFile': this.display.excelDocumentFile,
            'printableExcelDocumentName': this.display.excelDocumentName
          };
          this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el traslado de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el traslado de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.generateProductTransfer = false;
    },
    
    async selectProductBasicInformationForBillView(){
      this.loader.selectProductBasicInformationForBillView = true;
      const selectProductBasicInformationForBillViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductBasicInformationForBillViewByLocalityID', {'localityID': this.localStorage.localityID});
      if (selectProductBasicInformationForBillViewResult.success){
        this.display.productOptionsDisplay = selectProductBasicInformationForBillViewResult.result.map(productOption => productOption.s + ' - ' + productOption.n);
        this.display.productOptions = selectProductBasicInformationForBillViewResult.result.map(productOption => ({
          'productID': productOption.i,
          'productSKU': productOption.s,
          'productName': productOption.n,
          'productStockAmount': productOption.a,
          'productAutocompleteTitle': productOption.s + ' - ' + productOption.n
        }));
        this.loader.selectProductBasicInformationForBillView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    deleteProductFromProductTransferProducts(productTransferProductID){
      this.display.productTransferProductRows = this.display.productTransferProductRows.filter(productTransferProductRow => productTransferProductRow.productTransferProductID != productTransferProductID);
    },

    async updateProductTransferProductStockAmount(){
      for (var productTransferProductIndex in this.display.productTransferProductRows){
        const productTransferProduct = this.display.productTransferProductRows[productTransferProductIndex];
        if (productTransferProduct.productTransferProductID){
          const productTransferProductID = productTransferProduct.productTransferProductID;
          const selectProductStockRequestQuery = {'productStockProductID': productTransferProductID};
          const selectProductStockResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productStock/select/byProductStockProductID', selectProductStockRequestQuery);
          if (selectProductStockResult.success){
            productTransferProduct['productTransferProductStockAmount'] = selectProductStockResult.result.find(productStock => productStock.localityID == this.input.selectedLocalityID).productStockAmount
          } else {
            this.$root.notificationDialog.showNotificationDialog({
              'notificationDialogTitle': 'ERROR',
              'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar el stock del producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
              'notificationDialogColor': 'error',
              'notificationDialogIsPersistent': false
            });
          }
        }
      }
    },

    async appendToProductToProductTransferProducts(){
      const productAutocompleteTitle = this.input.productIDOrName;
      const newProductOption = this.display.productOptions.find(productOption => productOption['productAutocompleteTitle'] == productAutocompleteTitle);
      if (newProductOption){
        const currentProductOption = this.display.productTransferProductRows.find(productTransferProduct => `${productTransferProduct['productTransferProductSKU']} - ${productTransferProduct['productTransferProductName']}` == productAutocompleteTitle);
        if (currentProductOption){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ATENCIÓN',
            'notificationDialogBody': 'El producto ya existe dentro del traslado',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        } else {

          const selectProductStockRequestQuery = {'productStockProductID': newProductOption.productID};
          const selectProductStockResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productStock/select/byProductStockProductID', selectProductStockRequestQuery);
          if (selectProductStockResult.success){
            const productTransferProductRow = 
            {
              'productTransferProductID': newProductOption['productID'],
              'productTransferProductStockAmount': selectProductStockResult.result.find(productStock => productStock.localityID == this.input.selectedLocalityID).productStockAmount,
              'productTransferProductSKU': newProductOption['productSKU'],
              'productTransferProductName': newProductOption['productName'],
              'productTransferProductNote': null
            };
            for (let selectedLocalityIDIndex in this.input.selectedLocalityIDS){
              const selectedLocalityID = this.input.selectedLocalityIDS[selectedLocalityIDIndex];
              productTransferProductRow[`productTransferProductSendAmount_${selectedLocalityID}`] = 0;
            }
            this.display.productTransferProductRows.unshift(productTransferProductRow);
            if (this.display.productTransferProductRows.length == 1){
              const productTransferProductRow = 
              {
                'productTransferProductID': null,
                'productTransferProductStockAmount': null,
                'productTransferProductSKU': null,
                'productTransferProductName': null,
                'productTransferProductNote': null
              };
              for (let selectedLocalityIDIndex in this.input.selectedLocalityIDS){
                const selectedLocalityID = this.input.selectedLocalityIDS[selectedLocalityIDIndex];
                productTransferProductRow[`productTransferProductSendAmount_${selectedLocalityID}`] = null;
              }
              this.display.productTransferProductRows.push(productTransferProductRow);
            }
            this.$root.notificationDialog.showNotificationDialog({
              'notificationDialogTitle': 'ÉXITO',
              'notificationDialogBody': 'El producto ha sido agregado al traslado',
              'notificationDialogColor': 'success',
              'notificationDialogIsPersistent': false
            });
          } else {
            this.$root.notificationDialog.showNotificationDialog({
              'notificationDialogTitle': 'ERROR',
              'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar el stock del producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
              'notificationDialogColor': 'error',
              'notificationDialogIsPersistent': false
            });
          }
        }
      }
      this.input.productIDOrName = null;
      this.blurSelectedProductInputInterval();
    },

    blurSelectedProductInputInterval(){
      const blurSelectedProductInputInterval = setInterval(() => {
        if (this.$refs.selectedProductInput) {
          this.$refs.selectedProductInput.blur();
          clearInterval(blurSelectedProductInputInterval);
        }
      }, 1);
    },

    async selectLocalityIDAndLocalityName(){
      this.loader.selectLocalityIDAndLocalityName = true;
      const selectLocalityIDAndLocalityNameResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityName');
      if (selectLocalityIDAndLocalityNameResult.success){
        this.display.localityOptions = selectLocalityIDAndLocalityNameResult.result;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectLocalityIDAndLocalityName = false;
    },

    updateSelectedLocalityIDS(){
      this.display.productTransferProductHeaders = 
      [
        {key: 'productTransferProductDeleteAction', title: ''},
        {key: 'productTransferProductName', title: 'NOMBRE'},
        {key: 'productTransferProductStockAmount', title: 'STOCK'},
        {key: 'productTransferProductNote', title: 'NOTA (OPCIONAL)'},
      ];
      for (var selectedLocalityIDIndex in this.input.selectedLocalityIDS){
        const selectedLocalityID = this.input.selectedLocalityIDS[selectedLocalityIDIndex];
        this.display.productTransferProductHeaders.push(
          {
            key: `productTransferProductSendAmount_${selectedLocalityID}`, 
            title: this.display.localityOptions.find(localityOption => localityOption.localityID == selectedLocalityID)?.localityName.toUpperCase() || 'Error'
          }
        );
        for (var productTransferProductRowIndex in this.display.productTransferProductRows){
          const productTransferProduct = this.display.productTransferProductRows[productTransferProductRowIndex];
          if (!productTransferProduct[`productTransferProductSendAmount_${selectedLocalityID}`]){
            if (productTransferProduct.productTransferProductID){
              productTransferProduct[`productTransferProductSendAmount_${selectedLocalityID}`] = 0;
            }
          }
        }
      }
    },

    async selectAllLocality(){
      if (this.input.allLocalitySelected == false){
        this.input.selectedLocalityIDS = this.display.localityOptions.map(localityOption => localityOption.localityID);
      } else {
        this.input.selectedLocalityIDS.length = 0;
      }
      this.updateSelectedLocalityIDS();
    },

    setDefaultValues(){
      this.input.productIDOrName = null;
      this.input.selectedLocalityID = null;
      this.input.selectedLocalityIDS.length = 0;
      this.input.allLocalitySelected = false;
      this.display.productTransferProductRows.length = 0;
      this.loader.generateProductTransfer = false;
    },

    getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
    },

    closeInsertProductTransferDialog(){
      this.setDefaultValues();
      this.navigation.insertProductTransferDialogIsVisible = false;
    },

    async openInsertProductTransferDialog() {
      this.setDefaultValues();
      this.getLocalStorageData();
      await this.selectProductBasicInformationForBillView();
      await this.selectLocalityIDAndLocalityName();
      this.navigation.insertProductTransferDialogIsVisible = true;
    },
  },

  mounted(){
  }
}
</script>