<template>
  <v-dialog v-model="navigation.updateProformaDialogIsVisible" :persistent="true" width="auto">
    <v-card style="padding: 20px 20px 0px 20px;" elevation="3" width="500">
      <div v-if="loader.updateProformaState" style="text-align: center;">
        <v-progress-circular color="success" indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <v-select v-model="input.updatingProformaState" :items="['Proforma aprobada', 'Proforma cancelada']" append-inner-icon="mdi-state-machine" label="Estado de la proforma" variant="solo-filled"></v-select>
        <div v-if="input.updatingProformaState == 'Proforma aprobada'" style="text-align: center;">
          <br>
          <input @change="processImage()" type="file" accept="image/*, .pdf" ref="uploadProformaPaymentImage" id="uploadProformaPaymentImage" style="display: none;">  
          <img v-if="input.updatingProformaImage == null" @click="uploadImage()" :src="assets.placeholderImage" style="cursor: pointer; width: 60%;">
          <img v-else @click="uploadImage()" :src="input.updatingProformaImage" style="cursor: pointer; width: 60%;">
          <br>
        </div>
        <div v-if="input.updatingProformaState">
          <br><br>
          <v-btn @click="updateProformaState()" dark block height="38" color="success">
            <h3>{{ `${input.updatingProformaState == 'Proforma aprobada' ? 'APROBAR' : 'CANCELAR'} PROFORMA` }}</h3>
            <v-icon style="margin-left: 10px;" dark right>mdi-check-circle</v-icon>
          </v-btn>
          <br>
          <v-btn @click="navigation.updateProformaDialogIsVisible = false" dark block height="38" color="error" style="margin-bottom: 20px;">
            <h3>CANCELAR</h3>
            <v-icon style="margin-left: 10px;" dark right>mdi-close-circle</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
  <v-dialog v-model="navigation.dispatchProformaDialogIsVisible" :persistent="true" width="auto">
    <v-card style="padding: 20px;" elevation="3" width="850">
      <div v-if="loader.dispatchProforma" style="text-align: center;">
        <v-progress-circular color="success" indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <v-card style="background-color: #e9e8e8; padding: 20px;">
          <v-data-table-virtual :headers="display.dispatchProformaProductHeaders" :items="display.proformaDrawer.proformaProducts" style="overflow-x: hidden;">
            <template v-slot:item="row">
              <tr :style="(row.item.productStock < row.item.proformaProductAmount) ? 'background-color: #d9b6b6' : (row.item.proformaProductReady ? 'background-color: #bdd9c4' : '')" @contextmenu.prevent="row.item.proformaProductReady = !row.item.proformaProductReady" style="cursor: pointer;">
                <td>
                  <v-checkbox v-model="row.item.proformaProductReady" :disabled="row.item.productStock < row.item.proformaProductAmount" color="info" hide-details></v-checkbox>
                </td>
                <td>
                  {{ row.item.productName }}
                  <p><strong>{{ row.item.productSKU }}</strong></p>
                </td>
                <td>
                  {{ row.item.proformaProductAmount }}
                </td>
                <td>
                  <div v-if="row.item.productStock >= row.item.proformaProductAmount">
                    <p style="color: #009929;"><strong>{{ row.item.productStock }}</strong></p>
                  </div>
                  <div v-else>
                    <v-tooltip text="El producto no cuenta con stock suficiente en esta localidad" location="bottom">
                      <template v-slot:activator="{ props }">
                        <p v-bind="props" class="stockWarningLabel"><strong>{{ row.item.productStock }}</strong></p>
                      </template>
                    </v-tooltip>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table-virtual>          
        </v-card>
        <br><br>
        <div style="text-align: center">
          <input @change="processImage()" type="file" accept="image/*" ref="uploadProformaPaymentImage" id="uploadProformaPaymentImage" style="display: none;">  
          <img v-if="input.updatingProformaImage == null" @click="uploadImage()" :src="assets.placeholderImage" style="cursor: pointer; width: 40%;">
          <img v-else @click="uploadImage()" :src="input.updatingProformaImage" style="cursor: pointer; width: 40%;">
        </div>
        <br><br><br>
        <v-btn @click="dispatchProforma()" dark block height="38" color="success">
          <h3>DESPACHAR PROFORMA</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-cash-register</v-icon>
        </v-btn>
        <br>
        <v-btn @click="navigation.dispatchProformaDialogIsVisible = false" dark block height="38" color="error">
          <h3>CANCELAR</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-close-circle</v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
  <div style="margin: 2% 3% 0% 3%;">
    <v-card style="padding: 20px; background-color: #e9e8e8;" elevation="3">
      <v-expansion-panels v-model="navigation.searchVisible">
        <v-expansion-panel value="searchVisible">
          <v-expansion-panel-title>
            <p style="font-size: large;">Búsqueda de proformas</p>
            <template v-slot:actions>
              <v-icon icon="mdi-magnify"></v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <br>
            <v-autocomplete v-model="input.proformaContactID" :items="display.contactOptions" :loading="loader.selectContactFromProformasView" :disabled="loader.selectContactFromProformasView" item-title="contactName" item-value="contactID" append-inner-icon="mdi-account" label="Filtrar por contacto" variant="solo-filled"></v-autocomplete>
            <div style="display: flex;">
              <div style="width: 49.5%; margin-right: 0.5%;">
                <v-autocomplete v-model="input.proformaCreationAgentID" :items="display.agentOptions" :loading="loader.selectAgentByAgentLocalityIDAndByAgentIsActive" :disabled="loader.selectAgentByAgentLocalityIDAndByAgentIsActive" item-title="agentName" item-value="agentID" append-inner-icon="mdi-account" label="Filtrar por agente de venta" variant="solo-filled"></v-autocomplete>
                <v-select v-model="input.proformaState" :items="display.proformaStateOptions" item-title="title" item-value="value" append-inner-icon="mdi-state-machine" label="Filtrar por estado de la proforma" variant="solo-filled"></v-select>
              </div>
              <div style="width: 49.5%; margin-left: 0.5%;">
                <v-text-field v-model="display.startDateFormatted" @click="navigation.startDatePickerIsVisible = true" readonly label="Filtrar por fecha inicial" append-inner-icon="mdi-calendar" variant="solo-filled"></v-text-field>
                <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
                  <v-card style="display: flex;">
                    <v-date-picker v-model="input.startDate" hide-header color="primary" width="100%"></v-date-picker>
                    <v-chip @click="input.startDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
                  </v-card>
                </v-dialog>
                <v-text-field v-model="display.endDateFormatted" @click="navigation.endDatePickerIsVisible = true" readonly label="Filtrar por fecha final" append-inner-icon="mdi-calendar" variant="solo-filled"></v-text-field>
                <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
                  <v-card>
                    <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
                    <v-chip @click="input.endDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
                  </v-card>
                </v-dialog>
              </div>
            </div>
            <br><br>
            <div v-if="loader.selectProformaFromProformasView" style="text-align: center;">
              <br>
              <v-progress-circular color="info" indeterminate></v-progress-circular>
              <br><br>
            </div>
            <div v-else>
              <v-btn @click="selectProformaFromProformasView()" :disabled="loader.selectAgentByAgentLocalityIDAndByAgentIsActive || loader.selectProformaFromProformasView" dark block height="38" color="primary">
                <h3>BUSCAR</h3>
                <v-icon style="margin-left: 10px;" dark right>mdi-magnify</v-icon>
              </v-btn>
              <br>
              <v-btn @click="setDefaultValues()" :disabled="loader.selectAgentByAgentLocalityIDAndByAgentIsActive || loader.selectProformaFromProformasView" dark block height="38" color="#ffd978" style="margin-bottom: 15px;">
                <h3>LIMPIAR</h3>
                <v-icon style="margin-left: 10px;" dark right>mdi-backspace</v-icon>
              </v-btn>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <div v-if="display.proformaRows.length > 0">
      <br><br>
      <v-card style="padding: 20px; background-color: #e9e8e8;" elevation="3">
        <v-data-table-virtual :headers="display.proformaHeaders" :items="display.proformaRows">
          <template v-slot:item="row">
            <tr @click="openProformaDrawer(row.item.proformaID)" style="cursor: pointer;">
              <td>
                <div style="display: flex;">
                  <v-tooltip :text="row.item.proformaIsCredit ? 'Proforma a crédito' : 'Proforma a contado'" location="right">
                    <template v-slot:activator="{ props }">
                      <v-icon v-bind="props" :color="row.item.proformaIsCredit ? 'error' : 'success'" size="25">{{ row.item.proformaIsCredit ? 'mdi-cash-clock' : 'mdi-cash' }}</v-icon>
                    </template>
                  </v-tooltip>
                  <v-tooltip text="Información del cliente" location="right">
                    <template v-slot:activator="{ props }">
                      <v-icon v-bind="props" @click="$generalFunctions.default.methods.navigateTo(`contact/${row.item.contactID}`)" color="info" style="margin-left: 10px;" size="25">mdi-information</v-icon>
                    </template>
                  </v-tooltip>
                </div>
              </td>
              <td>
                <p>{{ row.item.proformaID }}</p>
              </td>
              <td>
                <v-chip :color="localStorage.proformaStateColors[row.item.proformaState].proformaStateColor" variant="flat">
                  <p :style="{color: localStorage.proformaStateColors[row.item.proformaState].proformaStateFontColor}">{{ row.item.proformaState }}</p>
                </v-chip>
              </td>
              <td>
                <p>{{ row.item.contactName }}</p>
                <p><strong>{{ row.item.contactSSN }}</strong></p>
              </td>
              <td>
                <p>{{ `₡ ${((row.item.proformaProducts.reduce((proformaTotalAmount, proformaProduct) => (proformaTotalAmount + (proformaProduct.proformaProductPrice * proformaProduct.proformaProductAmount)), 0)) + (row.item.proformaProducts.reduce((proformaTaxAmount, proformaProduct) => (proformaTaxAmount + ((proformaProduct.proformaProductPrice * (proformaProduct.proformaProductTaxMargin/100)) * proformaProduct.proformaProductAmount)), 0)) + (row.item.proformaShippingAmount ? row.item.proformaShippingAmount : 0)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}` }}</p>
              </td>
              <td>
                <v-chip :color="row.item.creationAgentColor" variant="flat">
                  <p :style="{color: row.item.creationAgentFontColor}">{{ row.item.creationAgentName }}</p>
                </v-chip>
              </td>
              <td>
                <p>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(row.item.proformaCreationDatetime) }}</p>
                <p><strong>{{ row.item.proformaCreationTimer }}</strong></p>
              </td>
              <td>
                <v-chip :color="row.item.approvedAgentColor || '#bababa'" variant="flat">
                  <p :style="{color: row.item.approvedAgentFontColor || 'black'}">{{ row.item.approvedAgentName || 'No ha sido aprobada' }}</p>
                </v-chip>
              </td>
              <td>
                <p>{{ row.item.proformaApprovedDatetime ? $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(row.item.proformaApprovedDatetime) : 'No ha sido aprobada' }}</p>
                <p v-if="row.item.proformaApprovedDatetime"><strong>{{ row.item.proformaApprovedTimer }}</strong></p>
              </td>
              <td>
                <v-chip :color="row.item.dispatchedAgentColor || '#bababa'" variant="flat">
                  <p :style="{color: row.item.dispatchedAgentFontColor || 'black'}">{{ row.item.dispatchedAgentName || 'No ha sido despachada' }}</p>
                </v-chip>
              </td>
              <td>
                <p>{{ row.item.proformaDispatchedDatetime ? $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(row.item.proformaDispatchedDatetime) : 'No ha sido despachada'}}</p>
              </td>
            </tr>
          </template>
        </v-data-table-virtual>
      </v-card>
    </div>

    <v-navigation-drawer v-model="navigation.proformaDrawerIsVisible" absolute temporary class="bg-info" location="right" style="min-width: 45%;">
      <div v-if="loader.selectProformaFromProformaDrawer" style="display: flex; justify-content: center; align-items: center; height: 100%;">
        <v-progress-circular color="white" indeterminate></v-progress-circular>
      </div>
      <div v-else style="padding: 30px;">
        <div v-if="display.proformaDrawer">
          <v-card style="padding: 20px;">
            <div style="display: flex; justify-content: space-between;">
              <div style="width: 70%;">
                <p style="font-size: large;"><strong>Contacto: </strong> {{ display.proformaDrawer.contactName }}</p>
                <p style="font-size: large; margin-top: 10px;"><strong>Documento de identidad: </strong> {{ display.proformaDrawer.contactSSN }}</p>
              </div>
              <div style="text-align: right;">
                <v-tooltip location="left">
                  <div style="min-width: 600px; display: flex">
                    <div style="width: 49%; margin-right: 1%;">
                      <div v-if="display.proformaDrawer.contactCommercialName" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-store</v-icon>
                        <p style="margin-left: 10px; font-size: large;">{{ display.proformaDrawer.contactCommercialName }}</p>
                      </div>
                      <div v-if="display.proformaDrawer.contactPhoneNumber" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-phone</v-icon>
                        <p style="margin-left: 10px; font-size: large;">{{ display.proformaDrawer.contactPhoneNumber }}</p>
                      </div>
                      <div v-if="display.proformaDrawer.contactEmail" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-email</v-icon>
                        <p style="margin-left: 10px; font-size: large;">{{ display.proformaDrawer.contactEmail }}</p>
                      </div>
                    </div>
                    <div style="width: 49%; margin-left: 1%;">
                      <div v-if="display.proformaDrawer.contactWebPage" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-web</v-icon>
                        <a :href="display.proformaDrawer.contactWebPage.startsWith('http') ? display.proformaDrawer.contactWebPage : `http://${display.proformaDrawer.contactWebPage}`" target="_blank" style="margin-left: 10px; text-decoration: none; color: inherit; font-size: large;">
                          {{ display.proformaDrawer.contactWebPage }}
                        </a>
                      </div>
                      <div v-if="display.proformaDrawer.contactCountry" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-earth</v-icon>
                        <p style="margin-left: 10px; font-size: large;">{{ display.proformaDrawer.contactCountry }}</p>
                      </div>
                      <div v-if="display.proformaDrawer.contactProvince || display.proformaDrawer.contactCanton || display.proformaDrawer.contactDistrict" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-map-marker</v-icon>
                        <p style="margin-left: 10px; font-size: large;">{{ [display.proformaDrawer.contactProvince, display.proformaDrawer.contactCanton, display.proformaDrawer.contactDistrict].filter(Boolean).join(', ') }}</p>
                      </div>
                    </div>
                  </div>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" color="info" size="30" style="cursor: pointer;">mdi-information</v-icon>
                  </template>
                </v-tooltip>
              </div>
            </div>
          </v-card>
          <br>
          <v-card style="padding: 20px;">
            <div style="display: flex; width: 100%;">
              <div style="width: 49%; margin-right: 1%;">
                <p style="font-size: large;"><strong>ID de la proforma: </strong> {{ display.proformaDrawer.proformaID }}</p>
                <div style="display: flex; margin-top: 10px;">
                  <p style="font-size: large;"><strong>Estado: </strong></p>
                  <v-chip :color="localStorage.proformaStateColors[display.proformaDrawer.proformaState].proformaStateColor" variant="flat" style="margin-left: 10px; position: relative; top: -2px;">
                    <p :style="{color: localStorage.proformaStateColors[display.proformaDrawer.proformaState].proformaStateFontColor}">{{ display.proformaDrawer.proformaState }}</p>
                  </v-chip>
                </div>
                <div style="display: flex; margin-top: 30px;">
                  <p style="font-size: large;"><strong>Aprobado por: </strong></p>
                  <v-chip :color="display.proformaDrawer.approvedAgentColor || '#bababa'" variant="flat" style="margin-left: 10px; position: relative; top: -2px;">
                    <p :style="{ color: display.proformaDrawer.approvedAgentFontColor || 'black' }">
                      {{ display.proformaDrawer.approvedAgentName || 'No ha sido aprobada' }}
                    </p>
                  </v-chip>
                </div>
                <p style="font-size: large; margin-top: 10px;"><strong>Aprobado el: </strong> {{ display.proformaDrawer.proformaApprovedDatetime ? $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(display.proformaDrawer.proformaApprovedDatetime) : 'No ha sido aprobada' }}</p>
              </div>
              <div style="width: 49%; margin-left: 1%;">
                <p style="font-size: large;"><strong>Generado el: </strong> {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(display.proformaDrawer.proformaCreationDatetime) }}</p>
                <div style="display: flex; margin-top: 10px;">
                  <p style="font-size: large;"><strong>Generado por: </strong></p>
                  <v-chip :color="display.proformaDrawer.creationAgentColor" variant="flat" style="margin-left: 10px; position: relative; top: -2px;">
                    <p :style="{color: display.proformaDrawer.creationAgentFontColor}">{{ display.proformaDrawer.creationAgentName }}</p>
                  </v-chip>
                </div>
                <div style="display: flex; margin-top: 30px;">
                  <p style="font-size: large;"><strong>Despachado por: </strong></p>
                  <v-chip :color="display.proformaDrawer.dispatchedAgentColor || '#bababa'" variant="flat" style="margin-left: 10px; position: relative; top: -2px;">
                    <p :style="{ color: display.proformaDrawer.dispatchedAgentFontColor || 'black' }">
                      {{ display.proformaDrawer.dispatchedAgentName || 'No ha sido despachada' }}
                    </p>
                  </v-chip>
                </div>
                <p style="font-size: large; margin-top: 10px;"><strong>Despachado el: </strong> {{ display.proformaDrawer.proformaDispatchedDatetime ? $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(display.proformaDrawer.proformaDispatchedDatetime) : 'No ha sido despachada' }}</p>
              </div>
            </div>            
          </v-card>
          <br>
          <v-card style="padding: 20px 20px 0px 20px;">
            <p style="font-size: large;"><strong>Nota: </strong></p>
            <br>
            <v-textarea @click:append-inner="updateProformaNote()" v-model="display.proformaDrawer.proformaNote" rows="3" append-inner-icon="mdi-content-save" variant="solo-filled"></v-textarea>
          </v-card>
          <br>
          <v-card style="padding: 20px;">
            <v-card style="background-color: #e9e8e8; padding: 20px;">
              <v-data-table-virtual :headers="display.proformaProductHeaders" :items="display.proformaDrawer.proformaProducts" style="max-height: 500; overflow-y: auto;">
                <template v-slot:item.productName="{ item }">
                  {{ item.productName }}
                  <p><strong>{{ item.productSKU }}</strong></p>
                </template>
                <template v-slot:item.proformaProductOriginalPrice="{ item }">
                  ₡ {{ item.proformaProductPrice.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                </template>
                <template v-slot:item.proformaProductDiscountMargin="{ item }">
                  ₡ {{ (item.proformaProductPrice * (item.proformaProductDiscountMargin/100)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                </template>
                <template v-slot:item.proformaProductPrice="{ item }">
                  ₡ {{ (item.proformaProductPrice * item.proformaProductAmount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                </template>
              </v-data-table-virtual>
            </v-card>
            <br>
            <div style="display: flex; width: 100%;">
              <div style="display: flex; width: 50%;">
                <div style="width: 49%; margin-right: 1%;">
                  <p style="margin-left: 10px; font-size: large;"><strong>{{ `Unidad${(display.proformaDrawer.proformaProducts.reduce((proformaUnitAmount, proformaProduct) => (proformaUnitAmount + proformaProduct.proformaProductAmount), 0)) > 1 ? 'es' : ''}: ` }}</strong></p>
                  <p style="margin-left: 10px; font-size: large;"><strong>Subtotal: </strong></p>
                  <p style="margin-left: 10px; font-size: large;"><strong>Impuesto: </strong></p>
                  <p v-if="(display.proformaDrawer.proformaProducts.reduce((proformaDiscountAmount, proformaProduct) => (proformaDiscountAmount + ((proformaProduct.proformaProductPrice * (proformaProduct.proformaProductDiscountMargin/100)) * proformaProduct.proformaProductAmount)), 0)) > 0" style="margin-left: 10px; font-size: large;"><strong>Descuento: </strong></p>
                  <p v-if="display.proformaDrawer.proformaShippingAmount > 0" style="margin-left: 10px; font-size: large;"><strong>Envío: </strong></p>
                  <br>
                  <p style="margin-left: 10px; font-size: x-large;"><strong>TOTAL: </strong></p>
                </div>
                <div style="width: 49%; margin-left: 1%;">
                  <p style="font-size: large;">{{ display.proformaDrawer.proformaProducts.reduce((proformaUnitAmount, proformaProduct) => (proformaUnitAmount + proformaProduct.proformaProductAmount), 0) }}</p>
                  <p style="font-size: large;">₡ {{ (display.proformaDrawer.proformaProducts.reduce((proformaSubtotalAmount, proformaProduct) => (proformaSubtotalAmount + (proformaProduct.proformaProductPrice * proformaProduct.proformaProductAmount)), 0)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
                  <p style="font-size: large;">₡ {{ (display.proformaDrawer.proformaProducts.reduce((proformaTaxAmount, proformaProduct) => (proformaTaxAmount + ((proformaProduct.proformaProductPrice * (proformaProduct.proformaProductTaxMargin/100)) * proformaProduct.proformaProductAmount)), 0)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
                  <p v-if="(display.proformaDrawer.proformaProducts.reduce((proformaDiscountAmount, proformaProduct) => (proformaDiscountAmount + ((proformaProduct.proformaProductPrice * (proformaProduct.proformaProductDiscountMargin/100)) * proformaProduct.proformaProductAmount)), 0)) > 0" style="font-size: large;">            ₡ {{ (display.proformaDrawer.proformaProducts.reduce((proformaDiscountAmount, proformaProduct) => (proformaDiscountAmount + ((proformaProduct.proformaProductOriginalPrice * (proformaProduct.proformaProductDiscountMargin/100)) * proformaProduct.proformaProductAmount)), 0)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
                  <p v-if="display.proformaDrawer.proformaShippingAmount > 0" style="font-size: large;">₡ {{ display.proformaDrawer.proformaShippingAmount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
                  <br>
                  <p style="font-size: x-large;">₡ {{ ((display.proformaDrawer.proformaProducts.reduce((proformaTotalAmount, proformaProduct) => (proformaTotalAmount + (proformaProduct.proformaProductPrice * proformaProduct.proformaProductAmount)), 0)) + (display.proformaDrawer.proformaProducts.reduce((proformaTaxAmount, proformaProduct) => (proformaTaxAmount + ((proformaProduct.proformaProductPrice * (proformaProduct.proformaProductTaxMargin/100)) * proformaProduct.proformaProductAmount)), 0)) + (display.proformaDrawer.proformaShippingAmount ? display.proformaDrawer.proformaShippingAmount : 0)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
                </div>
              </div>
              <div style="width: 50%; text-align: right; ">
                <v-tooltip location="left">
                  <div style="min-width: 300px;">
                    <div style="display: flex; padding: 5px;">
                      <v-icon color="info" center>mdi-account-cash</v-icon>
                      <p style="margin-left: 10px; font-size: large;">{{ display.proformaDrawer.proformaIsCredit ? 'Proforma a crédito' : 'Proforma a contado' }}</p>
                    </div>
                    <div style="display: flex; padding: 5px;">
                      <v-icon color="info" center>mdi-source-branch</v-icon>
                      <p style="margin-left: 10px; font-size: large;">{{ display.proformaDrawer.proformaType }}</p>
                    </div>
                    <div style="display: flex; padding: 5px;">
                      <v-icon color="info" center>mdi-cash-multiple</v-icon>
                      <p style="margin-left: 10px; font-size: large;">{{ display.proformaDrawer.proformaPaymentType }}</p>
                    </div>
                    <div v-if="display.proformaDrawer.proformaMixedPaymentID">
                      <br>
                      <div v-if="display.proformaDrawer.proformaMixedPaymentCashAmount" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-cash</v-icon>
                        <p style="margin-left: 10px; font-size: large;">₡ {{ display.proformaDrawer.proformaMixedPaymentCashAmount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
                      </div>
                      <div v-if="display.proformaDrawer.proformaMixedPaymentCardAmount" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-credit-card-outline</v-icon>
                        <p style="margin-left: 10px; font-size: large;">₡ {{ display.proformaDrawer.proformaMixedPaymentCardAmount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
                      </div>
                      <div v-if="display.proformaDrawer.proformaMixedPaymentSINPEAmount" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-cellphone</v-icon>
                        <p style="margin-left: 10px; font-size: large;">₡ {{ display.proformaDrawer.proformaMixedPaymentSINPEAmount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
                      </div>
                      <div v-if="display.proformaDrawer.proformaMixedPaymentDepositAmount" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-bank</v-icon>
                        <p style="margin-left: 10px; font-size: large;">₡ {{ display.proformaDrawer.proformaMixedPaymentDepositAmount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
                      </div>
                    </div>
                  </div>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" color="info" size="30" style="cursor: pointer;">mdi-information</v-icon>
                  </template>
                </v-tooltip>
              </div>
            </div>
            
          </v-card>
          <br>
          <v-card style="padding: 20px;" v-if="((display.proformaDrawer.proformaState == 'Proforma generada') && accessCredential['/proformas/approveProforma']) || (display.proformaDrawer.proformaState == 'Proforma aprobada')">
            <div v-if="accessCredential['/proformas/approveProforma'] && (display.proformaDrawer.proformaState == 'Proforma generada' || display.proformaDrawer.proformaState == 'Proforma cancelada')">
              <v-btn @click="openUpdateProformaDialog()" dark block height="38" color="warning">
                <h3>APROBAR PROFORMA</h3>
                <v-icon style="margin-left: 10px;" dark right>mdi-check-circle</v-icon>
              </v-btn>
            </div>
            <div v-if="accessCredential['/proformas/approveProforma'] && display.proformaDrawer.proformaState == 'Proforma aprobada'">
              <v-btn @click="openUpdateProformaDialog()" dark block height="38" color="error">
                <h3>CANCELAR PROFORMA</h3>
                <v-icon style="margin-left: 10px;" dark right>mdi-close-circle</v-icon>
              </v-btn>
              <br>
            </div>
            <div v-if="accessCredential['/proformas/dispatchProforma'] && display.proformaDrawer.proformaState == 'Proforma aprobada'">
              <v-btn @click="openDispatchProformaDialog()" dark block height="38" color="success">
                <h3>DESPACHAR PROFORMA</h3>
                <v-icon style="margin-left: 10px;" dark right>mdi-package</v-icon>
              </v-btn>
            </div>
          </v-card>
        </div>
      </div>
    </v-navigation-drawer>
    <br><br>
  </div>
</template>

<style scoped>
  .stockWarningLabel {
    cursor: pointer;
    color: rgb(171, 0, 0);
    animation: stockWarningLabelAnimation 2s infinite;
  }

  @keyframes stockWarningLabelAnimation {
    0% {transform: scale(1)}
    50% {transform: scale(1.2)}
    100% {transform: scale(1)}
  }
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProformasView',
  inject: ['$generalFunctions'],

  
  data: () => ({
    input: 
    {
      proformaContactID: null,
      proformaCreationAgentID: null,
      proformaState: null,
      startDate: null,
      endDate: null,
      updatingProformaState: null,
      updatingProformaImage: null
    },

    navigation: 
    {
      searchVisible: 'searchVisible',
      startDatePickerIsVisible: false,
      endDatePickerIsVisible: false,
      proformaDrawerIsVisible: false,
      updateProformaDialogIsVisible: false,
      dispatchProformaDialogIsVisible: false,
      updateInterval: null
    },

    loader: 
    {
      selectContactFromProformasView: false,
      selectAgentByAgentLocalityIDAndByAgentIsActive: false,
      selectProformaFromProformasView: false,
      selectProformaFromProformaDrawer: false,
      updateProformaState: false,
      dispatchProforma: false
    },

    display:
    {
      contactOptions: [],
      agentOptions: [],
      startDateFormatted: null,
      endDateFormatted: null,
      proformaStateOptions: 
      [
        {value: null, title: 'Todos los estados de la proforma'},
        {value: 'Proforma generada', title: 'Proforma generada'},
        {value: 'Proforma aprobada', title: 'Proforma aprobada'},
        {value: 'Proforma facturada', title: 'Proforma facturada'},
        {value: 'Proforma cancelada', title: 'Proforma cancelada'}
      ],
      proformaHeaders: 
      [
        {key: 'proformaIsCredit', title: null},
        {key: 'proformaID', title: 'ID'},
        {key: 'proformaState', title: 'ESTADO'},
        {key: 'contactName', title: 'CONTACTO'},
        {key: 'proformaTotal', title: 'MONTO'},
        {key: 'creationAgentName', title: 'GENERADO POR'},
        {key: 'proformaCreationDatetime', title: 'GENERADO EL'},
        {key: 'approvedAgentName', title: 'APROBADO POR'},
        {key: 'proformaApprovedDatetime', title: 'APROBADO EL'},
        {key: 'dispatchedAgentName', title: 'DESPACHADA POR'},
        {key: 'proformaDispatchedDatetime', title: 'DESPACHADA EL'}
      ],
      proformaRows: [],
      proformaDrawer: null,
      proformaProductHeaders:
      [
        {key: 'productName', title: 'NOMBRE'},
        {key: 'proformaProductOriginalPrice', title: 'PRECIO'},
        {key: 'proformaProductDiscountMargin', title: 'DESCUENTO'},
        {key: 'proformaProductAmount', title: 'CANTIDAD'},
        {key: 'proformaProductPrice', title: 'SUBTOTAL'}
      ],
      dispatchProformaProductHeaders:
      [
        {key: 'proformaProductReady', title: null},
        {key: 'productName', title: 'NOMBRE'},
        {key: 'proformaProductAmount', title: 'CANTIDAD'},
        {key: 'productStock', title: 'STOCK'},

      ]
    },

    localStorage: 
    {
      localityID: null,
      agentID: null,
      proformaStateColors: null
    },

    assets: 
    {
      placeholderImage: null
    },

    accessCredential: {}

  }),

  watch: {
    'input.startDate': function() {
      this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB');
      this.navigation.startDatePickerIsVisible = false;
      this.display.proformaRows.length = 0;
    },
    'input.endDate': function() {
      this.display.endDateFormatted = new Date(this.input.endDate).toLocaleDateString('en-GB');
      this.navigation.endDatePickerIsVisible = false;
      this.display.proformaRows.length = 0;
    },
    'input.updatingProformaState': function(){
      this.input.updatingProformaImage = null;
    }
  },

  methods: {

    async dispatchProforma(){
      if (this.display.proformaDrawer.proformaProducts.filter(proformaProduct => proformaProduct.proformaProductReady).length == this.display.proformaDrawer.proformaProducts.length){
        this.loader.dispatchProforma = true;
        const dispatchProformaRequestQuery =
        {
          'proformaID': this.display.proformaDrawer.proformaID,
          'proformaDispatchedAgentID': this.localStorage.agentID,
          'proformaDispatchedImage': this.input.updatingProformaImage,
          'billLocalityID': 8, // x mayor
          'billStockLocalityID': 5, // bodega
        };
        const dispatchProformaResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/proforma/functions/dispatchProforma', dispatchProformaRequestQuery);
        if (dispatchProformaResult.success){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ÉXITO',
            'notificationDialogBody': 'Se ha despachado la proforma exitosamente',
            'notificationDialogColor': 'success',
            'notificationDialogIsPersistent': false
          });
          this.navigation.dispatchProformaDialogIsVisible = false;
          await this.selectProformaFromProformasView();
          await this.openProformaDrawer(this.display.proformaDrawer.proformaID);
          this.$root.printableBillDialog.showPrintableBillDialog(dispatchProformaResult.result);
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al despachar la proforma, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
        this.loader.dispatchProforma = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al despachar la proforma, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },
    
    async updateProformaNote(){
      this.loader.selectProformaFromProformaDrawer = true;
      const updateProformaNoteRequestQuery =
      {
        'proformaID': this.display.proformaDrawer.proformaID,
        'proformaNote': this.display.proformaDrawer.proformaNote
      };
      const updateProformaNoteResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/proforma/functions/updateProformaNote', updateProformaNoteRequestQuery);
      if (updateProformaNoteResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'Se ha actualizado la nota de la proforma exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        await this.openProformaDrawer(this.display.proformaDrawer.proformaID);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al actualizar la nota de la proforma, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectProformaFromProformaDrawer = false;
    },

    async updateProformaState(){
      this.loader.updateProformaState = true;
      const updateProformaStateRequestQuery =
      {
        'proformaID': this.display.proformaDrawer.proformaID,
        'proformaApprovedAgentID': this.localStorage.agentID,
        'proformaState': this.input.updatingProformaState,
        'proformaApprovedImage': this.input.updatingProformaImage
      };
      const updateProformaStateResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/proforma/functions/updateProformaState', updateProformaStateRequestQuery);
      if (updateProformaStateResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'Se ha actualizado el estado de la proforma exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        this.navigation.updateProformaDialogIsVisible = false;
        await this.selectProformaFromProformasView();
        await this.openProformaDrawer(this.display.proformaDrawer.proformaID);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al actualizar el estado de la proforma, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.updateProformaState = false;
    },

    async openProformaDrawer(proformaID){
      this.navigation.proformaDrawerIsVisible = true;
      this.loader.selectProformaFromProformaDrawer = true;
      const selectProformaFromProformaDrawerRequestQuery =
      {
        'proformaID': proformaID,
        'localityID': this.localStorage.localityID
      };
      const selectProformaFromProformaDrawerResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/proforma/functions/selectProformaFromProformaDrawer', selectProformaFromProformaDrawerRequestQuery);
      if (selectProformaFromProformaDrawerResult.success){
        this.display.proformaDrawer = selectProformaFromProformaDrawerResult.result;
        this.display.proformaDrawer.proformaLoader = false;
        this.display.proformaDrawer.proformaApprovedImage = this.display.proformaDrawer.proformaApprovedImage ? `data:image/jpeg;base64,${this.display.proformaDrawer.proformaApprovedImage}` : null;
        this.display.proformaDrawer.proformaDispatchedImage = this.display.proformaDrawer.proformaDispatchedImage ? `data:image/jpeg;base64,${this.display.proformaDrawer.proformaDispatchedImage}` : null;
        this.display.proformaDrawer.proformaProducts = this.display.proformaDrawer.proformaProducts.map(proformaProduct => ({
          ...proformaProduct,
          'proformaProductReady': false
        }));
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la información de la proforma, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
        this.navigation.proformaDrawerIsVisible = false;
      }
      this.loader.selectProformaFromProformaDrawer = false;
    },

    async selectProformaFromProformasView(){
      this.loader.selectProformaFromProformasView = true;
      const selectProformaFromProformasViewRequestQuery =
      {
        'proformaContactID': this.input.proformaContactID,
        'proformaCreationAgentID': this.input.proformaCreationAgentID,
        'proformaState': this.input.proformaState,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate
      };
      const selectProformaFromProformasViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/proforma/functions/selectProformaFromProformasView', selectProformaFromProformasViewRequestQuery);
      if (selectProformaFromProformasViewResult.success){
        this.display.proformaRows = selectProformaFromProformasViewResult.result.map(proformaRow => ({
          ...proformaRow,
          'proformaCreationTimer': null,
          'proformaApprovedTimer': null
        }));
        this.updateTimers();
        if (this.display.proformaRows.length == 0){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ATENCIÓN',
            'notificationDialogBody': 'No se ha encontrado ninguna proforma con los filtros seleccionados, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        } else {
          this.navigation.searchVisible = '';
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las proformas, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectProformaFromProformasView = false;
    },

    async createProformaPDFDocument(proforma){
      proforma.proformaLoader = true;
      const createProformaPDFDocumentRequestQuery =
      {
        'reprint': true,
        'proformaID': proforma.proformaID
      };
      const createProformaPDFDocumentResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/proforma/functions/createProformaPDFDocument', createProformaPDFDocumentRequestQuery);
      if (createProformaPDFDocumentResult.success){
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': createProformaPDFDocumentResult.result.documentFile.data,
          'printablePDFDocumentName': createProformaPDFDocumentResult.result.documentName,
          'printableExcelDocumentFile': null,
          'printableExcelDocumentName': null
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al reimprimir la proforma, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      proforma.proformaLoader = false;
    },

    async selectAgentByAgentLocalityIDAndByAgentIsActive(){
      this.loader.selectAgentByAgentLocalityIDAndByAgentIsActive = true;
      this.input.selectedProductInAndOutAgentID = null;
      const selectAgentByAgentLocalityIDAndByAgentIsActiveRequestQuery = 
      {
        'agentLocalityID': null, 
        'agentIsActive': true
      };
      const selectAgentByAgentLocalityIDAndByAgentIsActiveResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/select/byAgentLocalityIDAndByAgentIsActive', selectAgentByAgentLocalityIDAndByAgentIsActiveRequestQuery);
      if (selectAgentByAgentLocalityIDAndByAgentIsActiveResult.success){
        this.display.agentOptions =
        [
          {'agentID': null, 'agentName': 'Todos los agentes'},
          ...selectAgentByAgentLocalityIDAndByAgentIsActiveResult.result
        ];
        this.loader.selectAgentByAgentLocalityIDAndByAgentIsActive = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los facturadores, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectContactFromProformasView(){
      this.loader.selectContactFromProformasView = true;
      const selectContactFromProformasViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/contact/functions/selectContactFromInsertProformaDialog');
      if (selectContactFromProformasViewResult.success){
        this.display.contactOptions =
        [
          {'contactID': null, 'contactName': 'Todos los contactos'},
          ...selectContactFromProformasViewResult.result
        ];
        this.loader.selectContactFromProformasView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los contactos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    openUpdateProformaDialog(){
      this.input.updatingProformaState = null;
      this.input.updatingProformaImage = null;
      this.navigation.updateProformaDialogIsVisible = true;
    },

    openDispatchProformaDialog(){
      this.input.updatingProformaImage = null;
      this.display.proformaDrawer.proformaProducts.forEach(proformaProduct => {
        proformaProduct.proformaProductReady = false
      });
      this.navigation.dispatchProformaDialogIsVisible = true;
    },

    uploadImage(){
      document.getElementById('uploadProformaPaymentImage').click();
    },


    processImage() {
  const previousImage = this.input.updatingProformaImage;
  try {
    const selectedFile = this.$refs.uploadProformaPaymentImage.files[0];
    
    // Verificar si se seleccionó un archivo
    if (!selectedFile) {
      throw new Error('No file selected');
    }

    const fileType = selectedFile.type;

    // Verificar si el archivo es una imagen o un PDF
    if (fileType.startsWith('image/')) {
      // Procesar imagen
      const imageFileReader = new FileReader();
      imageFileReader.readAsDataURL(selectedFile);
      imageFileReader.onload = () => {
        this.input.updatingProformaImage = imageFileReader.result;
      };
      imageFileReader.onerror = () => {
        this.$root.notificationDialog.showNotificationDialog({
          notificationDialogTitle: 'ERROR',
          notificationDialogBody: 'Ha ocurrido un error inesperado al cargar la imagen del pago de la proforma. Por favor intente nuevamente. Si el problema persiste, consulte a su administrador de sistema.',
          notificationDialogColor: 'error',
          notificationDialogIsPersistent: false,
        });
        throw new Error('Error when charging the image');
      };
    } else if (fileType === 'application/pdf') {
      // Procesar PDF
      const pdfFileReader = new FileReader();
      pdfFileReader.readAsDataURL(selectedFile);
      pdfFileReader.onload = () => {
        this.input.updatingProformaImage = pdfFileReader.result; // Guardar como base64 si es necesario
      };
      pdfFileReader.onerror = () => {
        this.$root.notificationDialog.showNotificationDialog({
          notificationDialogTitle: 'ERROR',
          notificationDialogBody: 'Ha ocurrido un error inesperado al cargar el archivo PDF. Por favor intente nuevamente. Si el problema persiste, consulte a su administrador de sistema.',
          notificationDialogColor: 'error',
          notificationDialogIsPersistent: false,
        });
        throw new Error('Error when charging the PDF');
      };
    } else {
      this.$root.notificationDialog.showNotificationDialog({
        notificationDialogTitle: 'ERROR',
        notificationDialogBody: 'Formato no compatible. Solo se aceptan imágenes o archivos PDF.',
        notificationDialogColor: 'error',
        notificationDialogIsPersistent: false,
      });
      throw new Error('Unsupported file format');
    }
  } catch (error) {
    this.input.updatingProformaImage = previousImage;
    console.error(error.message);
  }
},

    

    updateTimers(){
      this.navigation.updateInterval = setInterval(() => {
        const formatNumber = (num) => String(num).padStart(2, '0');
        const calculateTimer = (startDate, endDate) => {
          const diff = Math.abs(new Date(endDate || new Date()) - new Date(startDate));
          return `${formatNumber(Math.floor(diff / (1000 * 60 * 60 * 24)))}:${formatNumber(Math.floor((diff / (1000 * 60 * 60)) % 24))}:${formatNumber(Math.floor((diff / (1000 * 60)) % 60))}:${formatNumber(Math.floor((diff / 1000) % 60))}`;
        };
        this.display.proformaRows.forEach(proforma => {
          proforma.proformaCreationTimer = calculateTimer(proforma.proformaCreationDatetime, proforma.proformaApprovedDatetime);
          if (proforma.proformaApprovedDatetime && proforma.proformaState != 'Proforma cancelada') {
            proforma.proformaApprovedTimer = calculateTimer(proforma.proformaApprovedDatetime, proforma.proformaDispatchedDatetime);
          }
        });
      }, 1000);
    },

    async setDefaultValues(){
      this.input.proformaCreationAgentID = null;
      this.input.proformaState = null;
      this.input.startDate = new Date();
      this.input.endDate = new Date();
      this.input.updatingProformaState = null;
      this.input.updatingProformaImage = null;
      this.navigation.startDatePickerIsVisible = false;
      this.navigation.endDatePickerIsVisible = false;
      this.navigation.proformaProductsDialogIsVisible = false;
      this.navigation.updateProformaDialogIsVisible = false;
      this.display.startDateFormatted = null;
      this.display.endDateFormatted = null;
      this.display.proformaRows.length = 0;
    },

    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.localStorage.proformaStateColors = this.$generalFunctions.default.data.proformaStateColors;
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
      this.assets.placeholderImage = this.$generalFunctions.default.data.placeholderImage;
    }
  },


  async mounted(){
    await this.setDefaultValues();
    await this.getLocalStorageData();
    await this.selectAgentByAgentLocalityIDAndByAgentIsActive();
    await this.selectContactFromProformasView();
  },

  beforeUnmount(){
    this.navigation.updateInterval = null;
  }

});

</script>
