<template>
  <div style="margin: 2% 3% 0% 3%;">
    <div v-if="display.information">
      <div style="display: flex;">
        <div style="width: 31.3%; height: 20%; overflow-y: auto;">
          <v-card elevation="3" style="background-color: #ededed; padding: 20px;">
            <div style="text-align: center;">
              <h2>TAREAS:</h2>
              <br>
            </div>
            <div v-if="display.task.length == 0" style="text-align: center;">
              <p>Sin tareas asignadas</p>
            </div>
            <div v-for="task in display.task" v-bind:key="task">
              <v-card elevation="3" style="margin: 20px; padding: 10px; background-color: #c4c4c4;">
                <div style="display: flex; align-items: center; margin-bottom: 15px;">
                  <v-chip :color="task.creationAgentColor" variant="flat">
                    <p :style="{color: task.creationAgentFontColor}"><strong>{{ task.creationAgentName }}</strong></p>
                  </v-chip>
                  <div style="display: flex; margin-left: auto;">
                    <p style="font-size: small;">{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(task.taskCreationDatetime) }}</p>
                    <v-tooltip v-if="task.taskState == 'Tarea asignada'" text="Marcar tarea como completada" location="top">
                      <template v-slot:activator="{ props }">
                        <v-icon @click="completeTask(task.taskID)" v-bind="props" color="warning" style="cursor: pointer; margin-left: 10px;">mdi-check-circle</v-icon>
                      </template>
                    </v-tooltip>
                    <v-tooltip v-else-if="task.taskState == 'Tarea completada'" :text="task.taskState" location="top">
                      <template v-slot:activator="{ props }">
                        <v-icon v-bind="props" color="success" style="cursor: pointer; margin-left: 10px;">mdi-check-circle</v-icon>
                      </template>
                    </v-tooltip>
                  </div>
                </div>
                <p><strong>Tipo de la tarea: </strong>{{ task.taskType }}</p>
                <p v-if="task.taskExpectedDatetime"><strong>Fecha límite: </strong>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(task.taskExpectedDatetime) }}</p>
                <p>"{{ task.taskComment }}"</p>
              </v-card>
            </div>
          </v-card>
        </div>
      </div>
      <br><br>
      <div style="display: flex;">
        <div style="width: 48.5%; height: 20%;">
          <v-card elevation="3" style="background-color: #ededed; padding: 20px; overflow-y: auto;">
            <div style="text-align: center;">
              <h2>CUENTAS POR COBRAR:</h2>
            </div>
            <br>
            <v-data-table-virtual :headers="display.pendingPaymentHeaders" :items="display.information.pendingPayment">
              <template v-slot:item.contactName="{ item }">
                <p>{{ item.contactName }}</p>
                <p><strong>{{ item.contactSSN }}</strong></p>
                <p>{{ item.contactPhoneNumber }}</p>
              </template>
              <template v-slot:item.contactLastBill="{ item }">
                <div v-if="item.contactLastBill">
                  <p><strong>Fecha: </strong>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.contactLastBill) }}</p>
                  <p>Hace {{ $generalFunctions.default.methods.getCurrentDatetimeAndDatetimeOffsetInDays(item.contactLastBill) }} días</p>
                </div>
                <p v-else>Sin compras registradas</p>
              </template>
              <template v-slot:item.contactLastDebtPayment="{ item }">
                <div v-if="item.contactLastDebtPayment">
                  <p><strong>Fecha: </strong>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.contactLastDebtPayment) }}</p>
                  <p>Hace {{ $generalFunctions.default.methods.getCurrentDatetimeAndDatetimeOffsetInDays(item.contactLastDebtPayment) }} días</p>
                </div>
                <p v-else>Sin abonos registrados</p>
              </template>
            </v-data-table-virtual>
          </v-card>
        </div>
        <div style="width: 48.5%; height: 20%; margin-left: 3%;">
          <v-card elevation="3" style="background-color: #ededed; padding: 20px; overflow-y: auto;">
            <div style="text-align: center; display: flex; align-items: center; justify-content: center;">
              <h2>CAMBIO DE PRODUCTOS:</h2>
              <v-icon @click="$generalFunctions.default.methods.navigateTo('/productChange')" size="25" color="info" style="margin-left: 10px;">mdi-invoice-text</v-icon>
            </div>
            <br>
            <v-data-table-virtual :headers="display.productChangeHeaders"  :items="display.information.productChange">
              <template v-slot:item.agentName="{ item }">
                <v-chip :color="item.agentColor" variant="flat">
                  <p :style="{color: item.agentFontColor}">{{ item.agentName }}</p>
                </v-chip>
              </template>
              <template v-slot:item.localityName="{ item }">
                <v-chip :color="item.localityColor" variant="flat">
                  <p :style="{color: item.localityFontColor}">{{ item.localityName }}</p>
                </v-chip>
              </template>
              <template v-slot:item.productChangeDatetime="{ item }">
                <p>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.productChangeDatetime) }}</p>
              </template>
            </v-data-table-virtual>
          </v-card>
        </div>
      </div>
    </div>
    <br><br>
  </div>
</template>

<style scoped>
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'WholesaleDashboardView',
  inject: ['$generalFunctions'],

  
  data: () => ({

    loader: 
    {
      
    },

    display:
    {
      task: [],
      information: null,
      pendingPaymentHeaders: 
      [
        {key: 'contactName', title: 'CONTACTO'},
        {key: 'contactLastBill', title: 'ÚLTIMA COMPRA'},
        {key: 'contactLastDebtPayment', title: 'ÚLTIMO ABONO'}
      ],
      productChangeHeaders: 
      [
        {key: 'productChangeID', title: 'ID'},
        {key: 'localityName', title: 'SOLICITADO EN'},
        {key: 'agentName', title: 'SOLICITADO POR'},
        {key: 'productChangeDatetime', title: 'SOLICITADO EL'},
      ]
    },

    localStorage: {}

  }),

  watch: {
    
  },

  methods: {

    async completeTask(taskID){
      const completeTaskRequestQuery = 
      {
        'taskID': taskID,
        'taskState': 'Tarea completada'
      };
      const completeTaskResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/task/functions/updateTaskState', completeTaskRequestQuery);
      if (completeTaskResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'La tarea ha sido marcada como completada exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        await this.selectTaskByAgentID();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al marcar la tarea como completada, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectTaskByAgentID(){
      const selectTaskByAssignedAgentIDRequestQuery = 
      {
        'taskAssignedAgentID': this.localStorage.agentID
      }
      const selectTaskByAssignedAgentIDResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/task/functions/selectTaskByAgentID', selectTaskByAssignedAgentIDRequestQuery);
      if (selectTaskByAssignedAgentIDResult.success){
        this.display.task = selectTaskByAssignedAgentIDResult.result;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de tareas, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectInformation(){
      const selectInformationRequestQuery =
      {
        'localityID': this.localStorage.localityID
      };
      const selectInformationResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/dashboard/office/functions/selectInformation', selectInformationRequestQuery);
      if (selectInformationResult.success){
        this.display.information = selectInformationResult.result;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la información del dashboard, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },
  
  },

  async mounted(){
    await this.selectInformation();
    await this.selectTaskByAgentID();
  }

});

</script>
