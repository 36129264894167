<template>
  <v-dialog v-model="navigation.productTransferDialogIsVisible" width="auto">
    <v-card width="800" style="padding: 20px;">
      <div v-if="loader.selectProductTransferFromProductTransferDialog" style="text-align: center;">
        <v-progress-circular color="info" indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <p style="font-size: large;"><strong>ID interno: </strong> {{ display.productTransfer.productTransferID }}</p>
        <p style="font-size: large;"><strong>Estado: </strong> {{ display.productTransfer.productTransferState }}</p>
        <br>
        <p style="font-size: large;"><strong>Enviado desde: </strong> {{ display.productTransfer.sendLocalityName }}</p>
        <p style="font-size: large;"><strong>Enviado por: </strong> {{ display.productTransfer.sendAgentName }}</p>
        <p style="font-size: large;"><strong>Enviado el: </strong> {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(display.productTransfer.productTransferSendDatetime) }}</p>
        <br>
        <p style="font-size: large;"><strong>Enviado hasta: </strong> {{ display.productTransfer.receiveLocalityName }}</p>
        <p v-if="display.productTransfer.receiveAgentName" style="font-size: large;"><strong>Recibido por: </strong> {{ display.productTransfer.receiveAgentName }}</p>
        <p v-if="display.productTransfer.productTransferReceiveDatetime" style="font-size: large;"><strong>Recibido el: </strong> {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(display.productTransfer.productTransferReceiveDatetime) }}</p>
        <br>
        <div style="padding: 10px; background-color: #e9e8e8;">
          <v-data-table-virtual :headers="display.productTransferProductHeaders" :items="display.productTransfer.productTransferProducts">
            <template v-slot:item.productTransferProductNote="{ item }">
              {{ item.productTransferProductNote || 'Sin nota del producto' }}
            </template>
          </v-data-table-virtual>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
  
<style scoped>
</style>
  
<script>
export default {
  name: 'ProductTransferDialog',
  inject: ['$generalFunctions'],

  data: () => ({

    loader: 
    {
      selectProductTransferFromProductTransferDialog: false
    },
    
    navigation: 
    {
      productTransferDialogIsVisible: false,
      productTransferID: null
    },

    display:
    {
      productTransfer: null,
      productTransferProductHeaders: 
      [
        {key: 'productSKU', title: 'SKU'},
        {key: 'productName', title: 'NOMBRE'},
        {key: 'productTransferProductSendAmount', title: 'CANTIDAD ENVIADA'},
        {key: 'productTransferProductNote', title: 'NOTA'}
      ],
    }

  }),

  methods: {
    async selectProductTransferFromProductTransferDialog(){
      this.loader.selectProductTransferFromProductTransferDialog = true;
      const selectProductTransferFromProductTransferDialogRequestQuery = 
      {
        'productTransferID': this.navigation.productTransferID
      };
      const selectProductTransferFromProductTransferDialogResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productTransfer/functions/selectProductTransferFromProductTransferDialog', selectProductTransferFromProductTransferDialogRequestQuery);
      if (selectProductTransferFromProductTransferDialogResult.success){
        this.display.productTransfer = selectProductTransferFromProductTransferDialogResult.result;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la información del traslado de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
        await this.setDefaultValues();
      }
      this.loader.selectProductTransferFromProductTransferDialog = false;
    },

    async setDefaultValues(){
      this.loader.selectProductTransferFromProductTransferDialog = false;
      this.navigation.productTransferDialogIsVisible = false;
      this.navigation.productTransferID = null;
      this.display.productTransfer = null;
    },

    async openProductTransferDialog(productTransferDialogRequestQuery){
      await this.setDefaultValues();
      this.navigation.productTransferID = productTransferDialogRequestQuery.productTransferID;
      this.navigation.productTransferDialogIsVisible = true;
      await this.selectProductTransferFromProductTransferDialog();
    }
  },

  mounted(){
    
  }
}
</script>