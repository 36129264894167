<template>
  <v-dialog v-model="navigation.printablePDFExcelDocumentDialogIsVisible" persistent width="auto">
    <v-card width="800" style="padding: 20px;">
      <iframe ref="pdfIframe" style="display: none;"></iframe>
      <div v-if="display.printablePDFDocumentFile">
        <div>
          <vue-pdf-app ref="printablePDFDocument" style="height: 60vh" :pdf="display.printablePDFDocumentBlob"/>
          <br>
        </div>        
        <v-btn @click="downloadPDFDocument()" block dark height="38" color="error">
          <h3>DESCARGAR PDF</h3>
          <v-icon style="margin-left: 20px;" dark right>mdi-file-pdf-box</v-icon>
        </v-btn>
        <br>
        <v-btn @click="printPDFDocument()" block dark height="38" color="#e9e8e8">
          <h3>IMPRIMIR PDF</h3>
          <v-icon style="margin-left: 20px;" dark right>mdi-file-pdf-box</v-icon>
        </v-btn>
        <br v-if="display.printableExcelDocumentFile">
        <br v-if="display.printableExcelDocumentFile">
        <br v-if="display.printableExcelDocumentFile">
      </div>
      <div v-if="display.printableExcelDocumentFile">
        <v-btn @click="downloadExcelDocument()" block dark height="38" color="success">
          <h3>DESCARGAR EXCEL</h3>
          <v-icon style="margin-left: 20px;" dark right>mdi-microsoft-excel</v-icon>
        </v-btn> 
      </div>
      <br><br><br><br><br>
      <v-btn @click="closePrintablePDFExcelDocumentDialog()" block dark height="38" color="info">
        <h3>CERRAR</h3>
        <v-icon style="margin-left: 20px;" dark right>mdi-close-circle-outline</v-icon>
      </v-btn> 
    </v-card>
  </v-dialog>
</template>


<style scoped>

</style>


<script>
import VuePdfApp from "vue3-pdf-app";
import "vue3-pdf-app/dist/icons/main.css";

export default {
  name: "PrintablePDFExcelDocumentDialog",

  components: {
    VuePdfApp
  },

  data: () => ({
    navigation: 
    {
      printablePDFExcelDocumentDialogIsVisible: false,
      printablePDFDocumentIsVisible: false,
      printBillInterval: null
    },

    display: 
    {
      printablePDFDocumentFile: null,
      printablePDFDocumentName: null,
      printablePDFDocumentBlob: null,

      printableExcelDocumentFile: null,
      printableExcelDocumentName: null
    }


  }),

  methods: {

    downloadPDFDocument(){
      try {
        const downloadPDFDocumentURL = URL.createObjectURL(new Blob([new Uint8Array(this.display.printablePDFDocumentFile)], { type: 'application/pdf' }));
        const downloadPDFDocumentElement = document.createElement('a');
        downloadPDFDocumentElement.href = downloadPDFDocumentURL;
        downloadPDFDocumentElement.download = this.display.printablePDFDocumentName;
        document.body.appendChild(downloadPDFDocumentElement);
        downloadPDFDocumentElement.click();
        document.body.removeChild(downloadPDFDocumentElement);
        URL.revokeObjectURL(downloadPDFDocumentURL);
      } catch {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al descargar el documento como PDF, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    printPDFDocument(){
      this.navigation.printablePDFDocumentIsVisible = true;    
      const downloadPDFDocumentURL = URL.createObjectURL(new Blob([new Uint8Array(this.display.printablePDFDocumentFile)], { type: 'application/pdf' }));
      const iframe = this.$refs.pdfIframe;
      iframe.src = downloadPDFDocumentURL;
      iframe.onload = () => {
        iframe.contentWindow.print();
        URL.revokeObjectURL(downloadPDFDocumentURL);
      };
    },

    downloadExcelDocument(){
      try {
        const downloadExcelDocumentURL = URL.createObjectURL(new Blob([new Uint8Array(this.display.printableExcelDocumentFile)], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
        const downloadExcelDocumentElement = document.createElement('a');
        downloadExcelDocumentElement.href = downloadExcelDocumentURL;
        downloadExcelDocumentElement.download = this.display.printableExcelDocumentName;
        document.body.appendChild(downloadExcelDocumentElement);
        downloadExcelDocumentElement.click();
        document.body.removeChild(downloadExcelDocumentElement);
        URL.revokeObjectURL(downloadExcelDocumentURL);
      } catch {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al descargar el documento como Excel, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    createPrintablePDFDocumentBlob(){
      if (this.display.printablePDFDocumentFile){
        this.display.printablePDFDocumentBlob = new Uint8Array(this.display.printablePDFDocumentFile);
      }
    },

    closePrintablePDFExcelDocumentDialog(){
      this.navigation.printablePDFExcelDocumentDialogIsVisible = false;

    },

    openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery) {
      if (this.navigation.printBillInterval){
        clearInterval(this.navigation.printBillInterval);
      }
      this.display.printablePDFDocumentFile = printablePDFExcelDocumentDialogRequestQuery.printablePDFDocumentFile;
      this.display.printablePDFDocumentName = printablePDFExcelDocumentDialogRequestQuery.printablePDFDocumentName;
      this.display.printableExcelDocumentFile = printablePDFExcelDocumentDialogRequestQuery.printableExcelDocumentFile;
      this.display.printableExcelDocumentName = printablePDFExcelDocumentDialogRequestQuery.printableExcelDocumentName;
      this.createPrintablePDFDocumentBlob();
      this.navigation.printablePDFExcelDocumentDialogIsVisible = true;
    }
  }
}
</script>