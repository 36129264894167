<template>
  <v-dialog v-model="navigation.updateProductTransferDialogIsVisible" :persistent="true" width="auto"> 
    <v-card width="1000" style="padding: 20px;">
      <v-select v-model="input.selectedProductTransferResult" :items="display.productTransferResultOptions" append-inner-icon="mdi-store" label="Resultado del traslado" variant="solo-filled"></v-select>
      <v-textarea v-model="input.productTransferReceiveNote" rows="3" prepend-inner-icon="mdi-text-box-outline" label="Nota al recibir" variant="solo-filled"></v-textarea>
      <div v-if="input.selectedProductTransferResult == 'Aceptar'" style="padding: 15px; background-color: #e9e8e8;">
        <v-data-table-virtual :headers="display.productTransferProductHeaders" :items="display.productTransferProductRows">
          <template v-slot:item.productTransferProductNote="{ item }">
            {{ item.productTransferProductNote || 'Sin nota del producto' }}
          </template>
          <template v-slot:item.productTransferProductReceiveAmount="{ item }">
            <v-text-field v-model="item.productTransferProductReceiveAmount" type="number" density="dense" variant="solo-filled" readonly="true" style="margin-top: 20px;"></v-text-field>
          </template>
        </v-data-table-virtual>
        <br>
      </div>
      <br>
      <div v-if="loader.receiveProductTransfer" style="text-align: center;">
        <v-progress-circular color="success" indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <v-btn v-if="input.selectedProductTransferResult" @click="receiveProductTransfer()" dark block height="38" color="success">
          <h3>{{ input.selectedProductTransferResult.toUpperCase() }} TRASLADO</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-check</v-icon>
        </v-btn>
        <br>
        <v-btn @click="closeUpdateProductTransferDialog()" dark block height="38" color="error">
          <h3>CANCELAR</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
  
<style scoped>
</style>
  

<script>
import { viewMethodCaller } from '../viewMethodCaller.js';

export default {
  name: 'InsertProductTransferDialog',
  inject: ['$generalFunctions'],

  data: () => ({

    input:
    {
      selectedProductTransferResult: null,
      productTransferReceiveNote: null
    },

    loader: 
    {
      receiveProductTransfer: false
    },
    
    navigation: 
    {
      productTransferID: null,
      updateProductTransferDialogIsVisible: false
    },

    display:
    {
      productTransferResultOptions: ['Aceptar', 'Rechazar'],
      productTransferProductRows: [],
      productTransferProductHeaders: 
      [
        {key: 'productID', title: 'CÓDIGO INTERNO'},
        {key: 'productSKU', title: 'SKU'},
        {key: 'productName', title: 'NOMBRE'},
        {key: 'productTransferProductSendAmount', title: 'CANTIDAD ENVIADA'},
        {key: 'productTransferProductReceiveAmount', title: 'CANTIDAD RECIBIDA'},
        {key: 'productTransferProductNote', title: 'NOTA'}
      ],
      productTransferOptions: ['Aceptar', 'Rechazar']
    },

    localStorage:
    {
      localityID: null,
      agentID: null
    }
  }),

  watch: {
    
  },

  methods: {

    async receiveProductTransfer(){
      this.loader.receiveProductTransfer = true;
      const receiveProductTransferRequestQuery = 
      {
        'productTransferID': this.navigation.productTransferID,
        'productTransferReceiveAgentID': this.localStorage.agentID,
        'productTransferReceiveNote': this.input.productTransferReceiveNote,
        'productTransferResult': this.input.selectedProductTransferResult,
        'productTransferProducts': this.display.productTransferProductRows
      };
      const receiveProductTransferResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productTransfer/functions/receiveProductTransfer', receiveProductTransferRequestQuery);
      if (receiveProductTransferResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': `El traslado ha sido ${this.input.selectedProductTransferResult == 'Aceptar' ? 'aceptado' : 'rechazado'} exitosamente`,
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        viewMethodCaller.emit('ProductTransferView/methods/selectProductTransferOnProductTransferView()');
        await this.setDefaultValues();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': `Ha ocurrido un error inesperado al ${this.input.selectedProductTransferResult.toLowerCase()} el traslado, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema`,
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.receiveProductTransfer = false;
    },

    async setDefaultValues(){
      this.input.selectedProductTransferResult = null;
      this.input.productTransferReceiveNote = null;
      this.loader.receiveProductTransfer = false;
      this.navigation.productTransferID = null;
      this.navigation.updateProductTransferDialogIsVisible = false;
      this.display.productTransferProductRows.length = 0;
    },
    
    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
    },

    async openUpdateProductTransferDialog(updateProductTransferDialogRequestQuery) {
      await this.setDefaultValues();
      await this.getLocalStorageData();
      this.navigation.productTransferID = updateProductTransferDialogRequestQuery.productTransferID;
      this.display.productTransferProductRows = updateProductTransferDialogRequestQuery.productTransferProducts;
      this.input.selectedProductTransferResult = updateProductTransferDialogRequestQuery.productTransferResult;
      this.navigation.updateProductTransferDialogIsVisible = true;
    },
  },

  mounted(){
  }
}
</script>