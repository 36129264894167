<template>
  <v-dialog v-model="navigation.productStockDialogIsVisible" width="auto">
    <v-card width="600" class="productStockDialogContainer">
      <div v-if="loader.selectProductStockByProductStockProductID">
        <v-progress-circular color="info" indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <div v-for="productStockAmountByLocalityNameIndex in display.productStockAmountByLocalityName" v-bind:key="productStockAmountByLocalityNameIndex">
          <p style="font-size: larger;"><strong>{{ productStockAmountByLocalityNameIndex.localityName }}:</strong> {{ productStockAmountByLocalityNameIndex.productStockAmount }}</p>          
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
  
<style scoped>
  .productStockDialogContainer {
    padding: 25px;
    text-align: center;
  }
</style>
  

<script>

export default {
  name: 'ProductStockDialog',
  inject: ['$generalFunctions'],

  data: () => ({

    loader: 
    {
      selectProductStockByProductStockProductID: false
    },
    
    navigation: 
    {
      productStockDialogIsVisible: false
    },

    display:
    {
      productStockAmountByLocalityName: []
    }

  }),

  methods: {
    async selectProductStockByProductStockProductID(productStockProductID){
      this.loader.selectProductStockByProductStockProductID = true;
      const selectProductStockByProductStockProductIDRequestQuery = {'productStockProductID': productStockProductID};
      const selectProductStockByProductStockProductIDResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productStock/select/byProductStockProductID', selectProductStockByProductStockProductIDRequestQuery);
      if (selectProductStockByProductStockProductIDResult.success){
        this.display.productStockAmountByLocalityName = selectProductStockByProductStockProductIDResult.result;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar el stock del producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
        this.navigation.productStockDialogIsVisible = false;
      }
      this.loader.selectProductStockByProductStockProductID = false;
    },

    openProductStockDialog(openProductStockDialogRequestQuery){
      const productStockProductID = openProductStockDialogRequestQuery.productStockProductID;
      this.navigation.productStockDialogIsVisible = true;
      this.selectProductStockByProductStockProductID(productStockProductID);
    }
  },

  mounted(){
    
  }
}
</script>