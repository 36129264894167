<template>
  <div style="margin: 2% 3% 0% 3%;">
    <v-card elevation="3" style="padding: 20px; background-color: #e9e8e8;">
      <div style="display: flex" >
        <div style="width: 80%;">
          <v-select v-model="input.selectedLocalityIDS" :items="display.localityOptions" :loading="loader.selectLocalityIDAndLocalityName" multiple chips item-title="localityName" item-value="localityID" append-inner-icon="mdi-store" label="Localidad" variant="solo-filled"></v-select>
        </div>
        <div style="width: 19%; margin-left: 1%;">
          <v-checkbox @click="selectAllLocality()" v-model="input.allLocalitySelected" color="info" label="Todas las localidades" hide-details></v-checkbox>
        </div>
      </div>
      <div style="display: flex" >
        <div style="width: 80%;">
          <v-select v-model="input.selectedAgentIDS" :items="display.agentOptions" :loading="loader.selectAgentByAgentLocalityIDAndByAgentIsActive" multiple chips item-title="agentName" item-value="agentID" append-inner-icon="mdi-account" label="Agente facturador" variant="solo-filled"></v-select>
        </div>
        <div style="width: 19%; margin-left: 1%;">
          <v-checkbox @click="selectAllAgent()" v-model="input.allAgentSelected" color="info" label="Todos los agentes" hide-details></v-checkbox>
        </div>
      </div>
      <v-text-field v-model="display.startDateFormatted" @click="navigation.startDatePickerIsVisible = true" readonly label="Fecha de consulta (inicial)" append-inner-icon="mdi-calendar" variant="solo-filled"></v-text-field>
      <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
        <v-card style="display: flex;">
          <v-date-picker v-model="input.startDate" hide-header color="primary" width="100%"></v-date-picker>
          <v-chip @click="input.startDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
        </v-card>
      </v-dialog>
      <v-text-field v-model="display.endDateFormatted" @click="navigation.endDatePickerIsVisible = true" readonly label="Fecha de consulta (final)" append-inner-icon="mdi-calendar" variant="solo-filled"></v-text-field>
      <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
        <v-card>
          <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
          <v-chip @click="input.endDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
        </v-card>
      </v-dialog>
      <br>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-title>
            Búsqueda avanzada por producto
            <template v-slot:actions>
              <v-icon icon="mdi-magnify"></v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <br>
            <v-autocomplete v-model="input.selectedProductID" :items="display.productOptions" :loading="loader.selectProductBasicBasicInformationForBillsView" :disabled="loader.selectProductBasicBasicInformationForBillsView" ref="selectedProductIDInput" item-title="productAutocompleteTitle" item-value="productID" append-inner-icon="mdi-alpha-a-box-outline" label="Filtrar por producto" variant="solo-filled"></v-autocomplete>
            <div style="display: flex;">
              <div style="width: 49.5%; margin-right: 0.5%;">
                <v-autocomplete v-model="input.selectedProductCategory" :items="display.productCategoryOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productCategoryName" item-value="productCategoryID" append-inner-icon="mdi-cog" label="Filtrar por categoría del producto" variant="solo-filled"></v-autocomplete>
                <v-autocomplete v-model="input.selectedProductBrand" :items="display.productBrandOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productBrandName" item-value="productBrandID" append-inner-icon="mdi-bookshelf" label="Filtrar por marca del producto" variant="solo-filled"></v-autocomplete>
                <v-autocomplete v-model="input.selectedProductModel" :items="display.productModelOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productModelName" item-value="productModelID" append-inner-icon="mdi-source-branch" label="Filtrar por modelo del producto" variant="solo-filled"></v-autocomplete>
              </div>
              <div style="width: 49.5%; margin-left: 0.5%;">
                <v-autocomplete v-model="input.selectedProductFlavor" :items="display.productFlavorOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productFlavorName" item-value="productFlavorID" append-inner-icon="mdi-water" label="Filtrar por sabor del producto" variant="solo-filled"></v-autocomplete>
                <v-autocomplete v-model="input.selectedProductNicotineLevel" :items="display.productNicotineLevelOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productNicotineLevelName" item-value="productNicotineLevelID" append-inner-icon="mdi-weather-cloudy" label="Filtrar por graduación de nicotina del producto" variant="solo-filled"></v-autocomplete>
                <v-autocomplete v-model="input.selectedProductSize" :items="display.productSizeOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productSizeName" item-value="productSizeID" append-inner-icon="mdi-ruler" label="Filtrar por tamaño del producto" variant="solo-filled"></v-autocomplete>
              </div>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <br><br>
      <div v-if="loader.selectBillProductOnProductDailySaleView" style="text-align: center;">
        <v-progress-circular color="info" indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <v-btn @click="selectBillProductOnProductDailySaleView()" :disabled="loader.selectBillProductOnProductDailySaleView" dark block height="38" color="primary">
          <h3>BUSCAR</h3>
          <v-icon style="margin-left: 20px;" dark right>mdi-magnify</v-icon>
        </v-btn>
        <br>
        <v-btn @click="setDefaultValues()" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive || loader.selectAgentByAgentLocalityIDAndByAgentIsActive" dark block height="38" color="#ffd978">
          <h3>LIMPIAR</h3>
          <v-icon style="margin-left: 20px;" dark right>mdi-backspace</v-icon>
        </v-btn>
      </div>
    </v-card>
    <div v-if="display.billProductRows.length != 0">
      <br><br>
      <v-card elevation="3" style="padding: 20px; background-color: #e9e8e8;">
        <br>
        <v-btn @click="generateReportOnProductSaleView()" dark block height="38" color="success">
          <h3>GENERAR REPORTE</h3>
          <v-icon style="margin-left: 20px;" dark right>mdi-printer</v-icon>
        </v-btn>
        <br><br>
        <v-data-table-virtual :headers="display.billProductHeaders"  :items="display.billProductRows" style="max-height: 700px; overflow-y: auto;">
          <template v-slot:item.productCost="{ item }">
            <p>₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(item.productCost) }}</p>
          </template>
          <template v-slot:item.productPrice="{ item }">
            <p>₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(item.productPrice) }}</p>
          </template>
          <template v-slot:item.billProductDiscount="{ item }">
            <p>₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(item.billProductDiscount) }}</p>
          </template>
          <template v-slot:item.billProductSale="{ item }">
            <p>₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(item.billProductSale) }}</p>
          </template>
        </v-data-table-virtual>
      </v-card>
      <br><br>
    </div>
  </div>
</template>

<style scoped>
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProductSaleView',

  inject: ['$generalFunctions'],

  data: () => ({
    input: 
    {
      selectedLocalityIDS: [],
      selectedAgentIDS: [],
      allLocalitySelected: false,
      startDate: null,
      endDate: null,
      selectedProductID: null,
      searchedProductID: null,
      selectedProductCategory: null,
      selectedProductBrand: null,
      selectedProductModel: null,
      selectedProductFlavor: null,
      selectedProductNicotineLevel: null,
      selectedProductSize: null
    },

    display: 
    {
      localityOptions: [],
      startDateFormatted: null,
      endDateFormatted: null,
      productOptions: [],
      productCategoryOptions: [],
      productBrandOptions: [],
      productModelOptions: [],
      productFlavorOptions: [],
      productNicotineLevelOptions: [],
      productSizeOptions: [],
      billProductRows: [],
      billProductHeaders: 
      [
        {key: 'productSKU', title: 'SKU'},
        {key: 'productName', title: 'NOMBRE'},
        {key: 'productCost', title: 'PRECIO DE COSTO'},
        {key: 'productPrice', title: 'PRECIO DE VENTA'},
        {key: 'billProductAmount', title: 'UNIDADES VENDIDAS'},
        {key: 'billProductDiscount', title: 'DESCUENTO TOTAL'},
        {key: 'billProductSale', title: 'VENTA TOTAL'}
      ]
    },

    navigation: 
    {
      startDatePickerIsVisible: false,
      endDatePickerIsVisible: false
    },

    loader:
    {
      selectLocalityIDAndLocalityName: false,
      selectAgentByAgentLocalityIDAndByAgentIsActive: false,
      selectProductSearchParameters: false,
      selectProductBasicBasicInformationForBillsView: false
    },

    localStorage:
    {
      localityID: null,
      agentID: null
    },

    accessCredential: {}
  }),

  watch: {
    'input.startDate': async function() {
      this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB');
      this.navigation.startDatePickerIsVisible = false;
      this.display.billProductRows.length = 0;
    },
    'input.endDate': async function() {
      this.display.endDateFormatted = new Date(this.input.endDate).toLocaleDateString('en-GB');
      this.navigation.endDatePickerIsVisible = false;
      this.display.billProductRows.length = 0;
    },
    'input.selectedLocalityIDS': async function (){
      this.display.billProductRows.length = 0;
      this.input.allLocalitySelected = this.input.selectedLocalityIDS.length == this.display.localityOptions.length;
    },
    'input.selectedAgentIDS': async function(){
      this.display.billProductRows.length = 0;
      this.input.allAgentSelected = this.input.selectedAgentIDS.length == this.display.agentOptions.length;
    }
  },

  methods: {
    
    async selectLocalityIDAndLocalityName(){
      this.loader.selectLocalityIDAndLocalityName = true;
      const selectLocalityIDAndLocalityNameResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityName');
      if (selectLocalityIDAndLocalityNameResult.success){
        this.display.localityOptions = selectLocalityIDAndLocalityNameResult.result;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectLocalityIDAndLocalityName = false;
    },

    async selectAgentByAgentLocalityIDAndByAgentIsActive(){
      this.loader.selectAgentByAgentLocalityIDAndByAgentIsActive = true;
      const selectAgentFromContactDialogRequestQuery = 
      {
        'agentLocalityID': null, 
        'agentIsActive': true,
        'accessCredentialName': '/bill/billAgentID'
      };
      const selectAgentFromContactDialogResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/select/agentIDAndAgentName/byAgentLocalityIDAndByAgentIsActiveAndByAccessCredentialName', selectAgentFromContactDialogRequestQuery);
      if (selectAgentFromContactDialogResult.success){
        this.display.agentOptions = selectAgentFromContactDialogResult.result;
        this.loader.selectAgentByAgentLocalityIDAndByAgentIsActive = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de agentes facturadores, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductSearchParameters(){
      this.loader.selectProductSearchParameters = true;
      const selectProductSearchParametersResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductSearchParameters');
      if (selectProductSearchParametersResult.success){
        this.display.productCategoryOptions = [{'productCategoryName': 'Todas las categorías', 'productCategoryID': null}, ...selectProductSearchParametersResult.result.productCategory];
        this.display.productBrandOptions = [{'productBrandName': 'Todas las marcas', 'productBrandID': null}, ...selectProductSearchParametersResult.result.productBrand];
        this.display.productModelOptions = [{'productModelName': 'Todos los modelos', 'productModelID': null}, ...selectProductSearchParametersResult.result.productModel];
        this.display.productFlavorOptions = [{'productFlavorName': 'Todos los sabores', 'productFlavorID': null}, ...selectProductSearchParametersResult.result.productFlavor];
        this.display.productNicotineLevelOptions = [{'productNicotineLevelName': 'Todas las graduaciones de nicotina', 'productNicotineLevelID': null}, ...selectProductSearchParametersResult.result.productNicotineLevel];
        this.display.productSizeOptions = [{'productSizeName': 'Todos los tamaños', 'productSizeID': null}, ...selectProductSearchParametersResult.result.productSize];
        this.loader.selectProductSearchParameters = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de características de los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductBasicBasicInformationForBillsView(){
      this.loader.selectProductBasicBasicInformationForBillsView = true;
      const selectProductBasicBasicInformationForBillsViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductBasicBasicInformationForBillsView');
      if (selectProductBasicBasicInformationForBillsViewResult.success){
        this.display.productOptions = selectProductBasicBasicInformationForBillsViewResult.result.map(productOption => ({
          'productID': productOption.i,
          'productSKU': productOption.s,
          'productName': productOption.n,
          'productAutocompleteTitle': productOption.s + ' - ' + productOption.n
        }));
        this.loader.selectProductBasicBasicInformationForBillsView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectBillProductOnProductDailySaleView(){
      this.loader.selectBillProductOnProductDailySaleView = true;
      this.display.billProductRows.length = 0;
      const selectBillProductOnProductDailySaleViewRequestQuery = 
      {
        'localityIDS': this.input.selectedLocalityIDS,
        'agentIDS': this.input.selectedAgentIDS,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate,
        'productID': this.input.selectedProductID,
        'productCategoryID': this.input.selectedProductCategory,
        'productBrandID': this.input.selectedProductBrand,
        'productModelID': this.input.selectedProductModel,
        'productFlavorID': this.input.selectedProductFlavor,
        'productNicotineLevelID': this.input.selectedProductNicotineLevel,
        'productSizeID': this.input.selectedProductSize
      };
      const selectBillProductOnProductDailySaleViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/billProduct/functions/selectBillProductOnProductSaleView', selectBillProductOnProductDailySaleViewRequestQuery);
      if (selectBillProductOnProductDailySaleViewResult.success){
        if (selectBillProductOnProductDailySaleViewResult.result.length > 0){
          this.display.billProductRows = selectBillProductOnProductDailySaleViewResult.result;
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ATENCIÓN',
            'notificationDialogBody': 'No se ha encontrado ningún producto vendido con los filtros seleccionados, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los productos vendidos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectBillProductOnProductDailySaleView = false;
    },

    async generateReportOnProductSaleView(){
      const generateReportOnProductSaleViewRequestQuery = 
      {
        'localityID': this.localStorage.localityID,
        'agentID': this.localStorage.agentID,
        'localityIDS': this.input.selectedLocalityIDS,
        'agentIDS': this.input.selectedAgentIDS,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate,
        'productID': this.input.selectedProductID,
        'productCategoryID': this.input.selectedProductCategory,
        'productBrandID': this.input.selectedProductBrand,
        'productModelID': this.input.selectedProductModel,
        'productFlavorID': this.input.selectedProductFlavor,
        'productNicotineLevelID': this.input.selectedProductNicotineLevel,
        'productSizeID': this.input.selectedProductSize
      };
      const generateReportOnProductSaleViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/billProduct/functions/generateReportOnProductSaleView', generateReportOnProductSaleViewRequestQuery);
      if (generateReportOnProductSaleViewResult.success){
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': generateReportOnProductSaleViewResult.result.documentFile.data,
          'printablePDFDocumentName': generateReportOnProductSaleViewResult.result.documentName
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el reporte de los productos vendidos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    selectAllLocality(){
      if (this.input.allLocalitySelected == false){
        this.input.selectedLocalityIDS = this.display.localityOptions.map(localityOption => localityOption.localityID);
      } else {
        this.input.selectedLocalityIDS.length = 0;
      }
    },

    selectAllAgent(){
      if (this.input.allAgentSelected == false){
        this.input.selectedAgentIDS = this.display.agentOptions.map(agentOption => agentOption.agentID);
      } else {
        this.input.selectedAgentIDS.length = 0;
      }
    },

    async setDefaultValues(){
      this.input.selectedLocalityIDS.length = 0;
      this.input.allLocalitySelected = false;
      this.input.selectedAgentIDS.length = 0;
      this.input.allAgentSelected = false;
      const startDate = new Date();
      startDate.setDate(1);
      this.input.startDate = startDate;
      this.input.endDate = new Date();
      this.display.billProductRows.length = 0;
    },

    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
    },
    
  },
  


  async mounted(){
    await this.setDefaultValues();
    await this.getLocalStorageData();
    await this.selectLocalityIDAndLocalityName();
    await this.selectAgentByAgentLocalityIDAndByAgentIsActive();
    this.selectProductBasicBasicInformationForBillsView();
    this.selectProductSearchParameters();
    this.selectAllLocality();
    this.selectAllAgent();
  }
});
</script>
