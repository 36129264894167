<template>
  <v-dialog v-model="navigation.productInAndOutDialogIsVisible" width="auto">
    <v-card width="800" style="padding: 20px;">
      <div v-if="loader.selectProductInAndOutFromProductInAndOutDialog" style="text-align: center;">
        <v-progress-circular color="info" indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <p style="font-size: large;"><strong>ID interno: </strong> {{ display.productInAndOut.productInAndOutID }}</p>
        <p style="font-size: large;"><strong>Tipo de movimiento: </strong> {{ display.productInAndOut.productInAndOutType }}</p>
        <p style="font-size: large;"><strong>Localidad afectada: </strong> {{ display.productInAndOut.affectedLocalityName }}</p>
        <br>
        <p style="font-size: large;"><strong>Generado en: </strong> {{ display.productInAndOut.generatedLocalityName }}</p>
        <p style="font-size: large;"><strong>Generado por: </strong> {{ display.productInAndOut.agentName }}</p>
        <p style="font-size: large;"><strong>Generado el: </strong> {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(display.productInAndOut.productInAndOutDatetime) }}</p>
        <br>
        <p style="font-size: large;"><strong>Motivo: </strong> {{ display.productInAndOut.productInAndOutReason }}</p>
        <p v-if="display.productInAndOut.productInAndOutNote" style="font-size: large;"><strong>Nota: </strong> {{ display.productInAndOut.productInAndOutNote }}</p>
        <br>
        <div style="padding: 10px; background-color: #e9e8e8;">
          <v-data-table-virtual :headers="display.productInAndOutProductHeaders" :items="display.productInAndOut.productInAndOutProducts">
            <template v-slot:item.productInAndOutProductNote="{ item }">
              {{ item.productInAndOutProductNote || 'Sin nota del producto' }}
            </template>
          </v-data-table-virtual>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
  
<style scoped>
</style>
  
<script>
export default {
  name: 'ProductInAndOutDialog',
  inject: ['$generalFunctions'],

  data: () => ({

    loader: 
    {
      selectProductInAndOutFromProductInAndOutDialog: false
    },
    
    navigation: 
    {
      productInAndOutDialogIsVisible: false,
      productInAndOutID: null
    },

    display:
    {
      productInAndOut: null,
      productInAndOutProductHeaders: 
      [
        {key: 'productSKU', title: 'SKU'},
        {key: 'productName', title: 'NOMBRE'},
        {key: 'productInAndOutProductAmount', title: 'CANTIDAD MODIFICADA'},
        {key: 'productInAndOutProductNote', title: 'NOTA'}
      ]
    }

  }),

  methods: {
    async selectProductInAndOutFromProductInAndOutDialog(){
      this.loader.selectProductInAndOutFromProductInAndOutDialog = true;
      const selectProductInAndOutFromProductInAndOutDialogRequestQuery = 
      {
        'productInAndOutID': this.navigation.productInAndOutID
      };
      const selectProductInAndOutFromProductInAndOutDialogResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productInAndOut/functions/selectProductInAndOutFromProductInAndOutDialog', selectProductInAndOutFromProductInAndOutDialogRequestQuery);
      if (selectProductInAndOutFromProductInAndOutDialogResult.success){
        this.display.productInAndOut = selectProductInAndOutFromProductInAndOutDialogResult.result;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la información del movimiento de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
        await this.setDefaultValues();
      }
      this.loader.selectProductInAndOutFromProductInAndOutDialog = false;
    },

    async setDefaultValues(){
      this.loader.selectProductInAndOutFromProductInAndOutDialog = false;
      this.navigation.productInAndOutDialogIsVisible = false;
      this.navigation.productInAndOutID = null;
      this.display.productInAndOut = null;
    },

    async openProductInAndOutDialog(productInAndOutDialogRequestQuery){
      await this.setDefaultValues();
      this.navigation.productInAndOutID = productInAndOutDialogRequestQuery.productInAndOutID;
      this.navigation.productInAndOutDialogIsVisible = true;
      await this.selectProductInAndOutFromProductInAndOutDialog();
    }
  },

  mounted(){
    
  }
}
</script>