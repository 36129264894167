<template>
  <v-dialog v-model="navigation.insertLocalityDialogIsVisible" width="auto">
    <v-card width="600" class="insertLocalityDialogContainer">
      <v-checkbox v-model="input.localityIsActive" label="La localidad estará activa desde su creación" color="success"></v-checkbox>
      <v-text-field v-model="input.localityTaxID" prepend-inner-icon="mdi-store" label="ID de facturación de la localidad" variant="solo-filled"></v-text-field>
      <v-text-field v-model="input.localityName" prepend-inner-icon="mdi-store" label="Nombre de la localidad" variant="solo-filled"></v-text-field>
      <v-select v-model="input.localityType" :items="display.localityTypeOptions" append-inner-icon="mdi-source-branch" label="Tipo de la localidad" variant="solo-filled"></v-select>      
      <v-textarea v-model="input.localityDescription" rows="3" prepend-inner-icon="mdi-text-box-outline" label="Descripción de la localidad" variant="solo-filled"></v-textarea>
      <v-text-field @click="navigation.localityColorDialogIsVisible = true" v-model="input.localityColor" :bg-color="input.localityColor" readonly="true" prepend-inner-icon="mdi-palette" label="Color de la localidad" variant="solo-filled"></v-text-field>
      <v-dialog v-model="navigation.localityColorDialogIsVisible" width="auto">
        <v-card width="300">
          <v-color-picker v-model="input.localityColor" v-model:mode="navigation.localityColorMode" hide-inputs></v-color-picker>
        </v-card>
      </v-dialog>
      <v-text-field @click="navigation.localityFontColorDialogIsVisible = true" v-model="input.localityFontColor" :bg-color="input.localityFontColor" readonly="true" prepend-inner-icon="mdi-palette" label="Color de letra de la localidad" variant="solo-filled"></v-text-field>
      <v-dialog v-model="navigation.localityFontColorDialogIsVisible" width="auto">
        <v-card width="300">
          <v-color-picker v-model="input.localityFontColor" v-model:mode="navigation.localityColorMode" hide-inputs></v-color-picker>
        </v-card>
      </v-dialog>

      <br>
      <div v-if="loader.insertLocality" style="text-align: center;">
        <v-progress-circular color="success" indeterminate></v-progress-circular>
        <br><br>
      </div>
      <div v-else>
        <v-btn @click="insertLocality()" dark block height="38" color="success" style="margin-bottom: 15px;">
          <h3>CREAR LOCALIDAD</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-plus-circle</v-icon>
        </v-btn>
        <v-btn @click="closeInsertLocalityDialog()" dark block height="38" color="error">
          <h3>CANCELAR</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped>
  .insertLocalityDialogContainer {
    padding: 20px;
    text-align: center;
  }
</style>

<script>

export default {
  name: "InsertLocalityDialog",
  inject: ['$generalFunctions'],

  data: () => ({
    navigation: 
    {
      insertLocalityDialogIsVisible: false,
      localityColorMode: 'hex',
      localityColorDialogIsVisible: false,
      localityFontColorDialogIsVisible: false
    },

    input:
    {
      localityTaxID: null,
      localityName: null,
      localityType: 'Tienda',
      localityDescription: null,
      localityColor: null,
      localityFontColor: null,
      localityIsActive: true
    },

    display:
    {
      localityTypeOptions: ['Tienda', 'Bodega']
    },

    loader:
    {
      insertLocality: false
    },

    localStorage: 
    {
    }

  }),

  watch: {
  },

  methods: {
    async insertLocality(){
      this.loader.insertLocality = true;
      const insertLocalityRequestQuery = 
      {
        'localityIsActive': this.input.localityIsActive,
        'localityTaxID': this.input.localityTaxID,
        'localityName': this.input.localityName,
        'localityType': this.input.localityType,
        'localityDescription': this.input.localityDescription,
        'localityColor': this.input.localityColor,
        'localityFontColor': this.input.localityFontColor
      };
      const insertLocalityResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/insert', insertLocalityRequestQuery);
      if (insertLocalityResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'Se ha creado la localidad exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        this.closeInsertLocalityDialog();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al crear la localidad, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.insertLocality = false;
    },

    setDefaultValues() {
      this.navigation.billCancelationDialogIsVisible = false;
      this.input.localityTaxID = null;
      this.input.localityName = null;
      this.input.localityType = 'Tienda';
      this.input.localityDescription = null;
      this.input.localityColor = null;
      this.input.localityFontColor = null;
      this.input.localityIsActive = true;
    },

    openInsertLocalityDialog() {
      this.setDefaultValues();
      this.navigation.insertLocalityDialogIsVisible = true;
    },

    closeInsertLocalityDialog(){
      this.setDefaultValues();
    }
  },

  mounted(){
  }
}
</script>