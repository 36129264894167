<template>
  <v-dialog v-model="navigation.insertTransitProductDialogIsVisible" :persistent="true" width="auto">
    <v-card width="500" style="padding: 20px 20px 20px 20px;">
      <v-combobox 
        v-model="input.transitProductName" 
        :items="display.productOptions" 
        :loading="loader.selectProductBasicBasicInformationForBillsView" 
        :disabled="loader.selectProductBasicBasicInformationForBillsView" 
        item-title="productAutocompleteTitle" 
        item-value="productID" 
        append-inner-icon="mdi-magnify" 
        label="Código o nombre del producto en tránsito" 
        variant="solo-filled"
      ></v-combobox>
      <v-text-field v-model="input.transitProductAmount" type="number" label="Cantidad del producto en tránsito" append-inner-icon="mdi-package" variant="solo-filled" hide-spin-buttons></v-text-field>
      <v-textarea v-model="input.transitProductNote" rows="4" append-inner-icon="mdi-text-box-outline" label="Nota del producto en tránsito (opcional)" variant="solo-filled"></v-textarea>
      <v-text-field v-model="display.transitProductExpetedDatetimeFormatted" @click="navigation.transitProductExpetedDatetimePickerIsVisible = true" readonly label="Fecha esperada para la llegada del producto en tránsito" append-inner-icon="mdi-calendar" variant="solo-filled"></v-text-field>
      <v-dialog v-model="navigation.transitProductExpetedDatetimePickerIsVisible" width="auto">
        <v-card style="display: flex;">
          <v-date-picker v-model="input.transitProductExpetedDatetime" hide-header color="primary" width="100%"></v-date-picker>
          <v-chip @click="input.transitProductExpetedDatetime = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
        </v-card>
      </v-dialog>
      <br><br>
      <v-btn @click="insertTransitProduct()" dark block height="38" color="success">
        <h3>CREAR PRODUCTO EN TRÁNSITO</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-content-save</v-icon>
      </v-btn>
      <br>
      <v-btn @click="navigation.insertTransitProductDialogIsVisible = false" dark block height="38" color="error">
        <h3>CANCELAR</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-close-circle</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
  
  <div style="margin: 2% 3% 0% 3%;">
    <v-select v-model="input.localityID" :items="display.localityOptions" :loading="loader.selectLocalityOptions || loader.updateAgentLocalityID" :disabled="loader.selectLocalityOptions || loader.updateAgentLocalityID" item-title="localityName" item-value="localityID" append-inner-icon="mdi-store" label="Localidad asignada" density="compact" variant="solo-filled"></v-select>
    <div style="display: flex;">
      <v-text-field v-model="display.startDateFormatted" @click="navigation.startDatePickerIsVisible = true" readonly label="Fecha inicial" append-inner-icon="mdi-calendar" variant="solo-filled" style="width: 49.5%; margin-right: 0.5%;" density="compact"></v-text-field>
      <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
        <v-card style="display: flex;">
          <v-date-picker v-model="input.startDate" hide-header color="primary" width="100%"></v-date-picker>
          <v-chip @click="input.startDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
        </v-card>
      </v-dialog>
      <v-text-field v-model="display.endDateFormatted" @click="navigation.endDatePickerIsVisible = true" readonly label="Fecha final" append-inner-icon="mdi-calendar" variant="solo-filled" style="width: 49.5%; margin-left: 0.5%;" density="compact"></v-text-field>
      <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
        <v-card>
          <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
          <v-chip @click="input.endDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
        </v-card>
      </v-dialog>
    </div>
    <br>
    <div v-if="display.information">
      <div style="display: flex;">
        <div v-if="navigation.token">
          <v-tooltip location="bottom">
            <template v-slot:activator="{ props }">
              <v-progress-circular @click="copyTokenValueToClipboard()" :model-value="display.tokenIntervalProgress" v-bind="props" :rotate="360" :size="250" :width="25" color="info" style="cursor: pointer;">
                <div>
                  <h1 style="user-select: none;">{{ navigation.token.tokenValue }}</h1>
                  <div style="text-align: center;">
                    <v-icon @click="createNewSystemToken()" color="info" size="xx-large" style="cursor: pointer">mdi-refresh</v-icon>
                  </div>
                </div>
              </v-progress-circular>   
            </template>
            <span>Tiempo restante: {{ display.tokenIntervalDisplayProgress }}</span>
          </v-tooltip>
        </div>
        <div style="margin-left: 3%;">
          <v-card elevation="3" style="background-color: #ededed;">
            <div style="text-align: center; margin-top: 20px; display: flex; align-items: center; justify-content: center;">
              <h2>VENTAS:</h2>
              <v-icon @click="$generalFunctions.default.methods.navigateTo('/bills')" size="25" color="info" style="margin-left: 10px;">mdi-printer-pos</v-icon>
            </div>
            <div v-for="saleByLocality in display.information.saleByLocality" v-bind:key="saleByLocality" style="display: flex; margin: 20px;">
              <div style="margin-right: 10px; padding: 6px;">
                <p style="font-size: large;">{{ saleByLocality.localityName }}</p>
              </div>
              <div :style="`padding: 5px; background-color: #dedede; cursor: pointer; border: 3px solid ${saleByLocality.localityColor};`">
                <p v-if="saleByLocality.visible" style="font-size: large;">₡ {{ saleByLocality.saleAmount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
                <v-icon v-else @click="setDataAsVisible(saleByLocality)" :color="saleByLocality.localityColor" center>mdi-eye</v-icon>
              </div>
            </div>
          </v-card>
        </div>
        <div style="margin-left: 3%;">
          <v-card elevation="3" style="background-color: #ededed;">
            <div style="text-align: center; margin-top: 20px; display: flex; align-items: center; justify-content: center;">
              <h2>TICKETS:</h2>
              <v-icon @click="$generalFunctions.default.methods.navigateTo('/bills')" size="25" color="info" style="margin-left: 10px;">mdi-printer-pos</v-icon>
            </div>
            <div v-for="ticketByLocality in display.information.ticketByLocality" v-bind:key="ticketByLocality" style="display: flex; margin: 20px;">
              <div style="margin-right: 10px; padding: 6px;">
                <p style="font-size: large;">{{ ticketByLocality.localityName }}</p>
              </div>
              <div :style="`padding: 5px; background-color: #dedede; cursor: pointer; border: 3px solid ${ticketByLocality.localityColor};`">
                <p v-if="ticketByLocality.visible" style="font-size: large;">{{ ticketByLocality.ticketAmount }}</p>
                <v-icon v-else @click="setDataAsVisible(ticketByLocality)" :color="ticketByLocality.localityColor" center>mdi-eye</v-icon>
              </div>
            </div>
          </v-card>
        </div>
        <div style="margin-left: 3%;">
          <v-card elevation="3" style="background-color: #ededed;">
            <div style="text-align: center; margin-top: 20px; display: flex; align-items: center; justify-content: center;">
              <h2>PROMEDIO:</h2>
              <v-icon @click="$generalFunctions.default.methods.navigateTo('/bills')" size="25" color="info" style="margin-left: 10px;">mdi-printer-pos</v-icon>
            </div>
            <div v-for="averageTicketByLocality in display.information.averageTicketByLocality" v-bind:key="averageTicketByLocality" style="display: flex; margin: 20px;">
              <div style="margin-right: 10px; padding: 6px;">
                <p style="font-size: large;">{{ averageTicketByLocality.localityName }}</p>
              </div>
              <div :style="`padding: 5px; background-color: #dedede; cursor: pointer; border: 3px solid ${averageTicketByLocality.localityColor};`">
                <p v-if="averageTicketByLocality.visible" style="font-size: large;">₡ {{ averageTicketByLocality.averageTicketAmount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
                <v-icon v-else @click="setDataAsVisible(averageTicketByLocality)" :color="averageTicketByLocality.localityColor" center>mdi-eye</v-icon>
              </div>
            </div>
          </v-card>
        </div>
        <div style="margin-left: 3%;">
          <v-card elevation="3" style="background-color: #ededed;">
            <div style="text-align: center; margin-top: 20px; display: flex; align-items: center; justify-content: center;">
              <h2>GASTOS:</h2>
              <v-icon @click="$generalFunctions.default.methods.navigateTo('/localityExpense')" size="25" color="info" style="margin-left: 10px;">mdi-cash-multiple</v-icon>
            </div>
            <div v-for="expenseByLocality in display.information.expenseByLocality" v-bind:key="expenseByLocality" style="display: flex; margin: 20px;">            
              <div style="margin-right: 10px; padding: 6px;">
                <p style="font-size: large;">{{ expenseByLocality.localityName }}</p>
              </div>
              <div :style="`padding: 5px; background-color: #dedede; cursor: pointer; border: 3px solid ${expenseByLocality.localityColor};`">
                <p v-if="expenseByLocality.visible" style="font-size: large;">₡ {{ expenseByLocality.expenseAmount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
                <v-icon v-else @click="setDataAsVisible(expenseByLocality)" :color="expenseByLocality.localityColor" center>mdi-eye</v-icon>
              </div>
            </div>
          </v-card>
        </div>
      </div>
      <br><br>
      <div style="display: flex;">
        <div v-if="display.information.monthSaleByAgentOptions && display.information.monthSaleByAgentSeries" style="width: 31.3%; height: 20%;">
          <v-card elevation="3" style="background-color: #ededed;">
            <div style="text-align: center; margin-top: 20px;">
              <h2>TOP VENDEDORES:</h2>
            </div>
            <apexchart type="pie" :options="display.information.monthSaleByAgentOptions" :series="display.information.monthSaleByAgentSeries" style="margin: 20px;"></apexchart>
          </v-card>
        </div>
        <div v-if="display.information.monthSaleByAgentOptions && display.information.monthSaleByAgentSeries" style="margin-left: 3%; width: 31.3%; height: 20%;">
          <v-card elevation="3" style="background-color: #ededed;">
            <div style="text-align: center; margin-top: 20px;">
              <h2>TOP MENSAJEROS:</h2>
            </div>
            <apexchart type="pie" :options="display.information.monthSaleByAgentOptions" :series="display.information.monthSaleByAgentSeries" style="margin: 20px;"></apexchart>
          </v-card>
        </div>
        <div v-if="display.information.monthSaleByProductOptions && display.information.monthSaleByProductSeries" style="margin-left: 3%; width: 31.3%; height: 20%;">
          <v-card elevation="3" style="background-color: #ededed;">
            <div style="text-align: center; margin-top: 20px;">
              <h2>TOP PRODUCTOS:</h2>
            </div>
            <apexchart type="pie" :options="display.information.monthSaleByProductOptions" :series="display.information.monthSaleByProductSeries" style="margin: 20px;"></apexchart>
          </v-card>
        </div>
      </div>
      <br><br>
      <div style="display: flex;">
        <div v-if="display.information.monthSaleByHourOptions && display.information.monthSaleByHourSeries" style="width: 50.6%;">
          <v-card elevation="3" style="background-color: #ededed;">
            <div style="text-align: center; margin-top: 20px;">
              <h2>HORAS DE FACTURACIÓN:</h2>
            </div>
            <apexchart 
              :options="display.information.monthSaleByHourOptions"
              :series="display.information.monthSaleByHourSeries"
              height="300" 
              type="line"
              style="margin: 20px;"
            >
            </apexchart>
          </v-card>
        </div>
        <div v-if="display.information.monthSaleByHourOptions && display.information.monthSaleByHourSeries" style="margin-left: 3%; width: 46.4%;">
          <v-card elevation="3" style="background-color: #ededed; padding: 20px;">
            <div style="text-align: center; display: flex; justify-content: center; align-items: center;">
              <h2 style="margin-right: 8px;">PRODUCTOS EN TRÁNSITO:</h2>
              <v-icon @click="openInsertTransitProductDialog()" color="success" size="35" style="cursor: pointer;">mdi-plus-circle-outline</v-icon>
            </div>
            <br>
            <v-data-table-virtual :headers="display.transitProductHeaders" :items="display.information.transitProduct">
              <template v-slot:item.transitProductCreationDatetime="{ item }">
                <p>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.transitProductCreationDatetime) }}</p>
              </template>
              <template v-slot:item.agentName="{ item }">
                <v-chip :color="item.agentColor" variant="flat">
                  <p :style="{color: item.agentFontColor}">{{ item.agentName }}</p>
                </v-chip>
              </template>
              <template v-slot:item.transitProductExpectedDatetime="{ item }">
                <p>{{ $generalFunctions.default.methods.parseDatetimeToTextDate(item.transitProductExpectedDatetime) }}</p>
              </template>
              
              <template v-slot:item.transitProductAction="{ item }">
                <v-icon @click="deleteTransitProduct(item.transitProductID)" color="error" style="cursor: pointer;">mdi-delete</v-icon>
              </template>
              
            </v-data-table-virtual>
          </v-card>
        </div>
      </div>
    </div>
    <div v-else style="width: 100%; text-align: center;">
      <br><br>
      <v-progress-circular color="info" indeterminate></v-progress-circular>
    </div>
    <br><br>
  </div>
</template>

<style scoped>
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MasterDashboardView',
  inject: ['$generalFunctions'],

  
  data: () => ({
    input: 
    {
      localityID: null,
      startDate: null,
      endDate: null,
      localityName: null,
      transitProductName: null,
      transitProductAmount: null,
      transitProductNote: null,
      transitProductExpetedDatetime: null
    },

    navigation: 
    {
      selectSystemTokenInterval: null,
      calculateTokenIntervalProgressInterval: null,
      token: null,
      startDatePickerIsVisible: false,
      endDatePickerIsVisible: false,
      insertTransitProductDialogIsVisible: null,
      transitProductExpetedDatetimePickerIsVisible: false
    },

    loader: 
    {
      selectLocalityOptions: false,
      selectProductBasicBasicInformationForBillsView: false,
      insertTransitProduct: false
    },

    display:
    {
      localityOptions: [],
      startDateFormatted: null,
      endDateFormatted: null,
      tokenIntervalProgress: 0,
      tokenIntervalDisplayProgress: 0,
      information: null,
      transitProductHeaders:
      [
        {key: 'transitProductName', title: 'PRODUCTO'},
        {key: 'transitProductCreationDatetime', title: 'CREADO EL'},
        {key: 'agentName', title: 'CREADO POR'},
        {key: 'transitProductAmount', title: 'CANTIDAD'},
        {key: 'transitProductNote', title: 'NOTA'},
        {key: 'transitProductExpectedDatetime', title: 'ESPERADO PARA EL'},
        {key: 'transitProductAction', title: null}
      ],
      productOptions: [],
      transitProductExpetedDatetimeFormatted: null
    },

    accessCredential: {},

    localStorage: 
    {
      localityID: null,
      agentID: null
    }

  }),

  watch: {
    'input.transitProductExpetedDatetime': async function() {
      if (this.input.transitProductExpetedDatetime){
        this.display.transitProductExpetedDatetimeFormatted = new Date(this.input.transitProductExpetedDatetime).toLocaleDateString('en-GB');
        this.navigation.transitProductExpetedDatetimePickerIsVisible = false;
      }
    },

    'input.localityID': async function(){
      localStorage.setItem('localityID', this.input.localityID);
      this.localStorage.localityID = this.input.localityID;
      const localityName = this.display.localityOptions.find(localityOption => localityOption.localityID == this.input.localityID).localityName;
      localStorage.setItem('localityName', localityName);
      await this.updateAgentLocalityID();
    },

    'input.startDate': async function() {
      this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB');
      this.navigation.startDatePickerIsVisible = false;
      await this.selectInformation();
    },
    'input.endDate': async function() {
      this.display.endDateFormatted = new Date(this.input.endDate).toLocaleDateString('en-GB');
      this.navigation.endDatePickerIsVisible = false;
      await this.selectInformation();
    },
  },

  methods: {

    openInsertTransitProductDialog(){
      this.input.transitProductName = null;
      this.input.transitProductAmount = null;
      this.input.transitProductNote = null;
      this.input.transitProductExpetedDatetime = null;
      this.display.transitProductExpetedDatetimeFormatted = null;
      this.navigation.insertTransitProductDialogIsVisible = true;
    },

    async createNewSystemToken(){
      const createNewSystemTokenResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/token/functions/createNewSystemToken');
      if (createNewSystemTokenResult.success == false){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al crear el nuevo token del sistema, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async updateAgentLocalityID(){
      this.loader.updateAgentLocalityID = true;
      const updateAgentLocalityIDRequestQuery = 
      {
        'agentID': this.localStorage.agentID,
        'localityID': this.input.localityID
      };
      const updateAgentLocalityIDResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/functions/updateAgentLocalityID', updateAgentLocalityIDRequestQuery);
      if (updateAgentLocalityIDResult.success){
        this.loader.updateAgentLocalityID = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al actualizar la localidad asignada, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async copyTokenValueToClipboard(){
      navigator.clipboard.writeText(this.navigation.token.tokenValue).then(() => {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': null,
          'notificationDialogBody': 'Token copiado al portapapeles',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });      
      }).catch(() => {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al copiar el token al portapapeles, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      });
    },

    async displayTokenIntervalProgress(seconds){
      if (seconds > 0){
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        this.display.tokenIntervalDisplayProgress = `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
      } else {
        this.display.tokenIntervalDisplayProgress = `00:00`;
      }
    },

    async calculateTokenIntervalProgress(){
      if (this.navigation.token){
        const tokenTime = new Date(this.navigation.token.tokenExpirationDatetime);
        const currentTime = new Date();
        const differenceInSeconds = (tokenTime - currentTime) / 1000;
        await this.displayTokenIntervalProgress(parseInt((tokenTime - currentTime) / 1000));
        this.display.tokenIntervalProgress = Math.max(0, Math.min(100, (differenceInSeconds / 120) * 100)) + 1;
      }
    },

    async selectSystemToken(){
      const selectSystemTokenResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/token/functions/selectSystemToken');
      if (selectSystemTokenResult.success){
        this.navigation.token = selectSystemTokenResult.result;
        await this.calculateTokenIntervalProgress();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar el token del sistema, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductBasicBasicInformationForBillsView(){
      this.loader.selectProductBasicBasicInformationForBillsView = true;
      const selectProductBasicBasicInformationForBillsViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductBasicBasicInformationForBillsView');
      if (selectProductBasicBasicInformationForBillsViewResult.success){
        this.display.productOptions = selectProductBasicBasicInformationForBillsViewResult.result.map(productOption => ({
          'productID': productOption.i,
          'productSKU': productOption.s,
          'productName': productOption.n,
          'productAutocompleteTitle': productOption.s + ' - ' + productOption.n
        }));
        this.loader.selectProductBasicBasicInformationForBillsView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectLocalityIDAndLocalityNameByLocalityIsActive(){
      this.loader.selectLocalityOptions = true;
      const selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery = {'localityIsActive': true};
      const selectLocalityIDAndLocalityNameByLocalityIsActiveResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityNameByLocalityIsActive', selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery);
      if (selectLocalityIDAndLocalityNameByLocalityIsActiveResult.success){
        this.display.localityOptions = selectLocalityIDAndLocalityNameByLocalityIsActiveResult.result;
        this.input.localityID = parseInt(this.localStorage.localityID);
        this.loader.selectLocalityOptions = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async insertTransitProduct(){
      this.loader.insertTransitProduct = true;
      const insertTransitProductRequestQuery = 
      {
        'transitProductName': this.input.transitProductName.productName ? this.input.transitProductName.productName : this.input.transitProductName,
        'transitProductAgentID': this.localStorage.agentID,
        'transitProductAmount': this.input.transitProductAmount,
        'transitProductNote': this.input.transitProductNote,
        'transitProductExpectedDatetime': this.input.transitProductExpetedDatetime
      };
      const insertTransitProductResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/dashboard/master/functions/insertTransitProduct', insertTransitProductRequestQuery);
      if (insertTransitProductResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'Producto en tránsito creado exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        this.navigation.insertTransitProductDialogIsVisible = false;
        await this.selectInformation();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al crear el producto en tránsito, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.insertTransitProduct = false;
    },

    async deleteTransitProduct(transitProductID){
      const deleteTransitProductRequestQuery = 
      {
        'transitProductID': transitProductID
      };
      const deleteTransitProductResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/dashboard/master/functions/deleteTransitProduct', deleteTransitProductRequestQuery);
      if (deleteTransitProductResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'Producto en tránsito eliminado exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        await this.selectInformation();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al eliminar el producto en tránsito, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    setDataAsVisible(data){
      data.visible = true;
      setInterval(async () => {
        data.visible = false;
      }, 7000);
    },

    async selectSystemTokenInterval(){
      await this.selectSystemToken();
      this.navigation.selectSystemTokenInterval = setInterval(async () => {
        await this.selectSystemToken();
      }, 1000);

      this.navigation.calculateTokenIntervalProgressInterval = setInterval(async () => {
        await this.calculateTokenIntervalProgress();
      }, 50);
    },

    async generateChart(){
      this.display.information.monthSaleByAgentSeries = this.display.information.monthSaleByAgent.map(monthSaleByAgent => monthSaleByAgent.saleAmount);
      this.display.information.monthSaleByAgentOptions = 
      {
        chart: 
        {
          type: 'pie'
        },
        labels: this.display.information.monthSaleByAgent.map(monthSaleByAgent => monthSaleByAgent.agentName),
        colors: this.display.information.monthSaleByAgent.map(monthSaleByAgent => monthSaleByAgent.agentColor)
      };
      this.display.information.monthSaleByProductSeries = this.display.information.monthSaleByProduct.map(monthSaleByProduct => monthSaleByProduct.productAmount);
      this.display.information.monthSaleByProductOptions = 
      {
        chart: 
        {
          type: 'pie',
          events: {
            click: (clickEvent, chartContext, clickEventOptions) => {
              const clickedProductIndex = clickEventOptions.dataPointIndex; 
              const clickedProductID = this.display.information.monthSaleByProduct[clickedProductIndex].productID;
              this.$generalFunctions.default.methods.navigateTo(`/product/${clickedProductID}`);
            }
          },
        },
        labels: this.display.information.monthSaleByProduct.map(monthSaleByProduct => monthSaleByProduct.productName),
      };
      this.display.information.monthSaleByHourSeries = [{name: '', data: Object.values(this.display.information.monthSaleByHour).map(monthSaleByHour => monthSaleByHour.saleAmount)}];
      this.display.information.monthSaleByHourOptions = 
      {
        chart: {}, 
        markers: {size: 4}, 
        xaxis: {categories: ['8 AM', '9 AM', '10 AM', '11 AM', '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM', '7 PM', '8 PM', '9 PM']}
      };
    },

    async selectInformation(){
      this.display.information = null;
      const selectInformationRequestQuery = 
      {
        'startDate': this.input.startDate,
        'endDate': this.input.endDate
      };
      const selectInformationResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/dashboard/master/functions/selectInformation', selectInformationRequestQuery);
      if (selectInformationResult.success){
        this.display.information = selectInformationResult.result;
        this.generateChart();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la información del dashboard, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
      if (!this.accessCredential['/dashboard/master']){
        this.$generalFunctions.default.methods.navigateTo(localStorage.getItem('agentLandingPage'));
      }
    },

    async setDefaultValues(){
      const startDate = new Date();
      startDate.setDate(1);
      this.input.startDate = startDate;
      this.input.endDate = new Date();
    },
  
  },

  async beforeUnmount () {
    clearInterval(this.navigation.selectSystemTokenInterval)
  },

  async mounted(){
    await this.getLocalStorageData();
    await this.setDefaultValues();
    await this.selectLocalityIDAndLocalityNameByLocalityIsActive();
    await this.selectSystemTokenInterval();
    await this.selectProductBasicBasicInformationForBillsView();
  }

});

</script>