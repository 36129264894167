<template>
  <v-dialog v-model="navigation.insertProductDialogIsVisible" :persistent="true" width="auto">
    <v-card width="1500" style="padding: 20px;">
      <div v-if="loader.selectProductFromInsertProductDialog || loader.insertProduct" style="text-align: center;">
        <br>
        <v-progress-circular color="info" indeterminate></v-progress-circular>
        <br><br>
      </div>
      <div v-else>
        <div style="display: flex;">
          <div style="width: 58.5%; margin-right: 1.5%;">
            <v-card style="padding: 20px 20px 0px 20px;" elevation="2">
              <h3>Información general:</h3>
              <br>
              <v-select v-model="input.productCABYS" :items="display.productCABYSOptions" prepend-inner-icon="mdi-barcode" label="CABYS del producto" variant="solo-filled"></v-select>
              <v-text-field v-if="navigation.productID == null" v-model="input.productID" prepend-inner-icon="mdi-barcode" label="Código interno del producto" variant="solo-filled"></v-text-field>
              <v-text-field v-model="input.productSKU" prepend-inner-icon="mdi-barcode" label="SKU del producto" variant="solo-filled"></v-text-field>
              <v-text-field v-model="input.productName" prepend-inner-icon="mdi-package-variant-closed" label="Nombre del producto" variant="solo-filled"></v-text-field>
              <v-textarea v-model="input.productDescription" rows="10" prepend-inner-icon="mdi-text-box-outline" label="Descripción (página web) del producto" variant="solo-filled"></v-textarea>
              <v-textarea v-model="input.productShortDescription" rows="6" prepend-inner-icon="mdi-text-box-outline" label="Descripción (call center) del producto" variant="solo-filled"></v-textarea>
            </v-card>
            <br>
            <v-card style="padding: 20px 20px 0px 20px;" elevation="2">
              <h3>Información monetaria:</h3>
              <br>
              <v-text-field v-model="input.productCost" label="Precio de costo del producto" type="number" prepend-inner-icon="mdi-cash-minus" variant="solo-filled" hide-spin-buttons></v-text-field>
              <v-select v-model="input.productTaxMargin" :items="display.productTaxMarginOptions" label="Márgen de impuesto del producto" type="number" prepend-inner-icon="mdi-lock-percent" variant="solo-filled" hide-spin-buttons></v-select>
              <v-text-field v-model="input.productMaxDiscountMargin" label="Descuento porcentual máximo del producto" type="number" prepend-inner-icon="mdi-sack-percent" variant="solo-filled" hide-spin-buttons></v-text-field>
              <v-text-field v-model="input.productPrice" label="Precio de venta del producto (detalle)" type="number" prepend-inner-icon="mdi-cash-plus" variant="solo-filled" hide-spin-buttons></v-text-field>
              <div style="display: flex;">
                <div style="width: 49%; margin-right: 1%;">
                  <v-text-field v-model="input.productWholesaleColonPrice" label="Precio de venta del producto (por mayor) ₡" type="number" prepend-inner-icon="mdi-cash-plus" variant="solo-filled" hide-spin-buttons></v-text-field>
                </div>
                <div style="width: 49%; margin-left: 1%;">
                  <v-text-field v-model="input.productWholesaleDolarPrice" label="Precio de venta del producto (por mayor) $" type="number" prepend-inner-icon="mdi-cash-plus" variant="solo-filled" hide-spin-buttons></v-text-field>
                </div>
              </div>
            </v-card>
            <br>
            <v-card style="padding: 20px 20px 0px 20px;" elevation="2">
              <h3>Localidades:</h3>
              <br>
              <div v-if="loader.selectLocalityIDAndLocalityNameByLocalityIsActive" style="text-align: center;">
                <v-progress-circular color="info" indeterminate></v-progress-circular>
                <br><br>
              </div>
              <div v-else>
                <v-autocomplete v-model="input.selectedProductLocation" :items="display.productLocationOptions" append-inner-icon="mdi-library-shelves" label="Ubicación en bodega del producto" variant="solo-filled"></v-autocomplete>
                <div style="display: flex; flex-wrap: wrap; width: 100%;">
                  <div style="flex-basis: 33%; display: flex; flex-direction: column;">
                    <v-switch v-model="input.allLocalitySelected" @change="modifyAllLocalitySelected()" color="success" label="Todas" hide-details style="width: 100%;"></v-switch>
                    <v-text-field v-model="input.allLocalityProductStockGoalAmount" type="number" density="dense" variant="solo-filled" hide-spin-buttons style="margin-top: 10px; width: 40%;"></v-text-field>
                  </div>
                  <div v-for="localityOption in display.localityOptions" v-bind:key="localityOption" style="flex-basis: 33%; display: flex; flex-direction: column;">
                    <v-switch v-model="localityOption.localitySelected" @change="modifyLocalitySelected()" :color="localityOption.localityColor" :label="localityOption.localityName" hide-details style="width: 100%;"></v-switch>
                    <v-text-field v-model="localityOption.productStockGoalAmount" type="number" density="dense" variant="solo-filled" hide-spin-buttons style="margin-top: 10px; width: 40%;"></v-text-field>
                  </div>
                </div>
              </div>
            </v-card>
          </div>
          <div style="width: 40%;">
            <v-card style="padding: 20px 20px 0px 20px;" elevation="2">
              <h3>Composición:</h3>
              <br>
              <v-autocomplete v-model="input.selectedMainProduct" :items="display.mainProductOptions" :loading="loader.selectMainProductListFromInsertProductDialog" :disabled="loader.selectMainProductListFromInsertProductDialog" item-title="mainProductName" item-value="mainProductID" append-inner-icon="mdi-source-branch" label="Nombre del producto madre (opcional)" variant="solo-filled"></v-autocomplete>
            </v-card>
            <br>
            <v-card style="padding: 20px 20px 0px 20px;" elevation="2">
              <h3>Promoción:</h3>
              <br>
              <v-switch v-model="input.productPairProductRelatedToItself" color="indigo" label="Relacionar consigo mismo" hide-details style="margin-bottom: 10px;"></v-switch>
              <v-autocomplete v-if="input.productPairProductRelatedToItself == false" v-model="input.productPairProductID" :items="display.productOptions" :loading="loader.selectProductListFromInsertProductDialog" :disabled="loader.selectProductListFromInsertProductDialog" item-title="productName" item-value="productID" append-inner-icon="mdi-sale" label="Producto relacionado en promoción (opcional)" variant="solo-filled"></v-autocomplete>
            </v-card>
            <br>
            <v-card style="padding: 20px 20px 0px 20px;" elevation="2">
              <h3>Categorización:</h3>
              <br>
              <v-autocomplete v-model="input.selectedProductCategory" :items="display.productCategoryOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productCategoryName" item-value="productCategoryID" append-inner-icon="mdi-cog" label="Categoría del producto (opcional)" variant="solo-filled"></v-autocomplete>
              <v-autocomplete @click:append-inner="openInsertProductSearchParameterDialog('proveedor', 'El')" v-model="input.selectedProductProvider" :items="display.productProviderOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productProviderName" item-value="productProviderID" append-inner-icon="mdi-briefcase" label="Proveedor del producto (opcional)" variant="solo-filled"></v-autocomplete>
              <v-autocomplete @click:append-inner="openInsertProductSearchParameterDialog('marca', 'La')" v-model="input.selectedProductBrand" :items="display.productBrandOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productBrandName" item-value="productBrandID" append-inner-icon="mdi-bookshelf" label="Marca del producto (opcional)" variant="solo-filled"></v-autocomplete>
              <v-autocomplete @click:append-inner="openInsertProductSearchParameterDialog('modelo', 'El')" v-model="input.selectedProductModel" :items="display.productModelOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productModelName" item-value="productModelID" append-inner-icon="mdi-source-branch" label="Modelo del producto (opcional)" variant="solo-filled"></v-autocomplete>
              <v-autocomplete @click:append-inner="openInsertProductSearchParameterDialog('sabor', 'El')" v-model="input.selectedProductFlavor" :items="display.productFlavorOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productFlavorName" item-value="productFlavorID" append-inner-icon="mdi-water" label="Sabor del producto (opcional)" variant="solo-filled"></v-autocomplete>
              <v-autocomplete @click:append-inner="openInsertProductSearchParameterDialog('graduación de nicotina', 'La')" v-model="input.selectedProductNicotineLevel" :items="display.productNicotineLevelOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productNicotineLevelName" item-value="productNicotineLevelID" append-inner-icon="mdi-smoke" label="Graduación de nicotina del producto (opcional)" variant="solo-filled"></v-autocomplete>
              <v-autocomplete @click:append-inner="openInsertProductSearchParameterDialog('tamaño', 'El')" v-model="input.selectedProductSize" :items="display.productSizeOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productSizeName" item-value="productSizeID" append-inner-icon="mdi-ruler" label="Tamaño del producto (opcional)" variant="solo-filled"></v-autocomplete>
              <v-autocomplete @click:append-inner="openInsertProductSearchParameterDialog('color', 'El')" v-model="input.selectedProductColor" :items="display.productColorOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" :bg-color="getProductColorHex()" item-title="productColorName" item-value="productColorID" append-inner-icon="mdi-palette" label="Color del producto (opcional)" variant="solo-filled"></v-autocomplete>

              <v-autocomplete @click:append-inner="openInsertProductSearchParameterDialog('tag', 'El')" v-model="input.selectedProductTagIDS" :items="display.productTagOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" multiple chips item-title="productTagName" item-value="productTagID" append-inner-icon="mdi-tag" label="Tags del producto (opcional)" variant="solo-filled"></v-autocomplete>
            </v-card>
            <br>
            <v-card style="padding: 20px 20px 0px 20px;" elevation="2">
              <h3>Configuración:</h3>
              <br>
              <v-switch v-model="input.productIsReplacedDaily" color="info" label="El producto se repone diariamente" hide-details></v-switch>
              <v-switch v-model="input.productIsVisible" color="warning" label="El producto se muestra en línea" hide-details></v-switch>
              <v-switch v-model="input.productIsActive" color="success" label="El producto está activo" hide-details></v-switch>
            </v-card>
            <br>
            <v-card style="padding: 20px 20px 0px 20px;" elevation="2">
              <h3>Imagen:</h3>
              <br>
              <input @change="processImage()" type="file" accept="image/*" ref="uploadProductImage" id="uploadProductImage" style="display: none;">  
              <img v-if="input.productImage == null" @click="uploadImage()" :src="assets.placeholderImage" style="cursor: pointer; width: 100%;">
              <img v-else @click="uploadImage()" :src="input.productImage" style="cursor: pointer; width: 100%;">
              <br><br>
            </v-card>
          </div>
        </div>
        <br><br><br>
        <v-btn v-if="navigation.productID" @click="updateProduct()" dark block height="38" color="success">
          <h3>ACTUALIZAR PRODUCTO</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-check-circle-outline</v-icon>
        </v-btn>
        <v-btn v-else @click="insertProduct()" dark block height="38" color="success">
          <h3>CREAR PRODUCTO</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-plus-circle-outline</v-icon>
        </v-btn>
        <br>
        <v-btn @click="setDefaultValues()" dark block height="38" color="error">
          <h3>CANCELAR</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
    
<style scoped>
</style>

<script>
import { viewMethodCaller } from '../viewMethodCaller.js';

export default {
  name: 'InsertProductDialog',
  inject: ['$generalFunctions'],

  data: () => ({

    input:
    {
      selectedMainProduct: null,
      productCABYS: null,
      productID: null,
      productSKU: null,
      productName: null,
      productPairProductID: null,
      productPairProductRelatedToItself: false,
      selectedProductCategory: null,
      selectedProductProvider: null,
      selectedProductBrand: null,
      selectedProductModel: null,
      selectedProductFlavor: null,
      selectedProductNicotineLevel: null,
      selectedProductSize: null,
      selectedProductColor: null,
      selectedProductTagIDS: [],
      productDescription: null,
      productShortDescription: null,
      productCost: null,
      productTaxMargin: null,
      productPrice: null,
      productWholesaleColonPrice: null,
      productWholesaleDolarPrice: null,
      productMaxDiscountMargin: 25,
      selectedProductLocation: null,
      productIsReplacedDaily: true,
      productIsVisible: true,
      productIsActive: true,
      productImage: null,
      allLocalitySelected: true,
      allLocalityProductStockGoalAmount: 0
    },

    loader: 
    {
      selectNextAvailableProductID: false,
      selectMainProductListFromInsertProductDialog: false,
      selectProductListFromInsertProductDialog: false,
      selectProductSearchParameters: false,
      selectLocalityIDAndLocalityNameByLocalityIsActive: false,
      selectProductFromInsertProductDialog: false,
      insertProduct: false
    },
    
    navigation: 
    {
      insertProductDialogIsVisible: false,
      productID: null
    },

    display:
    {
      productCABYSOptions: 
      [
        {value: 12345, title: 'Vaporizadores'},
        {value: 67890, title: 'Productos herb'},
        {value: 1122, title: 'Tabaco'}
      ],
      productTaxMarginOptions: 
      [
        {value: 0, title: '0% (excento)'},
        {value: 2, title: '2%'},
        {value: 5, title: '5%'},
        {value: 13, title: '13%'}
      ],
      productLocationOptions: ['A', 'B', 'C1', 'C2', 'D1', 'D2', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q'],
      productOptions: [],
      mainProductOptions: [],
      productCategoryOptions: [],
      productProviderOptions: [],
      productBrandOptions: [],
      productModelOptions: [],
      productFlavorOptions: [],
      productNicotineLevelOptions: [],
      productSizeOptions: [],
      productColorOptions: [],
      productTagOptions: [],
      localityOptions: []
    },

    assets:
    {
      placeholderImage: null
    }

  }),

  watch: {
    async 'input.selectedMainProduct'(){
      if (this.navigation.productID == null){
        const mainProduct = this.display.mainProductOptions.find(mainProductOption => mainProductOption.mainProductID == this.input.selectedMainProduct);
        if (mainProduct && this.navigation.productID == null){
          await this.selectMainProductFromInsertProductDialog();
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al componer el producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });      
        }
      }
      
    },
    'input.selectedProductBrand'(){
      this.composeProductName();
    },
    'input.selectedProductModel'(){
      this.composeProductName();
    },
    'input.selectedProductFlavor'(){
      this.composeProductName();
    },
    'input.selectedProductNicotineLevel'(){
      this.composeProductName();
    },
    'input.selectedProductSize'(){
      this.composeProductName();
    },
    'input.selectedProductColor'(){
      this.composeProductName();
    },
    'input.allLocalityProductStockGoalAmount'(){
      this.display.localityOptions.forEach(localityOption => {
        localityOption.productStockGoalAmount = this.input.allLocalityProductStockGoalAmount;
      });
    }
  },


  methods: {
    validateInput(){
      const regularExpressionChecker = /\S/;
      const positiveDecimalNumberChecker = /^(?:0|(?:[1-9]\d*)|(?:0?\.\d*[1-9]\d*))$/;
      const positiveDecimalMarginNumberChecker = /^(?:100(?:\.0{1,2})?|(?:[1-9]?\d(?:\.\d{1,2})?)|(?:0?\.\d{1,2}))$/;
      if (this.input.productCABYS == null){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Seleccione el CABYS del producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.productID == null || !regularExpressionChecker.test(this.input.productID)){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque el ID del producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.productID.length >= 150){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN DEMASIADO LARGA',
          'notificationDialogBody': 'El ID del producto no puede exceder los 150 caracteres, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.productSKU == null || !regularExpressionChecker.test(this.input.productSKU)){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque el SKU del producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.productSKU.length >= 150){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN DEMASIADO LARGA',
          'notificationDialogBody': 'El SKU del producto no puede exceder los 150 caracteres, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.productName == null || !regularExpressionChecker.test(this.input.productName)){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque el nombre del producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.productName.length >= 150){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN DEMASIADO LARGA',
          'notificationDialogBody': 'El nombre del producto no puede exceder los 150 caracteres, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.productDescription == null || !regularExpressionChecker.test(this.input.productDescription)){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque la descripción (página web) del producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.productDescription.length >= 65000){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN DEMASIADO LARGA',
          'notificationDialogBody': 'La descripción (página web) del producto no puede exceder los 65000 caracteres, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.productShortDescription == null || !regularExpressionChecker.test(this.input.productShortDescription)){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque la descripción (call center) del producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.productShortDescription.length >= 65000){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN DEMASIADO LARGA',
          'notificationDialogBody': 'La descripción (call center) del producto madre no puede exceder los 65000 caracteres, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (!positiveDecimalNumberChecker.test(this.input.productCost)){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque un número positivo para el precio de costo del producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.productTaxMargin == null){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Seleccione el márgen de impuesto del producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (!positiveDecimalNumberChecker.test(this.input.productPrice)){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque un número positivo para el precio de venta del producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (!positiveDecimalMarginNumberChecker.test(this.input.productMaxDiscountMargin)){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque un número positivo entre 0 y 100 para el descuento porcentual máximo del producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.selectedProductLocation == null){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque una ubicación del producto en bodega, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.productImage == null){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque la imagen del producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.display.localityOptions.find(localityOption => !positiveDecimalNumberChecker.test(localityOption.productStockGoalAmount))){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': `Coloque una cantidad de stock mínimo para ${this.display.localityOptions.find(localityOption => !positiveDecimalNumberChecker.test(localityOption.productStockGoalAmount)).localityName}, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema`,
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      return true;
    },

    async selectMainProductFromInsertProductDialog(){
      this.loader.insertProduct = true;
      const selectMainProductFromInsertProductDialogRequestQuery = 
      {
        'mainProductID': this.input.selectedMainProduct
      };
      const selectMainProductFromInsertProductDialogResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/mainProduct/functions/selectMainProductFromInsertMainProductDialog', selectMainProductFromInsertProductDialogRequestQuery);
      if (selectMainProductFromInsertProductDialogResult.success){
        const mainProduct = selectMainProductFromInsertProductDialogResult.result;
        this.input.productCABYS = parseInt(mainProduct.mainProductCABYS);
        this.input.productName = mainProduct.mainProductName;
        this.input.productDescription = mainProduct.mainProductDescription;
        this.input.productShortDescription = mainProduct.mainProductShortDescription;
        this.input.selectedProductCategory = mainProduct.mainProductProductCategoryID;
        this.input.selectedProductProvider = mainProduct.mainProductProductProviderID;
        this.input.selectedProductBrand = mainProduct.mainProductProductBrandID;
        this.input.selectedProductModel = mainProduct.mainProductProductModelID;
        this.input.selectedProductFlavor = mainProduct.mainProductProductFlavorID;
        this.input.selectedProductNicotineLevel = mainProduct.mainProductProductNicotineLevelID;
        this.input.selectedProductSize = mainProduct.mainProductProductSizeID;
        this.input.selectedProductTagIDS = mainProduct.mainProductTagIDS.map(mainProductTagID => mainProductTagID.productTagID);
        this.input.productCost = mainProduct.mainProductCost;
        this.input.productTaxMargin = parseInt(mainProduct.mainProductTaxMargin);
        this.input.productPrice = mainProduct.mainProductPrice;
        this.input.productMaxDiscountMargin = mainProduct.mainProductMaxDiscountMargin;
        this.input.selectedProductLocation = mainProduct.mainProductLocation;
        this.input.productIsReplacedDaily = mainProduct.mainProductIsReplacedDaily == 1;
        this.input.productIsVisible = mainProduct.mainProductIsVisible == 1;
        this.input.productIsActive = mainProduct.mainProductIsActive == 1;
        this.input.productImage = `data:image/jpeg;base64,${mainProduct.mainProductImage}`;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al componer el producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'info',
          'notificationDialogIsPersistent': false
        }); 
      }
      this.loader.insertProduct = false;
    },

    async updateProduct(){
      if (this.validateInput()){
        this.loader.insertProduct = true;
        const updateProductRequestQuery = 
        {
          'productID': this.navigation.productID,
          'productPairProductID': this.input.productPairProductRelatedToItself ? this.navigation.productID : this.input.productPairProductID,
          'productMainProductID': this.input.selectedMainProduct,
          'productProductCategoryID': this.input.selectedProductCategory,
          'productProductProviderID': this.input.selectedProductProvider,
          'productProductBrandID': this.input.selectedProductBrand,
          'productProductModelID': this.input.selectedProductModel,
          'productProductFlavorID': this.input.selectedProductFlavor,
          'productProductNicotineLevelID': this.input.selectedProductNicotineLevel,
          'productProductSizeID': this.input.selectedProductSize,
          'productProductColorID': this.input.selectedProductColor,
          'productTagIDS': this.input.selectedProductTagIDS,
          'productSKU': this.input.productSKU,
          'productCABYS': this.input.productCABYS,
          'productName': this.input.productName,
          'productDescription': this.input.productDescription,
          'productShortDescription': this.input.productShortDescription,
          'productCost': this.input.productCost,
          'productTaxMargin': this.input.productTaxMargin,
          'productPrice': this.input.productPrice,
          'productWholesaleColonPrice': this.input.productWholesaleColonPrice,
          'productWholesaleDolarPrice': this.input.productWholesaleDolarPrice,
          'productMaxDiscountMargin': this.input.productMaxDiscountMargin,
          'productLocation': this.input.selectedProductLocation,
          'productIsReplacedDaily': this.input.productIsReplacedDaily,
          'productIsVisible': this.input.productIsVisible,
          'productIsActive': this.input.productIsActive,
          'productImage': this.input.productImage,
          'productInformationByLocality': this.display.localityOptions
        }
        const updateProductResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/updateProduct', updateProductRequestQuery);
        if (updateProductResult.success){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ÉXITO',
            'notificationDialogBody': 'Se ha actualizado el producto exitosamente',
            'notificationDialogColor': 'success',
            'notificationDialogIsPersistent': false
          });
          this.setDefaultValues();
          viewMethodCaller.emit('InsertProductDialog/methods/updateProduct()');
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al actualizar el producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
        this.loader.insertProduct = false;
      }
    },
    
    async insertProduct(){
      if (this.validateInput()){
        this.loader.insertProduct = true;
        const insertProductRequestQuery = 
        {
          'productID': this.input.productID,
          'productPairProductID': this.input.productPairProductRelatedToItself ? this.input.productID : this.input.productPairProductID,
          'productMainProductID': this.input.selectedMainProduct,
          'productProductCategoryID': this.input.selectedProductCategory,
          'productProductProviderID': this.input.selectedProductProvider,
          'productProductBrandID': this.input.selectedProductBrand,
          'productProductModelID': this.input.selectedProductModel,
          'productProductFlavorID': this.input.selectedProductFlavor,
          'productProductNicotineLevelID': this.input.selectedProductNicotineLevel,
          'productProductSizeID': this.input.selectedProductSize,
          'productProductColorID': this.input.selectedProductColor,
          'productTagIDS': this.input.selectedProductTagIDS,
          'productSKU': this.input.productSKU,
          'productCABYS': this.input.productCABYS,
          'productName': this.input.productName,
          'productDescription': this.input.productDescription,
          'productShortDescription': this.input.productShortDescription,
          'productCost': this.input.productCost,
          'productTaxMargin': this.input.productTaxMargin,
          'productPrice': this.input.productPrice,
          'productWholesaleColonPrice': this.input.productWholesaleColonPrice,
          'productWholesaleDolarPrice': this.input.productWholesaleDolarPrice,
          'productMaxDiscountMargin': this.input.productMaxDiscountMargin,
          'productLocation': this.input.selectedProductLocation,
          'productIsReplacedDaily': this.input.productIsReplacedDaily,
          'productIsVisible': this.input.productIsVisible,
          'productIsActive': this.input.productIsActive,
          'productImage': this.input.productImage,
          'productInformationByLocality': this.display.localityOptions
        }
        const insertProductResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/insertProduct', insertProductRequestQuery);
        if (insertProductResult.success){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ÉXITO',
            'notificationDialogBody': 'Se ha creado el producto exitosamente',
            'notificationDialogColor': 'success',
            'notificationDialogIsPersistent': false
          });
          this.setDefaultValues();
          viewMethodCaller.emit('InsertProductDialog/methods/insertProduct()');
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al crear el producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
        this.loader.insertProduct = false;
      }
    },

    async selectLocalityIDAndLocalityNameByLocalityIsActive(){
      this.loader.selectLocalityIDAndLocalityNameByLocalityIsActive = true;
      const selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery = {'localityIsActive': true};
      const selectLocalityIDAndLocalityNameByLocalityIsActiveResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityNameByLocalityIsActive', selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery);
      if (selectLocalityIDAndLocalityNameByLocalityIsActiveResult.success){
        this.display.localityOptions = selectLocalityIDAndLocalityNameByLocalityIsActiveResult.result.map(localityIDAndLocalityName => ({...localityIDAndLocalityName, 'localitySelected': true, 'productStockGoalAmount': 0}));
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
        this.setDefaultValues();
      }
      this.loader.selectLocalityIDAndLocalityNameByLocalityIsActive = false;
    },

    async selectMainProductListFromInsertProductDialog(){
      this.loader.selectMainProductListFromInsertProductDialog = true;
      const selectMainProductListFromInsertProductDialogResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/mainProduct/functions/selectMainProductListFromInsertProductDialog');
      if (selectMainProductListFromInsertProductDialogResult.success){
        this.display.mainProductOptions = [{'mainProductName': 'El producto no es compuesto', 'mainProductID': null}, ...selectMainProductListFromInsertProductDialogResult.result];
        this.loader.selectMainProductListFromInsertProductDialog = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de productos madre, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductListFromInsertProductDialog(){
      this.loader.selectProductListFromInsertProductDialog = true;
      const selectProductListFromInsertProductDialogResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductListFromInsertProductDialog');
      if (selectProductListFromInsertProductDialogResult.success){
        this.display.productOptions = [{'productName': 'El producto no está relacionado en promoción', 'productID': null}, ...selectProductListFromInsertProductDialogResult.result];
        this.loader.selectProductListFromInsertProductDialog = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de productos para relacionar en promoción, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },  

    async selectProductFromInsertProductDialog(){
      this.loader.selectProductFromInsertProductDialog = true;
      const selectProductFromInsertProductDialogRequestQuery = 
      {
        'productID': this.navigation.productID
      };
      const selectProductFromInsertProductDialogResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductFromInsertProductDialog', selectProductFromInsertProductDialogRequestQuery);
      if (selectProductFromInsertProductDialogResult.success){
        const product = selectProductFromInsertProductDialogResult.result;
        this.input.productCABYS = parseInt(product.productCABYS);
        this.input.productSKU = product.productSKU;
        this.input.productName = product.productName;
        this.input.productDescription = product.productDescription;
        this.input.productShortDescription = product.productShortDescription;
        this.input.productCost = product.productCost;
        this.input.productTaxMargin = product.productTaxMargin;
        this.input.productPrice = product.productPrice;
        this.input.productWholesaleColonPrice = product.productWholesaleColonPrice;
        this.input.productWholesaleDolarPrice = product.productWholesaleDolarPrice;
        this.input.productMaxDiscountMargin = product.productMaxDiscountMargin;
        this.input.selectedProductLocation = product.productLocation;
        this.input.productPairProductID = product.productPairProductID;
        this.input.productPairProductRelatedToItself = this.navigation.productID == product.productPairProductID;
        this.input.selectedMainProduct = product.productMainProductID;
        this.input.selectedProductCategory = product.productProductCategoryID;
        this.input.selectedProductProvider = product.productProductProviderID;
        this.input.selectedProductBrand = product.productProductBrandID;
        this.input.selectedProductModel = product.productProductModelID;
        this.input.selectedProductFlavor = product.productProductFlavorID;
        this.input.selectedProductNicotineLevel = product.productProductNicotineLevelID;
        this.input.selectedProductSize = product.productProductSizeID;
        this.input.selectedProductColor = product.productProductColorID;
        this.input.selectedProductTagIDS = product.productTagIDS;
        this.input.productImage = `data:image/jpeg;base64,${product.productImage}`;
        this.input.productIsReplacedDaily = product.productIsReplacedDaily == 1 ? true : false;
        this.input.productIsVisible = product.productIsVisible == 1 ? true : false;
        this.input.productIsActive = product.productIsActive == 1 ? true : false;
        for (var productStockIndex in product.productStock){
          const productStock = product.productStock[productStockIndex];
          const localityOption = this.display.localityOptions.find(localityOption => localityOption.localityID == productStock.localityID);
          localityOption.localitySelected = productStock.productStockIsActive == 1;
          localityOption.productStockGoalAmount = productStock.productStockGoalAmount;
        }
        this.modifyLocalitySelected();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la información del producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
        this.setDefaultValues();
      }
      this.loader.selectProductFromInsertProductDialog = false;
    },

    async selectProductSearchParameters(){
      this.loader.selectProductSearchParameters = true;
      const selectProductSearchParametersResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductSearchParameters');
      if (selectProductSearchParametersResult.success){
        this.display.productCategoryOptions = selectProductSearchParametersResult.result.productCategory;
        this.display.productProviderOptions = selectProductSearchParametersResult.result.productProvider;
        this.display.productBrandOptions = selectProductSearchParametersResult.result.productBrand;
        this.display.productModelOptions = selectProductSearchParametersResult.result.productModel;
        this.display.productFlavorOptions = selectProductSearchParametersResult.result.productFlavor;
        this.display.productNicotineLevelOptions = selectProductSearchParametersResult.result.productNicotineLevel;
        this.display.productSizeOptions = selectProductSearchParametersResult.result.productSize;
        this.display.productColorOptions = selectProductSearchParametersResult.result.productColor;
        this.display.productTagOptions = selectProductSearchParametersResult.result.productTag;
        this.loader.selectProductSearchParameters = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de características de los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectNextAvailableProductID(){
      this.loader.selectNextAvailableProductID = true;
      const selectNextAvailableProductIDResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectNextAvailableProductID');
      if (selectNextAvailableProductIDResult.success){
        this.input.productID = selectNextAvailableProductIDResult.result;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar el código interno sugerido del producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
        this.setDefaultValues();
      }
      this.loader.selectNextAvailableProductID = false;
    },

    getProductColorHex(){
      const productColorOption = this.display.productColorOptions.find(productColorOption => productColorOption.productColorID == this.input.selectedProductColor);
      if (productColorOption){
        return productColorOption.productColorHex;
      }
      return null;
    },

    composeProductName(){
      const productBrand = this.display.productBrandOptions.find(productBrandOption => productBrandOption.productBrandID == this.input.selectedProductBrand);
      const productModel = this.display.productModelOptions.find(productModelOption => productModelOption.productModelID == this.input.selectedProductModel);
      const productFlavor = this.display.productFlavorOptions.find(productFlavorOption => productFlavorOption.productFlavorID == this.input.selectedProductFlavor);
      const productNicotineLevel = this.display.productNicotineLevelOptions.find(productNicotineLevelOption => productNicotineLevelOption.productNicotineLevelID == this.input.selectedProductNicotineLevel);
      const productSize = this.display.productSizeOptions.find(productSizeOption => productSizeOption.productSizeID == this.input.selectedProductSize);
      const productColor = this.display.productColorOptions.find(productColorOption => productColorOption.productColorID == this.input.selectedProductColor);
      this.input.productName = `${productBrand ? productBrand.productBrandName : ''}${productModel ? ` ${productModel.productModelName}` : ''}${productFlavor ? ` ${productFlavor.productFlavorName}` : ''}${productNicotineLevel ? ` ${productNicotineLevel.productNicotineLevelName}` : ''}${productSize ? ` ${productSize.productSizeName}` : ''}${productColor ? ` ${productColor.productColorName}` : ''}`;
    },

    modifyAllLocalitySelected(){
      this.display.localityOptions.forEach(localityOption => {
        localityOption.localitySelected = this.input.allLocalitySelected;
      });
    },

    modifyLocalitySelected(){
      this.input.allLocalitySelected = this.display.localityOptions.every(localityOption => localityOption.localitySelected == true);
    },

    uploadImage(){
      document.getElementById('uploadProductImage').click();
    },

    processImage(){
      const previousImage = this.input.productImage;
      try {
        this.input.productImage = this.$refs.uploadProductImage.files[0];
        const imageFileReader = new FileReader();
        imageFileReader.readAsDataURL(this.input.productImage);
        imageFileReader.onload = () => {
          this.input.productImage = imageFileReader.result;
        };
        imageFileReader.onerror = function () {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al cargar la imagen del producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
          throw new Error('Error when charging the image');
        };
      } catch {
        this.input.productImage = previousImage;
      }
    },

    setDefaultValues(){
      this.input.selectedMainProduct = null;
      this.input.productCABYS = null;
      this.input.productID = null;
      this.input.productSKU = null;
      this.input.productName = null;
      this.input.productPairProductID = null;
      this.input.productPairProductRelatedToItself = false;
      this.input.selectedProductCategory = null;
      this.input.selectedProductProvider = null;
      this.input.selectedProductBrand = null;
      this.input.selectedProductModel = null;
      this.input.selectedProductFlavor = null;
      this.input.selectedProductNicotineLevel = null;
      this.input.selectedProductSize = null;
      this.input.selectedProductColor = null;
      this.input.selectedProductTagIDS.length = 0;
      this.input.productDescription = null;
      this.input.productShortDescription = null;
      this.input.productCost = null;
      this.input.productTaxMargin = 13;
      this.input.productPrice = null;
      this.input.productWholesaleColonPrice = null;
      this.input.productWholesaleDolarPrice = null;
      this.input.productMaxDiscountMargin = 25;
      this.input.selectedProductLocation = null;
      this.input.productIsReplacedDaily = true;
      this.input.productIsVisible = true;
      this.input.productIsActive = true;
      this.input.productImage = null;
      this.input.allLocalitySelected = true;
      this.input.allLocalityProductStockGoalAmount = 0;
      this.loader.selectNextAvailableProductID = false;
      this.loader.selectMainProductListFromInsertProductDialog = false;
      this.loader.selectProductListFromInsertProductDialog = false;
      this.loader.selectProductSearchParameters = false;
      this.loader.selectLocalityIDAndLocalityNameByLocalityIsActive = false;
      this.loader.selectProductFromInsertProductDialog = false;
      this.loader.insertProduct = false;
      this.navigation.insertProductDialogIsVisible = false;
      this.navigation.productID = null;
    },

    openInsertProductSearchParameterDialog(productSearchParameterName, productSearchParameterPrefix){
      const insertProductSearchParameterDialogRequestQuery = 
      {
        'productSearchParameterName': productSearchParameterName,
        'productSearchParameterPrefix': productSearchParameterPrefix
      };
      this.$root.insertProductSearchParameterDialog.openInsertProductSearchParameterDialog(insertProductSearchParameterDialogRequestQuery);
    },

    async openInsertProductDialog(productID){
      this.setDefaultValues();
      this.navigation.insertProductDialogIsVisible = true;
      await this.selectNextAvailableProductID();
      await this.selectLocalityIDAndLocalityNameByLocalityIsActive();
      await this.selectMainProductListFromInsertProductDialog();
      await this.selectProductListFromInsertProductDialog();
      await this.selectProductSearchParameters();
      this.navigation.productID = productID;
      if (this.navigation.productID){
        await this.selectProductFromInsertProductDialog();
      }
    }
  },

  mounted(){
    this.assets.placeholderImage = this.$generalFunctions.default.data.placeholderImage;
    viewMethodCaller.on('InsertProductSearchParameterDialog/methods/insertProductSearchParameter()', this.selectProductSearchParameters);
  }
}
</script>