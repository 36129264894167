<template>
  <div style="margin: 2% 3% 0% 3%;">
    <div v-if="navigation.selectedLocality">
      <v-card elevation="3" style="padding: 20px 20px 0px 20px; background-color: #e9e8e8;">
        <v-text-field v-model="display.startDateFormatted" @click="navigation.startDatePickerIsVisible = true" readonly label="Fecha inicial" append-inner-icon="mdi-calendar" variant="solo-filled"></v-text-field>
        <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
          <v-card style="display: flex;">
            <v-date-picker v-model="input.startDate" hide-header color="primary" width="100%"></v-date-picker>
            <v-chip @click="input.startDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
          </v-card>
        </v-dialog>
        <v-text-field v-model="display.endDateFormatted" @click="navigation.endDatePickerIsVisible = true" readonly label="Fecha final" append-inner-icon="mdi-calendar" variant="solo-filled"></v-text-field>
        <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
          <v-card>
            <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
            <v-chip @click="input.endDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
          </v-card>
        </v-dialog>
      </v-card>

      <div v-if="accessCredential['/localityExpense/showLocalityExpenseTotal']">
        <br><br>
        <v-card elevation="3" style="padding: 20px; background-color: #e9e8e8; text-align: center;">
          <p style="font-size: xx-large;"><strong>TOTAL: </strong>₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(display.localityExpenseCategories.reduce((total, localityExpenseCategory) => total + localityExpenseCategory.localityExpenseCategoryAmount, 0)) }}</p>
          <br>
          <v-icon @click="generateLocalityExpensePDF()" size="40" color="success" dark right>mdi-printer</v-icon>
        </v-card>
      </div>
  
      <br><br><br><br>
      <div style="width: 100%; text-align: center;">
        <h1>GASTOS MENSUALES:</h1>
      </div>
      <br><br>

      <div style="display: flex; flex-wrap: wrap;">
        <div v-for="localityExpenseCategory in display.localityExpenseCategories.filter(localityExpenseCategory => localityExpenseCategory.localityExpenseCategoryType == 'monthly')" v-bind:key="localityExpenseCategory" style="flex-basis: 50%; box-sizing: border-box; padding: 10px;">
          <v-card style="background-color: #e9e8e8; min-height: 100px; padding: 20px; display: flex; flex-direction: column; justify-content: space-between; border-radius: 10px;">
            <div style="display: flex; justify-content: space-between;">
              <div style="display: flex; flex-direction: column;">
                <h1>{{ localityExpenseCategory.localityExpenseCategoryName }}</h1>
                <br>
                <p style="font-size: larger;"><strong>TOTAL: </strong>₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(localityExpenseCategory.localityExpenseCategoryAmount) }}</p>
              </div>
              <div style="display: flex; flex-direction: column;">
                <v-icon style="font-size: 50px;">{{ localityExpenseCategory.localityExpenseCategoryIcon }}</v-icon>
                <br>
                <v-icon @click="openInsertLocalityExpenseDialog(navigation.selectedLocality.localityID ,localityExpenseCategory)" style="font-size: 50px;" color="success">mdi-plus-circle-outline</v-icon>
              </div>
            </div>
            <br>
            <v-expansion-panels v-model="localityExpenseCategory.localityExpenseCategoryVisible">
              <v-expansion-panel>
                <v-expansion-panel-title @click="selectLocalityExpenseByLocalityExpenseCategoryName(localityExpenseCategory)">Ver lista de gastos</v-expansion-panel-title>
                <v-expansion-panel-text v-if="localityExpenseCategory.localityExpenseCategoryType == 'monthly'">
                  <v-data-table-virtual :headers="display.localityExpenseMonthlyHeaders" :items="localityExpenseCategory.localityExpenseList">
                    <template v-slot:item.localityExpenseMonth="{ item }">
                      {{ display.monthOptions[JSON.parse(item.localityExpenseInformation)['localityExpenseMonth']] }}
                    </template>
                    <template v-slot:item.agentName="{ item }">
                      <div style="width: 150px;">
                        <v-chip :color="item.agentColor" variant="flat">
                          <p :style="{color: item.agentFontColor}">{{ item.agentName }}</p>
                        </v-chip>
                      </div>
                    </template>
                    <template v-slot:item.localityExpenseAmount="{ item }">
                      <div style="width: 100px;">
                        ₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(item.localityExpenseAmount) }}
                      </div>
                    </template>
                    <template v-slot:item.localityExpenseNote="{ item }">
                      <div style="width: 200px;">
                        {{ item.localityExpenseNote }}
                      </div>
                    </template>
                    <template v-slot:item.localityExpenseRegisterDatetime="{ item }">
                      <div style="width: 100px;">
                        {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYY(item.localityExpenseRegisterDatetime) }}
                      </div>
                    </template>
                    <template v-slot:item.localityExpenseFile="{ item }">
                      <div v-if="item.localityExpenseFileLoader" style="text-align: center;">
                        <v-progress-circular color="warning" indeterminate></v-progress-circular>
                      </div>
                      <div v-else>
                        <v-icon v-if="item.localityExpenseFileName" @click="selectLocalityExpenseFile(item)" color="warning">mdi-file-download</v-icon>
                        <p v-else>Sin archivo</p>
                      </div>
                    </template>
                    <template v-slot:item.localityExpenseDeleteAction="{ item }">
                      <v-icon @click="openDeleteLocalityExpenseDialog(item)" color="error">mdi-delete</v-icon>
                    </template>
                  </v-data-table-virtual>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </div>
      </div>

      <br><br><br><br>
      <div style="width: 100%; text-align: center;">
        <h1>GASTOS OCASIONALES:</h1>
      </div>
      <br><br>

      <div style="display: flex; flex-wrap: wrap;">
        <div v-for="localityExpenseCategory in display.localityExpenseCategories.filter(localityExpenseCategory => localityExpenseCategory.localityExpenseCategoryType == 'ocassional')" v-bind:key="localityExpenseCategory" style="flex-basis: 50%; box-sizing: border-box; padding: 10px;">
          <v-card style="background-color: #e9e8e8; min-height: 100px; padding: 20px; display: flex; flex-direction: column; justify-content: space-between; border-radius: 10px;">
            <div style="display: flex; justify-content: space-between;">
              <div style="display: flex; flex-direction: column;">
                <h1>{{ localityExpenseCategory.localityExpenseCategoryName }}</h1>
                <br>
                <p style="font-size: larger;"><strong>TOTAL: </strong>₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(localityExpenseCategory.localityExpenseCategoryAmount) }}</p>
              </div>
              <div style="display: flex; flex-direction: column;">
                <v-icon style="font-size: 50px;">{{ localityExpenseCategory.localityExpenseCategoryIcon }}</v-icon>
                <br>
                <v-icon @click="openInsertLocalityExpenseDialog(navigation.selectedLocality.localityID ,localityExpenseCategory)" style="font-size: 50px;" color="success">mdi-plus-circle-outline</v-icon>
              </div>
            </div>
            <br>
            <v-expansion-panels v-model="localityExpenseCategory.localityExpenseCategoryVisible">
              <v-expansion-panel>
                <v-expansion-panel-title @click="selectLocalityExpenseByLocalityExpenseCategoryName(localityExpenseCategory)">Ver lista de gastos</v-expansion-panel-title>
                <v-expansion-panel-text v-if="localityExpenseCategory.localityExpenseCategoryType == 'ocassional'">
                  <v-data-table-virtual :headers="display.localityExpenseOcassionalHeaders" :items="localityExpenseCategory.localityExpenseList">
                    <template v-slot:item.localityExpenseInformation="{ item }">
                      <div style="width: 200px;">
                        <p v-if="JSON.parse(item.localityExpenseInformation).localityExpenseName"><strong>Nombre: </strong>{{ JSON.parse(item.localityExpenseInformation).localityExpenseName }}</p>
                        <p v-if="JSON.parse(item.localityExpenseInformation).localityExpenseType"><strong>Tipo: </strong>{{ JSON.parse(item.localityExpenseInformation).localityExpenseType }}</p>
                        <p v-if="JSON.parse(item.localityExpenseInformation).localityExpenseProvider"><strong>Proveedor: </strong>{{ JSON.parse(item.localityExpenseInformation).localityExpenseProvider }}</p>
                      </div>
                    </template>
                    <template v-slot:item.agentName="{ item }">
                      <div style="width: 150px;">
                        <v-chip :color="item.agentColor" variant="flat">
                          <p :style="{color: item.agentFontColor}">{{ item.agentName }}</p>
                        </v-chip>
                      </div>
                    </template>
                    <template v-slot:item.localityExpenseAmount="{ item }">
                      <div style="width: 100px;">
                        ₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(item.localityExpenseAmount) }}
                      </div>
                    </template>
                    <template v-slot:item.localityExpenseNote="{ item }">
                      <div style="width: 200px;">
                        {{ item.localityExpenseNote }}
                      </div>
                    </template>
                    <template v-slot:item.localityExpenseRegisterDatetime="{ item }">
                      <div style="width: 100px;">
                        {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYY(item.localityExpenseRegisterDatetime) }}
                      </div>
                    </template>
                    <template v-slot:item.localityExpenseFile="{ item }">
                      <div v-if="item.localityExpenseFileLoader" style="text-align: center;">
                        <v-progress-circular color="warning" indeterminate></v-progress-circular>
                      </div>
                      <div v-else>
                        <v-icon v-if="item.localityExpenseFileName" @click="selectLocalityExpenseFile(item)" color="warning">mdi-file-download</v-icon>
                        <p v-else>Sin archivo</p>
                      </div>
                    </template>
                    <template v-slot:item.localityExpenseDeleteAction="{ item }">
                      <v-icon @click="openDeleteLocalityExpenseDialog(item)" color="error">mdi-delete</v-icon>
                    </template>
                  </v-data-table-virtual>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </div>
      </div>
    </div>
    <div v-else>
      <div style="display: flex; flex-wrap: wrap;">
        <div v-for="localityOption in display.localityOptions" v-bind:key="localityOption" style="flex-basis: 33.33%; box-sizing: border-box; padding: 10px;">
          <v-card @click="selectLocality(localityOption)" :style="{'background-color': localityOption.localityColor}" style="min-height: 100px; padding: 20px; display: flex; flex-direction: column; justify-content: space-between; border-radius: 10px; cursor: pointer;">
            <div style="display: flex; justify-content: space-between; align-items: center;">
              <h1 :style="{color: localityOption.localityFontColor}">{{ localityOption.localityName }}</h1>
              <v-icon :color="localityOption.localityFontColor" style="font-size: 50px;">mdi-store</v-icon>
            </div>
          </v-card>
        </div>
      </div>
    </div>
    <br><br>
  </div>
  <v-dialog v-model="navigation.deleteLocalityExpenseDialogIsVisible" width="auto" :persistent="true">
    <v-card width="300" style="padding: 20px;">
      <div v-if="loader.localityExpenseAction" style="text-align: center;">
        <v-progress-circular color="error" indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <v-text-field v-model="input.token" append-inner-icon="mdi-lock" label="Token" variant="solo-filled" density="compact"></v-text-field>
        <br>
        <v-btn @click="deleteLocalityExpense()" dark block height="38" color="success">
          <h3>ELIMINAR GASTO</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-delete</v-icon>
        </v-btn>
        <br>
        <v-btn @click="navigation.deleteLocalityExpenseDialogIsVisible = false" dark block height="38" color="error">
          <h3>CANCELAR</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
        </v-btn>  
      </div>    
    </v-card>
  </v-dialog>


</template>

<style scoped>
</style>

<script>
import { defineComponent } from 'vue';
import { viewMethodCaller } from '../viewMethodCaller.js';

export default defineComponent({
  name: 'LocalityExpenseView',
  inject: ['$generalFunctions'],


  data: () => ({
    input: 
    {
      startDate: null,
      endDate: null,
      token: null
    },

    display:
    {
      startDateFormatted: null,
      endDateFormatted: null,

      localityOptions: [],

      localityExpenseCategories:
      [
        {
          localityExpenseCategoryName: 'ELECTRICIDAD', 
          localityExpenseCategoryIcon: 'mdi-power-plug',
          localityExpenseCategoryType: 'monthly',
          localityExpenseCategoryAmount: 0,
          localityExpenseCategoryVisible: false
        },
        {
          localityExpenseCategoryName: 'AGUA', 
          localityExpenseCategoryIcon: 'mdi-water-pump',
          localityExpenseCategoryType: 'monthly',
          localityExpenseCategoryAmount: 0,
          localityExpenseCategoryVisible: false
        },
        {
          localityExpenseCategoryName: 'INTERNET', 
          localityExpenseCategoryIcon: 'mdi-web',
          localityExpenseCategoryType: 'monthly',
          localityExpenseCategoryAmount: 0,
          localityExpenseCategoryVisible: false
        },
        {
          localityExpenseCategoryName: 'ALQUILER', 
          localityExpenseCategoryIcon: 'mdi-home',
          localityExpenseCategoryType: 'monthly',
          localityExpenseCategoryAmount: 0,
          localityExpenseCategoryVisible: false
        },
        {
          localityExpenseCategoryName: 'TELÉFONO', 
          localityExpenseCategoryIcon: 'mdi-phone',
          localityExpenseCategoryType: 'monthly',
          localityExpenseCategoryAmount: 0,
          localityExpenseCategoryVisible: false
        },
        {
          localityExpenseCategoryName: 'IMPUESTOS MUNICIPALES', 
          localityExpenseCategoryIcon: 'mdi-cash-minus',
          localityExpenseCategoryType: 'monthly',
          localityExpenseCategoryAmount: 0,
          localityExpenseCategoryVisible: false
        },
        {
          localityExpenseCategoryName: 'ABOGADOS', 
          localityExpenseCategoryIcon: 'mdi-gavel',
          localityExpenseCategoryType: 'ocassional',
          localityExpenseCategoryAmount: 0,
          localityExpenseCategoryVisible: false
        },
        {
          localityExpenseCategoryName: 'CONTADORES', 
          localityExpenseCategoryIcon: 'mdi-microsoft-excel',
          localityExpenseCategoryType: 'ocassional',
          localityExpenseCategoryAmount: 0,
          localityExpenseCategoryVisible: false
        },
        {
          localityExpenseCategoryName: 'MOTOS', 
          localityExpenseCategoryIcon: 'mdi-motorbike',
          localityExpenseCategoryType: 'ocassional',
          localityExpenseCategoryAmount: 0,
          localityExpenseCategoryVisible: false,
          localityExpenseSubcategories:
          [
            {
              localityExpenseSubcategoryName: 'Gasolina',
              localityExpenseSubcategoryIcon: 'mdi-gas-station',
              localityExpenseCategoryType: 'ocassional'
            },
            {
              localityExpenseSubcategoryName: 'Peaje',
              localityExpenseSubcategoryIcon: 'mdi-boom-gate-up',
              localityExpenseCategoryType: 'ocassional'
            },
            {
              localityExpenseSubcategoryName: 'Parqueo',
              localityExpenseSubcategoryIcon: 'mdi-car-brake-parking',
              localityExpenseCategoryType: 'ocassional'
            },
            {
              localityExpenseSubcategoryName: 'Mantenimiento',
              localityExpenseSubcategoryIcon: 'mdi-tools',
              localityExpenseCategoryType: 'ocassional'
            },
            {
              localityExpenseSubcategoryName: 'Reparación mecánica',
              localityExpenseSubcategoryIcon: 'mdi-cog',
              localityExpenseCategoryType: 'ocassional'
            },
            {
              localityExpenseSubcategoryName: 'Reparación de llantas',
              localityExpenseSubcategoryIcon: 'mdi-tire',
              localityExpenseCategoryType: 'ocassional'
            },
            {
              localityExpenseSubcategoryName: 'Lavado',
              localityExpenseSubcategoryIcon: 'mdi-water',
              localityExpenseSubcategoryType: 'ocassional'
            },
            {
              localityExpenseSubcategoryName: 'Multas de tránsito',
              localityExpenseSubcategoryIcon: 'mdi-currency-usd-off',
              localityExpenseSubcategoryType: 'ocassional'
            },
            {
              localityExpenseSubcategoryName: 'Derecho de circulación',
              localityExpenseSubcategoryIcon: 'mdi-alpha-m-box',
              localityExpenseSubcategoryType: 'ocassional'
            },
            {
              localityExpenseSubcategoryName: 'Revisión técnica',
              localityExpenseSubcategoryIcon: 'mdi-alpha-r-box',
              localityExpenseSubcategoryType: 'ocassional'
            }
          ]
        },
        {
          localityExpenseCategoryName: 'MARKETING', 
          localityExpenseCategoryIcon: 'mdi-star',
          localityExpenseCategoryType: 'ocassional',
          localityExpenseCategoryAmount: 0,
          localityExpenseCategoryVisible: false,
          localityExpenseSubcategories:
          [
            {
              localityExpenseSubcategoryName: 'Publicidad',
              localityExpenseSubcategoryIcon: 'mdi-sign-text',
              localityExpenseCategoryType: 'ocassional'
            },
            {
              localityExpenseSubcategoryName: 'Incluencers',
              localityExpenseSubcategoryIcon: 'mdi-account-star-outline',
              localityExpenseCategoryType: 'ocassional'
            },
            {
              localityExpenseSubcategoryName: 'Stickers',
              localityExpenseSubcategoryIcon: 'mdi-sticker',
              localityExpenseCategoryType: 'ocassional'
            }
          ]
        },
        {
          localityExpenseCategoryName: 'SEGUROS', 
          localityExpenseCategoryIcon: 'mdi-shield',
          localityExpenseCategoryType: 'ocassional',
          localityExpenseCategoryAmount: 0,
          localityExpenseCategoryVisible: false,
          localityExpenseSubcategories:
          [
            {
              localityExpenseSubcategoryName: 'CCSS',
              localityExpenseSubcategoryIcon: 'mdi-hospital-building',
              localityExpenseCategoryType: 'ocassional'
            },
            {
              localityExpenseSubcategoryName: 'Riesgo de trabajo',
              localityExpenseSubcategoryIcon: 'mdi-hard-hat',
              localityExpenseCategoryType: 'ocassional'
            },
            {
              localityExpenseSubcategoryName: 'Locales',
              localityExpenseSubcategoryIcon: 'mdi-store',
              localityExpenseCategoryType: 'ocassional'
            },
            {
              localityExpenseSubcategoryName: 'Motos',
              localityExpenseSubcategoryIcon: 'mdi-motorbike',
              localityExpenseCategoryType: 'ocassional'
            }
          ]
        },
        {
          localityExpenseCategoryName: 'PERSONAL', 
          localityExpenseCategoryIcon: 'mdi-account',
          localityExpenseCategoryType: 'ocassional',
          localityExpenseCategoryAmount: 0,
          localityExpenseCategoryVisible: false,
          localityExpenseSubcategories:
          [
            {
              localityExpenseSubcategoryName: 'Salarios',
              localityExpenseSubcategoryIcon: 'mdi-account-cash',
              localityExpenseCategoryType: 'ocassional'
            },
            {
              localityExpenseSubcategoryName: 'Regalías',
              localityExpenseSubcategoryIcon: 'mdi-gift',
              localityExpenseCategoryType: 'ocassional'
            },
            {
              localityExpenseSubcategoryName: 'Aguinaldo',
              localityExpenseSubcategoryIcon: 'mdi-sack',
              localityExpenseCategoryType: 'ocassional'
            },
            {
              localityExpenseSubcategoryName: 'Liquidaciones',
              localityExpenseSubcategoryIcon: 'mdi-sack',
              localityExpenseCategoryType: 'ocassional'
            }
          ]
        },
        {
          localityExpenseCategoryName: 'INFORMÁTICA', 
          localityExpenseCategoryIcon: 'mdi-microsoft-visual-studio-code',
          localityExpenseCategoryType: 'ocassional',
          localityExpenseCategoryAmount: 0,
          localityExpenseCategoryVisible: false,
          localityExpenseSubcategories:
          [
            {
              localityExpenseSubcategoryName: 'Servidores',
              localityExpenseSubcategoryIcon: 'mdi-server-network',
              localityExpenseCategoryType: 'ocassional'
            },
            {
              localityExpenseSubcategoryName: 'API',
              localityExpenseSubcategoryIcon: 'mdi-whatsapp',
              localityExpenseCategoryType: 'ocassional'
            },
            {
              localityExpenseSubcategoryName: 'Equipo',
              localityExpenseSubcategoryIcon: 'mdi-mouse',
              localityExpenseCategoryType: 'ocassional'
            }
          ]
        },
        {
          localityExpenseCategoryName: 'SUMINISTROS', 
          localityExpenseCategoryIcon: 'mdi-archive',
          localityExpenseCategoryType: 'ocassional',
          localityExpenseCategoryAmount: 0,
          localityExpenseCategoryVisible: false
        },
        {
          localityExpenseCategoryName: 'UBER', 
          localityExpenseCategoryIcon: 'mdi-car',
          localityExpenseCategoryType: 'ocassional',
          localityExpenseCategoryAmount: 0,
          localityExpenseCategoryVisible: false
        },
        {
          localityExpenseCategoryName: 'OTROS', 
          localityExpenseCategoryIcon: 'mdi-source-branch',
          localityExpenseCategoryType: 'ocassional',
          localityExpenseCategoryAmount: 0,
          localityExpenseCategoryVisible: false
        },
      ],

      localityExpenseMonthlyHeaders:
      [
        {key: 'localityExpenseID', title: 'ID'},
        {key: 'agentName', title: 'REGISTRADO POR'},
        {key: 'localityExpenseMonth', title: 'MES'},
        {key: 'localityExpenseNote', title: 'NOTA'},
        {key: 'localityExpenseAmount', title: 'MONTO'},
        {key: 'localityExpenseRegisterDatetime', title: 'FECHA'},
        {key: 'localityExpenseFile', title: 'ARCHIVO'},
        {key: 'localityExpenseDeleteAction', title: 'ELIMINAR'}
      ],

      localityExpenseOcassionalHeaders:
      [
        {key: 'localityExpenseID', title: 'ID'},
        {key: 'agentName', title: 'REGISTRADO POR'},
        {key: 'localityExpenseInformation', title: 'INFORMACIÓN'},
        {key: 'localityExpenseNote', title: 'NOTA'},
        {key: 'localityExpenseAmount', title: 'MONTO'},
        {key: 'localityExpenseRegisterDatetime', title: 'FECHA'},
        {key: 'localityExpenseFile', title: 'ARCHIVO'},
        {key: 'localityExpenseDeleteAction', title: 'ELIMINAR'}
      ],

      monthOptions: {
        '1': 'Enero',
        '2': 'Febrero',
        '3': 'Marzo',
        '4': 'Abril',
        '5': 'Mayo',
        '6': 'Junio',
        '7': 'Julio',
        '8': 'Agosto',
        '9': 'Septiembre',
        '10': 'Octubre',
        '11': 'Noviembre',
        '12': 'Diciembre'
      },

    },

    loader:
    {
      localityOptions: false,
      localityExpenseAction: false
    },

    navigation:
    {
      selectedLocality: null,
      dateChanged: false,
      startDatePickerIsVisible: false,
      endDatePickerIsVisible: false,
      deleteLocalityExpenseDialogIsVisible: false,
      deleteLocalityExpense: null
    },

    localStorage:
    {
      agentID: null
    },

    accessCredential: {}
    

  }),

  watch: {
    'input.startDate': function() {
      this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB');
      this.navigation.startDatePickerIsVisible = false;
      this.navigation.dateChanged = true;
      this.selectLocalityExpenseFromLocalityExpenseView();
    },
    'input.endDate': function() {
      this.display.endDateFormatted = new Date(this.input.endDate).toLocaleDateString('en-GB');
      this.navigation.endDatePickerIsVisible = false;
      this.navigation.dateChanged = true;
      this.selectLocalityExpenseFromLocalityExpenseView();
    },
    async '$route'(route) {
      const currentRoute = route.fullPath;
      if (currentRoute[currentRoute.length - 2] != '/'){
        this.navigation.selectedLocality = null;
      }
    },
  },

  methods: {

    async selectLocalityExpenseByLocalityExpenseCategoryName(localityExpenseCategory){
      const selectLocalityExpenseByLocalityExpenseCategoryNameRequestQuery =
      {
        'localityID': this.navigation.selectedLocality.localityID,
        'localityExpenseCategoryName': localityExpenseCategory.localityExpenseCategoryName,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate
      };
      const selectLocalityExpenseByLocalityExpenseCategoryNameResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/localityExpense/functions/selectLocalityExpenseByLocalityExpenseCategoryName', selectLocalityExpenseByLocalityExpenseCategoryNameRequestQuery);
      if (selectLocalityExpenseByLocalityExpenseCategoryNameResult.success){
        localityExpenseCategory['localityExpenseList'] = selectLocalityExpenseByLocalityExpenseCategoryNameResult.result.map(localityExpense => ({
          ...localityExpense,
          'localityExpenseCategoryName': localityExpenseCategory.localityExpenseCategoryName,
          'localityExpenseFileLoader': false
        }));
      } else { 
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': `Ha ocurrido un error inesperado al consultar los gastos de "${localityExpenseCategory.localityExpenseCategoryName}", por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema`,
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async generateLocalityExpensePDF(){
      const generateLocalityExpensePDFRequestQuery =
      {
        'localityID': this.navigation.selectedLocality.localityID,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate
      };
      const generateLocalityExpensePDFResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/localityExpense/functions/generateLocalityExpensePDF', generateLocalityExpensePDFRequestQuery);
      if (generateLocalityExpensePDFResult.success){
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': generateLocalityExpensePDFResult.result.data,
          'printablePDFDocumentName': generateLocalityExpensePDFResult.name,
          'printableExcelDocumentFile': null,
          'printableExcelDocumentName': null
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el reporte de los gastos de la localidad, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectLocalityExpenseFromLocalityExpenseView(){
      const selectLocalityExpenseFromLocalityExpenseViewRequestQuery =
      {
        'localityID': this.navigation.selectedLocality.localityID,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate
      };
      const selectLocalityExpenseFromLocalityExpenseViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/localityExpense/functions/selectLocalityExpenseFromLocalityExpenseView', selectLocalityExpenseFromLocalityExpenseViewRequestQuery);
      if (selectLocalityExpenseFromLocalityExpenseViewResult.success){
        this.display.localityExpenseCategories.forEach(localityExpenseCategory => {
          localityExpenseCategory.localityExpenseCategoryAmount = 0;
        });        
        for (var localityExpenseIndex in selectLocalityExpenseFromLocalityExpenseViewResult.result){
          const localityExpense = selectLocalityExpenseFromLocalityExpenseViewResult.result[localityExpenseIndex];
          const localityExpenseCategoryName = localityExpense.localityExpenseCategoryName;
          const localityExpenseCategoryAmount = localityExpense.localityExpenseCategoryAmount;
          const interfaceLocalityExpenseCategory = this.display.localityExpenseCategories.find(localityExpenseCategory => localityExpenseCategory['localityExpenseCategoryName'] == localityExpenseCategoryName);
          if (interfaceLocalityExpenseCategory){
            interfaceLocalityExpenseCategory['localityExpenseCategoryAmount'] = localityExpenseCategoryAmount;
            interfaceLocalityExpenseCategory['localityExpenseCategoryVisible'] = false;
          }
        }
      } else { 
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar el monto de los gastos de la localidad, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectLocalityExpenseFile(localityExpense){
      localityExpense.localityExpenseFileLoader = true;
      const selectLocalityExpenseFileRequestQuery =
      {
        'localityExpenseID': localityExpense.localityExpenseID
      };
      const selectLocalityExpenseFileResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/localityExpense/functions/selectLocalityExpenseFile', selectLocalityExpenseFileRequestQuery);
      if (selectLocalityExpenseFileResult.success){
        const localityExpenseFile = `data:image/png;base64,${selectLocalityExpenseFileResult.result}`;
        const localityExpenseDownloadURL = document.createElement('a');
        localityExpenseDownloadURL.href = localityExpenseFile;
        localityExpenseDownloadURL.download = localityExpense.localityExpenseFileName;
        document.body.appendChild(localityExpenseDownloadURL);
        localityExpenseDownloadURL.click();
        document.body.removeChild(localityExpenseDownloadURL);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar el archivo del gasto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      localityExpense.localityExpenseFileLoader = false;
    },

    async deleteLocalityExpense(){
      this.loader.localityExpenseAction = true;
      const deleteLocalityExpenseRequestQuery =
      {
        'tokenValue': this.input.token,
        'localityExpenseID': this.navigation.deleteLocalityExpense.localityExpenseID
      };
      const deleteLocalityExpenseResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/localityExpense/functions/delete', deleteLocalityExpenseRequestQuery);
      if (deleteLocalityExpenseResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'Se ha eliminado el gasto exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        const localityExpenseCategoryToRefresh = this.display.localityExpenseCategories.find(localityExpenseCategory => localityExpenseCategory.localityExpenseCategoryName == this.navigation.deleteLocalityExpense.localityExpenseCategoryName);
        await this.selectLocalityExpenseFromLocalityExpenseView();
        await this.selectLocalityExpenseByLocalityExpenseCategoryName(localityExpenseCategoryToRefresh);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al eliminar el gasto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.localityExpenseAction = false;
      this.navigation.deleteLocalityExpenseDialogIsVisible = false;
    },

    async selectLocality(locality){
      this.$generalFunctions.default.methods.navigateTo(`/localityExpense/${locality.localityID}`);
      this.navigation.selectedLocality = locality;
      await this.selectLocalityExpenseFromLocalityExpenseView();
    },

    openInsertLocalityExpenseDialog(localityID, localityExpenseCategory){
      const insertLocalityExpenseDialogRequestQuery =
      {
        'localityID': localityID,
        'localityExpenseCategory': localityExpenseCategory
      };
      this.$root.insertLocalityExpenseDialog.openInsertLocalityExpenseDialog(insertLocalityExpenseDialogRequestQuery);
    },

    openDeleteLocalityExpenseDialog(localityExpense){
      this.navigation.deleteLocalityExpense = localityExpense;
      this.navigation.deleteLocalityExpenseDialogIsVisible = true;
      this.input.token = null;
    },

    async selectLocalityIDAndLocalityName(){
      this.loader.localityOptions = true;
      this.navigation.selectLocalityExpenseIsDisabled = true;
      const selectLocalityIDAndLocalityNameResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityName');
      if (selectLocalityIDAndLocalityNameResult.success){
        this.navigation.selectLocalityExpenseIsDisabled = false;
        this.display.localityOptions = selectLocalityIDAndLocalityNameResult.result.filter(locality => locality.localityID != 5);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.localityOptions = false;
    },

    async setDefaultValues(){
      this.input.startDate = new Date(new Date().setDate(1));
      this.input.endDate = new Date();
    },

    async parseURL(){
      const currentRoute = this.$route.fullPath;
      if (currentRoute[currentRoute.length - 2] != '/'){
        this.navigation.selectedLocality = null;
      } else {
        const locality = this.display.localityOptions.find(localityOption => localityOption['localityID'] == currentRoute[currentRoute.length - 1]);
        if (locality){
          this.selectLocality(locality);
        }
      }
    },

    async listenToViewMethodCaller(){
      viewMethodCaller.on('LocalityExpenseView/methods/selectLocalityExpenseFromLocalityExpenseView()', this.selectLocalityExpenseFromLocalityExpenseView);
    },

    async getLocalStorageData(){
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    }
  },


  async mounted(){
    await this.getLocalStorageData();
    await this.listenToViewMethodCaller();
    await this.selectLocalityIDAndLocalityName();
    await this.parseURL();
    await this.setDefaultValues();

  }
});
</script>
