<template>
  <div class="notificationDialogContainer">
    <div v-for="currentNotificationDialog in display.currentNotificationDialogs" v-bind:key="currentNotificationDialog">
      <v-card :color="currentNotificationDialog.notificationDialogColor" :style="{'--notificationDialogDuration': (currentNotificationDialog.notificationDialogDuration/1000 - 1) + 's'}" :class="currentNotificationDialog.notificationDialogIsPersistent ? 'notificationDialogPersistentContainer' : 'notificationDialogNotPersistentContainer'">
        <div style="display: flex">
          <div style="width: 90%;">
            <h3 v-if="currentNotificationDialog.notificationDialogTitle" style="margin-bottom: 10px;">{{ currentNotificationDialog.notificationDialogTitle }}</h3>
            {{ currentNotificationDialog.notificationDialogBody }}
          </div>
          <div style="width: 10%;">
            <v-icon @click="discardNotificationDialog(currentNotificationDialog.notificationDialogID)" style="margin-left: 10px; cursor: pointer;" dark right>mdi-close-circle-outline</v-icon>
          </div>
          <div>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<style scoped>
  .notificationDialogContainer {
    z-index: 5000; 
    position: absolute; 
    top: 20px; 
    left: calc(100vw - 440px); 
    width: 400px;
  }

  .notificationDialogPersistentContainer {
    padding: 10px;
    margin-bottom: 10px;
    animation: notificationDialogPersistentContainerAnimation 2s infinite;
  }

  @keyframes notificationDialogPersistentContainerAnimation {
    0% { transform: scale(1); }
    50% { transform: scale(1.03); }
    100% { transform: scale(1); }
  }

  .notificationDialogNotPersistentContainer {
    padding: 10px;
    margin-bottom: 10px;
    animation: notificationDialogNotPersistentContainerAnimation var(--notificationDialogDuration) forwards;
  }

  @keyframes notificationDialogNotPersistentContainerAnimation {
    0%, 75% { opacity: 1 }
    100% { opacity: 0 }
  }

</style>

<script>
export default {
  name: "NotificationSnackbar",

  data: () => ({
    display: 
    {
      currentNotificationDialogs: []
    }
  }),

  methods: {
    showNotificationDialog(notificationDialogData) {
      const notificationDialogID = Math.floor(1000 + Math.random() * 9000);
      const notificationDialogTitle = notificationDialogData.notificationDialogTitle;
      const notificationDialogBody = notificationDialogData.notificationDialogBody;
      const notificationDialogColor = notificationDialogData.notificationDialogColor || 'info';
      const notificationDialogDuration = notificationDialogData.notificationDialogDuration || 5000;
      const notificationDialogIsPersistent = notificationDialogData.notificationDialogPersistent || false;
      this.display.currentNotificationDialogs.unshift({
        'notificationDialogID': notificationDialogID,
        'notificationDialogTitle': notificationDialogTitle,
        'notificationDialogBody': notificationDialogBody,
        'notificationDialogColor': notificationDialogColor, 
        'notificationDialogIsPersistent': notificationDialogIsPersistent,
      });
      if (!notificationDialogIsPersistent){
        setTimeout(() => {
          this.discardNotificationDialog(notificationDialogID);
        }, notificationDialogDuration);
      }
    },

    discardNotificationDialog(notificationDialogID){
      this.display.currentNotificationDialogs = this.display.currentNotificationDialogs.filter(currentNotificationDialog => currentNotificationDialog.notificationDialogID != notificationDialogID);
    },

    getNotificationDialogContainerClass(notificationDialogIsPersistent){
      return notificationDialogIsPersistent ? 'notificationDialogPersistentContainer' : 'notificationDialogNotPersistentContainer';
    }
  }
}
</script>