<template>
  <div style="margin: 2% 3% 0% 3%;">
    <v-card style="background-color: #e9e8e8; padding: 20px; width: 100%;">
      <div style="display: flex;">
        <div style="width: 90%;">
          <v-select v-model="input.localityIDS" :items="display.localityOptions" :loading="loader.selectLocalityIDAndLocalityName" :disabled="input.globalReportSelected" multiple chips item-title="localityName" item-value="localityID" append-inner-icon="mdi-store" label="Localidad" variant="solo-filled"></v-select>
        </div>
        <div style="width: 9%; margin-left: 1%;">
          <v-checkbox @click="selectAllLocality()" v-model="input.allLocalitySelected" color="info" label="Todas las localidades" hide-details></v-checkbox>
        </div>
      </div>
      <div style="display: flex;">
        <v-text-field v-model="display.startDateFormatted" @click="navigation.startDatePickerIsVisible = true" readonly label="Fecha inicial" append-inner-icon="mdi-calendar" variant="solo-filled" style="width: 49.5%; margin-right: 0.5%;"></v-text-field>
        <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
          <v-card style="display: flex;">
            <v-date-picker v-model="input.startDate" hide-header color="primary" width="100%"></v-date-picker>
            <v-chip @click="input.startDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
          </v-card>
        </v-dialog>
        <v-text-field v-model="display.endDateFormatted" @click="navigation.endDatePickerIsVisible = true" readonly label="Fecha final" append-inner-icon="mdi-calendar" variant="solo-filled" style="width: 49.5%; margin-left: 0.5%;"></v-text-field>
        <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
          <v-card>
            <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
            <v-chip @click="input.endDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
          </v-card>
        </v-dialog>
      </div>
      <br><br>
      <div v-if="loader.selectInformation" style="text-align: center;">
        <v-progress-circular color="info" indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <v-btn @click="selectInformation()" :disabled="loader.selectLocalityIDAndLocalityName || loader.selectInformation" dark block height="38" color="primary">
          <h3>BUSCAR</h3>
          <v-icon style="margin-left: 20px;" dark right>mdi-magnify</v-icon>
        </v-btn>
        <br>
        <v-btn @click="setDefaultValues()" :disabled="loader.selectLocalityIDAndLocalityName || loader.selectInformation" dark block height="38" color="#ffd978">
          <h3>LIMPIAR</h3>
          <v-icon style="margin-left: 20px;" dark right>mdi-backspace</v-icon>
        </v-btn>
      </div>
    </v-card>
    <br><br>
    <v-card v-if="display.information" style="background-color: #e9e8e8; padding: 20px; width: 100%;">
      <div style="display: flex;">
        <div style="width: 49%; margin-right: 1%;">
          <v-data-table-virtual :headers="display.agentSaleHeaders" :items="display.information">
            <template v-slot:item.agentRanking="{ item }">
              <v-chip color="purple" label style="font-size: larger; cursor: pointer;">
                <v-icon icon="mdi-medal" start></v-icon>
                  {{ item.index }}
                </v-chip>
            </template>
            <template v-slot:item.agentName="{ item }">
              <v-chip :color="item.agentColor" variant="flat">
                <p :style="{color: item.agentFontColor}">{{ item.agentName }}</p>
              </v-chip>
            </template>
            <template v-slot:item.localityName="{ item }">
              <v-chip :color="item.localityColor" variant="flat">
                <p :style="{color: item.localityFontColor}">{{ item.localityName }}</p>
              </v-chip>
            </template>
            <template v-slot:item.amount="{ item }">
              ₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(item.amount) }}
            </template>
            <template v-slot:item.average="{ item }">
              ₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(item.amount / item.billIDS) }}
            </template>
          </v-data-table-virtual>
        </div>
        <div style="width: 49%; margin-left: 1%;">
          <div v-if="display.chartOptions && display.chartSeries" style="width: 50%; height: 10%;">
            <apexchart type="pie" :options="display.chartOptions" :series="display.chartSeries"></apexchart>
          </div>
        </div>
      </div>      
    </v-card>
    <br><br>
  </div>
</template>

<style scoped>
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AgentSaleDashboardView',

  inject: ['$generalFunctions'],

  data: () => ({
    input: 
    {
      localityIDS: [],
      allLocalitySelected: false,
      startDate: null,
      endDate: null
    },

    display: 
    {
      localityOptions: [],
      startDateFormatted: null,
      endDateFormatted: null,
      information: null,
      chartSeries: null,
      chartOptions: null,
      agentSaleHeaders: 
      [
        {key: 'agentRanking', title: null},
        {key: 'agentName', title: 'AGENTE'},
        {key: 'localityName', title: 'LOCALIDAD'},
        {key: 'billIDS', title: 'TICKET VENDIDOS'},
        {key: 'amount', title: 'MONTO VENDIDO'},
        {key: 'average', title: 'TICKET PROMEDIO'}

      ],
    },

    navigation: 
    {
      startDatePickerIsVisible: false,
      endDatePickerIsVisible: false
    },

    loader:
    {
      selectLocalityIDAndLocalityName: false,
      selectInformation: false
    },

    localStorage:
    {
    },

    accessCredential: {}
  }),

  watch: {
    'input.startDate': async function() {
      this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB');
      this.navigation.startDatePickerIsVisible = false;
    },
    'input.endDate': async function() {
      this.display.endDateFormatted = new Date(this.input.endDate).toLocaleDateString('en-GB');
      this.navigation.endDatePickerIsVisible = false;
    },
    'input.localityIDS': async function (){
      this.input.allLocalitySelected = this.input.localityIDS.length == this.display.localityOptions.length;
    }
  },

  methods: {
    async generateChart(){
      this.display.chartSeries = this.display.information.map(agent => agent.amount);
      this.display.chartOptions = 
      {
        chart: 
        {
          type: 'pie'
        },
        labels: this.display.information.map(agent => agent.agentName),
        colors: this.display.information.map(agent => agent.agentColor)
      };
    },

    async selectLocalityIDAndLocalityName(){
      this.loader.selectLocalityIDAndLocalityName = true;
      const selectLocalityIDAndLocalityNameResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityName');
      if (selectLocalityIDAndLocalityNameResult.success){
        this.display.localityOptions = selectLocalityIDAndLocalityNameResult.result;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectLocalityIDAndLocalityName = false;
    },


    async selectInformation(){
      this.loader.selectInformation = true;
      this.display.information = null;
      this.display.chartSeries = null;
      this.display.chartOptions = null;
      const selectInformationRequestQuery =
      {
        'localityIDS': this.input.localityIDS,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate
      };
      const selectInformationResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/dashboard/agentSale/functions/selectInformation', selectInformationRequestQuery);
      if (selectInformationResult.success){
        this.display.information = selectInformationResult.result.map((item, index) => ({
          ...item,
          index: index + 1
        }));
        await this.generateChart();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la información del dashboard, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectInformation = false;
    },

    async selectAllLocality(){
      if (this.input.allLocalitySelected == false){
        this.input.localityIDS = this.display.localityOptions.map(localityOption => localityOption.localityID);
      } else {
        this.input.localityIDS.length = 0;
      }
    },

    async setDefaultValues(){
      this.input.localityIDS.length = 0;
      this.input.allLocalitySelected = false;
      const startDate = new Date();
      startDate.setDate(1);
      this.input.startDate = startDate;
      this.input.endDate = new Date();
      this.display.information = null;
      this.display.chartSeries = null;
      this.display.chartOptions = null;
      this.navigation.startDatePickerIsVisible = false;
      this.navigation.endDatePickerIsVisible = false;
      this.loader.selectLocalityIDAndLocalityName = false;
      this.loader.selectInformation = false;
    },
    
  },
  


  async mounted(){
    await this.setDefaultValues();
    await this.selectLocalityIDAndLocalityName();
    await this.selectAllLocality();
  }
});
</script>
