<template>
  <v-dialog v-model="navigation.debtPaymentDialogIsVisible" width="auto" :persistent="false">
    <v-card width="1000" style="padding: 20px;">
      <div v-if="loader.selectDebtPaymentFromDebtPaymentDialog" style="text-align: center;">
        <v-progress-circular color="info" indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <v-card style="background-color: #e9e8e8; padding: 20px;">
          <v-data-table-virtual :headers="display.debtPaymentHeaders" :items="display.debtPayment">
            <template v-slot:item.debtPaymentDatetime="{ item }">
              {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.debtPaymentDatetime) }}
            </template>
            <template v-slot:item.agentName="{ item }">
              <v-chip :color="item.agentColor" variant="flat">
                <p :style="{color: item.agentFontColor}">{{ item.agentName }}</p>
              </v-chip>
            </template>
            <template v-slot:item.debtPaymentPreviousAmount="{ item }">
              <p>₡ {{ item.debtPaymentPreviousAmount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
            </template>
            <template v-slot:item.debtPaymentAmount="{ item }">
              <v-chip color="#97cc91" variant="flat">
                <p style="color: white">₡ {{ item.debtPaymentAmount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
              </v-chip>
            </template>
            <template v-slot:item.debtPaymentBalanceAmount="{ item }">
              <p>₡ {{ item.debtPaymentBalanceAmount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
            </template>
          </v-data-table-virtual>
        </v-card>
      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped>
</style>

<script>

export default {
  name: "DebtPaymentDialog",
  inject: ['$generalFunctions'],

  data: () => ({
    navigation: 
    {
      debtPaymentDialogIsVisible: false,
      debtID: null
    },

    input:
    {
      
    },

    display: 
    {
      debtPayment: null,
      debtPaymentHeaders: 
      [
        {key: 'debtPaymentID', title: 'ID'},
        {key: 'debtPaymentType', title: 'MÉTODO DE PAGO'},
        {key: 'debtPaymentDatetime', title: 'ABONADO EL'},
        {key: 'agentName', title: 'REGISTRADO POR'},
        {key: 'debtPaymentPreviousAmount', title: 'SALDO ANTERIOR'},
        {key: 'debtPaymentAmount', title: 'CANTIDAD ABONADA'},
        {key: 'debtPaymentBalanceAmount', title: 'SALDO RESULTANTE'},
        {key: 'debtPaymentNote', title: 'NOTA'},
        {key: 'debtPaymentAction', title: null}
      ],
    },

    loader:
    {
      selectDebtPaymentFromDebtPaymentDialog: false
    },

    localStorage: 
    {
    }

  }),

  watch: {
  },

  methods: {
    
    async selectDebtPaymentFromDebtPaymentDialog(){
      this.loader.selectDebtPaymentFromDebtPaymentDialog = true;
      const selectDebtPaymentFromDebtPaymentDialogRequestQuery = 
      {
        'debtID': this.navigation.debtID
      };
      const selectDebtPaymentFromDebtPaymentDialogResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/contact/functions/selectDebtPaymentFromDebtPaymentDialog', selectDebtPaymentFromDebtPaymentDialogRequestQuery);
      if (selectDebtPaymentFromDebtPaymentDialogResult.success){
        this.display.debtPayment = selectDebtPaymentFromDebtPaymentDialogResult.result;
        this.loader.selectDebtPaymentFromDebtPaymentDialog = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la información de la deuda, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
        await this.setDefaultValues();
      }
      
    },

    async setDefaultValues() {
      this.navigation.debtPaymentDialogIsVisible = false;
      this.navigation.debtID = null;
      this.display.debtPayment = null;
      this.loader.selectDebtPaymentFromDebtPaymentDialog = false;
    },

    async openDebtPaymentDialog(debtID) {
      await this.setDefaultValues();
      this.navigation.debtID = debtID;
      this.navigation.debtPaymentDialogIsVisible = true;
      await this.selectDebtPaymentFromDebtPaymentDialog();
    }
  },

  mounted(){
  }
}
</script>