<template>
  <v-dialog v-model="navigation.insertProductSearchParameterDialogIsVisible" :persistent="true" width="auto">
    <v-card width="600" style="padding: 20px;">
      <div v-if="loader.insertProductSearchParameter" style="text-align: center;">
        <br>
        <v-progress-circular color="success" indeterminate></v-progress-circular>
        <br><br>
      </div>
      <div v-else>
        <v-text-field 
          v-model="input.productSearchParameterName" 
          ref="insertingProductSearchParameterName" 
          prepend-inner-icon="mdi-format-title" 
          :label="`Nombre de${navigation.productSearchParameterPrefix == 'La' ? ' la' : 'l'} ${navigation.productSearchParameterName}`" 
          variant="solo-filled"
        >
        </v-text-field>        
        <v-text-field v-if="navigation.productSearchParameterName == 'color'" @click="navigation.productColorDialogIsVisible = true" v-model="input.productSearchParameterDescription" :bg-color="input.productSearchParameterDescription" readonly="true" prepend-inner-icon="mdi-palette" label="Color (valor hexadecimal)" variant="solo-filled"></v-text-field>
        <v-dialog v-model="navigation.productColorDialogIsVisible" width="auto">
          <v-card width="300">
            <v-color-picker v-model="input.productSearchParameterDescription" :v-model:mode="'hex'" hide-inputs></v-color-picker>
          </v-card>
        </v-dialog>

        <v-checkbox 
          v-model="input.productSearchParameterIsActive" 
          :label="`${navigation.productSearchParameterPrefix} ${navigation.productSearchParameterName} estará activ${navigation.productSearchParameterPrefix == 'La' ? 'a' : 'o'} desde su creación`" 
          color="success"
        >
        </v-checkbox>
        <br>
        <v-btn @click="insertProductSearchParameter()" dark block height="38" color="success" style="margin-bottom: 15px;">
          <h3>{{ `CREAR NUEV${navigation.productSearchParameterPrefix == 'La' ? 'a' : 'o'} ${navigation.productSearchParameterName}` }}</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-plus-circle-outline</v-icon>
        </v-btn>
        <v-btn @click="closeInsertProductSearchParameterDialog()" dark block height="38" color="error">
          <h3>CANCELAR</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
  
<style scoped>
</style>
  

<script>
import { viewMethodCaller } from '../viewMethodCaller.js';

export default {
  name: 'InsertProductBrandDialog',
  inject: ['$generalFunctions'],

  data: () => ({

    input:
    {
      productSearchParameterName: null,
      productSearchParameterDescription: null,
      productSearchParameterIsActive: null
    },

    loader: 
    {
      insertProductSearchParameter: false
    },
    
    navigation: 
    {
      insertProductSearchParameterDialogIsVisible: false,
      productSearchParameterName: null,
      productSearchParameterPrefix: null,
      productColorDialogIsVisible: false
    },

    display:
    {
    },

    assets:
    {
    }


  }),

  watch: {
  },

  methods: {

    async insertProductSearchParameter(){
      this.loader.insertProductSearchParameter = true;
      const insertProductSearchParameterRequestQuery = 
      {
        'productSearchParameterType': this.navigation.productSearchParameterName,
        'productSearchParameterName': this.input.productSearchParameterName,
        'productSearchParameterDescription': this.navigation.productSearchParameterName == 'color' ? this.input.productSearchParameterDescription : ' ',
        'productSearchParameterIsActive': this.input.productSearchParameterIsActive
      };
      const insertProductSearchParameterResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/insertProductSearchParameter', insertProductSearchParameterRequestQuery);
      if (insertProductSearchParameterResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': `Se ha creado ${this.navigation.productSearchParameterPrefix == 'La' ? 'la' : 'el'} ${this.navigation.productSearchParameterName} exitosamente`,
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        viewMethodCaller.emit('InsertProductSearchParameterDialog/methods/insertProductSearchParameter()');
        this.navigation.insertProductSearchParameterDialogIsVisible = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': `Ha ocurrido un error inesperado al crear ${this.navigation.productSearchParameterPrefix == 'La' ? 'la' : 'el'} ${this.navigation.productSearchParameterName}, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema`,
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.insertProductSearchParameter = false;

    },
    

    setDefaultValues(){
      this.input.productSearchParameterName = null;
      this.input.productSearchParameterDescription = null;
      this.input.productSearchParameterIsActive = true;
      this.navigation.productColorDialogIsVisible = false;
      this.loader.insertProductSearchParameter = false;
    },

    focusOnInsertingProductSearchParameterNameInput(){
      const focusOnInsertingProductSearchParameterNameInterval = setInterval(() => {
        if (this.$refs.insertingProductSearchParameterName) {
          this.$refs.insertingProductSearchParameterName.focus();
          clearInterval(focusOnInsertingProductSearchParameterNameInterval);
        }
      }, 1);
    },

    openInsertProductSearchParameterDialog(insertProductBrandDialogRequestQuery) {
      this.navigation.productSearchParameterName = insertProductBrandDialogRequestQuery.productSearchParameterName;
      this.navigation.productSearchParameterPrefix = insertProductBrandDialogRequestQuery.productSearchParameterPrefix;
      this.setDefaultValues();
      this.navigation.insertProductSearchParameterDialogIsVisible = true;
      this.focusOnInsertingProductSearchParameterNameInput();
    },

    closeInsertProductSearchParameterDialog(){
      this.setDefaultValues();
      this.navigation.insertProductSearchParameterDialogIsVisible = false;
    }
  },


  mounted(){
  }
}
</script>