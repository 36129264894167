<template>
  <div class="billsViewContainer">
    <v-card class="billsInputsContainer" elevation="3">
      <v-expansion-panels v-model="navigation.searchVisible">
        <v-expansion-panel value="searchVisible">
          <v-expansion-panel-title>
            <p style="font-size: large;">Búsqueda de facturas previas</p>
            <template v-slot:actions>
              <v-icon icon="mdi-magnify"></v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <br>
            <v-switch v-model="input.searchByClientSSN" label="Búsqueda por cliente" hide-details color="info"></v-switch>
            <br>
            <div v-if="input.searchByClientSSN">
              <v-autocomplete v-model="input.selectedClientSSN" :items="display.clientOptions" :loading="loader.selectClientForClientSearch" :disabled="loader.selectClientForClientSearch" item-title="ac" item-value="ssn" append-inner-icon="mdi-account" label="Filtrar por cliente" variant="solo-filled"></v-autocomplete>
            </div>
            <div v-else>
              <div class="billsInputsMainContainer">
                <div class="billsInputsLeftContainer">
                  <v-select v-if="accessCredential['/bills/selectLocality']" v-model="input.selectedLocalityID" :items="display.localityOptions" :loading="loader.selectLocalityIDAndLocalityNameByLocalityIsActive" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive" item-title="localityName" item-value="localityID" append-inner-icon="mdi-store" label="Filtrar por localidad" variant="solo-filled"></v-select>
                  <v-select v-model="input.selectedAgentID" :items="display.agentOptions" :loading="loader.selectAgentByAgentLocalityIDAndByAgentIsActive" :disabled="loader.selectAgentByAgentLocalityIDAndByAgentIsActive" item-title="agentName" item-value="agentID" append-inner-icon="mdi-account" label="Filtrar por facturador" variant="solo-filled"></v-select>
                  <v-select v-model="input.selectedBillPaymentType" :items="display.billPaymentTypeOptions" append-inner-icon="mdi-cash-multiple" label="Filtrar por método de pago" variant="solo-filled"></v-select>
                </div>
                <div class="billsInputsRightContainer">
                  <v-text-field v-model="display.startDateFormatted" @click="navigation.startDatePickerIsVisible = true" readonly :label="`Filtrar por fecha ${accessCredential['/bills/selectEndDate'] ? 'inicial' : ''}`" append-inner-icon="mdi-calendar" variant="solo-filled"></v-text-field>
                  <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
                    <v-card style="display: flex;">
                      <v-date-picker v-model="input.startDate" :min="getMinimumDate()" :max="getMaximumDate()" hide-header color="primary" width="100%"></v-date-picker>
                      <v-chip @click="input.startDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
                    </v-card>
                  </v-dialog>
                  <v-text-field v-if="accessCredential['/bills/selectEndDate']" v-model="display.endDateFormatted" @click="navigation.endDatePickerIsVisible = true" readonly label="Filtrar por fecha final" append-inner-icon="mdi-calendar" variant="solo-filled"></v-text-field>
                  <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
                    <v-card>
                      <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
                      <v-chip @click="input.endDate = new Date()" class="selectTodayChip" color="primary" variant="flat">Hoy</v-chip>
                    </v-card>
                  </v-dialog>
                  <v-select v-model="input.selectedBillType" :items="display.billTypeOptions" append-inner-icon="mdi-text-box-outline" label="Filtrar por tipo de factura" variant="solo-filled"></v-select>
                </div>
              </div>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-title>
                    Búsqueda avanzada por producto
                    <template v-slot:actions>
                      <v-icon icon="mdi-magnify"></v-icon>
                    </template>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <br>
                    <v-autocomplete v-model="input.selectedProductID" :items="display.productOptions" :loading="loader.selectProductBasicBasicInformationForBillsView" :disabled="loader.selectProductBasicBasicInformationForBillsView" ref="selectedProductIDInput" item-title="productAutocompleteTitle" item-value="productID" append-inner-icon="mdi-alpha-a-box-outline" label="Filtrar por producto" variant="solo-filled"></v-autocomplete>
                    <div style="display: flex;">
                      <div style="width: 49.5%; margin-right: 0.5%;">
                        <v-autocomplete v-model="input.selectedProductCategory" :items="display.productCategoryOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productCategoryName" item-value="productCategoryID" append-inner-icon="mdi-cog" label="Filtrar por categoría del producto" variant="solo-filled"></v-autocomplete>
                        <v-autocomplete v-model="input.selectedProductBrand" :items="display.productBrandOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productBrandName" item-value="productBrandID" append-inner-icon="mdi-bookshelf" label="Filtrar por marca del producto" variant="solo-filled"></v-autocomplete>
                        <v-autocomplete v-model="input.selectedProductModel" :items="display.productModelOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productModelName" item-value="productModelID" append-inner-icon="mdi-source-branch" label="Filtrar por modelo del producto" variant="solo-filled"></v-autocomplete>
                      </div>
                      <div style="width: 49.5%; margin-left: 0.5%;">
                        <v-autocomplete v-model="input.selectedProductFlavor" :items="display.productFlavorOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productFlavorName" item-value="productFlavorID" append-inner-icon="mdi-water" label="Filtrar por sabor del producto" variant="solo-filled"></v-autocomplete>
                        <v-autocomplete v-model="input.selectedProductNicotineLevel" :items="display.productNicotineLevelOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productNicotineLevelName" item-value="productNicotineLevelID" append-inner-icon="mdi-weather-cloudy" label="Filtrar por graduación de nicotina del producto" variant="solo-filled"></v-autocomplete>
                        <v-autocomplete v-model="input.selectedProductSize" :items="display.productSizeOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productSizeName" item-value="productSizeID" append-inner-icon="mdi-ruler" label="Filtrar por tamaño del producto" variant="solo-filled"></v-autocomplete>
                      </div>
                    </div>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
              <br><br>
              <v-btn @click="selectBillOnBillsView()" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive || loader.selectAgentByAgentLocalityIDAndByAgentIsActive || loader.selectProductBasicBasicInformationForBillsView || loader.selectBillOnBillsView" dark block height="38" color="primary">
                <h3>BUSCAR</h3>
                <v-icon style="margin-left: 20px;" dark right>mdi-magnify</v-icon>
              </v-btn>
              <br>
              <v-btn @click="setDefaultValues()" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive || loader.selectAgentByAgentLocalityIDAndByAgentIsActive || loader.selectProductBasicBasicInformationForBillsView || loader.selectBillOnBillsView" dark block height="38" color="#ffd978">
                <h3>LIMPIAR</h3>
                <v-icon style="margin-left: 20px;" dark right>mdi-backspace</v-icon>
              </v-btn>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <br><br>
    <v-card v-if="loader.selectBillOnBillsView || (display.billsRows.length > 0)" class="billsTableContainer" elevation="3">
      <div v-if="loader.selectBillOnBillsView" style="text-align: center;">
        <br>
        <v-progress-circular color="info" indeterminate></v-progress-circular>
        <br><br>
      </div>
      <v-data-table-virtual v-else :headers="display.billsHeaders" :items="display.billsRows">
        <template v-slot:item.billState="{ item }">
          <v-chip :color="localStorage.billStateColors[item.billState].billStateColor" variant="flat">
            <p :style="{color: localStorage.billStateColors[item.billState].billStateFontColor}">{{ item.billState }}</p>
          </v-chip>
        </template>
        <template v-slot:item.localityName="{ item }">
          <v-chip :color="item.localityColor" variant="flat">
            <p :style="{color: item.localityFontColor}">{{ item.localityName }}</p>
          </v-chip>
        </template>
        <template v-slot:item.billAgentName="{ item }">
          <v-chip :color="item.billAgentColor" variant="flat">
            <p :style="{color: item.billAgentFontColor}">{{ item.billAgentName }}</p>
          </v-chip>
        </template>
        <template v-slot:item.clientName="{ item }">
          {{ item.clientName || item.contactName || 'Sin nombre registrado' }}
        </template>
        <template v-slot:item.billCreationDatetime="{ item }">
          {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.billCreationDatetime) }}
        </template>
        <template v-slot:item.billAmount="{ item }">
          {{ item.billCurrency == '₡' ? `${item.billCurrency} ${$generalFunctions.default.methods.parseAmountToDisplay(item.billAmount)}` : `${$generalFunctions.default.methods.parseAmountToDisplay(item.billAmount)} ${item.billCurrency}` }}
        </template>
        <template v-slot:item.billActions="{ item }">
          <v-menu open-on-hover>
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" dark height="38" color="info">
                <h3>ACCIONES</h3>
                <v-icon style="margin-left: 20px;" dark right>mdi-information</v-icon>
              </v-btn>
            </template>
            <v-list density="compact">
              <template v-for="billActionOption in display.billActionsOptions" :key="billActionOption">
                <v-list-item v-if="item.billState != 'Factura cancelada' && billActionOption.title == 'Cancelar factura' && accessCredential['/bills/cancelBill']" @click="openBillCancelationDialog(item.billID)" :value="billActionOption">
                  <template v-slot:prepend>
                    <v-icon :icon="billActionOption.icon"></v-icon>
                  </template>
                  <v-list-item-title>{{ billActionOption.title }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="billActionOption.title == 'Información de la factura'" @click="openBillDialog(item.billID)" :value="billActionOption">
                  <template v-slot:prepend>
                    <v-icon :icon="billActionOption.icon"></v-icon>
                  </template>
                  <v-list-item-title> {{ billActionOption.title }} </v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </template>
      </v-data-table-virtual>
    </v-card>
    <div v-if="accessCredential['/bills/result'] && (loader.selectBillOnBillsView || (display.billsRows.length > 0))">
      <br><br>
      <v-card v-if="loader.selectBillOnBillsView || (display.billsRows.length > 0)" class="billsTableContainer" elevation="3">
        <div v-if="loader.selectBillOnBillsView" style="text-align: center;">
          <br>
          <v-progress-circular color="info" indeterminate></v-progress-circular>
          <br><br>
        </div>
        <div v-else>
          <div style="display: flex;">
            <div style="width: 30%;">
              <p style="font-size: larger;"><strong>Efectivo: </strong></p>
              <p style="font-size: larger;"><strong>Tarjeta: </strong></p>
              <p style="font-size: larger;"><strong>SINPE: </strong></p>
              <p style="font-size: larger;"><strong>Transferencia o depósito: </strong></p>
              <p style="font-size: larger;"><strong>Página web: </strong></p>
              <p style="font-size: larger;"><strong>Uber: </strong></p>
              <p style="font-size: larger;"><strong>Rappi: </strong></p>
              <br>
              <p style="font-size: x-large;"><strong>TOTAL: </strong></p>
            </div>
            <div style="width: 30%; margin-left: 2%;">
              <p style="font-size: larger;">₡ {{ display.billsRows.filter(billRow => billRow.billPaymentType == 'Efectivo').reduce((total, row) => total + row.billAmount, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
              <p style="font-size: larger;">₡ {{ display.billsRows.filter(billRow => billRow.billPaymentType == 'Tarjeta').reduce((total, row) => total + row.billAmount, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
              <p style="font-size: larger;">₡ {{ display.billsRows.filter(billRow => billRow.billPaymentType == 'SINPE').reduce((total, row) => total + row.billAmount, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
              <p style="font-size: larger;">₡ {{ display.billsRows.filter(billRow => billRow.billPaymentType == 'Transferencia o depósito').reduce((total, row) => total + row.billAmount, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
              <p style="font-size: larger;">₡ {{ display.billsRows.filter(billRow => billRow.billPaymentType == 'Página web').reduce((total, row) => total + row.billAmount, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
              <p style="font-size: larger;">₡ {{ display.billsRows.filter(billRow => billRow.billPaymentType == 'Uber').reduce((total, row) => total + row.billAmount, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
              <p style="font-size: larger;">₡ {{ display.billsRows.filter(billRow => billRow.billPaymentType == 'Rappi').reduce((total, row) => total + row.billAmount, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
              <br>
              <p style="font-size: larger;">₡ {{ display.billsRows.reduce((total, row) => total + row.billAmount, 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
            </div>
          </div>
        </div>
      </v-card>
    </div>
    <br><br>
  </div>
</template>

<style scoped>
  .billsViewContainer {
    margin: 2% 3% 0% 3%;
  }

  .billsInputsContainer {
    padding: 1.5%;
    background-color: #e9e8e8;
  }

  .billsInputsMainContainer {
    display: flex;
  }

  .billsInputsLeftContainer {
    width: 50%;
  }

  .billsInputsRightContainer {
    width: 50%;
    margin-left: 2%;
  }

  .selectTodayChip {
    margin: 0% 5% 5% 5%;
    text-align: center;
    cursor: pointer;
  }

  .billsTableContainer {
    max-height: 1000px;
    overflow-y: auto;
    padding: 1%;
    background-color: #e9e8e8;
  }
  
</style>

<script>
import { defineComponent } from 'vue';
import { viewMethodCaller } from '../viewMethodCaller.js';

export default defineComponent({
  name: 'BillsView',
  inject: ['$generalFunctions'],

  
  data: () => ({
    input: 
    {
      searchByClientSSN: false,
      selectedClientSSN: null,
      selectedLocalityID: null,
      selectedAgentID: null,
      selectedBillPaymentType: null,
      startDate: null,
      endDate: null,
      selectedBillType: null,
      selectedProductID: null,
      searchedProductID: null,
      selectedProductCategory: null,
      selectedProductBrand: null,
      selectedProductModel: null,
      selectedProductFlavor: null,
      selectedProductNicotineLevel: null,
      selectedProductSize: null
    },

    navigation: 
    {
      searchVisible: 'searchVisible',
      startDatePickerIsVisible: false,
      endDatePickerIsVisible: false,
      selectBillOnBillsView: false,
      billDialogBillID: null,
      billCancelationBillID: null
    },

    loader: 
    {
      selectClientForClientSearch: false,
      selectLocalityIDAndLocalityNameByLocalityIsActive: false,
      selectAgentByAgentLocalityIDAndByAgentIsActive: false,
      selectProductBasicBasicInformationForBillsView: false,
      selectProductSearchParameters: false,
      selectBillOnBillsView: false
    },

    display:
    {
      localityOptions: [],
      agentOptions: [],
      billPaymentTypeOptions: 
      [
        {title: 'Todos los métodos de pago', value: null},
        {title: 'Efectivo', value: 'Efectivo'},
        {title: 'Tarjeta', value: 'Tarjeta'},
        {title: 'SINPE', value: 'SINPE'},
        {title: 'Transferencia o depósito', value: 'Transferencia o depósito'},
        {title: 'Página web', value: 'Página web'},
        {title: 'Rappi', value: 'Rappi'},
        {title: 'Mixto', value: 'Mixto'}
      ],
      startDateFormatted: '',
      endDateFormatted: '',
      billTypeOptions: 
      [
        {title: 'Todos los tipos de factura', value: null},
        {title: 'Factura electrónica', value: 'Factura electrónica'},
        {title: 'Tiquete electrónico', value: 'Tiquete electrónico'},
        {title: 'Recibo', value: 'Recibo'}
      ],
      clientOptions: [],
      productOptions: [],
      productCategoryOptions: [],
      productBrandOptions: [],
      productModelOptions: [],
      productFlavorOptions: [],
      productNicotineLevelOptions: [],
      productSizeOptions: [],
      billsRows: [],
      billsHeaders: 
      [
        {key: 'billID', title: 'ID'},
        {key: 'billState', title: 'ESTADO'},
        {key: 'localityName', title: 'LOCALIDAD'},
        {key: 'billAgentName', title: 'FACTURADOR'},
        {key: 'clientName', title: 'CLIENTE'},
        {key: 'billCreationDatetime', title: 'FECHA Y HORA'},
        {key: 'billType', title: 'TIPO DE FACTURA'},
        {key: 'billAmount', title: 'MONTO'},
        {key: 'billPaymentType', title: 'MÉTODO DE PAGO'},
        {key: 'billActions', title: 'ACCIONES'}
      ],
      billActionsOptions: 
      [
        {value: 1, title: 'Información de la factura', icon: 'mdi-help-circle'},
        {value: 3, title: 'Cancelar factura', icon: 'mdi-close-circle'}
      ],
    },

    accessCredential: {},

    localStorage: 
    {
      agentID: null,
      localityID: null,
      billStateColors: null
    }

  }),

  watch: {
    'input.selectedLocalityID': async function(){
      this.selectAgentByAgentLocalityIDAndByAgentIsActive();
    },
    'input.startDate': async function() {
      this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB');
      this.navigation.startDatePickerIsVisible = false;
    },
    'input.endDate': async function() {
      this.display.endDateFormatted = new Date(this.input.endDate).toLocaleDateString('en-GB');
      this.navigation.endDatePickerIsVisible = false;
    },
    'input.searchByClientSSN': async function(){
      this.setDefaultValues();
    },
    'input.selectedClientSSN': async function(){
      if (this.input.selectedClientSSN){
        this.selectBillOnBillsViewForClient();
      }
    }
  },

  methods: {
    getMinimumDate(){
      const offsetDays = (this.accessCredential['/bills/maxOffsetDays'] + 1) || 2;
      const minimumDate = new Date();
      minimumDate.setDate(minimumDate.getDate() - offsetDays);
      return this.accessCredential['/bills/selectEndDate'] ? '' : minimumDate;
    },

    getMaximumDate(){
      return this.accessCredential['/bills/selectEndDate'] ? '' : new Date();
    },

    async openBillDialog(billID){
      const billDialogRequestQuery = 
      {
        'billID': billID
      };
      this.$root.billDialog.openBillDialog(billDialogRequestQuery);
    },

    async openBillCancelationDialog(billID){
      const billCancelationDialogRequestQuery =
      {
        'billID': billID
      };
      this.$root.billCancelationDialog.showlBillCancelationDialog(billCancelationDialogRequestQuery);
    },

    blurInput(inputReference){
      const blurInputInterval = setInterval(() => {
        if (this.$refs[inputReference]) {
          this.$refs[inputReference].blur();
          clearInterval(blurInputInterval);
        }
      }, 1);
    },

    selectProductID(){
      const selectedProduct = this.display.productOptions.find(productOption => productOption.productSKU === this.input.searchedProductID);
      if (selectedProduct){
        const selectedProductID = selectedProduct.productID;
        if (!this.input.selectedProductIDS.includes(selectedProductID)){
          this.input.selectedProductIDS.push(selectedProduct.productID);
          this.blurInput('selectedProductIDSInput');
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': null,
            'notificationDialogBody': 'El producto escaneado ya se encuentra en la lista de productos seleccionados',
            'notificationDialogColor': 'info',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': null,
          'notificationDialogBody': 'El producto escaneado no ha sido encontrado, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
      }
      this.input.searchedProductID = null;
    },

    async selectBillOnBillsViewForClient(){
      this.loader.selectBillOnBillsView = true;
      this.display.billsRows.length = 0;
      const selectBillOnBillsViewForClientRequestQuery =
      {
        'clientSSN': this.input.selectedClientSSN
      };
      const selectBillOnBillsViewForClientResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/functions/selectBillOnBillsViewForClient', selectBillOnBillsViewForClientRequestQuery);
      if (selectBillOnBillsViewForClientResult.success){
        this.display.billsRows = selectBillOnBillsViewForClientResult.result;
        this.navigation.searchVisible = '';
        if (this.display.billsRows.length == 0){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': null,
            'notificationDialogBody': 'No se ha encontrado ninguna factura con las características especificadas, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las facturas, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectBillOnBillsView = false;
    },

    async selectBillOnBillsView(){
      this.loader.selectBillOnBillsView = true;
      this.display.billsRows.length = 0;
      const selectBillOnBillsViewRequestQuery = 
      {
        'billLocalityID': this.accessCredential['/bills/selectLocality'] ? this.input.selectedLocalityID : this.localStorage.localityID,
        'billAgentID': this.input.selectedAgentID,
        'billPaymentType': this.input.selectedBillPaymentType,
        'billCreationStartDatetime': this.input.startDate,
        'billCreationEndDatetime': this.input.endDate,
        'billType': this.input.selectedBillType,
        'productID': this.input.selectedProductID,
        'productCategoryID': this.input.selectedProductCategory,
        'productBrandID': this.input.selectedProductBrand,
        'productModelID': this.input.selectedProductModel,
        'productFlavorID': this.input.selectedProductFlavor,
        'productNicotineLevelID': this.input.selectedProductNicotineLevel,
        'productSizeID': this.input.selectedProductSize
      };
      const selectBillOnBillsViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/functions/selectBillOnBillsView', selectBillOnBillsViewRequestQuery);
      if (selectBillOnBillsViewResult.success){
        this.display.billsRows = selectBillOnBillsViewResult.result;
        this.navigation.searchVisible = '';
        if (this.display.billsRows.length == 0){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': null,
            'notificationDialogBody': 'No se ha encontrado ninguna factura con las características especificadas, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las facturas, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectBillOnBillsView = false;
    },

    async selectClientForClientSearch(){
      this.loader.selectClientForClientSearch = true;
      const selectClientForClientSearchResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/client/functions/selectClientForClientSearch');
      if (selectClientForClientSearchResult.success){
        this.display.clientOptions = selectClientForClientSearchResult.result;
        this.loader.selectClientForClientSearch = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los clientes, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectLocalityIDAndLocalityNameByLocalityIsActive(){
      this.loader.selectLocalityIDAndLocalityNameByLocalityIsActive = true;
      const selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery = {'localityIsActive': true};
      const selectLocalityIDAndLocalityNameByLocalityIsActiveResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityNameByLocalityIsActive', selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery);
      if (selectLocalityIDAndLocalityNameByLocalityIsActiveResult.success){
        this.display.localityOptions = 
        [
          {'localityID': null, 'localityName': 'Todas las localidades'}, 
          ...selectLocalityIDAndLocalityNameByLocalityIsActiveResult.result
        ];
        this.loader.selectLocalityIDAndLocalityNameByLocalityIsActive = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectAgentByAgentLocalityIDAndByAgentIsActive(){
      this.loader.selectAgentByAgentLocalityIDAndByAgentIsActive = true;
      const selectAgentByAgentLocalityIDAndByAgentIsActiveRequestQuery = 
      {
        'agentLocalityID': this.accessCredential['/bills/selectLocality'] ? this.input.selectedLocalityID : this.localStorage.localityID, 
        'agentIsActive': true
      };
      const selectAgentByAgentLocalityIDAndByAgentIsActiveResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/select/byAgentLocalityIDAndByAgentIsActive', selectAgentByAgentLocalityIDAndByAgentIsActiveRequestQuery);
      if (selectAgentByAgentLocalityIDAndByAgentIsActiveResult.success){
        this.display.agentOptions = 
        [
          {'agentID': null, 'agentName': 'Todos los agentes'},
          ...selectAgentByAgentLocalityIDAndByAgentIsActiveResult.result
        ];
        if (!this.display.agentOptions.map(agentOption => agentOption.agentID).includes(this.input.selectedAgentID)){
          this.input.selectedAgentID = null;
        }
        this.loader.selectAgentByAgentLocalityIDAndByAgentIsActive = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de facturadores, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductBasicBasicInformationForBillsView(){
      this.loader.selectProductBasicBasicInformationForBillsView = true;
      const selectProductBasicBasicInformationForBillsViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductBasicBasicInformationForBillsView');
      if (selectProductBasicBasicInformationForBillsViewResult.success){
        this.display.productOptions = selectProductBasicBasicInformationForBillsViewResult.result.map(productOption => ({
          'productID': productOption.i,
          'productSKU': productOption.s,
          'productName': productOption.n,
          'productAutocompleteTitle': productOption.s + ' - ' + productOption.n
        }));
        this.loader.selectProductBasicBasicInformationForBillsView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductSearchParameters(){
      this.loader.selectProductSearchParameters = true;
      const selectProductSearchParametersResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductSearchParameters');
      if (selectProductSearchParametersResult.success){
        this.display.productCategoryOptions = [{'productCategoryName': 'Todas las categorías', 'productCategoryID': null}, ...selectProductSearchParametersResult.result.productCategory];
        this.display.productBrandOptions = [{'productBrandName': 'Todas las marcas', 'productBrandID': null}, ...selectProductSearchParametersResult.result.productBrand];
        this.display.productModelOptions = [{'productModelName': 'Todos los modelos', 'productModelID': null}, ...selectProductSearchParametersResult.result.productModel];
        this.display.productFlavorOptions = [{'productFlavorName': 'Todos los sabores', 'productFlavorID': null}, ...selectProductSearchParametersResult.result.productFlavor];
        this.display.productNicotineLevelOptions = [{'productNicotineLevelName': 'Todas las graduaciones de nicotina', 'productNicotineLevelID': null}, ...selectProductSearchParametersResult.result.productNicotineLevel];
        this.display.productSizeOptions = [{'productSizeName': 'Todos los tamaños', 'productSizeID': null}, ...selectProductSearchParametersResult.result.productSize];
        this.loader.selectProductSearchParameters = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de características de los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async setDefaultValues(){
      this.input.selectedClientSSN = null;
      this.input.selectedLocalityID = null;
      this.input.selectedAgentID = null;
      this.input.selectedBillPaymentType = null;
      this.input.startDate = new Date();
      this.input.endDate = new Date();
      this.input.selectedBillType = null;
      this.input.selectedProductID = null;
      this.input.selectedProductCategory = null;
      this.input.selectedProductBrand = null;
      this.input.selectedProductModel = null;
      this.input.selectedProductFlavor = null;
      this.input.selectedProductNicotineLevel = null;
      this.input.selectedProductSize = null;
      this.display.billsRows.length = 0;
      this.navigation.billDialogBillID = null;
      this.navigation.billCancelationBillID = null;
    },

    async getLocalStorageData(){
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.billStateColors = this.$generalFunctions.default.data.billStateColors;
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    },

    listenToViewMethodCaller(){
      viewMethodCaller.on('BillsView/methods/selectBillOnBillsView()', this.selectBillOnBillsView);
    },

  },

  async mounted(){
    this.listenToViewMethodCaller();
    await this.getLocalStorageData();
    await this.selectClientForClientSearch();
    await this.selectLocalityIDAndLocalityNameByLocalityIsActive();
    await this.selectAgentByAgentLocalityIDAndByAgentIsActive();
    await this.selectProductBasicBasicInformationForBillsView();
    await this.selectProductSearchParameters();
    this.setDefaultValues();
  }

});

</script>
