<template>
  <v-dialog v-model="navigation.insertClientDialogIsVisible" width="auto" :persistent="true">
    <v-card width="600" style="padding: 20px; text-align: center;">
      <v-select v-model="input.selectedClientType" :items="display.clientTypeOptions" append-inner-icon="mdi-cog" density="compact" label="Tipo de cliente" variant="solo-filled"></v-select>
      <v-select v-model="input.selectedClientSSNType" :items="display.clientSSNTypeOptions" append-inner-icon="mdi-grid" density="compact" label="Tipo de documento" variant="solo-filled"></v-select>
      <v-text-field v-model="input.clientSSN" :loading="loader.selectClientOnBillView" :readonly="loader.selectClientOnBillView" append-inner-icon="mdi-card-account-details-outline" label="Cédula del cliente" variant="solo-filled" density="compact"></v-text-field>
      <v-text-field v-model="input.clientName" append-inner-icon="mdi-account-edit" label="Nombre del cliente" variant="solo-filled" density="compact"></v-text-field>
      <v-text-field v-model="input.clientEmail" append-inner-icon="mdi-email-outline" label="Correo del cliente" variant="solo-filled" density="compact"></v-text-field>
      <v-text-field v-model="input.clientPhoneNumber" append-inner-icon="mdi-phone" label="Número de teléfono del cliente" variant="solo-filled" density="compact"></v-text-field>
      <br>
      <v-btn @click="insertClient()" dark block height="38" color="success">
        <h3>AGREGAR CLIENTE</h3>
        <v-icon style="margin-left: 20px;" dark right>mdi-check</v-icon>
      </v-btn>
      <br>
      <v-btn @click="closeInsertClientDialog()" dark block height="38" color="error">
        <h3>SALIR</h3>
        <v-icon style="margin-left: 20px;" dark right>mdi-close</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<style scoped>
</style>

<script>
import { viewMethodCaller } from '../viewMethodCaller.js';

export default {
  name: "InsertClientDialog",
  inject: ['$generalFunctions'],

  data: () => ({
    navigation: 
    {
      insertClientDialogIsVisible: false
    },

    input:
    {
      selectedClientType: null,
      selectedClientSSNType: null,
      clientSSN: '',
      clientName: '',
      clientEmail: '',
      clientPhoneNumber: ''
    },

    display: 
    {
      clientTypeOptions: ['Persona', 'Empresa'],
      clientSSNTypeOptions: ['Física', 'Jurídica', 'DIMEX']
    },

    loader:
    {
      insertClient: false
    },

    localStorage: 
    {
    }

  }),

  watch: {
  },

  methods: {
    validateInsertClientInput(){
      const regularExpressionChecker = /\S/;
      if (this.input.selectedClientSSNType == null){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque un tipo de documento del cliente, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (regularExpressionChecker.test(this.input.clientSSN) == false){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque una cédula del cliente, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.clientSSN.length > 30){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN DEMASIADO LARGA',
          'notificationDialogBody': 'La cédula del cliente no puede exceder los 30 caracteres, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (regularExpressionChecker.test(this.input.clientName) == false){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque un nombre del cliente, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.clientName.length > 255){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN DEMASIADO LARGA',
          'notificationDialogBody': 'El nombre del cliente no puede exceder los 255 caracteres, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (regularExpressionChecker.test(this.input.clientEmail) == false){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque un correo del cliente, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.clientEmail.length > 255){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN DEMASIADO LARGA',
          'notificationDialogBody': 'El correo del cliente no puede exceder los 255 caracteres, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (regularExpressionChecker.test(this.input.clientPhoneNumber) == false){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque un teléfono del cliente, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.clientPhoneNumber.length > 255){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN DEMASIADO LARGA',
          'notificationDialogBody': 'El teléfono del cliente no puede exceder los 30 caracteres, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      return true;
    },


    async insertClient(){
      if (this.validateInsertClientInput()){
        const insertClientRequestQuery = 
        {
          'clientSSNType': this.input.selectedClientSSNType,
          'clientSSN': this.input.clientSSN,
          'clientName': this.input.clientName,
          'clientEmail': this.input.clientEmail,
          'clientPhoneNumber': this.input.clientPhoneNumber
        };
        const insertClientResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/client/insert', insertClientRequestQuery);
        if (insertClientResult.success){
          if (insertClientResult.result == 'duplicate'){
            this.$root.notificationDialog.showNotificationDialog({
              'notificationDialogTitle': 'ATENCIÓN',
              'notificationDialogBody': `Ya existe un cliente registrado con la cédula '${this.input.clientSSN}', por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema`,
              'notificationDialogColor': 'warning',
              'notificationDialogIsPersistent': false
            });
          } else {
            this.$root.notificationDialog.showNotificationDialog({
              'notificationDialogTitle': 'ÉXITO',
              'notificationDialogBody': 'Cliente agregado exitosamente',
              'notificationDialogColor': 'success',
              'notificationDialogIsPersistent': false
            });
            viewMethodCaller.emit('InsertClientDialog/methods/insertClient', {'clientSSN': this.input.clientSSN});

            this.closeInsertClientDialog();
          }
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al agregar al cliente, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
      }
    },

    setDefaultValues() {
      this.navigation.insertClientDialogIsVisible = false;
      this.input.selectedClientType = null;
      this.input.selectedClientSSNType = null;
      this.input.clientSSN = '';
      this.input.clientName = '';
      this.input.clientEmail = '';
      this.input.clientPhoneNumber = '';
    },

    openInsertClientDialog(insertClientDialogRequestQuery) {
      this.setDefaultValues();
      this.input.selectedClientType = insertClientDialogRequestQuery.clientType;
      this.navigation.insertClientDialogIsVisible = true;
    },

    closeInsertClientDialog(){
      this.setDefaultValues();
    }
  },

  mounted(){
  }
}
</script>