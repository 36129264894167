<template>
  <v-dialog v-model="navigation.accessCredentialDialogIsVisible" width="auto" :persistent="false">
    <v-card width="1500" style="padding: 20px;">
      <div v-if="loader.selectAccessCredentialByAccessCredentialAgentID" style="text-align: center;">
        <br><br>
        <v-progress-circular color="info" indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <v-expansion-panels>
          <v-expansion-panel v-for="accessCredentialCategory in display.accessCredentialCategories" :key="accessCredentialCategory">
            <v-expansion-panel-title>
              <p style="font-size: larger;">{{ accessCredentialCategory.title }}</p>
            </v-expansion-panel-title>
            <v-expansion-panel-text style="padding: 20px; background-color: #dedede;">
              <v-data-table-virtual :headers="display.accessCredentialsCategoryHeaders" :items="accessCredentialCategory.accessCredentials">
                <template v-slot:item.value="{ item }">
                  <div style="width: 100px;">
                    <v-text-field v-if="item.type == 'integer'" v-model="item.value" @input="item.save = true" type="number" density="dense" variant="solo-filled" style="margin-top: 20px;"></v-text-field>
                    <v-switch v-if="item.type == 'boolean'" v-model="item.value" @input="item.save = true" :true-value="1" :false-value="0" color="success" variant="solo-filled" style="margin-top: 20px;"></v-switch>
                  </div>
                </template>
                <template v-slot:item.expirationDatetime="{ item }">
                  <div v-if="item.current">
                    <div style="display: flex;">
                      <div>
                        <p v-if="item.expirationTime">{{ $generalFunctions.default.methods.parseSecondsToDDHHMMSS(item.expirationTime) }}</p>
                        <p v-else>Sin expiración (acceso permanente)</p>
                      </div>
                      <div style="margin-left: 30px;">
                        <v-icon @click="restartAccessCredentialTimer(item)" color="error" center>mdi-close</v-icon>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <v-select v-model="item.expirationDatetime" @update:model-value="startAccessCredentialTimer(item)" :items="display.accessCredentialExpirationDatetimeOptions" item-value="value" item-title="title" variant="solo-filled" density="dense" style="margin-top: 20px;"></v-select>
                  </div>
                </template>
                <template v-slot:item.expirationTime="{ item }">
                  <p v-if="item.expirationTime">{{ $generalFunctions.default.methods.parseSecondsToDDHHMMSS(item.expirationTime) }}</p>
                  <p v-else>Sin expiración (acceso permanente)</p>
                </template>
                <template v-slot:item.save="{ item }">
                  <div v-if="item.loader" style="text-align: center;">
                    <v-progress-circular color="success" indeterminate></v-progress-circular>
                  </div>
                  <div v-else>
                    <v-icon v-if="item.save" @click="assignAccessCredential(item)" color="success" center>mdi-content-save</v-icon>
                    <v-icon v-else :disabled="true" dark>mdi-content-save</v-icon>
                  </div>
                </template>
              </v-data-table-virtual>  
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped>
</style>

<script>

export default {
  name: "AccessCredentialDialog",
  inject: ['$generalFunctions'],

  data: () => ({
    navigation: 
    {
      accessCredentialAgentID: null,
      accessCredentialDialogIsVisible: false,
    },

    input:
    {
    },

    display: 
    {
      accessCredentialsCategoryHeaders:
      [
        {key: 'title', title: 'Nombre'},
        {key: 'description', title: 'Descripción'},
        {key: 'value', title: 'Valor'},
        {key: 'expirationDatetime', title: 'Expiración'},
        {key: 'expirationTime', title: 'Tiempo restante (DD:HH:MM:SS)'},
        {key: 'save', title: 'Guardar'}
      ],
      accessCredentialCategories: [],
      accessCredentialExpirationDatetimeOptions:
      [
        {value: null, title: 'Sin expiración (acceso permanente)'},
        {value: 1, title: '1 minuto'},
        {value: 5, title: '5 minutos'},
        {value: 10, title: '10 minutos'},
        {value: 30, title: '30 minutos'},
        {value: 60, title: '1 hora'},
        {value: 120, title: '2 horas'},
        {value: 240, title: '4 horas'},
        {value: 480, title: '8 horas'},
        {value: 1440, title: '1 día'},
        {value: 2880, title: '2 días'},
        {value: 4320, title: '3 días'},
        {value: 7200, title: '1 semana'},
        {value: 20160, title: '2 semanas'},
        {value: 43200, title: '1 mes'},
        {value: 129600, title: '3 meses'}
      ]
    },

    loader:
    {
      selectAccessCredentialByAccessCredentialAgentID: false
    },

    localStorage: 
    {
    }
  }),

  methods: {
    async assignAccessCredential(accessCredential){
      accessCredential.loader = true;
      const assingAccessCredentialRequestQuery = 
      {
        'accessCredentialAgentID': this.navigation.accessCredentialAgentID,
        'accessCredentialName': accessCredential.title,
        'accessCredentialType': accessCredential.type,
        'accessCredentialValue': accessCredential.value,
        'accessCredentialGrantedMinutes': accessCredential.expirationDatetime,
      };
      const assingAccessCredentialResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/accessCredential/functions/assignAccessCredential', assingAccessCredentialRequestQuery);
      if (assingAccessCredentialResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'Credencial de acceso actualizada exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al actualizar la credencial de acceso, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      accessCredential.loader = false;
    },

    async selectAccessCredentialByAccessCredentialAgentID(){
      this.display.accessCredentialCategories = structuredClone(this.$generalFunctions.default.data.defaulAccessCredential.map(category => {
        return {
          ...category,
          accessCredentials: category.accessCredentials.map(credential => {
            return {
              ...credential,
              current: false,
              expirationDatetime: credential.expirationDatetime || null,
              expirationTime: credential.expirationTime || null,
              interval: credential.interval || null,
              save: credential.save || null,
              loader: credential.loader !== undefined ? credential.loader : false
            };
          })
        };
      }));
      this.loader.selectAccessCredentialByAccessCredentialAgentID = true;
      const selectAccessCredentialByAccessCredentialAgentIDRequestQuery = 
      {
        'accessCredentialAgentID': this.navigation.accessCredentialAgentID
      };
      const selectAccessCredentialByAccessCredentialAgentIDResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/accessCredential/select/byAccessCredentialAgentID', selectAccessCredentialByAccessCredentialAgentIDRequestQuery);
      if (selectAccessCredentialByAccessCredentialAgentIDResult.success){
        for (var agentAccessCredentialIndex in selectAccessCredentialByAccessCredentialAgentIDResult.result){
          const agentAccessCredential = selectAccessCredentialByAccessCredentialAgentIDResult.result[agentAccessCredentialIndex];
          outerLoop:
            for (var accessCredentialCategoryIndex in this.display.accessCredentialCategories){
              const accessCredentials = this.display.accessCredentialCategories[accessCredentialCategoryIndex].accessCredentials;
              for (var accessCredentialIndex in accessCredentials){
                const accessCredential = accessCredentials[accessCredentialIndex];
                if (accessCredential.title == agentAccessCredential.accessCredentialName){
                  if (accessCredential.type == 'integer'){
                    accessCredential.value = agentAccessCredential.accessCredentialIntegerValue;
                  } else if (accessCredential.type == 'boolean'){
                    accessCredential.value = agentAccessCredential.accessCredentialBooleanValue;
                  } else if (accessCredential.type == 'string'){
                    accessCredential.value = agentAccessCredential.accessCredentialStringValue;
                  }
                  accessCredential.current = true;
                  if (agentAccessCredential.accessCredentialExpirationDatetime){
                    accessCredential.expirationDatetime = this.$generalFunctions.default.methods.getCurrentDatetimeAndDatetimeOffsetInMinutes(agentAccessCredential.accessCredentialExpirationDatetime);
                    this.startAccessCredentialTimer(accessCredential, false);
                  }
                  break outerLoop;
                }
              }
          }
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las credenciales de acceso del agente, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectAccessCredentialByAccessCredentialAgentID = false;
    },

    startAccessCredentialTimer(accessCredential, startTimer = true){
      accessCredential.save = startTimer;
      accessCredential.expirationTime = null;
      clearInterval(accessCredential.interval);
      if (accessCredential.expirationDatetime){
        accessCredential.expirationTime = accessCredential.expirationDatetime * 60;
        accessCredential.interval = setInterval(() => {
          accessCredential.expirationTime = accessCredential.expirationTime - 1;
        }, 1000);
      }
    },

    restartAccessCredentialTimer(accessCredential){
      accessCredential.save = true;
      accessCredential.expirationDatetime = null;
      accessCredential.expirationTime = null;
      accessCredential.current = false;
      clearInterval(accessCredential.interval);
    },


    setDefaultValues(){
      this.navigation.accessCredentialAgentID = null;
      this.navigation.accessCredentialDialogIsVisible = false;
    },

    async openAccessCredentialDialog(accessCredentialDialogRequestQuery) {
      this.setDefaultValues();
      this.navigation.accessCredentialAgentID = accessCredentialDialogRequestQuery.accessCredentialAgentID;
      this.navigation.accessCredentialDialogIsVisible = true;
      await this.selectAccessCredentialByAccessCredentialAgentID();
    },

    closeAccessCredentialDialog(){
      this.setDefaultValues();
    }
  },

  mounted(){
  }
}
</script>