<template>
  <div class="sessionExpiredContainer">
    <h1 class="sessionExpiredTitle">SESIÓN EXPIRADA</h1>
    <br>
    <p class="sessionExpiredSubtitle">Su sesión de SOUQ ha expirado. Por favor, inicie sesión nuevamente</p>
    <br><br>
    <v-progress-linear color="info" height="20" :model-value="display.sessionExpiredLoaderProgress" striped></v-progress-linear>
  </div>
</template>

<style scoped>
  .sessionExpiredContainer{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .sessionExpiredTitle {
    font-size: 60px;
  }
  .sessionExpiredSubtitle {
    font-size: 25px;  
  }
  .pageNotFoundButtonText {
    font-size: 20px;
  }
  
</style>

<script>

export default {
  name: 'SessionExpiredView',
  inject: ['$generalFunctions'],

  data: () => ({
    display:
    {
      sessionExpiredLoaderProgress: 0
    }
  }),

  methods: {
  },

  mounted(){
    const sessionHasExpiredInterval = setInterval(async () => {
      if (this.display.sessionExpiredLoaderProgress >= 110){
        this.$generalFunctions.default.methods.navigateTo('/');
        localStorage.clear();
        clearInterval(sessionHasExpiredInterval);
      } else {
        this.display.sessionExpiredLoaderProgress = this.display.sessionExpiredLoaderProgress + 5;
      }
    }, 100);

    this.$root.billCancelationDialog.closeBillCancelationDialog();
    this.$root.billDialog.closeBillDialog();
    this.$root.imageDialog.closeImageDialog();

    this.$root.insertProductDialog.setDefaultValues();


  }

}
</script>
