<template>
  <v-dialog v-model="navigation.productChangeDialogIsVisible" width="auto">
    <v-card width="800" style="padding: 20px;">
      <div v-if="loader.selectProductChangeFromProductChangeDialog" style="text-align: center;">
        <v-progress-circular color="info" indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <p style="font-size: large;"><strong>ID del cambio de producto: </strong> {{ display.productChange.productChangeID }}</p>
        <p style="font-size: large;"><strong>Estado: </strong> {{ display.productChange.productChangeState }}</p>
        <br>
        <p style="font-size: large;"><strong>ID de la factura: </strong> {{ display.productChange.productChangeBillID }}</p>
        <p style="font-size: large;"><strong>Facturado en: </strong> {{ display.productChange.billLocalityName }}</p>
        <p style="font-size: large;"><strong>Facturado por: </strong> {{ display.productChange.billAgentName }}</p>
        <p style="font-size: large;"><strong>Facturado el: </strong> {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(display.productChange.billCreationDatetime) }}</p>
        <br>
        <p style="font-size: large;"><strong>Solicitado por: </strong> {{ display.productChange.requesterAgentName }}</p>
        <p style="font-size: large;"><strong>Solicitado el: </strong> {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(display.productChange.productChangeDatetime) }}</p>
        <div v-if="display.productChange.approverAgentName">
          <br>
          <p style="font-size: large;"><strong>Aprobado por: </strong> {{ display.productChange.approverAgentName }}</p>
          <p style="font-size: large;"><strong>Aprobado el: </strong> {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(display.productChange.productChangeApprovedDatetime) }}</p>
        </div>
        <br>
        <div style="padding: 10px; background-color: #e9e8e8;">
          <v-data-table-virtual :headers="display.productChangeProductHeaders" :items="display.productChange.productChangeProduct">
            <template v-slot:item.productChangeProductType="{ item }">
              {{ item.productChangeProductType == 'oldProduct' ? 'Producto dañado' : 'Producto entregado' }}
            </template>
          </v-data-table-virtual>
        </div>
        
      </div>
    </v-card>
  </v-dialog>
</template>
  
<style scoped>
</style>
  
<script>
export default {
  name: 'ProductChangeDialog',
  inject: ['$generalFunctions'],

  data: () => ({

    loader: 
    {
      selectProductChangeFromProductChangeDialog: false
    },
    
    navigation: 
    {
      productChangeDialogIsVisible: false,
      productChangeID: null
    },

    display:
    {
      productChange: null,
      productChangeProductHeaders: 
      [
        {key: 'productSKU', title: 'SKU'},
        {key: 'productName', title: 'NOMBRE'},
        {key: 'productChangeProductType', title: 'ESTADO'}
      ],
    }

  }),

  methods: {
    async selectProductChangeFromProductChangeDialog(){
      this.loader.selectProductChangeFromProductChangeDialog = true;
      const selectProductChangeFromProductChangeDialogRequestQuery = 
      {
        'productChangeID': this.navigation.productChangeID
      };
      const selectProductChangeFromProductChangeDialogResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productChange/functions/selectProductChangeOnProductChangeDialog', selectProductChangeFromProductChangeDialogRequestQuery);
      if (selectProductChangeFromProductChangeDialogResult.success){
        this.display.productChange = selectProductChangeFromProductChangeDialogResult.result;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la información del cambio de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
        await this.setDefaultValues();
      }
      this.loader.selectProductChangeFromProductChangeDialog = false;
    },

    async setDefaultValues(){
      this.loader.selectProductChangeFromProductChangeDialog = false;
      //this.navigation.productChangeDialogIsVisible = false;
      this.navigation.productChangeID = null;
      this.display.productChange = null;
    },

    async openProductChangeDialog(productChangeDialogRequestQuery){
      await this.setDefaultValues();
      this.navigation.productChangeID = productChangeDialogRequestQuery.productChangeID;
      this.navigation.productChangeDialogIsVisible = true;
      await this.selectProductChangeFromProductChangeDialog();
    }
  },

  mounted(){
    
  }
}
</script>