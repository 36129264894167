<template>
  <v-dialog v-model="navigation.productImageDialogIsVisible" width="auto">
    <v-card width="600" class="productImageDialogContainer">
      <div v-if="loader.selectProductImageForProductImageDialog">
        <v-progress-circular color="info" indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <div v-if="display.productImages.length == 0">
          <p style="font-size: larger;"><strong>{{ display.productName }}</strong> no tiene imágenes asociadas</p>          
        </div>
        <div v-else>
          <div v-for="productImage in display.productImages" v-bind:key="productImage">
            <img :src="`data:image/jpeg;base64,${productImage}`" style="width: 100%;">
          </div>
        </div>
        
      </div>
    </v-card>
  </v-dialog>
</template>
  
<style scoped>
  .productImageDialogContainer {
    padding: 25px;
    text-align: center;
  }
</style>
  

<script>

export default {
  name: 'ProductImageDialog',
  inject: ['$generalFunctions'],

  data: () => ({
    
    navigation: 
    {
      productImageDialogIsVisible: false,
      productImageProductID: null
    },

    loader: 
    {
      selectProductImageForProductImageDialog: false
    },

    display:
    {
      productName: null,
      productImages: []
    }

  }),

  methods: {
    async selectProductImageForProductImageDialog(){
      this.loader.selectProductImageForProductImageDialog = true;
      const selectProductImageForProductImageDialogRequestQuery = {'productImageProductID': this.navigation.productImageProductID};
      const selectProductImageForProductImageDialogResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productImage/functions/selectProductImageForProductImageDialog', selectProductImageForProductImageDialogRequestQuery);
      if (selectProductImageForProductImageDialogResult.success){
        this.display.productImages = [selectProductImageForProductImageDialogResult.result];

      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las imágenes del producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
        this.setDefaultValues();
      }
      this.loader.selectProductImageForProductImageDialog = false;
    },

    setDefaultValues(){
      this.navigation.productImageDialogIsVisible = false;
      this.productImageProductID = null;
      this.loader.selectProductImageForProductImageDialog = false;
      this.display.productName = null;
      this.display.productImages.length = 0;
    },

    openProductImageDialog(productImageDialogRequestQuery){
      this.setDefaultValues();
      this.navigation.productImageProductID = productImageDialogRequestQuery.productImageProductID;
      this.display.productName = productImageDialogRequestQuery.productName;
      this.navigation.productImageDialogIsVisible = true;
      this.selectProductImageForProductImageDialog();
    }
  },

  mounted(){
    
  }
}
</script>