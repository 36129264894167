<template>
  <div style="margin: 2% 3% 0% 3%;">
    <div v-if="loader.contact">
      <v-progress-circular color="info" indeterminate></v-progress-circular>
    </div>
    <div v-else>
      <div v-if="display.contact">
        <div style="display: flex; width: 100%;">
           <div style="width: 10%;">
            <img :src="display.contact.contactImage || assets.userPlaceholderImage" style="width: 150px; height: 150px; border-radius: 50%; object-fit: cover;">
          </div>
          <div style="width: 28%; margin-left: 2%;">
            <div style="display: flex">
              <h1>{{ display.contact.contactName }}</h1>
              <v-tooltip :text="display.contact.contactCreditAmount ? 'Contacto a crédito' : 'Contacto a contado'" location="right">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" size="30" :color="display.contact.contactCreditAmount ? 'error' : 'success'" style="margin-left: 20px; margin-top: 12px;">{{ display.contact.contactCreditAmount ? 'mdi-cash-clock' : 'mdi-cash' }}</v-icon>
                </template>
              </v-tooltip> 
              <v-tooltip text="Editar contacto" location="right">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" @click="openContactDialog()" color="warning" size="30" style="cursor: pointer; margin-left: 20px; margin-top: 10px;">mdi-pencil</v-icon>
                </template>
              </v-tooltip>  
              <v-tooltip text="Generar proforma" location="right">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" @click="openInsertProformaDialog()" color="success" size="30" style="cursor: pointer; margin-left: 20px; margin-top: 10px;">mdi-invoice-plus</v-icon>
                </template>
              </v-tooltip>   
            </div>
            <p style="font-size: larger; margin-top: 15px;"><strong>Tipo de contacto: </strong> {{ display.contact.contactType }}</p>
            <p style="font-size: larger;"><strong>Documento de identidad: </strong> {{ display.contact.contactSSN }} ({{ display.contact.contactSSNType }})</p>
            <p style="font-size: larger;"><strong>Deuda: </strong> ₡ {{ display.contact.contactTotalDebtAmount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }} </p>
            <v-rating hover :length="5" :size="40" :model-value="display.contact.contactStarRating" active-color="primary" readonly style="margin-top: 10px;"></v-rating>
            <br>
          </div>
          <div style="width: 23%; margin-left: 2%;">
            <div style="display: flex;">
              <v-card style="width: 46%; margin-right: 4%; padding: 20px;">
                <div style="text-align: center; margin-bottom: 20px;">
                  <p style="font-size: larger;"><strong>Este mes: </strong></p>
                </div>
                <div style="display: flex;">
                  <v-icon color="info" center>mdi-package</v-icon>
                  <p style="margin-left: 10px;">{{ display.contact.contactBill.length }}</p>
                </div>
                <div style="display: flex;">
                  <v-icon color="info" center>mdi-cash</v-icon>
                  <p style="margin-left: 10px;">₡ {{ display.contact.contactBillTotalAmount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
                </div>
              </v-card>
              <v-card style="width: 46%; margin-left: 4%; padding: 20px;">
                <div style="text-align: center; margin-bottom: 20px;">
                  <p style="font-size: larger;"><strong>Este año: </strong></p>
                </div>
                <div style="display: flex;">
                  <v-icon color="info" center>mdi-package</v-icon>
                  <p style="margin-left: 10px;">{{ display.contact.contactBill.length }}</p>
                </div>
                <div style="display: flex; ">
                  <v-icon color="info" center>mdi-cash</v-icon>
                  <p style="margin-left: 10px;">₡ {{ display.contact.contactBillTotalAmount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
                </div>
              </v-card>
            </div>
          </div>
          <div style="width: 33%; margin-left: 2%;">
            <v-card style="padding: 20px;">
              <div style="display: flex;">
                <div style="width: 50%;">
                  <div v-if="display.contact.contactCommercialName" style="display: flex; padding: 5px;">
                    <v-icon color="info" center>mdi-store</v-icon>
                    <p style="margin-left: 10px;">{{ display.contact.contactCommercialName }}</p>
                  </div>
                  <div v-if="display.contact.contactPhoneNumber" style="display: flex; padding: 5px;">
                    <v-icon color="info" center>mdi-phone</v-icon>
                    <p style="margin-left: 10px;">{{ display.contact.contactPhoneNumber }}</p>
                  </div>
                  <div v-if="display.contact.contactEmail" style="display: flex; padding: 5px;">
                    <v-icon color="info" center>mdi-email</v-icon>
                    <p style="margin-left: 10px;">{{ display.contact.contactEmail }}</p>
                  </div>
                </div>
                <div style="width: 48%; margin-left: 2%;">
                  <div v-if="display.contact.contactWebPage" style="display: flex; padding: 5px;">
                    <v-icon color="info" center>mdi-web</v-icon>
                    <a :href="display.contact.contactWebPage.startsWith('http') ? display.contact.contactWebPage : `http://${display.contact.contactWebPage}`" target="_blank" style="margin-left: 10px; text-decoration: none; color: inherit;">
                      {{ display.contact.contactWebPage }}
                    </a>
                  </div>
                  <div v-if="display.contact.contactCountry" style="display: flex; padding: 5px;">
                    <v-icon color="info" center>mdi-earth</v-icon>
                    <p style="margin-left: 10px;">{{ display.contact.contactCountry }}</p>
                  </div>
                  <div v-if="display.contact.contactProvince || display.contact.contactCanton || display.contact.contactDistrict" style="display: flex; padding: 5px;">
                    <v-icon color="info" center>mdi-map-marker</v-icon>
                    <p style="margin-left: 10px;">{{ [display.contact.contactProvince, display.contact.contactCanton, display.contact.contactDistrict].filter(Boolean).join(', ') }}</p>
                  </div>
                </div>
              </div>
            </v-card>
          </div>
        </div>
        <br><br>
        <div v-if="loader.contactTransaction" style="text-align: center;">
          <br>
          <v-progress-circular color="info" indeterminate></v-progress-circular>
        </div>
        <div v-else>
          <div style="display: flex; width: 100%;">
            <v-text-field v-model="display.startDateFormatted" @click="navigation.startDatePickerIsVisible = true" readonly label="Filtrar por fecha inicial" append-inner-icon="mdi-calendar" variant="solo-filled" style="width: 49%; margin-right: 1%;"></v-text-field>
            <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
              <v-card style="display: flex;">
                <v-date-picker v-model="input.startDate" hide-header color="primary" width="100%"></v-date-picker>
                <v-chip @click="input.startDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
              </v-card>
            </v-dialog>
            <v-text-field v-model="display.endDateFormatted" @click="navigation.endDatePickerIsVisible = true" readonly label="Filtrar por fecha final" append-inner-icon="mdi-calendar" variant="solo-filled" style="width: 49%; margin-left: 1%;"></v-text-field>
            <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
              <v-card>
                <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
                <v-chip @click="input.endDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
              </v-card>
            </v-dialog>
          </div>
          <br>
          <div style="display: flex; width: 100%;">
            <v-btn @click="openInsertDebtPaymentDialog(display.contactTransactionRows)" height="38" color="success">
              <h3>REGISTRAR ABONO A VARIAS FACTURAS</h3>
              <v-icon style="margin-left: 10px;" dark right>mdi-cash</v-icon>
            </v-btn>
            <v-btn @click="createTransactionPDFDocument()" height="38" color="info" style="margin-left: 20px;">
              <h3>IMPRIMIR ESTADO DE CUENTA</h3>
              <v-icon style="margin-left: 10px;" dark right>mdi-printer</v-icon>
            </v-btn>
          </div>
          <br><br>
          <div style="padding: 20px; background-color: #e9e8e8;">
            <div style="text-align: right;">
              <p style="font-size: larger;"><strong>Saldo: </strong> ₡ {{ display.contact.contactTotalDebtAmount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }} </p>
            </div>
            <br>
            <v-data-table-virtual :headers="display.contactTransactionHeaders" :items="display.contactTransactionRows">
              <template v-slot:item="row">
                <tr>
                  <td>
                    {{ row.item.billID }}  
                  </td>
                  <td>
                    {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(row.item.billCreationDatetime) }}  
                  </td>
                  <td>
                    <v-icon @click="openProformaDrawer(row.item.billProformaID)" size="25" color="warning" center style="cursor: pointer;">mdi-receipt-text-outline</v-icon>
                  </td>
                  <td>
                    <v-icon @click="openBillDialog(row.item.billID)" size="25" color="info" center style="cursor: pointer;">mdi-cash-register</v-icon>
                  </td>
                  <td>
                    ₡ {{ (row.item.billAmount + (row.item.billAmount * 0.13) + (row.item.proformaShippingAmount ? row.item.proformaShippingAmount : 0)).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}  
                  </td>
                  <td>
                    <div v-if="row.item.billDebt == null">
                      <p>Factura a contado</p>                      
                    </div>
                    <div v-else @click="openDebtPaymentDialog(row.item.debtID)" style="cursor: pointer;">
                      <div v-if="row.item.billDebtPayment.length != 0">
                        <p><strong>Fecha del último pago: </strong>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(row.item.billDebtPayment[row.item.billDebtPayment.length - 1].debtPaymentDatetime) }}</p>
                        <p v-if="row.item.billDebt != 0"><strong>Días transcurridos desde el último pago: </strong>{{ $generalFunctions.default.methods.getCurrentDatetimeAndDatetimeOffsetInDays(row.item.billDebtPayment[row.item.billDebtPayment.length - 1].debtPaymentDatetime) }}</p>
                      </div>
                      <div v-else>
                        <p><strong>Fecha del último pago: </strong>Sin pagos registrados</p>
                        <p v-if="row.item.billDebt != 0"><strong>Días transcurridos desde el último pago: </strong>{{ $generalFunctions.default.methods.getCurrentDatetimeAndDatetimeOffsetInDays(row.item.billCreationDatetime) }}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div v-if="row.item.billDebt && row.item.billDebt != 0">
                      <v-icon @click="openInsertDebtPaymentDialog([row.item])" size="25" color="success" center style="cursor: pointer;">mdi-cash</v-icon>
                    </div>
                    <div v-else>
                      No se pueden realizar abonos a esta factura
                    </div>
                  </td>
                  <td>
                    <p>₡ {{ (row.item.billDebt ? row.item.billDebt : 0).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
                  </td>
                </tr>
              </template>
            </v-data-table-virtual>
          </div>
          <br><br>          
        </div>
      </div>
    </div>
    <v-navigation-drawer v-model="navigation.proformaDrawerIsVisible" absolute temporary class="bg-info" location="right" style="min-width: 45%;">
      <div v-if="loader.selectProformaFromProformaDrawer" style="display: flex; justify-content: center; align-items: center; height: 100%;">
        <v-progress-circular color="white" indeterminate></v-progress-circular>
      </div>
      <div v-else style="padding: 30px;">
        <div v-if="display.proformaDrawer">
          <v-card style="padding: 20px;">
            <div style="display: flex; justify-content: space-between;">
              <div style="width: 70%;">
                <p style="font-size: large;"><strong>Contacto: </strong> {{ display.proformaDrawer.contactName }}</p>
                <p style="font-size: large; margin-top: 10px;"><strong>Documento de identidad: </strong> {{ display.proformaDrawer.contactSSN }}</p>
              </div>
              <div style="text-align: right;">
                <v-tooltip location="left">
                  <div style="min-width: 600px; display: flex">
                    <div style="width: 49%; margin-right: 1%;">
                      <div v-if="display.proformaDrawer.contactCommercialName" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-store</v-icon>
                        <p style="margin-left: 10px; font-size: large;">{{ display.proformaDrawer.contactCommercialName }}</p>
                      </div>
                      <div v-if="display.proformaDrawer.contactPhoneNumber" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-phone</v-icon>
                        <p style="margin-left: 10px; font-size: large;">{{ display.proformaDrawer.contactPhoneNumber }}</p>
                      </div>
                      <div v-if="display.proformaDrawer.contactEmail" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-email</v-icon>
                        <p style="margin-left: 10px; font-size: large;">{{ display.proformaDrawer.contactEmail }}</p>
                      </div>
                    </div>
                    <div style="width: 49%; margin-left: 1%;">
                      <div v-if="display.proformaDrawer.contactWebPage" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-web</v-icon>
                        <a :href="display.proformaDrawer.contactWebPage.startsWith('http') ? display.proformaDrawer.contactWebPage : `http://${display.proformaDrawer.contactWebPage}`" target="_blank" style="margin-left: 10px; text-decoration: none; color: inherit; font-size: large;">
                          {{ display.proformaDrawer.contactWebPage }}
                        </a>
                      </div>
                      <div v-if="display.proformaDrawer.contactCountry" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-earth</v-icon>
                        <p style="margin-left: 10px; font-size: large;">{{ display.proformaDrawer.contactCountry }}</p>
                      </div>
                      <div v-if="display.proformaDrawer.contactProvince || display.proformaDrawer.contactCanton || display.proformaDrawer.contactDistrict" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-map-marker</v-icon>
                        <p style="margin-left: 10px; font-size: large;">{{ [display.proformaDrawer.contactProvince, display.proformaDrawer.contactCanton, display.proformaDrawer.contactDistrict].filter(Boolean).join(', ') }}</p>
                      </div>
                    </div>
                  </div>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" color="info" size="30" style="cursor: pointer;">mdi-information</v-icon>
                  </template>
                </v-tooltip>
              </div>
            </div>
          </v-card>
          <br>
          <v-card style="padding: 20px;">
            <div style="display: flex; justify-content: space-between;">
              <div style="width: 70%;">
                <p style="font-size: large;"><strong>ID interno: </strong> {{ display.proformaDrawer.proformaID }}</p>
              </div>
              <div style="text-align: right;">
                <div v-if="display.proformaDrawer.proformaLoader">
                  <v-progress-circular color="success" indeterminate></v-progress-circular>
                </div>
                <div v-else>
                  <v-icon @click="createProformaPDFDocument(display.proformaDrawer)" size="30" color="success" style="cursor: pointer;">mdi-printer</v-icon>
                </div>
              </div>
            </div>
            <div style="display: flex; margin-top: 10px;">
              <p style="font-size: large;"><strong>Estado: </strong></p>
              <v-chip :color="localStorage.proformaStateColors[display.proformaDrawer.proformaState].proformaStateColor" variant="flat" style="margin-left: 10px; position: relative; top: -2px;">
                <p :style="{color: localStorage.proformaStateColors[display.proformaDrawer.proformaState].proformaStateFontColor}">{{ display.proformaDrawer.proformaState }}</p>
              </v-chip>
            </div>
            <div style="display: flex; margin-top: 30px;">
              <p style="font-size: large;"><strong>Generado por: </strong></p>
              <v-chip :color="display.proformaDrawer.creationAgentColor" variant="flat" style="margin-left: 10px; position: relative; top: -2px;">
                <p :style="{color: display.proformaDrawer.creationAgentFontColor}">{{ display.proformaDrawer.creationAgentName }}</p>
              </v-chip>
            </div>
            <p style="font-size: large; margin-top: 10px;"><strong>Generado el: </strong> {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(display.proformaDrawer.proformaCreationDatetime) }}</p>
            <div style="display: flex; margin-top: 30px; justify-content: space-between;">
              <div style="display: flex; width: 70%;">
                <p style="font-size: large;"><strong>Aprobado por: </strong></p>
                <v-chip :color="display.proformaDrawer.approvedAgentColor || '#bababa'" variant="flat" style="margin-left: 10px; position: relative; top: -2px;">
                  <p :style="{ color: display.proformaDrawer.approvedAgentFontColor || 'black' }">
                    {{ display.proformaDrawer.approvedAgentName || 'No ha sido aprobada' }}
                  </p>
                </v-chip>
              </div>
              <div v-if="display.proformaDrawer.proformaApprovedImage" style="text-align: right;">
                <v-tooltip location="left">
                  <div style="min-width: 400px;">
                    <img :src="display.proformaDrawer.proformaApprovedImage" style="cursor: pointer; width: 400px;">
                  </div>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" color="warning" size="30" style="cursor: pointer;">mdi-image</v-icon>
                  </template>
                </v-tooltip>
              </div>
            </div>
            <p style="font-size: large; margin-top: 10px;"><strong>Aprobado el: </strong> {{ display.proformaDrawer.proformaApprovedDatetime ? $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(display.proformaDrawer.proformaApprovedDatetime) : 'No ha sido aprobada' }}</p>
            <div style="display: flex; margin-top: 30px; justify-content: space-between;">
              <div style="display: flex; width: 70%;">
                <p style="font-size: large;"><strong>Despachado por: </strong></p>
                <v-chip :color="display.proformaDrawer.dispatchedAgentColor || '#bababa'" variant="flat" style="margin-left: 10px; position: relative; top: -2px;">
                  <p :style="{ color: display.proformaDrawer.dispatchedAgentFontColor || 'black' }">
                    {{ display.proformaDrawer.dispatchedAgentName || 'No ha sido despachada' }}
                  </p>
                </v-chip>
              </div>
              <div v-if="display.proformaDrawer.proformaDispatchedImage" style="text-align: right;">
                <v-tooltip location="left">
                  <div style="min-width: 400px;">
                    <img :src="display.proformaDrawer.proformaDispatchedImage" style="cursor: pointer; width: 400px;">
                  </div>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" color="warning" size="30" style="cursor: pointer;">mdi-image</v-icon>
                  </template>
                </v-tooltip>
              </div>
            </div>
            <p style="font-size: large; margin-top: 10px;"><strong>Despachada el: </strong> {{ display.proformaDrawer.proformaDispatchedDatetime ? $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(display.proformaDrawer.proformaDispatchedDatetime) : 'No ha sido despachada' }}</p>
          </v-card>
          <br>
          <v-card style="padding: 20px 20px 0px 20px;">
            <p style="font-size: large;"><strong>Nota: </strong></p>
            <br>
            <v-textarea @click:append-inner="updateProformaNote()" v-model="display.proformaDrawer.proformaNote" rows="3" append-inner-icon="mdi-content-save" variant="solo-filled"></v-textarea>
          </v-card>
          <br>
          <v-card style="padding: 20px;">
            <v-card style="background-color: #e9e8e8; padding: 20px;">
              
              <v-data-table-virtual :headers="display.proformaProductHeaders" :items="display.proformaDrawer.proformaProducts">
                <template v-slot:item.productName="{ item }">
                  {{ item.productName }}
                  <p><strong>{{ item.productSKU }}</strong></p>
                </template>
                <template v-slot:item.proformaProductOriginalPrice="{ item }">
                  ₡ {{ item.proformaProductPrice.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                </template>
                <template v-slot:item.proformaProductDiscountMargin="{ item }">
                  ₡ {{ (item.proformaProductPrice * (item.proformaProductDiscountMargin/100)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                </template>
                <template v-slot:item.proformaProductPrice="{ item }">
                  ₡ {{ (item.proformaProductPrice * item.proformaProductAmount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                </template>
              </v-data-table-virtual>
            </v-card>
            <br>
            <p style="margin-left: 10px; font-size: large;"><strong>{{ `Unidad${(display.proformaDrawer.proformaProducts.reduce((proformaUnitAmount, proformaProduct) => (proformaUnitAmount + proformaProduct.proformaProductAmount), 0)) > 1 ? 'es' : ''}: ` }}</strong>{{ display.proformaDrawer.proformaProducts.reduce((proformaUnitAmount, proformaProduct) => (proformaUnitAmount + proformaProduct.proformaProductAmount), 0) }}</p>
            <p style="margin-left: 10px; font-size: large;"><strong>Subtotal: </strong>₡ {{ (display.proformaDrawer.proformaProducts.reduce((proformaSubtotalAmount, proformaProduct) => (proformaSubtotalAmount + (proformaProduct.proformaProductPrice * proformaProduct.proformaProductAmount)), 0)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
            <p style="margin-left: 10px; font-size: large;"><strong>Impuesto: </strong>₡ {{ (display.proformaDrawer.proformaProducts.reduce((proformaTaxAmount, proformaProduct) => (proformaTaxAmount + ((proformaProduct.proformaProductPrice * (proformaProduct.proformaProductTaxMargin/100)) * proformaProduct.proformaProductAmount)), 0)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
            <p v-if="(display.proformaDrawer.proformaProducts.reduce((proformaDiscountAmount, proformaProduct) => (proformaDiscountAmount + ((proformaProduct.proformaProductPrice * (proformaProduct.proformaProductDiscountMargin/100)) * proformaProduct.proformaProductAmount)), 0)) > 0" style="margin-left: 10px; font-size: large;"><strong>Descuento: </strong>₡ {{ (display.proformaDrawer.proformaProducts.reduce((proformaDiscountAmount, proformaProduct) => (proformaDiscountAmount + ((proformaProduct.proformaProductOriginalPrice * (proformaProduct.proformaProductDiscountMargin/100)) * proformaProduct.proformaProductAmount)), 0)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
            <p v-if="display.proformaDrawer.proformaShippingAmount > 0" style="margin-left: 10px; font-size: large;"><strong>Envío: </strong>₡ {{ display.proformaDrawer.proformaShippingAmount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
            <br>
            <div style="display: flex; justify-content: space-between;">
              <div style="width: 70%;">
                <p style="margin-left: 10px; font-size: large;"><strong>Total: </strong>₡ {{ ((display.proformaDrawer.proformaProducts.reduce((proformaTotalAmount, proformaProduct) => (proformaTotalAmount + (proformaProduct.proformaProductPrice * proformaProduct.proformaProductAmount)), 0)) + (display.proformaDrawer.proformaProducts.reduce((proformaTaxAmount, proformaProduct) => (proformaTaxAmount + ((proformaProduct.proformaProductPrice * (proformaProduct.proformaProductTaxMargin/100)) * proformaProduct.proformaProductAmount)), 0)) + (display.proformaDrawer.proformaShippingAmount ? display.proformaDrawer.proformaShippingAmount : 0)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
              </div>
              <div style="text-align: right;">
                <v-tooltip location="left">
                  <div style="min-width: 300px;">
                    <div style="display: flex; padding: 5px;">
                      <v-icon color="info" center>mdi-account-cash</v-icon>
                      <p style="margin-left: 10px; font-size: large;">{{ display.proformaDrawer.proformaIsCredit ? 'Proforma a crédito' : 'Proforma a contado' }}</p>
                    </div>
                    <div style="display: flex; padding: 5px;">
                      <v-icon color="info" center>mdi-source-branch</v-icon>
                      <p style="margin-left: 10px; font-size: large;">{{ display.proformaDrawer.proformaType }}</p>
                    </div>
                    <div style="display: flex; padding: 5px;">
                      <v-icon color="info" center>mdi-cash-multiple</v-icon>
                      <p style="margin-left: 10px; font-size: large;">{{ display.proformaDrawer.proformaPaymentType }}</p>
                    </div>
                    <div v-if="display.proformaDrawer.proformaMixedPaymentID">
                      <br>
                      <div v-if="display.proformaDrawer.proformaMixedPaymentCashAmount" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-cash</v-icon>
                        <p style="margin-left: 10px; font-size: large;">₡ {{ display.proformaDrawer.proformaMixedPaymentCashAmount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
                      </div>
                      <div v-if="display.proformaDrawer.proformaMixedPaymentCardAmount" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-credit-card-outline</v-icon>
                        <p style="margin-left: 10px; font-size: large;">₡ {{ display.proformaDrawer.proformaMixedPaymentCardAmount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
                      </div>
                      <div v-if="display.proformaDrawer.proformaMixedPaymentSINPEAmount" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-cellphone</v-icon>
                        <p style="margin-left: 10px; font-size: large;">₡ {{ display.proformaDrawer.proformaMixedPaymentSINPEAmount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
                      </div>
                      <div v-if="display.proformaDrawer.proformaMixedPaymentDepositAmount" style="display: flex; padding: 5px;">
                        <v-icon color="info" center>mdi-bank</v-icon>
                        <p style="margin-left: 10px; font-size: large;">₡ {{ display.proformaDrawer.proformaMixedPaymentDepositAmount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
                      </div>
                    </div>
                  </div>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" color="info" size="30" style="cursor: pointer;">mdi-information</v-icon>
                  </template>
                </v-tooltip>
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<style scoped>
</style>


<script>
import { defineComponent } from 'vue';
import { viewMethodCaller } from '../viewMethodCaller.js';

export default defineComponent({
  name: 'ContactView',

  inject: ['$generalFunctions'],

  data: () => ({
    input: 
    {
      startDate: null,
      endDate: null
    },

    display: 
    {
      contact: null,
      startDateFormatted: null,
      endDateFormatted: null,
      contactTransactionRows: [],
      contactTransactionHeaders: 
      [
        {key: 'transactionID', title: 'ID'},
        {key: 'transactionDatetime', title: 'FECHA'},
        {key: 'proformaAction', title: 'PROFORMA'},
        {key: 'billAction', title: 'FACTURA'},
        {key: 'billAmount', title: 'MONTO'},
        {key: 'billDebt', title: 'DEUDA'},
        {key: 'debtPaymentAction', title: 'REGISTRAR ABONO'},
        {key: 'billPendingDebt', title: 'SALDO'}
      ],
      debtPaymentHeaders: 
      [
        {key: 'debtPaymentID', title: 'ID'},
        {key: 'debtPaymentDatetime', title: 'FECHA'},
        {key: 'agentName', title: 'REGISTRADO POR'},
        {key: 'debtPaymentType', title: 'TIPO DE PAGO'},
        {key: 'debtPaymentFile', title: 'COMPROBANTE'},
        {key: 'debtPaymentPreviousAmount', title: 'SALDO ANTERIOR'},
        {key: 'debtPaymentAmount', title: 'ABONO'},
        {key: 'debtPaymentBalanceAmount', title: 'SALDO RESULTANTE'}
      ],
      proformaProductHeaders:
      [
        {key: 'productName', title: 'NOMBRE'},
        {key: 'proformaProductOriginalPrice', title: 'PRECIO'},
        {key: 'proformaProductDiscountMargin', title: 'DESCUENTO'},
        {key: 'proformaProductAmount', title: 'CANTIDAD'},
        {key: 'proformaProductPrice', title: 'SUBTOTAL'}
      ],
      proformaDrawer: null
    },

    navigation: 
    {
      contactID: null,
      startDatePickerIsVisible: false,
      endDatePickerIsVisible: false,
      proformaDrawerIsVisible: false
    },

    loader:
    {
      contact: false,
      contactTransaction: false,
      selectProformaFromProformaDrawer: false
    },

    localStorage: 
    {
      localityID: null,
      proformaStateColors: {}
    },

    assets: 
    {
      userPlaceholderImage: null
    },

    accessCredential: {}
  }),

  watch: {
    'input.startDate': async function() {
      this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB');
      this.navigation.startDatePickerIsVisible = false;
      await this.selectContactTransactionFromContactView();
    },
    'input.endDate': async function() {
      this.display.endDateFormatted = new Date(this.input.endDate).toLocaleDateString('en-GB');
      this.navigation.endDatePickerIsVisible = false;
      await this.selectContactTransactionFromContactView();
    }
  },

  methods: {

    async createTransactionPDFDocument(){
      const createTransactionPDFDocumentRequestQuery =
      {
        'contactID': this.navigation.contactID,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate
      };
      const createTransactionPDFDocumentResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/contact/functions/createTransactionPDFDocument', createTransactionPDFDocumentRequestQuery);
      if (createTransactionPDFDocumentResult.success){
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': createTransactionPDFDocumentResult.result.PDFDocumentFile.data,
          'printablePDFDocumentName': createTransactionPDFDocumentResult.result.PDFDocumentName
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el estado de cuenta, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async parseURL(){
      const currentRoute = this.$route.fullPath;
      const contactID = currentRoute.split('/')[currentRoute.split('/').length - 1];
      this.navigation.contactID = contactID;
    },

    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.assets.userPlaceholderImage = this.$generalFunctions.default.data.userPlaceholderImage;
      this.localStorage.proformaStateColors = this.$generalFunctions.default.data.proformaStateColors;
    },

    async selectContactFromContactView(){
      this.loader.contact = true;
      const selectContactFromContactViewRequestQuery =
      {
        'contactID': this.navigation.contactID
      };
      const selectContactFromContactViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/contact/functions/selectContactFromContactView', selectContactFromContactViewRequestQuery);
      if (selectContactFromContactViewResult.success){
        this.display.contact = selectContactFromContactViewResult.result;
        this.display.contact.contactImage = this.display.contact.contactImage ? `data:image/jpeg;base64,${this.display.contact.contactImage}` : null;
        this.loader.contact = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la información del contacto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectContactTransactionFromContactView(){
      this.loader.contactTransaction = true;
      const selectContactTransactionFromContactViewRequestQuery = 
      {
        'contactID': this.navigation.contactID,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate
      };
      const selectContactTransactionFromContactViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/contact/functions/selectContactTransactionFromContactView', selectContactTransactionFromContactViewRequestQuery);
      if (selectContactTransactionFromContactViewResult.success){
        this.display.contactTransactionRows = selectContactTransactionFromContactViewResult.result.sort((a, b) => b.billID - a.billID);
        this.loader.contactTransaction = false; 
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la información de las transacciones del contacto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async setDefaultValues(){
      this.input.startDate = new Date(new Date().setFullYear(new Date().getFullYear(), 0, 1));
      this.input.endDate = new Date();
    },

    async openInsertDebtPaymentDialog(contactTransactions){
      var billRows = JSON.parse(JSON.stringify(contactTransactions)).filter(contactTransactionRow => (contactTransactionRow.billDebt && contactTransactionRow.billDebt > 0)).map(billRow => ({
        'billID': billRow.billID,
        'debtID': billRow.debtID,
        'billCreationDatetime': billRow.billCreationDatetime,
        'billAmount': billRow.billAmount,
        'billDebt': billRow.billDebt,
        'debtPaymentAmount': 0
      }));
      this.$root.insertDebtPaymentDialog.openInsertDebtPaymentDialog(billRows);
    },

    async openProformaDrawer(proformaID){
      this.navigation.proformaDrawerIsVisible = true;
      this.loader.selectProformaFromProformaDrawer = true;
      const selectProformaFromProformaDrawerRequestQuery =
      {
        'proformaID': proformaID,
        'localityID': this.localStorage.localityID
      };
      const selectProformaFromProformaDrawerResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/proforma/functions/selectProformaFromProformaDrawer', selectProformaFromProformaDrawerRequestQuery);
      if (selectProformaFromProformaDrawerResult.success){
        this.display.proformaDrawer = selectProformaFromProformaDrawerResult.result;
        this.display.proformaDrawer.proformaLoader = false;
        this.display.proformaDrawer.proformaApprovedImage = this.display.proformaDrawer.proformaApprovedImage ? `data:image/jpeg;base64,${this.display.proformaDrawer.proformaApprovedImage}` : null;
        this.display.proformaDrawer.proformaDispatchedImage = this.display.proformaDrawer.proformaDispatchedImage ? `data:image/jpeg;base64,${this.display.proformaDrawer.proformaDispatchedImage}` : null;
        this.display.proformaDrawer.proformaProducts = this.display.proformaDrawer.proformaProducts.map(proformaProduct => ({
          ...proformaProduct,
          'proformaProductReady': false
        }));
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la información de la proforma, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
        this.navigation.proformaDrawerIsVisible = false;
      }
      this.loader.selectProformaFromProformaDrawer = false;
    },

    async updateProformaNote(){
      this.loader.selectProformaFromProformaDrawer = true;
      const updateProformaNoteRequestQuery =
      {
        'proformaID': this.display.proformaDrawer.proformaID,
        'proformaNote': this.display.proformaDrawer.proformaNote
      };
      const updateProformaNoteResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/proforma/functions/updateProformaNote', updateProformaNoteRequestQuery);
      if (updateProformaNoteResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'Se ha actualizado la nota de la proforma exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        await this.openProformaDrawer(this.display.proformaDrawer.proformaID);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al actualizar la nota de la proforma, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectProformaFromProformaDrawer = false;
    },

    async createProformaPDFDocument(proforma){
      proforma.proformaLoader = true;
      const createProformaPDFDocumentRequestQuery =
      {
        'reprint': true,
        'proformaID': proforma.proformaID
      };
      const createProformaPDFDocumentResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/proforma/functions/createProformaPDFDocument', createProformaPDFDocumentRequestQuery);
      if (createProformaPDFDocumentResult.success){
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': createProformaPDFDocumentResult.result.documentFile.data,
          'printablePDFDocumentName': createProformaPDFDocumentResult.result.documentName,
          'printableExcelDocumentFile': null,
          'printableExcelDocumentName': null
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al reimprimir la proforma, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      proforma.proformaLoader = false;
    },

    async openBillDialog(billID){
      const billDialogRequestQuery = 
      {
        'billID': billID
      };
      this.$root.billDialog.openBillDialog(billDialogRequestQuery);
    },

    async openDebtPaymentDialog(debtID){
      this.$root.debtPaymentDialog.openDebtPaymentDialog(debtID);
    },

    async openContactDialog(){
      this.$root.contactDialog.openContactDialog(this.navigation.contactID);
    },

    async openInsertProformaDialog(){
      this.$root.insertProformaDialog.openInsertProformaDialog(this.navigation.contactID);
    },
    
    async listenToViewMethodCaller(){
      viewMethodCaller.on('ContactView/methods/selectContactFromContactsView()', this.selectContactFromContactView);
      viewMethodCaller.on('ContactView/methods/selectContactTransactionFromContactView()', this.selectContactTransactionFromContactView);
    },
  },

  async mounted(){
    await this.parseURL();
    await this.getLocalStorageData();
    await this.setDefaultValues();
    await this.selectContactFromContactView();
    await this.selectContactTransactionFromContactView();
  }

});
</script>
