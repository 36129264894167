<template>
  <div class="billViewContainer">
    <div class="billViewMainContainer">
      <div class="billViewLeftContainer">
        <v-autocomplete
          v-model="input.matchedProductIDOrName"
          v-model:search="input.searchedProductIDOrName"
          @keydown.enter.prevent="appendProductIntoCurrentBillProducts(input.searchedProductIDOrName)"
          @update:modelValue="appendProductIntoCurrentBillProducts(input.matchedProductIDOrName.productID)"
          :items="display.productOptions"
          :loading="loader.selectProductBasicInformationForBillViewByLocalityID"
          :disabled="loader.selectProductBasicInformationForBillViewByLocalityID"
          return-object
          item-title="productAutocompleteTitle" 
          item-value="productID"
          ref="productIDOrNameInputReference"
          append-inner-icon="mdi-magnify"
          label="Código o nombre del producto"
          variant="solo-filled"
          class="productIDOrNameInput">
        </v-autocomplete>
        <v-card class="currentBillProductsTableContainer" elevation="3">
          <v-data-table-virtual :headers="display.billProductHeaders" :items="display.currentBillProducts" class="currentBillProductsTable">
            <template v-slot:item="row">
              <tr :style="row.item.billProductSelectedAction ? 'background-color: #c7e8fc' : ''" @contextmenu.prevent="row.item.billProductSelectedAction = !row.item.billProductSelectedAction">
                <td>
                  <v-checkbox v-model="row.item.billProductSelectedAction" color="info" style="margin-top: 20px;"></v-checkbox>
                  <div v-if="display.currentBillProducts.findIndex(currentBillProduct => currentBillProduct.billProductProductID == row.item.billProductProductID) == 0">
                    <v-checkbox v-model="input.allBillProductSelected" @click="selectAllBillProduct()" color="info" style="position: absolute; top: 0px;"></v-checkbox>
                  </div>
                </td>
                <td>
                  <div style="width: 200px;">
                    {{ row.item.billProductName }}
                    <br>
                    <strong>{{ row.item.billProductProductID }}</strong>
                  </div>
                </td>
                <td>
                  <div v-if="row.item.billProductLocalityStockAmount >= row.item.billProductAmount">
                    <p style="color: #009929; font-size: larger;"><strong>{{ row.item.billProductLocalityStockAmount }}</strong></p>
                  </div>
                  <div v-else>
                    <v-tooltip text="El producto no cuenta con stock suficiente en esta localidad" location="bottom">
                      <template v-slot:activator="{ props }">
                        <p v-bind="props" class="billProductAmountWarningLabel"><strong>{{ row.item.billProductLocalityStockAmount }}</strong></p>
                      </template>
                    </v-tooltip>
                  </div>
                </td>
                <td>
                  <div @click="openUpdateBillProductAmountDialog(row.item, row.item.billProductAmount)" class="updatableBillProductTableRow">
                    {{row.item.billProductAmount}}
                  </div>
                </td>
                <td>
                  <div v-if="accessCredential['/bill/updateBillProductPrice']" @click="openUpdateBillProductPriceDialog(row.item)" class="updatableBillProductTableRow">
                    {{ input.selectedCurrencySymbol == '₡' ? row.item.billProductWithoutTax.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) : (row.item.billProductWithoutTax/display.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}}
                  </div>
                  <div v-else>
                    {{ input.selectedCurrencySymbol == '₡' ? row.item.billProductWithoutTax.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) : (row.item.billProductWithoutTax/display.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}}
                  </div>
                </td>
                <td>
                  {{ input.selectedCurrencySymbol == '₡' ? row.item.billProductTax.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) : (row.item.billProductTax/display.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}}
                </td>
                <td>
                  <div @click="openUpdateBillProductDiscountMarginDialog(row.item)" class="updatableBillProductTableRow">
                    {{row.item.billProductDiscountMargin.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}}
                  </div>
                </td>
                <td>
                  {{ input.selectedCurrencySymbol == '₡' ? row.item.billProductDiscount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) : (row.item.billProductDiscount/display.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}}
                </td>
                <td>
                  {{ input.selectedCurrencySymbol == '₡' ? row.item.billProductSubtotal.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) : (row.item.billProductSubtotal/display.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}}
                </td>
                <td>
                  {{ input.selectedCurrencySymbol == '₡' ? row.item.billProductTotal.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) : (row.item.billProductTotal/display.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}}
                </td>
                <td>
                  <v-icon @click="openProductImageDialog(row.item.billProductProductID, row.item.billProductName)" style="margin-right: 2%;" color="info">mdi-image</v-icon>
                </td>
                <td>
                  <v-icon @click="deleteBillProduct(row.item.billProductProductID)" style="margin-right: 2%;" color="error">mdi-trash-can</v-icon>
                </td>
              </tr>
            </template>
          </v-data-table-virtual>
        </v-card>
      </div>
      <v-card class="billViewRightContainer" elevation="3">
        <div>
          <div style="display: flex;">
            <v-select v-model="input.selectedBillType" :items="display.billTypeOptions" append-inner-icon="mdi-text-box-outline" density="compact" label="Tipo de factura" variant="solo-filled"></v-select>
            <v-tooltip location="left">
              <template v-slot:activator="{ props }">
                <v-switch v-if="accessCredential['/bill/generateDolarBill']" v-model="input.selectedCurrencySymbol" v-bind="props" true-value="$" false-value="₡" :label="input.selectedCurrencySymbol" hide-details color="success" style="margin-left: 5%; position: relative; top: -10px;"></v-switch>
              </template>
              <div v-if="display.dolarExchangeRate" style="text-align: center;">
                <p>1 $</p>
                <p>=</p>
                <p>₡ {{ parseFloat(display.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
              </div>
            </v-tooltip>
            <v-tooltip location="left">
              <template v-slot:activator="{ props }">
                <v-btn @click="openInsertClientDialog()" v-bind="props" style="margin-left: 3%;" dark color="info">
                  <v-icon dark right>mdi-account</v-icon>
                </v-btn>
              </template>
              <div style="text-align: center;">
                <p>Agregar cliente</p>
              </div>
            </v-tooltip>
            <v-tooltip location="left">
              <template v-slot:activator="{ props }">
                <v-btn @click="openClientBillDialog()" v-bind="props" style="margin-left: 3%;" dark color="warning">
                  <v-icon dark right>mdi-folder-account</v-icon>
                </v-btn>
              </template>
              <div style="text-align: center;">
                <p>Historial por cliente</p>
              </div>
            </v-tooltip>
          </div>
          <v-select v-model="input.selectedAgentID" :items="display.agentOptions" :loading="loader.selectAgentFromBillView" :disabled="loader.selectAgentByAgentLocalityID" append-inner-icon="mdi-briefcase-account" density="compact" item-title="agentName" item-value="agentID" label="Agente facturador" variant="solo-filled"></v-select>
          <v-select v-model="input.selectedBillPaymentType" :items="display.billPaymentTypeOptions" append-inner-icon="mdi-cash-multiple" density="compact" label="Método de pago" variant="solo-filled"></v-select>
          <div v-if="input.selectedBillPaymentType == 'Efectivo'">
            <v-text-field v-model="input.billCashPaymentCashAmount" append-inner-icon="mdi-cash" label="El cliente cancela con..." variant="solo-filled" density="compact" type="number" hide-spin-buttons></v-text-field>
          </div>
          <div v-if="input.selectedBillPaymentType == 'Mixto'">
            <v-card class="mixedPaymentBillInformationInputContainer">
              <v-text-field v-model="input.billMixedPaymentCashAmount" append-inner-icon="mdi-cash" label="Cantidad en efectivo" variant="solo-filled" density="compact" style="margin-top: 20px;"></v-text-field>
              <v-text-field v-model="input.billMixedPaymentCardAmount" append-inner-icon="mdi-credit-card-outline" label="Cantidad en tarjeta" variant="solo-filled" density="compact"></v-text-field>
              <v-text-field v-model="input.billMixedPaymentSINPEAmount" append-inner-icon="mdi-cellphone" label="Cantidad en SINPE" variant="solo-filled" density="compact"></v-text-field>
              <v-text-field v-model="input.billMixedPaymentDepositAmount" append-inner-icon="mdi-bank" label="Cantidad en transferencia o depósito" variant="solo-filled" density="compact"></v-text-field>
              <v-text-field v-model="display.billMixedPaymentAmount" append-inner-icon="mdi-cash" label="Cantidad total del pago mixto" variant="solo-filled" density="compact" readonly="true"></v-text-field>

            </v-card>
            <br>
          </div>
          <v-card class="electronicBillInformationInputContainer">
            <v-select v-model="input.selectedClientSSNType" :items="display.clientSSNTypeOptions" append-inner-icon="mdi-grid" density="compact" label="Tipo de documento" variant="solo-filled"></v-select>
            <v-text-field v-model="input.clientSSN" @keydown.enter.prevent="selectClientOnBillView(true)" @click:append-inner="setDefaultClientValues()" :loading="loader.selectClientOnBillView" :readonly="loader.selectClientOnBillView || navigation.clientFound" :append-inner-icon="navigation.clientFound ? 'mdi-cancel' : 'mdi-card-account-details-outline'" label="Cédula del cliente" variant="solo-filled" density="compact"></v-text-field>
            <v-text-field v-model="input.clientName" append-inner-icon="mdi-account-edit" label="Nombre del cliente" variant="solo-filled" density="compact"></v-text-field>
            <v-text-field v-model="input.clientEmail" append-inner-icon="mdi-email-outline" label="Correo del cliente" variant="solo-filled" density="compact"></v-text-field>
            <v-text-field v-model="input.clientPhoneNumber" append-inner-icon="mdi-phone" label="Número de teléfono del cliente" variant="solo-filled" density="compact"></v-text-field>
          </v-card>
          <br>
        </div>
        <v-card v-if="display.currentBillProducts.length > 0" class="billResultContainer">
          <div class="billResultNumbersContainer">
            <div>
              <h2><strong>{{ display.billUnitAmount == 1 ? 'Unidad:' : 'Unidades:' }}</strong></h2>
              <h2><strong>Subtotal:</strong></h2>
              <h2><strong>Impuesto:</strong></h2>
              <h2 v-if="display.billDiscountAmount != 0"><strong>Descuento:</strong></h2>
              <h2><strong>Total:</strong></h2>
              <h2 v-if="input.selectedBillPaymentType == 'Efectivo' && display.billCashPaymentChangeAmount != null"><strong>Cambio:</strong></h2>
            </div>
            <div style="text-align: right; width: 100%;">
              <div v-if="input.selectedCurrencySymbol == '₡'">
                <h2>{{ display.billUnitAmount }}</h2>
                <h2>₡ {{display.billSubtotal.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}}</h2>
                <h2>₡ {{display.billTaxAmount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}}</h2>
                <h2 v-if="display.billDiscountAmount != 0">₡ {{display.billDiscountAmount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}}</h2>
                <h2>₡ {{display.billTotal.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}}</h2>
                <h2 v-if="input.selectedBillPaymentType == 'Efectivo' && display.billCashPaymentChangeAmount != null">₡ {{display.billCashPaymentChangeAmount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}}</h2>
              </div>
              <div v-else>
                <h2>{{ display.billUnitAmount }}</h2>
                <h2>{{(display.billSubtotal/display.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}} $</h2>
                <h2>{{(display.billTaxAmount/display.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}} $</h2>
                <h2 v-if="display.billDiscountAmount != 0">{{(display.billDiscountAmount/display.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}} $</h2>
                <h2>{{(display.billTotal/display.dolarExchangeRate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}} $</h2>
                <h2 v-if="input.selectedBillPaymentType == 'Efectivo' && display.billCashPaymentChangeAmount != null">₡ {{display.billCashPaymentChangeAmount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2})}}</h2>
              </div>
            </div>
          </div>
          <div class="billResultButtonsContainer">
            <div v-if="loader.generateBill">
              <v-progress-circular color="success" indeterminate></v-progress-circular>
            </div>
            <v-btn v-else @click="generateBill(true)" dark height="50" color="success" block>
              <h2>FACTURAR</h2>
              <v-icon style="margin-left: 10px;" dark right>mdi-cash-register</v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-card>
    </div>
  </div>
  
  <v-dialog v-model="navigation.updateBillProductPriceDialogIsVisible" width="auto">
    <v-card width="300" class="insertProductCategoryDialogContainer">
      <v-text-field
        v-model="input.updatingBillProductPrice"
        @keydown.enter.prevent="updateBillProductPrice()"
        label="Precio del producto"
        variant="solo-filled"
        class="updatingBillProductInput"
        type="number"
        ref="updatingBillProductPriceInputReference"
        hide-spin-buttons
      ></v-text-field>
      <v-btn @click="updateBillProductPrice()" dark height="38" color="success" class="updateBillProductButton">
        <h3>GUARDAR</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-check-bold</v-icon>
      </v-btn>
      <v-btn @click="navigation.updateBillProductPriceDialogIsVisible = false" dark height="38" color="error" class="updateBillProductButton">
        <h3>CANCELAR</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>

  <v-dialog v-model="navigation.updateBillProductTaxMarginDialogIsVisible" width="auto">
    <v-card width="300" class="insertProductCategoryDialogContainer">
      <v-text-field
        v-model="input.updatingBillProductTaxMargin"
        @keydown.enter.prevent="updateBillProductTaxMargin()"
        label="Márgen de impuesto del producto"
        variant="solo-filled"
        class="updatingBillProductInput"
        type="number"
        ref="updatingBillProductTaxMarginInputReference"
        hide-spin-buttons
      ></v-text-field>
      <v-btn @click="updateBillProductTaxMargin()" dark height="38" color="success" class="updateBillProductButton">
        <h3>GUARDAR</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-check-bold</v-icon>
      </v-btn>
      <v-btn @click="navigation.updateBillProductTaxMarginDialogIsVisible = false" dark height="38" color="error" class="updateBillProductButton">
        <h3>CANCELAR</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>

  <v-dialog v-model="navigation.updateBillProductDiscountMarginDialogIsVisible" width="auto">
    <v-card width="300" class="insertProductCategoryDialogContainer">
      <v-text-field
        v-model="input.updatingBillProductDiscountMargin"
        @keydown.enter.prevent="updateBillProductDiscountMargin()"
        label="Márgen de descuento del producto"
        variant="solo-filled"
        class="updatingBillProductInput"
        type="number"
        ref="updatingBillProductDiscountMarginInputReference"
        hide-spin-buttons
      ></v-text-field>
      <v-btn @click="updateBillProductDiscountMargin()" dark height="38" color="success" class="updateBillProductButton">
        <h3>GUARDAR</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-check-bold</v-icon>
      </v-btn>
      <v-btn @click="navigation.updateBillProductDiscountMarginDialogIsVisible = false" dark height="38" color="error" class="updateBillProductButton">
        <h3>CANCELAR</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>

  <v-dialog v-model="navigation.updateBillProductAmountDialogIsVisible" width="auto">
    <v-card width="300" class="insertProductCategoryDialogContainer">
      <v-text-field
        v-model="input.updatingBillProductAmount"
        @keydown.enter.prevent="updateBillProductAmount()"
        label="Cantidad del producto"
        variant="solo-filled"
        class="updatingBillProductInput"
        type="number"
        ref="updatingBillProductAmountInputReference"
      ></v-text-field>
      <v-btn @click="updateBillProductAmount()" dark height="38" color="success" class="updateBillProductButton">
        <h3>GUARDAR</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-check-bold</v-icon>
      </v-btn>
      <v-btn @click="navigation.updateBillProductAmountDialogIsVisible = false" dark height="38" color="error" class="updateBillProductButton">
        <h3>CANCELAR</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>

  <v-dialog v-model="navigation.productDiscountMarginTokenDialogIsVisible" :persistent="true" width="auto">
    <v-card width="300" class="insertProductCategoryDialogContainer">
      <div v-if="loader.validateProductDiscountMarginToken" style="text-align: center;">
        <br>
        <v-progress-circular color="info" indeterminate></v-progress-circular>
        <br><br>
      </div>
      <div v-else style="text-align: center;">
        <v-text-field
          v-model="input.productDiscountMarginToken"
          @keydown.enter.prevent="validateProductDiscountMarginToken()"
          label="Token"
          variant="solo-filled"
          class="updatingBillProductInput"
          type="number"
          ref="productDiscountMarginTokenInputReference"
        ></v-text-field>
        <v-btn @click="validateProductDiscountMarginToken()" dark height="38" color="success" class="updateBillProductButton">
          <h3>VALIDAR</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-check-bold</v-icon>
        </v-btn>
        <v-btn @click="navigation.productDiscountMarginTokenDialogIsVisible = false" dark height="38" color="error" class="updateBillProductButton">
          <h3>CANCELAR</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
        </v-btn>        
      </div>
    </v-card>
  </v-dialog>

  <v-dialog v-model="navigation.waitForBillPaymentValidationDialogIsVisible" :persistent="true" width="auto">
    <v-card width="400" style="padding: 20px;">
      <div style="text-align: center;">
        Esperando aprobación del pago. Por favor espere...
        <br><br>
        <v-progress-circular color="info" indeterminate></v-progress-circular>
        <br>
      </div>
    </v-card>
  </v-dialog>
  
</template>

<style scoped>

  .billViewContainer {
    margin: 2% 3% 2% 3%;
  }

  .billViewMainContainer {
    display: flex;
    min-height: 85vh;
  }
  
  .billViewLeftContainer {
    width: 70%;
    margin-right: 1%;
  }

  .billViewRightContainer {
    width: 30%;
    margin-left: 1%;
    min-height: 80vh;
    overflow-y: auto;
    padding: 20px;
    background-color: #e9e8e8;
    display: flex;
    flex-direction: column;
  }

  .billProductAmountWarningLabel {
    cursor: pointer;
    color: rgb(171, 0, 0);
    font-size: larger;
    animation: billProductAmountWarningLabelAnimation 2s infinite;
  }

  @keyframes billProductAmountWarningLabelAnimation {
    0% {transform: scale(1)}
    50% {transform: scale(1.4)}
    100% {transform: scale(1)}
  }

  .billResultNumbersContainer {
    margin-top: auto; 
    width: 100%; 
    overflow-y: auto;
    background-color: white;
    padding: 3%;
    display: flex;
  }

  .billResultButtonsContainer {
    text-align: center;
    padding: 20px;
  }

  .productIDOrNameInput {
    margin-bottom: 0px;
  }

  .currentBillProductsTableContainer {
    z-index: 1;
    position: relative;
    height: 90%;
  }

  .currentBillProductsTable {
    max-height: 100%;
    overflow-y: auto;
  }

  .updatableBillProductTableRow {
    width: 100px;
    cursor: pointer;
  }

  .updatingBillProductInput {
    padding: 10px;
  }

  .updateBillProductButton {
    margin-bottom: 15px; 
    margin-left: 15px; 
    margin-right: 15px;
  }

  .electronicBillInformationInputContainer {
    padding: 20px 20px 0px 20px;
    background-color: rgb(214, 93, 0);
  }

  .mixedPaymentBillInformationInputContainer {
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgb(7, 143, 0);
  }

</style>

<script>
import { defineComponent } from 'vue';
import { viewMethodCaller } from '../viewMethodCaller.js';

export default defineComponent({
  name: 'BillView',
  inject: ['$generalFunctions'],

  
  data: () => ({
    input: 
    {
      searchedProductIDOrName: null,
      matchedProductIDOrName: null,
      allBillProductSelected: false,
      updatingBillProduct: null,
      updatingBillProductMultipleSelected: null,
      updatingBillProductPrice: null,
      updatingBillProductTaxMargin: null,
      updatingBillProductDiscountMargin: null,
      updatingBillProductAmount: null,
      productDiscountMarginToken: null,
      selectedBillType: null,
      selectedBillPaymentType: null,
      selectedCurrencySymbol: '₡',
      selectedAgentID: null,
      billCashPaymentCashAmount: null,
      billMixedPaymentCashAmount: 0,
      billMixedPaymentCardAmount: 0,
      billMixedPaymentSINPEAmount: 0,
      billMixedPaymentDepositAmount: 0,
      selectedClientSSNType: null,
      clientSSN: '',
      clientName: '',
      clientEmail: '',
      clientPhoneNumber: '',
    },

    navigation: 
    {
      updateBillProductPriceDialogIsVisible: false,
      updateBillProductTaxMarginDialogIsVisible: false,
      updateBillProductDiscountMarginDialogIsVisible: false,
      updateBillProductAmountDialogIsVisible: false,
      productDiscountMarginTokenDialogIsVisible: false,
      clientFound: null,
      waitForBillPaymentValidationDialogIsVisible: false,
      billPaymentValidationID: null,
      billPaymentValidationQueryInterval: null
    },

    loader: 
    {
      selectProductBasicInformationForBillViewByLocalityID: false,
      validateProductDiscountMarginToken: false,
      selectAgentFromBillView: false,
      selectClientOnBillView: false,
      generateBill: false,
      selectClientForClientSearch: false
    },

    display:
    {
      productOptions: [],
      billProductHeaders: 
      [
        {key: 'billProductSelectAction', title: ''},
        {key: 'billProductName', title: 'Nombre'},
        {key: 'billProductLocalityStockAmount', title: 'Stock'},
        {key: 'billProductAmount', title: 'Cantidad'},
        {key: 'billProductPrice', title: 'Precio'},
        {key: 'billProductTax', title: 'Impuesto'},
        {key: 'billProductDiscountMargin', title: 'Descuento(%)'},
        {key: 'billProductDiscount', title: 'Descuento'},
        {key: 'billProductSubtotal', title: 'Subtotal'},
        {key: 'billProductTotal', title: 'Total'},
        {key: 'billProductInformationAction', title: 'Imagen'},
        {key: 'billProductDeleteAction', title: 'Eliminar'}
      ],
      currentBillProducts: [],
      billTypeOptions: ['Factura electrónica', 'Tiquete electrónico', 'Recibo'],
      billPaymentTypeOptions: ['Efectivo', 'Tarjeta', 'SINPE', 'Transferencia o depósito', 'Página web', 'Uber', 'Rappi', 'Mixto'],
      agentOptions: [],
      clientSSNTypeOptions: ['Física', 'Jurídica', 'DIMEX'],
      billUnitAmount: 0,
      billSubtotal: 0,
      billDiscountAmount: 0,
      billTaxAmount: 0,
      billTotal: 0,
      billCashPaymentChangeAmount: 0,
      billMixedPaymentAmount: 0,
      dolarExchangeRate: null,
      clientOptions: []
    },

    localStorage: 
    {
      localityID: null,
      agentID: null
    },

    accessCredential: {}

  }),

  watch: {
    'input.billCashPaymentCashAmount'(){
      this.calculateBillResult();
    },
    'input.selectedCurrencySymbol'(){
      this.calculateBillResult();
    },
    'input.billMixedPaymentCashAmount'(){
      const billMixedPaymentAmount = parseInt(this.input.billMixedPaymentCashAmount) + parseInt(this.input.billMixedPaymentCardAmount) + parseInt(this.input.billMixedPaymentSINPEAmount) + parseInt(this.input.billMixedPaymentDepositAmount);
      this.display.billMixedPaymentAmount = isNaN(billMixedPaymentAmount) ? 'Error' : billMixedPaymentAmount;
    },
    'input.billMixedPaymentCardAmount'(){
      const billMixedPaymentAmount = parseInt(this.input.billMixedPaymentCashAmount) + parseInt(this.input.billMixedPaymentCardAmount) + parseInt(this.input.billMixedPaymentSINPEAmount) + parseInt(this.input.billMixedPaymentDepositAmount);
      this.display.billMixedPaymentAmount = isNaN(billMixedPaymentAmount) ? 'Error' : billMixedPaymentAmount;
    },
    'input.billMixedPaymentSINPEAmount'(){
      const billMixedPaymentAmount = parseInt(this.input.billMixedPaymentCashAmount) + parseInt(this.input.billMixedPaymentCardAmount) + parseInt(this.input.billMixedPaymentSINPEAmount) + parseInt(this.input.billMixedPaymentDepositAmount);
      this.display.billMixedPaymentAmount = isNaN(billMixedPaymentAmount) ? 'Error' : billMixedPaymentAmount;
    },
    'input.billMixedPaymentDepositAmount'(){
      const billMixedPaymentAmount = parseInt(this.input.billMixedPaymentCashAmount) + parseInt(this.input.billMixedPaymentCardAmount) + parseInt(this.input.billMixedPaymentSINPEAmount) + parseInt(this.input.billMixedPaymentDepositAmount);
      this.display.billMixedPaymentAmount = isNaN(billMixedPaymentAmount) ? 'Error' : billMixedPaymentAmount
    },
  },

  methods: {
    selectAllBillProduct(){
      this.input.allBillProductSelected = !this.input.allBillProductSelected;
      this.display.currentBillProducts.forEach(currentBillProduct => {
        currentBillProduct.billProductSelectedAction = this.input.allBillProductSelected;
      });    
    },

    async selectClientOnBillView(showNotificationDialog){
      this.loader.selectClientOnBillView = true;
      this.navigation.clientFound = false;
      const selectClientOnBillViewRequestQuery = {'clientSSN': this.input.clientSSN};
      const selectClientOnBillViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/client/functions/selectClientOnBillView', selectClientOnBillViewRequestQuery);
      if (selectClientOnBillViewResult.success){
        if (selectClientOnBillViewResult.result){
          this.input.selectedClientSSNType = selectClientOnBillViewResult.result.clientSSNType;
          this.input.clientName = selectClientOnBillViewResult.result.clientName;
          this.input.clientEmail = selectClientOnBillViewResult.result.clientEmail;
          this.input.clientPhoneNumber = selectClientOnBillViewResult.result.clientPhoneNumber;
          this.navigation.clientFound = true;
          if (showNotificationDialog){
            this.$root.notificationDialog.showNotificationDialog({
              'notificationDialogTitle': 'ÉXITO',
              'notificationDialogBody': 'La información del cliente ha sido completada',
              'notificationDialogColor': 'success',
              'notificationDialogIsPersistent': false
            });
          }
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': null,
            'notificationDialogBody': 'No se ha encontrado al cliente seleccionado, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la información del cliente, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectClientOnBillView = false;
    },

    setDefaultClientValues(){
      if (this.navigation.clientFound){
        this.input.selectedClientSSNType = null;
        this.input.clientSSN = '';
        this.input.clientName = '';
        this.input.clientEmail = '';
        this.input.clientPhoneNumber = '';
        this.navigation.clientFound = false;
      }
    },

    async selectProductBasicInformationForBillViewByLocalityID(){
      this.loader.selectProductBasicInformationForBillViewByLocalityID = true;
      const selectProductBasicInformationForBillViewByLocalityIDRequestQuery = {'localityID': this.localStorage.localityID};
      const selectProductBasicInformationForBillViewByLocalityIDResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductBasicInformationForBillViewByLocalityID', selectProductBasicInformationForBillViewByLocalityIDRequestQuery);
      if (selectProductBasicInformationForBillViewByLocalityIDResult.success){
        this.display.productOptions = selectProductBasicInformationForBillViewByLocalityIDResult.result.map(productOption => ({
          'productID': productOption.i,
          'productSKU': productOption.s,
          'productName': productOption.n,
          'productPrice': productOption.p,
          'productTaxMargin': productOption.t,
          'productMaxDiscountMargin': productOption.d,
          'productLocalityStockAmount': productOption.a,
          'productAutocompleteTitle': productOption.s + ' - ' + productOption.n
        }));
        this.loader.selectProductBasicInformationForBillViewByLocalityID = false;
        this.focusOnInput('productIDOrNameInputReference');
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectAgentFromBillView(){
      this.loader.selectAgentFromBillView = true;
      const selectAgentFromBillViewRequestQuery = 
      {
        'agentLocalityID': this.localStorage.localityID, 
        'agentIsActive': true,
        'accessCredentialName': '/bill/billAgentID'
      };
      const selectAgentFromBillViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/select/agentIDAndAgentName/byAgentLocalityIDAndByAgentIsActiveAndByAccessCredentialName', selectAgentFromBillViewRequestQuery);
      if (selectAgentFromBillViewResult.success){
        this.display.agentOptions = selectAgentFromBillViewResult.result;
        this.loader.selectAgentFromBillView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de agentes facturadores, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    validateGenerateBillInput(){
      const regularExpressionChecker = /\S/;
      const positiveNumberChecker = /^(?:0|[1-9]\d*)(?:\.\d+)?$/;
      if (this.display.currentBillProducts.length == 0){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Seleccione al menos un producto para facturar, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (!this.accessCredential['/bill/generateBillWithoutStock'] && !this.display.currentBillProducts.every(currentBillProduct => currentBillProduct.billProductAmount <= currentBillProduct.billProductLocalityStockAmount)){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Su usuario no tiene permitido facturar productos sin stock en la localidad, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.selectedBillType == null){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Seleccione un tipo de factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.selectedBillPaymentType == null){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Seleccione un método de pago, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.selectedAgentID == null){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Seleccione un agente facturador, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.selectedBillPaymentType == 'Tiquete electrónico'){
        if (this.input.selectedClientSSNType == null){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
            'notificationDialogBody': 'Coloque un tipo de documento del cliente, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
        if (regularExpressionChecker.test(this.input.clientSSN) == false){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
            'notificationDialogBody': 'Coloque una cédula del cliente, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
        if (this.input.clientSSN.length > 30){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN DEMASIADO LARGA',
            'notificationDialogBody': 'La cédula del cliente no puede exceder los 30 caracteres, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
        if (regularExpressionChecker.test(this.input.clientName) == false){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
            'notificationDialogBody': 'Coloque un nombre del cliente, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
        if (this.input.clientName.length > 255){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN DEMASIADO LARGA',
            'notificationDialogBody': 'El nombre del cliente no puede exceder los 255 caracteres, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
        if (regularExpressionChecker.test(this.input.clientEmail) == false){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
            'notificationDialogBody': 'Coloque un correo del cliente, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
        if (this.input.clientEmail.length > 255){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN DEMASIADO LARGA',
            'notificationDialogBody': 'El correo del cliente no puede exceder los 255 caracteres, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
        if (regularExpressionChecker.test(this.input.clientPhoneNumber) == false){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
            'notificationDialogBody': 'Coloque un teléfono del cliente, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
        if (this.input.clientPhoneNumber.length > 255){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN DEMASIADO LARGA',
            'notificationDialogBody': 'El teléfono del cliente no puede exceder los 30 caracteres, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
      }
      if (this.input.selectedBillPaymentType == 'Efectivo'){
        if (this.display.billCashPaymentChangeAmount == null){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
            'notificationDialogBody': 'Coloque el monto en efectivo con el que cancela el cliente. El monto debe ser mayor o igual al monto total total de la factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
      }
      if (this.input.selectedBillPaymentType == 'Mixto'){
        if (positiveNumberChecker.test(this.input.billMixedPaymentCashAmount) == false){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
            'notificationDialogBody': 'Coloque un número válido positivo para la cantidad de dinero en efectivo de la factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
        if (positiveNumberChecker.test(this.input.billMixedPaymentCardAmount) == false){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
            'notificationDialogBody': 'Coloque un número válido positivo para la cantidad de dinero en tarjeta de la factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
        if (positiveNumberChecker.test(this.input.billMixedPaymentSINPEAmount) == false){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
            'notificationDialogBody': 'Coloque un número válido positivo para la cantidad de dinero en SINPE de la factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
        if (positiveNumberChecker.test(this.input.billMixedPaymentDepositAmount) == false){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
            'notificationDialogBody': 'Coloque un número válido positivo para la cantidad de dinero en transferencia o depósito de la factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
          return false;
        }
      }
      return true;
    },

    async generateBill(waitForBillPaymentValidation){
      this.loader.generateBill = true;
      if (this.validateGenerateBillInput()){
        if (waitForBillPaymentValidation){
          await this.waitForBillPaymentValidation();
        } else {
          const billProducts = this.display.currentBillProducts.map(currentBillProduct => {
            return {
              'billProductProductID': currentBillProduct.billProductProductID,
              'billProductPrice': currentBillProduct.billProductPrice,
              'billProductTaxMargin': currentBillProduct.billProductTaxMargin,
              'billProductDiscountMargin': currentBillProduct.billProductDiscountMargin,
              'billProductSubtotal': currentBillProduct.billProductSubtotal,
              'billProductAmount': currentBillProduct.billProductAmount
            };
          });
          const generateBillRequestQuery = 
          {
            'billLocalityID': this.localStorage.localityID,
            'billAgentID': this.input.selectedAgentID,
            'billType': this.input.selectedBillType,
            'billPaymentType': this.input.selectedBillPaymentType,
            'billCurrency': this.input.selectedCurrencySymbol,
            'billProducts': billProducts,
            'clientFound': this.navigation.clientFound,
            'clientSSNType': this.input.selectedClientSSNType,
            'clientSSN': this.input.clientSSN,
            'clientName': this.input.clientName,
            'clientEmail': this.input.clientEmail,
            'clientPhoneNumber': this.input.clientPhoneNumber,
            'billCashPaymentCashAmount': this.input.selectedCurrencySymbol == '$' ? this.input.billCashPaymentCashAmount * this.display.dolarExchangeRate : this.input.billCashPaymentCashAmount,
            'billCashPaymentChangeAmount': this.display.billCashPaymentChangeAmount,
            'billMixedPaymentCashAmount': this.input.billMixedPaymentCashAmount,
            'billMixedPaymentCardAmount': this.input.billMixedPaymentCardAmount,
            'billMixedPaymentSINPEAmount': this.input.billMixedPaymentSINPEAmount,
            'billMixedPaymentDepositAmount': this.input.billMixedPaymentDepositAmount
          };
          const generateBillResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/functions/generateBill', generateBillRequestQuery);
          if (generateBillResult.success){
            await this.selectProductBasicInformationForBillViewByLocalityID();
            this.$root.printableBillDialog.showPrintableBillDialog(generateBillResult.result);
            this.setDefaultValues();
            this.focusOnInput('productIDOrNameInputReference');
            this.$root.notificationDialog.showNotificationDialog({
              'notificationDialogTitle': 'ÉXITO',
              'notificationDialogBody': 'Se ha generado la factura exitosamente.',
              'notificationDialogColor': 'success',
              'notificationDialogIsPersistent': false
            });
          } else {
            this.$root.notificationDialog.showNotificationDialog({
              'notificationDialogTitle': 'ERROR',
              'notificationDialogBody': 'Ha ocurrido un error inesperado al crear la factura, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
              'notificationDialogColor': 'error',
              'notificationDialogIsPersistent': false
            });
          }
        }
      }
      this.loader.generateBill = false;
    },

    async waitForBillPaymentValidation(){
      if (this.input.selectedBillPaymentType == 'Transferencia o depósito' || this.input.billMixedPaymentDepositAmount != 0){
        this.navigation.waitForBillPaymentValidationDialogIsVisible = true;
        const insertBillPaymentValidationRequestQuery =
        {
          'billPaymentValidationRequestAgentID': this.input.selectedAgentID,
          'billPaymentValidationPaymentType': 'Transferencia o depósito',
          'billPaymentValidationAmount': this.input.billMixedPaymentDepositAmount != 0 ? this.input.billMixedPaymentDepositAmount : this.display.billTotal
        };
        const insertBillPaymentValidationResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/billPaymentValidation/functions/insert', insertBillPaymentValidationRequestQuery);
        if (insertBillPaymentValidationResult.success){
          this.navigation.billPaymentValidationID = insertBillPaymentValidationResult.result;
          this.navigation.billPaymentValidationQueryInterval = setInterval(async () => {
            const checkForBillPaymentValidationRequestQuery =
            {
              'billPaymentValidationID': this.navigation.billPaymentValidationID
            };
            const checkForBillPaymentValidationResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/billPaymentValidation/functions/checkForBillPaymentValidation', checkForBillPaymentValidationRequestQuery);
            if (checkForBillPaymentValidationResult.success){
              if (checkForBillPaymentValidationResult.result == true){
                this.$root.notificationDialog.showNotificationDialog({
                  'notificationDialogTitle': 'ÉXITO',
                  'notificationDialogBody': 'Se ha comprobado el pago exitosamente',
                  'notificationDialogColor': 'success',
                  'notificationDialogIsPersistent': false
                });
                this.navigation.waitForBillPaymentValidationDialogIsVisible = false;
                clearInterval(this.navigation.billPaymentValidationQueryInterval);
                await this.generateBill(false);
              }
            } else {
              this.$root.notificationDialog.showNotificationDialog({
                'notificationDialogTitle': 'ERROR',
                'notificationDialogBody': 'Ha ocurrido un error inesperado al recibir la confirmación de aprobación del pago, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
                'notificationDialogColor': 'error',
                'notificationDialogIsPersistent': false
              });
            }
          }, 3000);
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al generar la solicitud de aprobación del pago, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
          this.navigation.waitForBillPaymentValidationDialogIsVisible = false;
        }
      } else {
        await this.generateBill(false);
      }
    },

    async appendProductIntoCurrentBillProducts(productID){
      const regularExpressionChecker = /\S/;
      if (regularExpressionChecker.test(productID)){
        const product = this.display.productOptions.find(productOption => productOption.productID == productID);
        if (product){
          const billProductTax = product.productPrice * (product.productTaxMargin/100);
          const billProductPriceWithoutTax = product.productPrice - billProductTax;
          const billProductAlreadyOnCurrentBillProducts = this.display.currentBillProducts.find(currentBillProduct => currentBillProduct.billProductProductID == product.productID)
          if (billProductAlreadyOnCurrentBillProducts){
            billProductAlreadyOnCurrentBillProducts.billProductAmount = billProductAlreadyOnCurrentBillProducts.billProductAmount + 1;
            billProductAlreadyOnCurrentBillProducts.billProductTotal = product.productPrice * billProductAlreadyOnCurrentBillProducts.billProductAmount;
          } else {
            this.display.currentBillProducts.push({
              'billProductSelectedAction': false,
              'billProductProductID': product.productID,
              'billProductName': product.productName,
              'billProductPrice': product.productPrice,
              'billProductWithoutTax': billProductPriceWithoutTax,
              'billProductTaxMargin': product.productTaxMargin,
              'billProductMaxDiscountMargin': product.productMaxDiscountMargin,
              'billProductTax': billProductTax,
              'billProductDiscountMargin': 0,
              'billProductDiscount': 0,
              'billProductSubtotal': product.productPrice,
              'billProductAmount': 1,
              'billProductLocalityStockAmount': product.productLocalityStockAmount,
              'billProductTotal': product.productPrice
            });
          }
          this.input.searchedProductIDOrName = null;
          this.input.matchedProductIDOrName = null;
          this.input.allBillProductSelected = false;
          this.focusOnInput('productIDOrNameInputReference');
          this.calculateBillResult();
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': null,
            'notificationDialogBody': 'No se ha encontrado el producto seleccionado, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque un código para el producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
      }
    },

    setDefaultValues(){
      this.input.searchedProductIDOrName = null;
      this.input.matchedProductIDOrName = null;
      this.input.allBillProductSelected = false;
      this.input.updatingBillProduct = null;
      this.input.updatingBillProductPrice = null;
      this.input.updatingBillProductTaxMargin = null;
      this.input.updatingBillProductDiscountMargin = null;
      this.input.updatingBillProductAmount = null;
      this.input.productDiscountMarginToken = null;
      this.input.selectedBillType = null;
      this.input.selectedBillPaymentType = null;
      this.input.selectedCurrencySymbol = '₡';
      this.input.selectedAgentID = null;
      this.input.billCashPaymentCashAmount = null;
      this.display.billCashPaymentChangeAmount = null;
      this.input.billMixedPaymentCashAmount = 0;
      this.input.billMixedPaymentCardAmount = 0;
      this.input.billMixedPaymentSINPEAmount = 0;
      this.input.billMixedPaymentDepositAmount = 0;
      this.input.selectedClientSSNType = null;
      this.input.clientSSN = '';
      this.input.clientName = '';
      this.input.clientEmail = '';
      this.input.clientPhoneNumber = '';
      this.navigation.updateBillProductPriceDialogIsVisible = false;
      this.navigation.updateBillProductTaxMarginDialogIsVisible = false;
      this.navigation.updateBillProductDiscountMarginDialogIsVisible = false;
      this.navigation.updateBillProductAmountDialogIsVisible = false;
      this.navigation.productDiscountMarginTokenDialogIsVisible = false;
      this.navigation.waitForBillPaymentValidationDialogIsVisible = false;
      this.navigation.clientFound = false;
      this.display.currentBillProducts = [];
      this.display.billUnitAmount = 0;
      this.display.billSubtotal = 0;
      this.display.billDiscountAmount = 0;
      this.display.billTaxAmount = 0;
      this.display.billTotal = 0;
    },

    focusOnInput(inputReference){
      const blurInputInterval = setInterval(() => {
        if (this.$refs[inputReference]) {
          this.$refs[inputReference].blur();
          const focusInputInterval = setInterval(() => {
            if (this.$refs[inputReference]) {
              this.$refs[inputReference].focus();
              clearInterval(focusInputInterval);
            }
          }, 1);
          clearInterval(blurInputInterval);
        }
      }, 1);
    },

    calculateBillResult(){
      this.display.billUnitAmount = this.display.currentBillProducts.reduce((billUnitAmount, currentBillProduct) => billUnitAmount + currentBillProduct.billProductAmount, 0);
      this.display.billSubtotal = this.display.currentBillProducts.reduce((billSubtotalAmount, currentBillProduct) => billSubtotalAmount + currentBillProduct.billProductWithoutTax * currentBillProduct.billProductAmount, 0);
      this.display.billTaxAmount = this.display.currentBillProducts.reduce((billTaxAmount, currentBillProduct) => billTaxAmount + currentBillProduct.billProductTax * currentBillProduct.billProductAmount, 0);
      this.display.billDiscountAmount = this.display.currentBillProducts.reduce((billDiscountAmount, currentBillProduct) => billDiscountAmount + currentBillProduct.billProductDiscount * currentBillProduct.billProductAmount, 0);
      this.display.billTotal = this.display.currentBillProducts.reduce((billTotal, currentBillProduct) => billTotal + currentBillProduct.billProductTotal, 0);
      if (this.input.selectedCurrencySymbol == '$'){
        this.input.billCashPaymentCashAmount * this.display.dolarExchangeRate - this.display.billTotal >= 0 ? (this.display.billCashPaymentChangeAmount = this.input.billCashPaymentCashAmount * this.display.dolarExchangeRate - this.display.billTotal) : (this.display.billCashPaymentChangeAmount = null);
      } else {
        this.input.billCashPaymentCashAmount - this.display.billTotal >= 0 ? (this.display.billCashPaymentChangeAmount = this.input.billCashPaymentCashAmount - this.display.billTotal) : (this.display.billCashPaymentChangeAmount = null);
      }
    },

    updateBillProduct(){
      this.input.updatingBillProduct.forEach(updatingBillProduct => {
        updatingBillProduct.billProductTax = updatingBillProduct.billProductPrice * (updatingBillProduct.billProductTaxMargin / 100);
        updatingBillProduct.billProductDiscount = (updatingBillProduct.billProductPrice) * (updatingBillProduct.billProductDiscountMargin / 100);
        updatingBillProduct.billProductSubtotal = updatingBillProduct.billProductPrice - updatingBillProduct.billProductDiscount;
        updatingBillProduct.billProductTotal = updatingBillProduct.billProductSubtotal * updatingBillProduct.billProductAmount;
      });
      if (this.display.updatingBillProductMultipleSelected){
        this.display.currentBillProducts.forEach(currentBillProduct => {
          currentBillProduct.billProductSelectedAction = false;
        });
      }
    },

    openUpdateBillProductPriceDialog(billProduct){
      if (this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction).find(currentBillProduct => currentBillProduct.billProductProductID == billProduct.billProductProductID)){
        this.input.updatingBillProduct = this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction);
        if (this.input.updatingBillProduct.every(currentUpdatingBillProduct => currentUpdatingBillProduct.billProductPrice == this.input.updatingBillProduct[0].billProductPrice)){
          this.input.updatingBillProductPrice = this.input.updatingBillProduct[0].billProductPrice;
        } else {
          this.input.updatingBillProductPrice = 0;
        }
        this.display.updatingBillProductMultipleSelected = true;
      } else {
        this.input.updatingBillProduct = [billProduct];
        this.input.updatingBillProductPrice = billProduct.billProductPrice;
        this.display.updatingBillProductMultipleSelected = false;
      }
      this.focusOnInput('updatingBillProductPriceInputReference');
      this.navigation.updateBillProductPriceDialogIsVisible = true;
    },

    updateBillProductPrice(){
      this.input.updatingBillProductPrice = parseFloat(this.input.updatingBillProductPrice);
      this.input.updatingBillProduct.forEach(updatingBillProduct => {
        updatingBillProduct.billProductPrice = this.input.updatingBillProductPrice;
      });
      this.updateBillProduct();
      this.calculateBillResult();
      this.navigation.updateBillProductPriceDialogIsVisible = false;
    },

    openUpdateBillProductTaxMarginDialog(billProduct){
      if (this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction).find(currentBillProduct => currentBillProduct.billProductProductID == billProduct.billProductProductID)){
        this.input.updatingBillProduct = this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction);
        if (this.input.updatingBillProduct.every(currentUpdatingBillProduct => currentUpdatingBillProduct.billProductTaxMargin == this.input.updatingBillProduct[0].billProductTaxMargin)){
          this.input.updatingBillProductTaxMargin = this.input.updatingBillProduct[0].billProductTaxMargin;
        } else {
          this.input.updatingBillProductTaxMargin = 0;
        }
        this.display.updatingBillProductMultipleSelected = true;
      } else {
        this.input.updatingBillProduct = [billProduct];
        this.input.updatingBillProductTaxMargin = billProduct.billProductTaxMargin;
        this.display.updatingBillProductMultipleSelected = false;
      }
      this.focusOnInput('updatingBillProductTaxMarginInputReference');
      this.navigation.updateBillProductTaxMarginDialogIsVisible = true;      
    },

    updateBillProductTaxMargin(){
      this.input.updatingBillProductTaxMargin = parseFloat(isNaN(parseFloat(this.input.updatingBillProductTaxMargin)) ? 0 : this.input.updatingBillProductTaxMargin);
      this.input.updatingBillProduct.forEach(updatingBillProduct => {
        updatingBillProduct.billProductTaxMargin = this.input.updatingBillProductTaxMargin;
      });
      this.updateBillProduct();
      this.calculateBillResult();
      this.navigation.updateBillProductTaxMarginDialogIsVisible = false;
    },

    openUpdateBillProductDiscountMarginDialog(billProduct){
      if (this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction).find(currentBillProduct => currentBillProduct.billProductProductID == billProduct.billProductProductID)){
        this.input.updatingBillProduct = this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction);
        if (this.input.updatingBillProduct.every(currentUpdatingBillProduct => currentUpdatingBillProduct.billProductDiscountMargin == this.input.updatingBillProduct[0].billProductDiscountMargin)){
          this.input.updatingBillProductDiscountMargin = this.input.updatingBillProduct[0].billProductDiscountMargin;
        } else {
          this.input.updatingBillProductDiscountMargin = 0;
        }
        this.display.updatingBillProductMultipleSelected = true;
      } else {
        this.input.updatingBillProduct = [billProduct];
        this.input.updatingBillProductDiscountMargin = billProduct.billProductDiscountMargin;
        this.display.updatingBillProductMultipleSelected = false;
      }
      this.focusOnInput('updatingBillProductDiscountMarginInputReference');
      this.navigation.updateBillProductDiscountMarginDialogIsVisible = true;
    },

    async validateProductDiscountMarginToken(){
      this.loader.validateProductDiscountMarginToken = true;
      const validateTokenRequestQuery = 
      {
        'tokenType': 'Descuento',
        'tokenValue': this.input.productDiscountMarginToken
      };
      const validateTokenResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/token/functions/validateToken', validateTokenRequestQuery);
      if (validateTokenResult.success){
        this.input.updatingBillProduct.forEach(updatingBillProduct => {
          updatingBillProduct.billProductDiscountMargin = this.input.updatingBillProductDiscountMargin;
        });
        this.updateBillProduct();
        this.calculateBillResult();
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': `El token "${this.input.productDiscountMarginToken}" ha sido validado exitosamente, descuento aplicado`,
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': `El token "${this.input.productDiscountMarginToken}" no es válido, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema`,
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.validateProductDiscountMarginToken = false;
      this.navigation.productDiscountMarginTokenDialogIsVisible = false;
    },

    openProductDiscountMarginTokenDialog(productExcededMaxDiscountMargin){
      this.focusOnInput('productDiscountMarginTokenInputReference');
      this.input.productDiscountMarginToken = null;
      this.navigation.updateBillProductDiscountMarginDialogIsVisible = false;
      this.navigation.productDiscountMarginTokenDialogIsVisible = true;
      this.$root.notificationDialog.showNotificationDialog({
        'notificationDialogTitle': 'ATENCIÓN',
        'notificationDialogBody': 
          `
            El descuento máximo permitido para el producto 
            "${productExcededMaxDiscountMargin.billProductName}" 
            es del 
            ${productExcededMaxDiscountMargin.billProductMaxDiscountMargin}%
            ${this.accessCredential['/bill/maxDiscountMargin'] ? `. Su usuario tiene habilitado un descuento máximo del ${this.accessCredential['/bill/maxDiscountMargin']}%`: ''}.
            Coloque un token de descuento en caso de contar con uno
          `,
        'notificationDialogColor': 'warning',
        'notificationDialogIsPersistent': false
      });
    },

    updateBillProductDiscountMargin(){
      this.input.updatingBillProductDiscountMargin = parseFloat(isNaN(parseFloat(this.input.updatingBillProductDiscountMargin)) ? 0 : this.input.updatingBillProductDiscountMargin);
      const productExcededMaxDiscountMargin = this.input.updatingBillProduct.find(product => this.input.updatingBillProductDiscountMargin > product.billProductMaxDiscountMargin);
      if (productExcededMaxDiscountMargin){
        if (this.accessCredential['/bill/maxDiscountMargin'] < this.input.updatingBillProductDiscountMargin || this.accessCredential['/bill/maxDiscountMargin'] == null){
          this.openProductDiscountMarginTokenDialog(productExcededMaxDiscountMargin);
          return;
        }
      }
      this.input.updatingBillProduct.forEach(updatingBillProduct => {
        updatingBillProduct.billProductDiscountMargin = this.input.updatingBillProductDiscountMargin;
      });
      this.updateBillProduct();
      this.calculateBillResult();
      this.navigation.updateBillProductDiscountMarginDialogIsVisible = false;
    },


    openUpdateBillProductAmountDialog(billProduct){
      if (this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction).find(currentBillProduct => currentBillProduct.billProductProductID == billProduct.billProductProductID)){
        this.input.updatingBillProduct = this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction);
        if (this.input.updatingBillProduct.every(currentUpdatingBillProduct => currentUpdatingBillProduct.billProductAmount == this.input.updatingBillProduct[0].billProductAmount)){
          this.input.updatingBillProductAmount = this.input.updatingBillProduct[0].billProductAmount;
        } else {
          this.input.updatingBillProductAmount = 0;
        }
        this.display.updatingBillProductMultipleSelected = true;
      } else {
        this.input.updatingBillProduct = [billProduct];
        this.input.updatingBillProductAmount = billProduct.billProductAmount;
        this.display.updatingBillProductMultipleSelected = false;
      }
      this.focusOnInput('updatingBillProductAmountInputReference');
      this.navigation.updateBillProductAmountDialogIsVisible = true;
    },

    updateBillProductAmount(){
      this.input.updatingBillProduct.forEach(updatingBillProduct => {
        updatingBillProduct.billProductAmount = parseInt(isNaN(parseInt(this.input.updatingBillProductAmount)) ? 1 : this.input.updatingBillProductAmount);
      });
      this.updateBillProduct();
      this.calculateBillResult();
      this.navigation.updateBillProductAmountDialogIsVisible = false;
    },

    openProductImageDialog(productID, productName){
      const productImageDialogRequestQuery = 
      {
        'productImageProductID': productID,
        'productName': productName
      };
      this.$root.productImageDialog.openProductImageDialog(productImageDialogRequestQuery);
    },

    deleteBillProduct(billProductProductID){
      if (this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductSelectedAction).find(currentBillProduct => currentBillProduct.billProductProductID == billProductProductID)){
        this.display.currentBillProducts = this.display.currentBillProducts.filter(currentBillProduct => !currentBillProduct.billProductSelectedAction);
      } else {
        this.display.currentBillProducts = this.display.currentBillProducts.filter(currentBillProduct => currentBillProduct.billProductProductID != billProductProductID);
      }
      this.calculateBillResult();
    },

    openInsertClientDialog(){
      const insertClientDialogRequestQuery = 
      {
        'clientType': 'Persona'
      };
      this.$root.insertClientDialog.openInsertClientDialog(insertClientDialogRequestQuery);
    },

    openClientBillDialog(){
      this.$root.clientBillDialog.openClientBillDialog();
    },

    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
      this.display.dolarExchangeRate = await this.$generalFunctions.default.methods.selectDolarExchangeRate();
    },

    async decodeWhatsappInvoice(){
      const whatsappInvoice = this.$route.query.whatsappInvoice;
      if (whatsappInvoice){
        const decodedQuery = JSON.parse(decodeURIComponent(whatsappInvoice));
        this.display.currentBillProducts = decodedQuery.whatsappInvoiceProducts;
        this.input.selectedBillType = decodedQuery.whatsappInvoiceType;
        this.input.selectedBillPaymentType = decodedQuery.whatsappInvoicePaymentMethod;
        this.input.clientSSN = decodedQuery.clientSSN;
        await this.selectClientOnBillView(false);
        this.$generalFunctions.default.methods.navigateTo('/bill')
        this.calculateBillResult();
      }
    },

    async selectClientForClientSearch(){
      this.loader.selectClientForClientSearch = true;
      const selectClientForClientSearchResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/client/functions/selectClientForClientSearch');
      if (selectClientForClientSearchResult.success){
        this.display.clientOptions = selectClientForClientSearchResult.result;
        this.loader.selectClientForClientSearch = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de clientes, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async deleteBillPaymentValidation(){
      if (this.navigation.billPaymentValidationID){
        const deleteBillPaymentValidationRequestQuery =
        {
          'billPaymentValidationID': this.navigation.billPaymentValidationID
        };
        await this.$generalFunctions.default.methods.executeHttpPostRequest('/bill/billPaymentValidation/delete', deleteBillPaymentValidationRequestQuery);
      }
    },

  },

  created() {
    window.addEventListener('beforeunload', this.deleteBillPaymentValidation)
    viewMethodCaller.on('InsertClientDialog/methods/insertClient', (params) => {
      this.input.clientSSN = params.clientSSN;
    });
  },

  async mounted(){
    await this.getLocalStorageData();
    await this.selectProductBasicInformationForBillViewByLocalityID();
    await this.selectAgentFromBillView();
    await this.selectClientForClientSearch();
    await this.decodeWhatsappInvoice();



  }

});

</script>
