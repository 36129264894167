<template>
  <v-dialog v-model="navigation.recoverAgentPasswordDialogIsVisible" :persistent="loader.recoverAgentPassword || navigation.recoverAgentPasswordIntervalView || navigation.redirectView" width="auto">
    <v-card width="600" class="recoverAgentPasswordDialogContainer">
      <div v-if="navigation.recoverAgentPasswordIntervalView" class="recoverAgentPasswordIntervalViewContainer">
        <div v-if="navigation.redirectView">
          <p>Su pin de acceso ha sido validado correctamente. Será redirigido a su perfil para que pueda cambiar su contraseña. Espere unos segundos.</p>
          <br><br>
          <v-progress-linear color="teal" height="20" :model-value="display.redirectIntervalProgress" striped></v-progress-linear>
        </div>
        <div v-else-if="navigation.timeoutView">
          <p>El pin de verificación ha expirado, por favor, intente nuevamente. Espere unos segundos.</p>
          <br><br>
          <v-progress-linear color="teal" height="20" :model-value="display.timeoutIntervalProgress" striped></v-progress-linear>
        </div>
        <div v-else>
          <p>Se ha enviado un mensaje de WhatsApp o un correo electrónico con su pin de verificación a las direcciones especificadas. No comparta su pin de verificación con nadie. El mismo tiene una expiración de cinco minutos.</p>
          <br>
          <p>Coloque el pin de verificación a continuación:</p>
          <br><br>
          <v-text-field v-model="input.recoverAgentPasswordRequestPin" label="Pin (6 dígitos)" prepend-inner-icon="mdi-dialpad" variant="solo-filled"></v-text-field>
          <br>
          <v-progress-circular :model-value="display.recoverAgentPasswordIntervalProgress / 3" :rotate="360" :size="200" :width="15" color="teal">
            <h2>{{ parseSecondsToMMSS(display.recoverAgentPasswordIntervalProgress) }}</h2>
          </v-progress-circular>
          <br>
        </div>
      </div>
      <div v-else>
        <h3 class="recoverAgentPasswordWarningLabel"><strong>¡ATENCIÓN!</strong></h3>
        <br>
        <p>La recuperación de contraseña requiere una autenticación en dos pasos con su número de teléfono</p>
        <br>
        <v-text-field v-model="input.agentPhoneNumber" @keydown="clearAgentEmailInput()" ref="agentPhoneNumberInput" label="Número de teléfono" prepend-inner-icon="mdi-phone" variant="solo-filled"></v-text-field>
        <p>o mediante su correo electrónico</p>
        <br>
        <v-text-field v-model="input.agentEmail" @keydown="clearAgentPhoneNumberInput()" ref="agentEmailInput" label="Correo electrónico" prepend-inner-icon="mdi-email" variant="solo-filled"></v-text-field>
        <br>
        <v-btn @click="recoverAgentPassword()" dark block height="50" color="info">
          <h3>SOLICITAR RECUPERACIÓN DE CONTRASEÑA</h3>
          <v-icon style="margin-left: 20px;" dark right>mdi-information-outline</v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
  
<style scoped>
  .recoverAgentPasswordDialogContainer {
    padding: 20px;
    text-align: center;
  }
  
  .recoverAgentPasswordIntervalViewContainer {
    padding: 20px;
    text-align: center;
  }

  .recoverAgentPasswordWarningLabel {
    color: var(--warningFontColor);
  }

</style>
  

<script>

export default {
  name: 'RecoverAgentPasswordDialog',
  inject: ['$generalFunctions'],

  data: () => ({

    input:
    {
      agentPhoneNumber: null,
      agentEmail: null,
      recoverAgentPasswordRequestPin: null
    },

    loader: 
    {
      recoverAgentPassword: false
    },
    
    navigation: 
    {
      recoverAgentPasswordDialogIsVisible: false,
      recoverAgentPasswordIntervalView: false,
      redirectView: false,
      timeoutView: false
    },

    display:
    {
      recoverAgentPasswordIntervalProgress: 0,
      redirectIntervalProgress: 0,
      timeoutIntervalProgress: 0
    },

    assets:
    {
    }


  }),

  watch: {
    'input.recoverAgentPasswordRequestPin'(){
      this.validateRecoverAgentPassword();
    }
  },

  methods: {
    showTimeoutView(){
      this.navigation.timeoutView = true;
      this.display.timeoutIntervalProgress = 0;
      const timeoutInterval = setInterval(async () => {
        if (this.display.timeoutIntervalProgress >= 110){
          this.closeRecoverAgentPasswordDialog();
          clearInterval(timeoutInterval);
        } else {
          this.display.timeoutIntervalProgress = this.display.timeoutIntervalProgress + 5;
        }
      }, 100);
    },

    showRedirectView(){
      this.navigation.redirectView = true;
      this.display.redirectIntervalProgress = 0;
      const redirectInterval = setInterval(async () => {
        if (this.display.redirectIntervalProgress >= 110){
          this.$generalFunctions.default.methods.navigateTo('/bill');
          this.closeRecoverAgentPasswordDialog();
          clearInterval(redirectInterval);
        } else {
          this.display.redirectIntervalProgress = this.display.redirectIntervalProgress + 5;
        }
      }, 100);
    },

    async validateRecoverAgentPassword(){
      if (this.input.recoverAgentPasswordRequestPin.length == 6){
        const validateRecoverAgentPasswordRequestQuery =
        {
          'recoverAgentPasswordRequestPin': this.input.recoverAgentPasswordRequestPin
        };
        const validateRecoverAgentPasswordResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/functions/validateRecoverPassword', validateRecoverAgentPasswordRequestQuery);
        if (validateRecoverAgentPasswordResult.success){
          if (validateRecoverAgentPasswordResult.result){
            const agentID = validateRecoverAgentPasswordResult.result.agentID;
            const agentName = validateRecoverAgentPasswordResult.result.agentName;
            const localityID = validateRecoverAgentPasswordResult.result.localityID;
            const localityName = validateRecoverAgentPasswordResult.result.localityName;
            const JWTValue = validateRecoverAgentPasswordResult.result.JWTValue;
            const JWTAgentID = validateRecoverAgentPasswordResult.result.JWTAgentID;
            localStorage.clear();
            localStorage.setItem('agentID', agentID);
            localStorage.setItem('agentName', agentName);
            localStorage.setItem('localityID', localityID);
            localStorage.setItem('localityName', localityName);
            localStorage.setItem('JWTValue', JWTValue);
            localStorage.setItem('JWTAgentID', JWTAgentID);
            this.showRedirectView();
          } else {  
            this.$root.notificationDialog.showNotificationDialog({
              'notificationDialogTitle': 'ERROR',
              'notificationDialogBody': 'El pin de recuperación de contraseña no coincide con ninguna solicitud de recuperación de contraseña, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
              'notificationDialogColor': 'error',
              'notificationDialogIsPersistent': false
            });
          }
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al validar el pin de recuperación de contraseña, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
      }
    },

    parseSecondsToMMSS(seconds){
      const MMSS = this.$generalFunctions.default.methods.parseSecondsToMMSS(seconds);
      return MMSS;
    },

    showRecoverAgentPasswordIntervalView(){
      this.navigation.recoverAgentPasswordIntervalView = true;
      this.display.recoverAgentPasswordIntervalProgress = 0;
      const recoverAgentPasswordInterval = setInterval(async () => {
        if (this.display.recoverAgentPasswordIntervalProgress >= 300){
          this.showTimeoutView();          
          clearInterval(recoverAgentPasswordInterval);
        } else {
          this.display.recoverAgentPasswordIntervalProgress = this.display.recoverAgentPasswordIntervalProgress + 1;
        }
      }, 1000);
    },

    async recoverAgentPassword(){
      this.loader.recoverAgentPassword = true;
      const recoverAgentPasswordRequestQuery =
      {
        'agentPhoneNumber': this.input.agentPhoneNumber,
        'agentEmail': this.input.agentEmail
      };
      const recoverAgentPasswordResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/functions/recoverPassword', recoverAgentPasswordRequestQuery);
      if (recoverAgentPasswordResult.success){
        if (recoverAgentPasswordResult.result){
          this.showRecoverAgentPasswordIntervalView();
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'No existe ningún agente asociado a este número de teléfono o correo electrónico, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al solicitar la recuperación de contraseña, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.recoverAgentPassword = false;
    },

    clearAgentEmailInput(){
      this.input.agentEmail = null;
    },

    clearAgentPhoneNumberInput(){
      this.input.agentPhoneNumber = null;
    },

    setInputDefaultValues(){
      this.input.agentPhoneNumber = null;
      this.input.agentEmail = null;
      this.input.recoverAgentPasswordRequestPin = null;
      this.navigation.recoverAgentPasswordIntervalView = false;
      this.navigation.redirectView = false;
      this.navigation.timeoutView = false;
      this.display.recoverAgentPasswordIntervalProgress = 0;
      this.display.redirectIntervalProgress = 0;
      this.display.timeoutIntervalProgress = 0;
    },

    focusOnAgentPhoneNumberInput(){
      const focusOnAgentPhoneNumberInputInterval = setInterval(() => {
        if (this.$refs.agentPhoneNumberInput) {
          this.$refs.agentPhoneNumberInput.focus();
          clearInterval(focusOnAgentPhoneNumberInputInterval);
        }
      }, 1);
    },

    openRecoverAgentPasswordDialog() {
      this.setInputDefaultValues();
      this.navigation.recoverAgentPasswordDialogIsVisible = true;
      this.focusOnAgentPhoneNumberInput();
    },

    closeRecoverAgentPasswordDialog() {
      this.navigation.recoverAgentPasswordDialogIsVisible = false;
      this.setInputDefaultValues();
    }
  },

  mounted(){
  }
}
</script>