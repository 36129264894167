<template>
  <v-dialog v-model="navigation.imageDialogIsVisible" width="auto">
    <v-card width="900">
      <img :src="display.imageDialogFile">
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ImageDialog",

  data: () => ({
    navigation: 
    {
      imageDialogIsVisible: false
    },

    display: 
    {
      imageDialogFile: null
    }

  }),

  methods: {
    setDefaultValues(){
      this.navigation.imageDialogIsVisible = false;
      this.display.imageDialogFile = null;
    },

    closeImageDialog(){
      this.setDefaultValues();
    },

    openImageDialog(imageDialogData) {
      this.display.imageDialogFile = imageDialogData.imageDialogFile;
      this.navigation.imageDialogIsVisible = true
    }
  }
}
</script>