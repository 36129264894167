<template>
  <v-dialog v-model="navigation.productInAndOutProductsDialogIsVisible" :persistent="false" width="auto">
    <v-card style="padding: 20px;" elevation="3" width="800">
      <div style="background-color: #e9e8e8; padding: 20px;">
        <v-data-table-virtual :headers="display.productHeaders" :items="display.productInAndOut.productInAndOutProducts">

          <template v-slot:item.productInAndOutProductAmount="{ item }">
            <v-chip v-if="display.productInAndOut.productInAndOutType == 'Entrada'" color="#97cc91" variant="flat" style="margin: 0px 5px 0px 5px;">
              <p style="color: white;">+ {{ item.productInAndOutProductAmount }}</p>
            </v-chip>
            <v-chip v-else color="#ff6e69" variant="flat" style="margin: 0px 5px 0px 5px;">
              <p style="color: white;">- {{ item.productInAndOutProductAmount }}</p>
            </v-chip>
          </template>

          <template v-slot:item.productInAndOutProductResultAmount="{ item }">
            <p v-if="display.productInAndOut.productInAndOutType == 'Entrada'">{{ item.productInAndOutProductCurrentAmount + item.productInAndOutProductAmount }}</p>
            <p v-else>{{ item.productInAndOutProductCurrentAmount - item.productInAndOutProductAmount }}</p>
          </template>

          <template v-slot:item.productInAndOutProductNote="{ item }">
            {{ item.productInAndOutProductNote || 'Sin nota' }}
          </template>


          

        </v-data-table-virtual>
      </div>
    </v-card>
  </v-dialog>
  <div style="margin: 2% 3% 0% 3%;">
    <v-card style="padding: 20px; background-color: #e9e8e8;" elevation="3">
      <v-expansion-panels v-model="navigation.searchVisible">
        <v-expansion-panel value="searchVisible">
          <v-expansion-panel-title>
            <p style="font-size: large;">Búsqueda de movimientos de producto</p>
            <template v-slot:actions>
              <v-icon icon="mdi-magnify"></v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <br>
            <div style="display: flex;">
              <div style="width: 49.5%; margin-right: 0.5%;">
                <v-select v-model="input.selectedProductInAndOutGeneratedLocalityID" :items="display.localityOptions" :loading="loader.selectLocalityIDAndLocalityNameByLocalityIsActive" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive" item-title="localityName" item-value="localityID" append-inner-icon="mdi-store" label="Filtrar por localidad generadora" variant="solo-filled"></v-select>
                <v-select v-model="input.selectedProductInAndOutAgentID" :items="display.agentOptions" :loading="loader.selectAgentByAgentLocalityIDAndByAgentIsActive" :disabled="loader.selectAgentByAgentLocalityIDAndByAgentIsActive" item-title="agentName" item-value="agentID" append-inner-icon="mdi-account" label="Filtrar por agente generador" variant="solo-filled"></v-select>
                <v-select v-model="input.selectedProductInAndOutLocalityID" :items="display.localityOptions" :loading="loader.selectLocalityIDAndLocalityNameByLocalityIsActive" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive" item-title="localityName" item-value="localityID" append-inner-icon="mdi-store" label="Filtrar por localidad afectada" variant="solo-filled"></v-select>          
              </div>
              <div style="width: 49.5%; margin-left: 0.5%;">
                <v-text-field v-model="display.startDateFormatted" @click="navigation.startDatePickerIsVisible = true" readonly label="Filtrar por fecha inicial" append-inner-icon="mdi-calendar"></v-text-field>
                <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
                  <v-card style="display: flex;">
                    <v-date-picker v-model="input.startDate" hide-header color="primary" width="100%"></v-date-picker>
                    <v-chip @click="input.startDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
                  </v-card>
                </v-dialog>
                <v-text-field v-model="display.endDateFormatted" @click="navigation.endDatePickerIsVisible = true" readonly label="Filtrar por fecha final" append-inner-icon="mdi-calendar"></v-text-field>
                <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
                  <v-card>
                    <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
                    <v-chip @click="input.endDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
                  </v-card>
                </v-dialog>
                <v-select v-model="input.selectedProductInAndOutType" :items="display.productInAndOutTypeOptions" item-title="title" item-value="value" append-inner-icon="mdi-swap-horizontal" label="Filtrar por tipo de movimiento" variant="solo-filled"></v-select>
              </div>
            </div>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-title>
                  Búsqueda avanzada por producto
                  <template v-slot:actions>
                    <v-icon icon="mdi-magnify"></v-icon>
                  </template>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <br>
                  <v-autocomplete v-model="input.selectedProductID" :items="display.productOptions" :loading="loader.selectProductBasicBasicInformationForBillsView" :disabled="loader.selectProductBasicBasicInformationForBillsView" ref="selectedProductIDInput" item-title="productAutocompleteTitle" item-value="productID" append-inner-icon="mdi-alpha-a-box-outline" label="Filtrar por producto" variant="solo-filled"></v-autocomplete>
                  <div style="display: flex;">
                    <div style="width: 49.5%; margin-right: 0.5%;">
                      <v-autocomplete v-model="input.selectedProductCategory" :items="display.productCategoryOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productCategoryName" item-value="productCategoryID" append-inner-icon="mdi-cog" label="Filtrar por categoría del producto" variant="solo-filled"></v-autocomplete>
                      <v-autocomplete v-model="input.selectedProductBrand" :items="display.productBrandOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productBrandName" item-value="productBrandID" append-inner-icon="mdi-bookshelf" label="Filtrar por marca del producto" variant="solo-filled"></v-autocomplete>
                      <v-autocomplete v-model="input.selectedProductModel" :items="display.productModelOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productModelName" item-value="productModelID" append-inner-icon="mdi-source-branch" label="Filtrar por modelo del producto" variant="solo-filled"></v-autocomplete>
                    </div>
                    <div style="width: 49.5%; margin-left: 0.5%;">
                      <v-autocomplete v-model="input.selectedProductFlavor" :items="display.productFlavorOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productFlavorName" item-value="productFlavorID" append-inner-icon="mdi-water" label="Filtrar por sabor del producto" variant="solo-filled"></v-autocomplete>
                      <v-autocomplete v-model="input.selectedProductNicotineLevel" :items="display.productNicotineLevelOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productNicotineLevelName" item-value="productNicotineLevelID" append-inner-icon="mdi-weather-cloudy" label="Filtrar por graduación de nicotina del producto" variant="solo-filled"></v-autocomplete>
                      <v-autocomplete v-model="input.selectedProductSize" :items="display.productSizeOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productSizeName" item-value="productSizeID" append-inner-icon="mdi-ruler" label="Filtrar por tamaño del producto" variant="solo-filled"></v-autocomplete>
                    </div>
                  </div>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
            <br><br>
            <div v-if="loader.selectProductInAndOutForProductInAndOutView" style="text-align: center;">
              <br>
              <v-progress-circular color="info" indeterminate></v-progress-circular>
            </div>
            <div v-else>
              <v-btn @click="selectProductInAndOutForProductInAndOutView()" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive || loader.selectAgentByAgentLocalityIDAndByAgentIsActive || loader.selectProductBasicBasicInformationForBillsView || loader.selectProductInAndOutForProductInAndOutView" dark block height="38" color="primary">
                <h3>BUSCAR</h3>
                <v-icon style="margin-left: 20px;" dark right>mdi-magnify</v-icon>
              </v-btn>
              <br>
              <v-btn @click="setDefaultValues()" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive || loader.selectAgentByAgentLocalityIDAndByAgentIsActive || loader.selectProductBasicBasicInformationForBillsView || loader.selectProductBasicBasicInformationForBillsView" dark block height="38" color="#ffd978">
                <h3>LIMPIAR</h3>
                <v-icon style="margin-left: 20px;" dark right>mdi-backspace</v-icon>
              </v-btn>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <br><br>
    <v-card v-if="display.productInAndOutRows.length != 0" style="padding: 20px; background-color: #e9e8e8;" elevation="3">
      <div style="max-height: 700px; overflow-y: auto;">
        <v-data-table-virtual :headers="display.productInAndOutHeaders" :items="display.productInAndOutRows">
          <template v-slot:item.generatedLocalityName="{ item }">
            <v-chip :color="item.generatedLocalityColor" variant="flat" style="margin: 0px 5px 0px 5px;">
              <p :style="{color: item.generatedLocalityFontColor}">{{ item.generatedLocalityName }}</p>
            </v-chip>
          </template>
          <template v-slot:item.agentName="{ item }">
            <v-chip :color="item.agentColor" variant="flat" style="margin: 0px 5px 0px 5px;">
              <p :style="{color: item.agentFontColor}">{{ item.agentName }}</p>
            </v-chip>
          </template>
          <template v-slot:item.affectedLocalityName="{ item }">
            <v-chip :color="item.affectedLocalityColor" variant="flat" style="margin: 0px 5px 0px 5px;">
              <p :style="{color: item.affectedLocalityFontColor}">{{ item.affectedLocalityName }}</p>
            </v-chip>
          </template>
          <template v-slot:item.productInAndOutDatetime="{ item }">
            {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.productInAndOutDatetime) }}
          </template>
          <template v-slot:item.productInAndOutType="{ item }">
            <v-chip :color="item.productInAndOutType == 'Entrada' ? '#97cc91' : '#ff6e69'" variant="flat" style="margin: 0px 5px 0px 5px;">
              <p style="color: white">{{ item.productInAndOutType }}</p>
            </v-chip>
          </template>
          <template v-slot:item.productInAndOutProducts="{ item }">
            <v-icon @click="openProductInAndOutProductsDialog(item)" color="info" dark right>mdi-magnify</v-icon>
          </template>
          <template v-slot:item.productInAndOutActions="{ item }">
            <div v-if="item.productInAndOutActions" style="text-align: center;">
              <v-progress-circular color="info" indeterminate></v-progress-circular>
            </div>
            <v-icon v-else @click="generateReportOnProductInAndOutView(item)" color="success" dark right>mdi-printer</v-icon>
          </template>
        </v-data-table-virtual>
      </div>
    </v-card>
    <br><br>
  </div>
</template>

<style scoped>
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProductInAndOutView',
  inject: ['$generalFunctions'],

  data: () => ({
    input: 
    {
      selectedProductInAndOutGeneratedLocalityID: null,
      selectedProductInAndOutAgentID: null,
      selectedProductInAndOutLocalityID: null,
      startDate: null,
      endDate: null,
      selectedProductInAndOutType: null,
      selectedProductID: null,
      selectedProductCategory: null,
      selectedProductBrand: null,
      selectedProductModel: null,
      selectedProductFlavor: null,
      selectedProductNicotineLevel: null,
      selectedProductSize: null
    },

    display: 
    {
      localityOptions: [],
      agentOptions: [],
      productInAndOutTypeOptions: 
      [
        {value: null, title: 'Todos los tipos de movimiento'},
        {value: 'Entrada', title: 'Entrada'},
        {value: 'Salida', title: 'Salida'}
      ],
      startDateFormatted: null,
      endDateFormatted: null,
      productOptions: [],
      productCategoryOptions: [],
      productModelOptions: [],
      productBrandOptions: [],
      productFlavorOptions: [],
      productNicotineLevelOptions: [],
      productSizeOptions: [],
      productInAndOutHeaders: 
      [
        {key: 'productInAndOutID', title: 'CÓDIGO DE MOVIMIENTO'},
        {key: 'productInAndOutVerifiedTransactionID', title: 'CÓDIGO DE VERIFICACIÓN'},
        {key: 'generatedLocalityName', title: 'LOCALIDAD GENERADORA'},
        {key: 'agentName', title: 'AGENTE GENERADOR'},
        {key: 'affectedLocalityName', title: 'LOCALIDAD AFECTADA'},
        {key: 'productInAndOutDatetime', title: 'FECHA'},
        {key: 'productInAndOutType', title: 'TIPO'},
        {key: 'productInAndOutReason', title: 'MOTIVO'},
        {key: 'productInAndOutNote', title: 'NOTA'},
        {key: 'productInAndOutProducts', title: 'PRODUCTOS'},
        {key: 'productInAndOutActions', title: 'REPORTE'}
      ],
      productHeaders: 
      [
        {key: 'productSKU', title: 'SKU'},
        {key: 'productName', title: 'NOMBRE'},
        {key: 'productInAndOutProductCurrentAmount', title: 'CANTIDAD ANTERIOR'},
        {key: 'productInAndOutProductAmount', title: 'CANTIDAD MODIFICADA'},
        {key: 'productInAndOutProductResultAmount', title: 'TOTAL'},
        {key: 'productInAndOutProductNote', title: 'NOTA'}
      ],
      productInAndOutRows: [],
      productInAndOut: [],
      PDFDocumentFile: null,
      PDFDocumentName: null,
      excelDocumentFile: null,
      excelDocumentName: null
    },

    navigation: 
    {
      searchVisible: 'searchVisible',
      startDatePickerIsVisible: false,
      endDatePickerIsVisible: false,
      productInAndOutProductsDialogIsVisible: false
    },

    loader:
    {
      selectLocalityIDAndLocalityName: false,
      selectProductSearchParameters: false,
      selectProductBasicBasicInformationForBillsView: false,
      selectProductForForProductInAndOutView: false,
      selectProductInAndOutForProductInAndOutView: false
    },

    accessCredential: {},

    localStorage:
    {
      localityID: null,
      agentID: null
    }
  }),

  watch: {
    'input.selectedProductInAndOutGeneratedLocalityID': async function(){
      this.selectAgentByAgentLocalityIDAndByAgentIsActive();
    },
    'input.startDate': async function() {
      this.input.startDate ? this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB') : this.display.startDateFormatted = null;
      this.navigation.startDatePickerIsVisible = false;
    },
    'input.endDate': async function() {
      this.input.endDate ? this.display.endDateFormatted = new Date(this.input.endDate).toLocaleDateString('en-GB') : this.display.endDateFormatted = null;
      this.navigation.endDatePickerIsVisible = false;
    }
  },

  methods: {
    async generateReportOnProductInAndOutView(productInAndOut){
      productInAndOut.productInAndOutActions = true;
      const generateReportOnProductInAndOutViewRequestQuery = 
      {
        'productInAndOutID': productInAndOut.productInAndOutID
      };
      const generateReportOnProductInAndOutViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productInAndOut/functions/generateReportOnProductInAndOutView', generateReportOnProductInAndOutViewRequestQuery);
      if (generateReportOnProductInAndOutViewResult.success){
        if (generateReportOnProductInAndOutViewResult.result.PDFDocument.success){
          this.display.PDFDocumentFile = generateReportOnProductInAndOutViewResult.result.PDFDocument.result.documentFile.data;
          this.display.PDFDocumentName = generateReportOnProductInAndOutViewResult.result.PDFDocument.result.documentName;
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el documento en PDF, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
        if (generateReportOnProductInAndOutViewResult.result.excelDocument.success){
          this.display.excelDocumentFile = generateReportOnProductInAndOutViewResult.result.excelDocument.result.documentFile.data;
          this.display.excelDocumentName = generateReportOnProductInAndOutViewResult.result.excelDocument.result.documentName;
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el documento en Excel, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': this.display.PDFDocumentFile,
          'printablePDFDocumentName': this.display.PDFDocumentName,
          'printableExcelDocumentFile': this.display.excelDocumentFile,
          'printableExcelDocumentName': this.display.excelDocumentName
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': `Ha ocurrido un error inesperado al generar el reporte de la ${productInAndOut.productInAndOutType.toLowerCase()} de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema`,
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      productInAndOut.productInAndOutActions = false;
    },

    async selectProductInAndOutForProductInAndOutView(){
      this.loader.selectProductInAndOutForProductInAndOutView = true;
      this.display.productInAndOutRows.length = 0;
      const selectProductInAndOutForProductInAndOutViewRequestQuery = 
      {
        'productInAndOutGeneratedLocalityID': this.input.selectedProductInAndOutGeneratedLocalityID,
        'productInAndOutLocalityID': this.input.selectedProductInAndOutLocalityID,
        'productInAndOutAgentID': this.input.selectedProductInAndOutAgentID,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate,
        'productInAndOutType': this.input.selectedProductInAndOutType,
        'productID': this.input.selectedProductID,
        'productCategoryID': this.input.selectedProductCategory,
        'productBrandID': this.input.selectedProductBrand,
        'productModelID': this.input.selectedProductCategory,
        'productFlavorID': this.input.selectedProductFlavor,
        'productNicotineLevelID': this.input.selectedProductNicotineLevel,
        'productSizeID': this.input.selectedProductSize
      };
      const selectProductInAndOutForProductInAndOutViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productInAndOut/functions/selectProductInAndOutForProductInAndOutView', selectProductInAndOutForProductInAndOutViewRequestQuery);
      if (selectProductInAndOutForProductInAndOutViewResult.success){
        if (selectProductInAndOutForProductInAndOutViewResult.result.length > 0){
          this.display.productInAndOutRows = selectProductInAndOutForProductInAndOutViewResult.result.map(productInAndOutRow => {
            return {
              ...productInAndOutRow,
              'productInAndOutActions': false
            }
          });
          this.navigation.searchVisible = '';
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ATENCIÓN',
            'notificationDialogBody': 'No se ha encontrado ningún movimiento de productos con los filtros seleccionados, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los movimientos de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectProductInAndOutForProductInAndOutView = false;
    },

    async selectLocalityIDAndLocalityNameByLocalityIsActive(){
      this.loader.selectLocalityIDAndLocalityNameByLocalityIsActive = true;
      const selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery = {'localityIsActive': true};
      const selectLocalityIDAndLocalityNameByLocalityIsActiveResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityNameByLocalityIsActive', selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery);
      if (selectLocalityIDAndLocalityNameByLocalityIsActiveResult.success){
        this.display.localityOptions = 
        [
          {'localityID': null, 'localityName': 'Todas las localidades'},
          ...selectLocalityIDAndLocalityNameByLocalityIsActiveResult.result
        ];
        this.loader.selectLocalityIDAndLocalityNameByLocalityIsActive = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectAgentByAgentLocalityIDAndByAgentIsActive(){
      this.loader.selectAgentByAgentLocalityIDAndByAgentIsActive = true;
      this.input.selectedProductInAndOutAgentID = null;
      const selectAgentByAgentLocalityIDAndByAgentIsActiveRequestQuery = {'agentLocalityID': this.input.selectedProductInAndOutGeneratedLocalityID, 'agentIsActive': true};
      const selectAgentByAgentLocalityIDAndByAgentIsActiveResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/select/byAgentLocalityIDAndByAgentIsActive', selectAgentByAgentLocalityIDAndByAgentIsActiveRequestQuery);
      if (selectAgentByAgentLocalityIDAndByAgentIsActiveResult.success){
        this.display.agentOptions =
        [
          {'agentID': null, 'agentName': 'Todos los agentes'},
          ...selectAgentByAgentLocalityIDAndByAgentIsActiveResult.result
        ];
        this.loader.selectAgentByAgentLocalityIDAndByAgentIsActive = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los facturadores, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductBasicBasicInformationForBillsView(){
      this.loader.selectProductBasicBasicInformationForBillsView = true;
      const selectProductBasicBasicInformationForBillsViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductBasicBasicInformationForBillsView');
      if (selectProductBasicBasicInformationForBillsViewResult.success){
        this.display.productOptions = selectProductBasicBasicInformationForBillsViewResult.result.map(productOption => ({
          'productID': productOption.i,
          'productSKU': productOption.s,
          'productName': productOption.n,
          'productAutocompleteTitle': productOption.s + ' - ' + productOption.n
        }));
        this.loader.selectProductBasicBasicInformationForBillsView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los facturadores, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductSearchParameters(){
      this.loader.selectProductSearchParameters = true;
      const selectProductSearchParametersResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductSearchParameters');
      if (selectProductSearchParametersResult.success){
        this.display.productCategoryOptions = [{'productCategoryName': 'Todas las categorías', 'productCategoryID': null}, ...selectProductSearchParametersResult.result.productCategory];
        this.display.productBrandOptions = [{'productBrandName': 'Todas las marcas', 'productBrandID': null}, ...selectProductSearchParametersResult.result.productBrand];
        this.display.productModelOptions = [{'productModelName': 'Todos los modelos', 'productModelID': null}, ...selectProductSearchParametersResult.result.productModel];
        this.display.productFlavorOptions = [{'productFlavorName': 'Todos los sabores', 'productFlavorID': null}, ...selectProductSearchParametersResult.result.productFlavor];
        this.display.productNicotineLevelOptions = [{'productNicotineLevelName': 'Todas las graduaciones de nicotina', 'productNicotineLevelID': null}, ...selectProductSearchParametersResult.result.productNicotineLevel];
        this.display.productSizeOptions = [{'productSizeName': 'Todos los tamaños', 'productSizeID': null}, ...selectProductSearchParametersResult.result.productSize];
        this.loader.selectProductSearchParameters = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de características de los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    openProductInAndOutProductsDialog(productInAndOut){
      this.display.productInAndOut = productInAndOut;
      this.navigation.productInAndOutProductsDialogIsVisible = true;
    },

    setDefaultValues(){
      this.input.selectedProductInAndOutGeneratedLocalityID = null;
      this.input.selectedProductInAndOutAgentID = null;
      this.input.selectedProductInAndOutLocalityID = null;
      this.input.startDate = null;
      this.input.endDate = null;
      this.input.selectedProductInAndOutType = null;
      this.input.selectedProductID = null;
      this.input.selectedProductCategory = null;
      this.input.selectedProductBrand = null;
      this.input.selectedProductModel = null;
      this.input.selectedProductFlavor = null;
      this.input.selectedProductNicotineLevel = null;
      this.input.selectedProductSize = null;
      this.display.productInAndOutRows.length = 0;
      this.display.productInAndOut.length = 0;
      this.navigation.startDatePickerIsVisible = false;
      this.navigation.endDatePickerIsVisible = false;
      this.navigation.productInAndOutProductsDialogIsVisible = false;
      this.loader.selectLocalityIDAndLocalityName = false;
      this.loader.selectProductForForProductInAndOutView = false;
      this.loader.selectProductInAndOutForProductInAndOutView = false;
    },

    async getLocalStorageData(){
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    },
  },

  async mounted(){
    this.setDefaultValues();
    await this.getLocalStorageData();
    await this.selectLocalityIDAndLocalityNameByLocalityIsActive();
    await this.selectAgentByAgentLocalityIDAndByAgentIsActive();
    await this.selectProductBasicBasicInformationForBillsView();
    await this.selectProductSearchParameters();
  }
});
</script>