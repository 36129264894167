<template>
  <div style="margin: 2% 3% 0% 3%;">
    <h1>Cuentas por cobrar</h1>
    <br><br>
    <v-card elevation="3" style="padding: 20px; background-color: #f5f5f5; max-height: 700px; overflow-y: auto;">
      <v-data-table-virtual :headers="display.pendingPaymentHeaders" :items="display.pendingPaymentRows">
        <template v-slot:item="row">
          <tr @click="$generalFunctions.default.methods.navigateTo(`/contact/${row.item.contactID}`)" :style="getPendingPaymentRowStyle(row.item)">
            <td>
              {{ row.item.contactName }}
            </td>
            <td>
              {{ row.item.contactSSN }}
            </td>
            <td>
              <p><strong>Monto: </strong>₡ {{ row.item.contactTotalDebtAmount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
              <p><strong>Límite: </strong>₡ {{ row.item.contactCreditAmount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
              <p><strong>Días máximos: </strong>{{ row.item.contactCreditDay }}</p>
            </td>
            <td>
              <div v-if="row.item.contactLastBill">
                <p><strong>Fecha: </strong>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(row.item.contactLastBill) }}</p>
                <p>Hace {{ $generalFunctions.default.methods.getCurrentDatetimeAndDatetimeOffsetInDays(row.item.contactLastBill) }} días</p>
              </div>
              <p v-else>Sin compras registradas</p>
            </td>
            <td>
              <div v-if="row.item.contactLastDebtPayment">
                <p><strong>Fecha: </strong>{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(row.item.contactLastDebtPayment) }}</p>
                <p>Hace {{ $generalFunctions.default.methods.getCurrentDatetimeAndDatetimeOffsetInDays(row.item.contactLastDebtPayment) }} días</p>
              </div>
              <p v-else>Sin abonos registrados</p>     
            </td>
            <td>
              <v-chip :color="row.item.agentColor" variant="flat">
                <p :style="{color: row.item.agentFontColor}">{{ row.item.agentName }}</p>
              </v-chip>
            </td>
          </tr>
        </template>
      </v-data-table-virtual>  
    </v-card>
  </div>
</template>

<style scoped>
</style>


<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PendingPaymentsView',

  inject: ['$generalFunctions'],

  data: () => ({
    input: 
    {
    },

    display: 
    {
      pendingPaymentHeaders:
      [
        {key: 'contactName', title: 'CONTACTO'},
        {key: 'contactSSN', title: 'DOCUMENTO DE IDENTIDAD'},
        {key: 'contactTotalDebtAmount', title: 'DEUDA'},
        {key: 'contactLastBill', title: 'ÚLTIMA COMPRA'},
        {key: 'contactLastDebtPayment', title: 'ÚLTIMO ABONO'},
        {key: 'agentName', title: 'AGENTE ENCARGADO'}
      ],
      pendingPaymentRows: []
    },

    navigation: 
    {
    },

    loader:
    {
      selectContactFromPendingPaymentsView: false
    },

    localStorage: 
    {
    },

    assets: 
    {
    },

    accessCredential: {}
  }),

  watch: {
    
   
  },

  methods: {

    getPendingPaymentRowStyle(pendingPayment){
      if (pendingPayment.contactLastBill){
        const contactLastBillDatetime = pendingPayment.contactLastBill.billCreationDatetime;
        if (pendingPayment.contactLastDebtPayment){
          const contactLastDebtPaymentDatetime = pendingPayment.contactLastDebtPayment.debtPaymentDatetime;          
          if (((new Date(contactLastBillDatetime).getTime() - new Date(contactLastDebtPaymentDatetime).getTime()) / (1000*60*60*24)) >= pendingPayment.contactCreditDay){
            return 'cursor: pointer; background-color: #de929f;';
          }
        } else {
          if (this.$generalFunctions.default.methods.getCurrentDatetimeAndDatetimeOffsetInDays(contactLastBillDatetime) >= pendingPayment.contactCreditDay){
            return 'cursor: pointer; background-color: #de929f;';
          }
        }
      }
      return 'cursor: pointer;';
    },
  

    async selectContactFromPendingPaymentsView(){
      this.loader.selectContactFromPendingPaymentsView = true;
      const selectContactFromPendingPaymentsViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/contact/functions/selectContactFromPendingPaymentsView');
      if (selectContactFromPendingPaymentsViewResult.success){
        this.display.pendingPaymentRows = selectContactFromPendingPaymentsViewResult.result;
        this.loader.selectContactFromPendingPaymentsView = false; 
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la información de las cuentas por cobrar, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async setDefaultValues(){
      this.display.pendingPaymentRows.length = 0;
      this.loader.selectContactFromPendingPaymentsView = false;
    }

  },

  async mounted(){
    await this.setDefaultValues();
    await this.selectContactFromPendingPaymentsView();
  }

});
</script>
