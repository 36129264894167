<template>
  <v-dialog v-model="navigation.insertMainProductDialogIsVisible" :persistent="true" width="auto">
    <v-card width="1500" style="padding: 20px;">
      <div v-if="loader.selectMainProductFromInsertMainProductDialog || loader.insertMainProduct" style="text-align: center;">
        <br>
        <v-progress-circular color="info" indeterminate></v-progress-circular>
        <br><br>
      </div>
      <div v-else>
        <div style="display: flex;">
          <div style="width: 58.5%; margin-right: 1.5%;">
            <v-card style="padding: 20px 20px 0px 20px;" elevation="2">
              <h3>Información general:</h3>
              <br>
              <v-select v-model="input.mainProductCABYS" :items="display.productCABYSOptions" prepend-inner-icon="mdi-barcode" label="CABYS del producto madre" variant="solo-filled"></v-select>
              <v-text-field v-model="input.mainProductName" prepend-inner-icon="mdi-package-variant-closed" label="Nombre del producto madre" variant="solo-filled"></v-text-field>
              <v-textarea v-model="input.mainProductDescription" rows="10" prepend-inner-icon="mdi-text-box-outline" label="Descripción (página web) producto madre" variant="solo-filled"></v-textarea>
              <v-textarea v-model="input.mainProductShortDescription" rows="6" prepend-inner-icon="mdi-text-box-outline" label="Descripción (call center) del producto madre" variant="solo-filled"></v-textarea>
            </v-card>
            <br>
            <v-card style="padding: 20px 20px 0px 20px;" elevation="2">
              <h3>Información monetaria:</h3>
              <br>
              <v-text-field v-model="input.mainProductCost" label="Precio de costo del producto madre" type="number" prepend-inner-icon="mdi-cash-plus" variant="solo-filled" hide-spin-buttons></v-text-field>
              <v-select v-model="input.mainProductTaxMargin" :items="display.productTaxMarginOptions" label="Márgen de impuesto del producto madre" type="number" prepend-inner-icon="mdi-lock-percent" variant="solo-filled" hide-spin-buttons></v-select>
              <v-text-field v-model="input.mainProductPrice" label="Precio de venta del producto madre" type="number" prepend-inner-icon="mdi-cash-minus" variant="solo-filled" hide-spin-buttons></v-text-field>
              <v-text-field v-model="input.mainProductMaxDiscountMargin" label="Descuento porcentual máximo del producto madre" type="number" prepend-inner-icon="mdi-sack-percent" variant="solo-filled" hide-spin-buttons></v-text-field>
            </v-card>
          </div>
          <div style="width: 40%;">
            <v-card style="padding: 20px 20px 0px 20px;" elevation="2">
              <h3>Categorización:</h3>
              <br>
              <v-autocomplete v-model="input.selectedProductCategory" :items="display.productCategoryOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productCategoryName" item-value="productCategoryID" append-inner-icon="mdi-cog" label="Categoría del producto (opcional)" variant="solo-filled"></v-autocomplete>
              <v-autocomplete @click:append-inner="openInsertProductSearchParameterDialog('proveedor', 'El')" v-model="input.selectedProductProvider" :items="display.productProviderOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productProviderName" item-value="productProviderID" append-inner-icon="mdi-briefcase" label="Proveedor del producto (opcional)" variant="solo-filled"></v-autocomplete>
              <v-autocomplete @click:append-inner="openInsertProductSearchParameterDialog('marca', 'La')" v-model="input.selectedProductBrand" :items="display.productBrandOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productBrandName" item-value="productBrandID" append-inner-icon="mdi-bookshelf" label="Marca del producto madre (opcional)" variant="solo-filled"></v-autocomplete>
              <v-autocomplete @click:append-inner="openInsertProductSearchParameterDialog('modelo', 'El')" v-model="input.selectedProductModel" :items="display.productModelOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productModelName" item-value="productModelID" append-inner-icon="mdi-source-branch" label="Modelo del producto madre (opcional)" variant="solo-filled"></v-autocomplete>
              <v-autocomplete @click:append-inner="openInsertProductSearchParameterDialog('sabor', 'El')" v-model="input.selectedProductFlavor" :items="display.productFlavorOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productFlavorName" item-value="productFlavorID" append-inner-icon="mdi-water" label="Sabor del producto madre (opcional)" variant="solo-filled"></v-autocomplete>
              <v-autocomplete @click:append-inner="openInsertProductSearchParameterDialog('graduación de nicotina', 'La')" v-model="input.selectedProductNicotineLevel" :items="display.productNicotineLevelOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productNicotineLevelName" item-value="productNicotineLevelID" append-inner-icon="mdi-smoke" label="Graduación de nicotina del producto madre (opcional)" variant="solo-filled"></v-autocomplete>
              <v-autocomplete @click:append-inner="openInsertProductSearchParameterDialog('tamaño', 'El')" v-model="input.selectedProductSize" :items="display.productSizeOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productSizeName" item-value="productSizeID" append-inner-icon="mdi-ruler" label="Tamaño del producto madre (opcional)" variant="solo-filled"></v-autocomplete>
              <v-autocomplete @click:append-inner="openInsertProductSearchParameterDialog('tag', 'El')" v-model="input.selectedProductTagIDS" :items="display.productTagOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" multiple chips item-title="productTagName" item-value="productTagID" append-inner-icon="mdi-tag" label="Tags del producto madre (opcional)" variant="solo-filled"></v-autocomplete>
            </v-card>
            <br>
            <v-card style="padding: 20px 20px 0px 20px;" elevation="2">
              <h3>Configuración:</h3>
              <br>
              <v-autocomplete v-model="input.selectedProductLocation" :items="display.productLocationOptions" append-inner-icon="mdi-library-shelves" label="Ubicación en bodega del producto" variant="solo-filled"></v-autocomplete>
              <v-switch v-model="input.mainProductIsReplacedDaily" color="info" label="El producto madre se repone diariamente" hide-details></v-switch>
              <v-switch v-model="input.mainProductIsVisible" color="warning" label="El producto madre se muestra en línea" hide-details></v-switch>
              <v-switch v-model="input.mainProductIsActive" color="success" label="El producto madre está activo" hide-details></v-switch>
            </v-card>
            <br>
            <v-card style="padding: 20px 20px 0px 20px;" elevation="2">
              <h3>Imagen:</h3>
              <br>
              <input @change="processImage()" type="file" accept="image/*" ref="uploadMainProductImage" id="uploadMainProductImage" style="display: none;">  
              <img v-if="input.mainProductImage == null" @click="uploadImage()" :src="assets.placeholderImage" style="cursor: pointer; width: 42%;">
              <img v-else @click="uploadImage()" :src="input.mainProductImage" style="cursor: pointer; width: 42%;">
              <br><br>
            </v-card>
          </div>
        </div>
        <br><br><br><br>
        <v-btn v-if="navigation.mainProductID" @click="updateMainProduct()" dark block height="38" color="success">
          <h3>ACTUALIZAR PRODUCTO MADRE</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-check-circle-outline</v-icon>
        </v-btn>
        <v-btn v-else @click="insertMainProduct()" dark block height="38" color="success">
          <h3>CREAR PRODUCTO MADRE</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-check-circle-outline</v-icon>
        </v-btn>
        <br>
        <v-btn @click="setDefaultValues()" dark block height="38" color="error">
          <h3>CANCELAR</h3>
          <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
    
<style scoped>
</style>
  
<script>
import { viewMethodCaller } from '../viewMethodCaller.js';

export default {
  name: 'InsertMainProductDialog',
  inject: ['$generalFunctions'],

  data: () => ({

    input:
    {
      mainProductCABYS: null,
      mainProductName: null,
      selectedProductCategory: null,
      selectedProductProvider: null,
      selectedProductBrand: null,
      selectedProductModel: null,
      selectedProductFlavor: null,
      selectedProductNicotineLevel: null,
      selectedProductSize: null,
      selectedProductTagIDS: [],
      mainProductDescription: null,
      mainProductShortDescription: null,
      mainProductCost: null,
      mainProductPrice: null,
      mainProductTaxMargin: 13,
      mainProductMaxDiscountMargin: 25,
      selectedProductLocation: null,
      mainProductIsActive: true,
      mainProductIsReplacedDaily: true,
      mainProductIsVisible: true,
      mainProductImage: null
    },

    loader: 
    {
      selectProductSearchParameters: false,
      selectMainProductFromInsertMainProductDialog: false,
      insertMainProduct: false
    },
    
    navigation: 
    {
      insertMainProductDialogIsVisible: false,
      mainProductID: null
    },

    display:
    {
      productCABYSOptions: 
      [
        {value: 12345, title: 'Vaporizadores'},
        {value: 67890, title: 'Productos herb'},
        {value: 1122, title: 'Tabaco'}
      ],
      productTaxMarginOptions: 
      [
        {value: 0, title: '0% (excento)'},
        {value: 2, title: '2%'},
        {value: 5, title: '5%'},
        {value: 13, title: '13%'}
      ],
      productLocationOptions: ['A', 'B', 'C1', 'C2', 'D1', 'D2', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q'],
      productCategoryOptions: [],
      productProviderOptions: [],
      productBrandOptions: [],
      productModelOptions: [],
      productFlavorOptions: [],
      productNicotineLevelOptions: [],
      productSizeOptions: [],
      productTagOptions: []
    },

    assets:
    {
      placeholderImage: null
    }

  }),

  watch: {
    'input.selectedProductBrand'(){
      this.composeProductName();
    },
    'input.selectedProductModel'(){
      this.composeProductName();
    },

    'input.selectedProductFlavor'(){
      this.composeProductName();
    },

    'input.selectedProductNicotineLevel'(){
      this.composeProductName();
    },

    'input.selectedProductSize'(){
      this.composeProductName();
    },
  },

  methods: {
    validateInput(){
      const regularExpressionChecker = /\S/;
      const positiveDecimalNumberChecker = /^(?:0|(?:[1-9]\d*)|(?:0?\.\d*[1-9]\d*))$/;
      const positiveDecimalMarginNumberChecker = /^(?:100(?:\.0{1,2})?|(?:[1-9]?\d(?:\.\d{1,2})?)|(?:0?\.\d{1,2}))$/;
      if (this.input.mainProductCABYS == null){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Seleccione el CABYS del producto madre, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.mainProductName == null || !regularExpressionChecker.test(this.input.mainProductName)){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque el nombre del producto madre, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.mainProductName.length >= 150){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN DEMASIADO LARGA',
          'notificationDialogBody': 'El nombre del producto madre no puede exceder los 150 caracteres, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.mainProductDescription == null || !regularExpressionChecker.test(this.input.mainProductDescription)){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque la descripción (página web) del producto madre, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.mainProductDescription.length >= 65000){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN DEMASIADO LARGA',
          'notificationDialogBody': 'La descripción (página web) del producto madre no puede exceder los 65000 caracteres, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.mainProductShortDescription == null || !regularExpressionChecker.test(this.input.mainProductShortDescription)){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque la descripción (call center) del producto madre, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.mainProductShortDescription.length >= 65000){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN DEMASIADO LARGA',
          'notificationDialogBody': 'La descripción (call center) del producto madre no puede exceder los 65000 caracteres, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (!positiveDecimalNumberChecker.test(this.input.mainProductCost)){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque un número positivo para el precio de costo del producto madre, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.mainProductTaxMargin == null){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Seleccione el márgen de impuesto del producto madre, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (!positiveDecimalNumberChecker.test(this.input.mainProductPrice)){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque un número positivo para el precio de venta del producto madre, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (!positiveDecimalMarginNumberChecker.test(this.input.mainProductMaxDiscountMargin)){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque un número positivo entre 0 y 100 para el descuento porcentual máximo del producto madre, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      if (this.input.mainProductImage == null){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': 'Coloque la imagen del producto madre, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
        return false;
      }
      return true;
    },

    async insertMainProduct(){
      if (this.validateInput()){
        this.loader.insertMainProduct = true;
        const insertMainProductRequestQuery = 
        {
          'mainProductCABYS': this.input.mainProductCABYS,
          'mainProductName': this.input.mainProductName,
          'mainProductDescription': this.input.mainProductDescription,
          'mainProductShortDescription': this.input.mainProductShortDescription,
          'mainProductCost': this.input.mainProductCost,
          'mainProductTaxMargin': this.input.mainProductTaxMargin,
          'mainProductPrice': this.input.mainProductPrice,
          'mainProductMaxDiscountMargin': this.input.mainProductMaxDiscountMargin,
          'mainProductIsReplacedDaily': this.input.mainProductIsReplacedDaily,
          'mainProductIsVisible': this.input.mainProductIsVisible,
          'mainProductIsActive': this.input.mainProductIsActive,
          'mainProductProductCategoryID': this.input.selectedProductCategory,
          'mainProductProductProviderID': this.input.selectedProductProvider,
          'mainProductProductBrandID': this.input.selectedProductBrand,
          'mainProductProductModelID': this.input.selectedProductModel,
          'mainProductProductFlavorID': this.input.selectedProductFlavor,
          'mainProductProductNicotineLevelID': this.input.selectedProductNicotineLevel,
          'mainProductProductSizeID': this.input.selectedProductSize,
          'mainProductProductTagIDS': this.input.selectedProductTagIDS,
          'mainProductLocation': this.input.selectedProductLocation,
          'mainProductImage': this.input.mainProductImage
        };
        const insertProductResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/mainProduct/insert', insertMainProductRequestQuery);
        if (insertProductResult.success){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ÉXITO',
            'notificationDialogBody': 'Se ha creado el producto madre exitosamente',
            'notificationDialogColor': 'success',
            'notificationDialogIsPersistent': false
          });
          this.setDefaultValues();
          viewMethodCaller.emit('InsertMainProductDialog/methods/insertMainProduct()');
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al crear el producto madre, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
        this.loader.insertMainProduct = false;
      }
    },

    async updateMainProduct(){
      if (this.validateInput()){
        this.loader.insertMainProduct = true;
        const updateMainProductRequestQuery = 
        {
          'mainProductID': this.navigation.mainProductID,
          'mainProductCABYS': this.input.mainProductCABYS,
          'mainProductName': this.input.mainProductName,
          'mainProductDescription': this.input.mainProductDescription,
          'mainProductShortDescription': this.input.mainProductShortDescription,
          'mainProductCost': this.input.mainProductCost,
          'mainProductTaxMargin': this.input.mainProductTaxMargin,
          'mainProductPrice': this.input.mainProductPrice,
          'mainProductMaxDiscountMargin': this.input.mainProductMaxDiscountMargin,
          'mainProductIsReplacedDaily': this.input.mainProductIsReplacedDaily,
          'mainProductIsVisible': this.input.mainProductIsVisible,
          'mainProductIsActive': this.input.mainProductIsActive,
          'mainProductProductCategoryID': this.input.selectedProductCategory,
          'mainProductProductProviderID': this.input.selectedProductProvider,
          'mainProductProductBrandID': this.input.selectedProductBrand,
          'mainProductProductModelID': this.input.selectedProductModel,
          'mainProductProductFlavorID': this.input.selectedProductFlavor,
          'mainProductProductNicotineLevelID': this.input.selectedProductNicotineLevel,
          'mainProductProductSizeID': this.input.selectedProductSize,
          'mainProductProductTagIDS': this.input.selectedProductTagIDS,
          'mainProductLocation': this.input.selectedProductLocation,
          'mainProductImage': this.input.mainProductImage
        };
        const updateMainProductResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/mainProduct/update', updateMainProductRequestQuery);
        if (updateMainProductResult.success){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ÉXITO',
            'notificationDialogBody': 'Se ha actualizado el producto madre exitosamente',
            'notificationDialogColor': 'success',
            'notificationDialogIsPersistent': false
          });
          this.setDefaultValues();
          viewMethodCaller.emit('InsertMainProductDialog/methods/updateMainProduct()');
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al actualizar el producto madre, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
        this.loader.insertMainProduct = false;
      }
    },

    async selectMainProductFromInsertMainProductDialog(){
      this.loader.selectMainProductFromInsertMainProductDialog = true;
      const selectMainProductFromInsertMainProductDialogRequestQuery = 
      {
        'mainProductID': this.navigation.mainProductID
      };
      const selectMainProductFromInsertMainProductDialogResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/mainProduct/functions/selectMainProductFromInsertMainProductDialog', selectMainProductFromInsertMainProductDialogRequestQuery);
      if (selectMainProductFromInsertMainProductDialogResult.success){
        const mainProduct = selectMainProductFromInsertMainProductDialogResult.result;
        this.input.mainProductCABYS = parseInt(mainProduct.mainProductCABYS);
        this.input.mainProductName = mainProduct.mainProductName;
        this.input.mainProductDescription = mainProduct.mainProductDescription;
        this.input.mainProductShortDescription = mainProduct.mainProductShortDescription;
        this.input.mainProductCost = mainProduct.mainProductCost;
        this.input.mainProductTaxMargin = mainProduct.mainProductTaxMargin;
        this.input.mainProductPrice = mainProduct.mainProductPrice;
        this.input.mainProductMaxDiscountMargin = mainProduct.mainProductMaxDiscountMargin;
        this.input.selectedProductCategory = mainProduct.mainProductProductCategoryID;
        this.input.selectedProductProvider = mainProduct.mainProductProductProviderID;
        this.input.selectedProductBrand = mainProduct.mainProductProductBrandID;
        this.input.selectedProductModel = mainProduct.mainProductProductModelID;
        this.input.selectedProductFlavor = mainProduct.mainProductProductFlavorID;
        this.input.selectedProductNicotineLevel = mainProduct.mainProductProductNicotineLevelID;
        this.input.selectedProductSize = mainProduct.mainProductProductSizeID;
        this.input.selectedProductTagIDS = mainProduct.mainProductTagIDS;       
        this.input.mainProductIsReplacedDaily = mainProduct.mainProductIsReplacedDaily == 1 ? true : false;
        this.input.mainProductIsVisible = mainProduct.mainProductIsVisible == 1 ? true : false;
        this.input.mainProductIsActive = mainProduct.mainProductIsActive == 1 ? true : false;
        this.input.selectedProductLocation = mainProduct.mainProductLocation;
        this.input.mainProductImage = `data:image/jpeg;base64,${mainProduct.mainProductImage}`;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la información del producto madre, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
        this.setDefaultValues();
      }
      this.loader.selectMainProductFromInsertMainProductDialog = false;

    },

    async selectProductSearchParameters(){
      this.loader.selectProductSearchParameters = true;
      const selectProductSearchParametersResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductSearchParameters');
      if (selectProductSearchParametersResult.success){
        this.display.productCategoryOptions = selectProductSearchParametersResult.result.productCategory;
        this.display.productProviderOptions = selectProductSearchParametersResult.result.productProvider;
        this.display.productBrandOptions = selectProductSearchParametersResult.result.productBrand;
        this.display.productModelOptions = selectProductSearchParametersResult.result.productModel;
        this.display.productFlavorOptions = selectProductSearchParametersResult.result.productFlavor;
        this.display.productNicotineLevelOptions = selectProductSearchParametersResult.result.productNicotineLevel;
        this.display.productSizeOptions = selectProductSearchParametersResult.result.productSize;
        this.display.productTagOptions = selectProductSearchParametersResult.result.productTag;
        this.loader.selectProductSearchParameters = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de características de los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    composeProductName(){
      const productBrand = this.display.productBrandOptions.find(productBrandOption => productBrandOption.productBrandID == this.input.selectedProductBrand);
      const productModel = this.display.productModelOptions.find(productModelOption => productModelOption.productModelID == this.input.selectedProductModel);
      const productFlavor = this.display.productFlavorOptions.find(productFlavorOption => productFlavorOption.productFlavorID == this.input.selectedProductFlavor);
      const productNicotineLevel = this.display.productNicotineLevelOptions.find(productNicotineLevelOption => productNicotineLevelOption.productNicotineLevelID == this.input.selectedProductNicotineLevel);
      const productSize = this.display.productSizeOptions.find(productSizeOption => productSizeOption.productSizeID == this.input.selectedProductSize);
      this.input.mainProductName = `${productBrand ? productBrand.productBrandName : ''}${productModel ? ` ${productModel.productModelName}` : ''}${productFlavor ? ` ${productFlavor.productFlavorName}` : ''}${productNicotineLevel ? ` ${productNicotineLevel.productNicotineLevelName}` : ''}${productSize ? ` ${productSize.productSizeName}` : ''}`;
    },

    uploadImage(){
      document.getElementById('uploadMainProductImage').click();
    },

    processImage(){
      const previousImage = this.input.mainProductImage;
      try {
        this.input.mainProductImage = this.$refs.uploadMainProductImage.files[0];
        const imageFileReader = new FileReader();
        imageFileReader.readAsDataURL(this.input.mainProductImage);
        imageFileReader.onload = () => {
          this.input.mainProductImage = imageFileReader.result;
        };
        imageFileReader.onerror = function () {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al cargar la imagen del producto madre, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
          throw new Error('Error when charging the image');
        };
      } catch {
        this.input.mainProductImage = previousImage;
      }
    },
    
    setDefaultValues(){
      this.input.mainProductCABYS = null;
      this.input.mainProductName = null;
      this.input.selectedProductCategory = null;
      this.input.selectedProductProvider = null;
      this.input.selectedProductBrand = null;
      this.input.selectedProductModel = null;
      this.input.selectedProductFlavor = null;
      this.input.selectedProductNicotineLevel = null;
      this.input.selectedProductSize = null;
      this.input.selectedProductTagIDS.length = 0;
      this.input.mainProductDescription = null;
      this.input.mainProductShortDescription = null;
      this.input.mainProductCost = null;
      this.input.mainProductPrice = null;
      this.input.mainProductTaxMargin = 13;
      this.input.mainProductMaxDiscountMargin = 25;
      this.input.selectedProductLocation = null;
      this.input.mainProductIsActive = true;
      this.input.mainProductIsReplacedDaily = true;
      this.input.mainProductIsVisible = true;
      this.input.mainProductImage = null;
      this.loader.selectProductSearchParameters = false;
      this.loader.selectMainProductListFromInsertProductDialog = false;
      this.loader.selectMainProductFromInsertMainProductDialog = false;
      this.loader.insertMainProduct = false;
      this.navigation.insertMainProductDialogIsVisible = false;
      this.navigation.mainProductID = null;
      this.assets.placeholderImage = this.$generalFunctions.default.data.placeholderImage;
    },

    openInsertProductSearchParameterDialog(productSearchParameterName, productSearchParameterPrefix){
      const insertProductSearchParameterDialogRequestQuery = 
      {
        'productSearchParameterName': productSearchParameterName,
        'productSearchParameterPrefix': productSearchParameterPrefix
      };
      this.$root.insertProductSearchParameterDialog.openInsertProductSearchParameterDialog(insertProductSearchParameterDialogRequestQuery);
    },

    async openInsertMainProductDialog(mainProductID){
      this.setDefaultValues();
      this.navigation.mainProductID = mainProductID;
      this.navigation.insertMainProductDialogIsVisible = true;
      await this.selectProductSearchParameters();
      if (this.navigation.mainProductID){
        await this.selectMainProductFromInsertMainProductDialog();
      }
    },
  },

  mounted(){
    viewMethodCaller.on('InsertProductSearchParameterDialog/methods/insertProductSearchParameter()', this.selectProductSearchParameters);
  }
}
</script>