<template>
  <v-dialog v-model="navigation.insertProductInAndOutDialogIsVisible" :persistent="true" width="auto">
    <v-card style="padding: 20px;" elevation="3" width="1500">
      <div style="display: flex;">
        <div style="width: 49.5%; margin-right: 0.5%;">
          <v-select v-model="input.selectedLocalityID" :items="display.localityOptions" :loading="loader.selectLocalityIDAndLocalityName" item-value="localityID" item-title="localityName" append-inner-icon="mdi-store" label="Localidad afectada por el movimiento de productos" variant="solo-filled"></v-select>
        </div>
        <div style="width: 49.5%; margin-left: 0.5%;">
          <v-select v-model="input.selectedProductInAndOutType" :items="['Entrada', 'Salida']" append-inner-icon="mdi-swap-horizontal" label="Tipo del movimiento de productos" variant="solo-filled"></v-select>
        </div>
      </div>
        <div v-if="input.selectedProductInAndOutType && input.selectedLocalityID">
        <v-select v-model="input.productInAndOutReason" :items="display.productInAndOutReasonOptions" append-inner-icon="mdi-source-branch" label="Motivo del movimiento de productos" variant="solo-filled"></v-select>
        <v-textarea v-model="input.productInAndOutNote" append-inner-icon="mdi-text-box-outline" label="Nota del movimiento de productos (opcional)" rows="3" variant="solo-filled"></v-textarea>
        <br>
        <v-card style="padding: 20px; background-color: #dedede;" elevation="3">
          <div v-if="loader.appendProductToSelectedProducts" style="text-align: center;">
            <v-progress-circular color="info" indeterminate></v-progress-circular>
          </div>
          <div v-else>
            <v-autocomplete v-model="input.selectedProduct" @update:modelValue="appendProductToSelectedProducts()" :items="display.productOptions" :loading="loader.selectProductForInsertProductInAndOutDialog" :disabled="loader.selectProductForInsertProductInAndOutDialog" ref="selectedProductInput" item-title="productAutocompleteTitle" item-value="productID" append-inner-icon="mdi-magnify" label="Código o nombre del producto" variant="solo-filled"></v-autocomplete>
            <div v-if="display.selectedProducts.length != 0">
              <br>
              <v-data-table-virtual :headers="display.selectedProductsHeaders" :items="display.selectedProducts" style="max-height: 600px; overflow-y: auto;">
                <template v-slot:item.productName="{ item }">
                  {{ item.productName }}
                  <br>
                  <strong>{{ item.productSKU }}</strong>
                </template>
                <template v-slot:item.productInAndOutProductAmount="{ item }">
                  <br>
                  <v-text-field :min="0" v-model="item.productInAndOutProductAmount" density="dense" variant="solo-filled" type="number" style="width: 100px;"></v-text-field>
                </template>
                <template v-slot:item.productStock="{ item }">
                  <div v-if="accessCredential['/productInAndOut/insertProductInAndOutDialog/showStock']" style="display: flex;">
                    <div v-for="productStockByAllLocality in item.productStock" v-bind:key="productStockByAllLocality">
                      <v-tooltip :text="productStockByAllLocality.localityName" location="top">
                        <template v-slot:activator="{ props }">
                          <v-chip v-bind="props" :color="productStockByAllLocality.localityColor" variant="flat" style="margin: 0px 5px 0px 5px; cursor: pointer;">
                            <p :style="{color: productStockByAllLocality.localityFontColor}"><strong>{{ productStockByAllLocality.productStockAmount }}</strong></p>
                          </v-chip>
                        </template>
                      </v-tooltip>
                    </div>
                  </div>
                  <div v-else style="display: flex;">
                    <div v-for="productStockByLocality in item.productStock" v-bind:key="productStockByLocality">
                      <v-tooltip v-if="productStockByLocality.localityID == input.selectedLocalityID" :text="productStockByLocality.localityName" location="top">
                        <template v-slot:activator="{ props }">
                          <v-chip v-bind="props" :color="productStockByLocality.localityColor" variant="flat" style="margin: 0px 5px 0px 5px; cursor: pointer;">
                            <p :style="{color: productStockByLocality.localityFontColor}"><strong>{{ productStockByLocality.productStockAmount }}</strong></p>
                          </v-chip>
                        </template>
                      </v-tooltip>
                    </div>
                  </div>                    
                </template>
                <template v-slot:item.productInAndOutProductNoteAction="{ item }">
                  <div style="width: 300px;">
                    <br>
                    <v-select v-model="item.productInAndOutProductNoteAction" :items="display.productInAndOutProductNoteOptions" density="dense" variant="solo-filled"></v-select>
                    <v-textarea v-if="item.productInAndOutProductNoteAction == 'Otro'" v-model="item.productInAndOutProductNote" rows="2" variant="solo-filled"></v-textarea>
                  </div>
                </template>
                <template v-slot:item.productInAndOutProductDeleteAction="{ item }">
                  <v-icon @click="removeProductFromSelectedProducts(item.productInAndOutProductID)" color="error">mdi-trash-can</v-icon>
                </template>
              </v-data-table-virtual>
            </div>
          </div>
        </v-card>
        <br><br><br>
        <div v-if="loader.generateProductInAndOut" style="text-align: center;">
          <v-progress-circular color="success" indeterminate></v-progress-circular>
        </div>
        <div v-else>
          <div v-if="display.selectedProducts.length != 0 && input.selectedProductInAndOutType">
            <v-text-field v-model="input.tokenValue" append-inner-icon="mdi-lock" variant="solo-filled" type="number" label="Token" hide-spin-buttons></v-text-field>
            <br>
            <v-btn @click="generateProductInAndOut()" block height="38" color="success">
              <h3>GENERAR {{ input.selectedProductInAndOutType.toUpperCase() }} DE PRODUCTOS</h3>
              <v-icon style="margin-left: 10px;" dark right>mdi-check</v-icon>
            </v-btn>
          </div>
        </div>
        
      </div>
      <br>
      <v-btn @click="closeInsertProductInAndOutDialog()" block height="38" color="error">
        <h3>CANCELAR {{ input.selectedProductInAndOutType ? input.selectedProductInAndOutType.toUpperCase() : 'MOVIMIENTO' }} DE PRODUCTOS</h3>
        <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<style scoped>
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'InsertProductInAndOutDialog',

  inject: ['$generalFunctions'],

  data: () => ({
    input: 
    {
      tokenValue: null,
      selectedLocalityID: null,
      selectedProductInAndOutType: null,
      productInAndOutReason: null,
      productInAndOutNote: null,
      selectedProduct: null
    },

    display: 
    {
      localityOptions: [],
      productOptions: [],
      selectedProducts: [],
      productInAndOutReasonOptions: ['Ingreso de producto', 'Error de facturación', 'Devolución de producto', 'Otro'],
      selectedProductsHeaders: 
      [
        {key: 'productInAndOutProductID', title: 'CÓDIGO INTERNO'},
        {key: 'productName', title: 'NOMBRE'},
        {key: 'productInAndOutProductAmount', title: 'CANTIDAD'},
        {key: 'productStock', title: 'STOCK'},
        {key: 'productInAndOutProductNoteAction', title: 'NOTA'},
        {key: 'productInAndOutProductDeleteAction', title: 'ELIMINAR'},
      ],
      productInAndOutProductNoteOptions: ['Ingreso de producto', 'Error de facturación', 'Devolución de producto', 'Otro'],
      PDFDocumentFile: null,
      PDFDocumentName: null,
      excelDocumentFile: null,
      excelDocumentName: null
    },

    navigation: 
    {
      insertProductInAndOutDialogIsVisible: false
    },

    loader:
    {
      selectLocalityIDAndLocalityName: false,
      selectProductForInsertProductInAndOutDialog: false,
      appendProductToSelectedProducts: false,
      generateProductInAndOut: false
    },

    accessCredential: {},

    localStorage:
    {
      localityID: null,
      agentID: null
    }
  }),

  watch: {

  },

  methods: {
    validateInputValuesForGenerateProductInAndOut(){
      if (this.input.selectedLocalityID){
        if (this.input.productInAndOutReason){
          const positiveIntegerChecker = /^[1-9]\d*$/;
          for (var displaySelectedProductIndex in this.display.selectedProducts){
            const displaySelectedProduct = this.display.selectedProducts[displaySelectedProductIndex];
            if (!positiveIntegerChecker.test(displaySelectedProduct.productInAndOutProductAmount)){
              this.$root.notificationDialog.showNotificationDialog({
                'notificationDialogTitle': 'INFORMACIÓN INCORRECTA',
                'notificationDialogBody': `La cantidad del producto "${displaySelectedProduct.productName}" debe ser un número entero positivo, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema`,
                'notificationDialogColor': 'warning',
                'notificationDialogIsPersistent': false
              });
              return false;
            }
            if (this.input.selectedProductInAndOutType == 'Salida'){
              const productLocalityStockAmount = displaySelectedProduct.productStock.find(productLocalityStock => productLocalityStock.localityID == this.input.selectedLocalityID).productStockAmount;
              if (productLocalityStockAmount < displaySelectedProduct.productInAndOutProductAmount){
                this.$root.notificationDialog.showNotificationDialog({
                  'notificationDialogTitle': 'INFORMACIÓN INCORRECTA',
                  'notificationDialogBody': `No se puede generar una salida del producto "${displaySelectedProduct.productName}" por ${displaySelectedProduct.productInAndOutProductAmount} unidad${displaySelectedProduct.productInAndOutProductAmount == 1 ? '' : 'es'}, ya que quedaría en stock negativo en la localidad seleccionada, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema`,
                  'notificationDialogColor': 'warning',
                  'notificationDialogIsPersistent': false
                });
                return false;
              }
            }
          }
          return true;
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
            'notificationDialogBody': `Seleccione un motivo para la ${this.input.selectedProductInAndOutType.toLowerCase()} de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema`,
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'INFORMACIÓN INCOMPLETA',
          'notificationDialogBody': `Seleccione una localidad para la ${this.input.selectedProductInAndOutType.toLowerCase()} de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema`,
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
      }
      return false;
    },

    async generateProductInAndOut(){
      if (this.validateInputValuesForGenerateProductInAndOut()){
        this.loader.generateProductInAndOut = true;
        const productInAndOutProducts = this.display.selectedProducts.map(selectedProduct => ({
          'productInAndOutProductID': selectedProduct.productInAndOutProductID,
          'productInAndOutProductSKU': selectedProduct.productSKU,
          'productInAndOutProductName': selectedProduct.productName,
          'productInAndOutProductCurrentAmount': selectedProduct.productStock.find(productStockByLocality => productStockByLocality.localityID == this.input.selectedLocalityID).productStockAmount,
          'productInAndOutProductAmount': selectedProduct.productInAndOutProductAmount,
          'productInAndOutProductNote': selectedProduct.productInAndOutProductNoteAction == 'Otro' ? selectedProduct.productInAndOutProductNote : selectedProduct.productInAndOutProductNoteAction,
        }));
        const generateProductInAndOutRequestQuery =
        {
          'tokenValue': this.input.tokenValue,
          'productInAndOutGeneratedLocalityID': this.localStorage.localityID,
          'productInAndOutLocalityID': this.input.selectedLocalityID,
          'productInAndOutAgentID': this.localStorage.agentID,
          'productInAndOutType': this.input.selectedProductInAndOutType,
          'productInAndOutReason': this.input.productInAndOutReason,
          'productInAndOutNote': this.input.productInAndOutNote,
          'productInAndOutProducts': productInAndOutProducts
        }
        const generateProductInAndOutResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productInAndOut/functions/generateProductInAndOut', generateProductInAndOutRequestQuery);
        if (generateProductInAndOutResult.success){
          if (generateProductInAndOutResult.result.PDFDocument.success){
            this.display.PDFDocumentFile = generateProductInAndOutResult.result.PDFDocument.result.documentFile.data;
            this.display.PDFDocumentName = generateProductInAndOutResult.result.PDFDocument.result.documentName;
          } else {
            this.$root.notificationDialog.showNotificationDialog({
              'notificationDialogTitle': 'ERROR',
              'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el documento en PDF, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
              'notificationDialogColor': 'error',
              'notificationDialogIsPersistent': false
            });
          }
          if (generateProductInAndOutResult.result.excelDocument.success){
            this.display.excelDocumentFile = generateProductInAndOutResult.result.excelDocument.result.documentFile.data;
            this.display.excelDocumentName = generateProductInAndOutResult.result.excelDocument.result.documentName;
          } else {
            this.$root.notificationDialog.showNotificationDialog({
              'notificationDialogTitle': 'ERROR',
              'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el documento en PDF, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
              'notificationDialogColor': 'error',
              'notificationDialogIsPersistent': false
            });
          }
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ÉXITO',
            'notificationDialogBody': `Se ha generado la ${this.input.selectedProductInAndOutType.toLowerCase()} de productos exitosamente`,
            'notificationDialogColor': 'success',
            'notificationDialogIsPersistent': false
          });

          const printablePDFExcelDocumentDialogRequestQuery = 
          {
            'printablePDFDocumentFile': this.display.PDFDocumentFile,
            'printablePDFDocumentName': this.display.PDFDocumentName,
            'printableExcelDocumentFile': this.display.excelDocumentFile,
            'printableExcelDocumentName': this.display.excelDocumentName
          };
          this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
          this.closeInsertProductInAndOutDialog();
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': `Ha ocurrido un error inesperado al generar la ${this.input.selectedProductInAndOutType.toLowerCase()} de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema`,
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
        this.loader.generateProductInAndOut = false;
      }
    },

    removeProductFromSelectedProducts(productInAndOutProductID){
      const displaySelectedProductToBeRemovedIndex = this.display.selectedProducts.findIndex(selectedProduct => selectedProduct.productInAndOutProductID == productInAndOutProductID);
      if (displaySelectedProductToBeRemovedIndex != -1){
        this.display.selectedProducts.splice(displaySelectedProductToBeRemovedIndex, 1);
      }
    },

    blurSelectedProductInputInterval(){
      const blurSelectedProductInputInterval = setInterval(() => {
        if (this.$refs.selectedProductInput) {
          this.$refs.selectedProductInput.blur();
          clearInterval(blurSelectedProductInputInterval);
        }
      }, 1);
    },

    async appendProductToSelectedProducts(){
      this.loader.appendProductToSelectedProducts = true;
      const currentSelectedProduct = this.display.selectedProducts.find(selectedProduct => selectedProduct['productInAndOutProductID'] == this.input.selectedProduct);
      if (currentSelectedProduct){
        const selectProductStockRequestQuery = {'productStockProductID': currentSelectedProduct.productInAndOutProductID};
        const selectProductStockResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productStock/select/byProductStockProductID', selectProductStockRequestQuery);
        if (selectProductStockResult.success){
          currentSelectedProduct['productInAndOutProductAmount'] = currentSelectedProduct['productInAndOutProductAmount'] + 1;
          currentSelectedProduct['productStock'] = selectProductStockResult.result;
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar el stock del producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        const selectedProductToBeAppended = this.display.productOptions.find(productOption => productOption.productID == this.input.selectedProduct);
        const selectProductStockRequestQuery = {'productStockProductID': selectedProductToBeAppended.productID};
        const selectProductStockResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productStock/select/byProductStockProductID', selectProductStockRequestQuery);
        if (selectProductStockResult.success){
          this.display.selectedProducts.push({
            'productInAndOutProductID': selectedProductToBeAppended.productID,
            'productSKU': selectedProductToBeAppended.productSKU,
            'productName': selectedProductToBeAppended.productName,
            'productInAndOutProductAmount': 1,
            'productStock': selectProductStockResult.result,
            'productInAndOutProductNote': null,
            'productInAndOutProductNoteAction': null,
            'productInAndOutProductDeleteAction': null
          });
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ERROR',
            'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar el stock del producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'error',
            'notificationDialogIsPersistent': false
          });
        }
      }
      this.loader.appendProductToSelectedProducts = false;
      this.input.selectedProduct = null;
      this.blurSelectedProductInputInterval();
    },

    async selectProductForInsertProductInAndOutDialog(){
      this.loader.selectProductForInsertProductInAndOutDialog = true;
      const selectProductForInsertProductInAndOutDialogResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductForInsertProductInAndOutDialog');
      if (selectProductForInsertProductInAndOutDialogResult.success){
        this.display.productOptions = selectProductForInsertProductInAndOutDialogResult.result.map(productOption => ({
          'productID': productOption.i,
          'productSKU': productOption.s,
          'productName': productOption.n,
          'productAutocompleteTitle': productOption.s + ' - ' + productOption.n
        }));
        this.loader.selectProductForInsertProductInAndOutDialog = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },
    
    async selectLocalityIDAndLocalityName(){
      this.loader.selectLocalityIDAndLocalityName = true;
      const selectLocalityIDAndLocalityNameResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityName');
      if (selectLocalityIDAndLocalityNameResult.success){
        this.display.localityOptions = selectLocalityIDAndLocalityNameResult.result;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectLocalityIDAndLocalityName = false;
    },

    setDefaultValues(){
      this.input.tokenValue = null;
      this.input.selectedLocalityID = null;
      this.input.productInAndOutReason = null;
      this.input.productInAndOutNote = null;
      this.input.selectedProduct = null;
      this.display.selectedProducts.length = 0;
      this.loader.selectLocalityIDAndLocalityName = false;
      this.loader.selectProductForInsertProductInAndOutDialog = false;
      this.loader.appendProductToSelectedProducts = false;
      this.loader.generateProductInAndOut = false;
    },

    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    },

    closeInsertProductInAndOutDialog(){
      this.navigation.insertProductInAndOutDialogIsVisible = false;
      this.setDefaultValues();
    },

    async openInsertProductInAndOutDialog(){
      await this.getLocalStorageData();
      this.setDefaultValues();
      this.navigation.insertProductInAndOutDialogIsVisible = true;
    }
  },

  async mounted(){
    await this.getLocalStorageData();
    this.setDefaultValues();
    this.selectLocalityIDAndLocalityName();
    this.selectProductForInsertProductInAndOutDialog();
  }
});
</script>