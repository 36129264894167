<template>
  <div style="margin: 2% 3% 0% 3%;">
    <div v-if="accessCredential['/agent/selectLocality']">
      <v-card elevation="3" style="padding: 20px; background-color: #e9e8e8;">
        <v-autocomplete v-model="input.selectedLocalityID" :items="display.localityOptions" :loading="loader.selectLocalityIDAndLocalityName" :disabled="loader.selectLocalityIDAndLocalityName" item-value="localityID" item-title="localityName" append-inner-icon="mdi-store" label="Localidad" variant="solo-filled"></v-autocomplete>
      </v-card>
      <br>
    </div>
    <br>
    <v-card elevation="3" style="max-height: 600px; padding: 30px; overflow-y: auto; background-color: #e9e8e8;">
      <div v-if="loader.selectAgentFromAgentView" style="text-align: center;">
        <v-progress-circular color="info" indeterminate></v-progress-circular>
        <br>
      </div>
      <div v-else>
        <v-data-table-virtual :headers="display.agentHeaders" :items="display.agentRows">
          <template v-slot:item.agentName="{ item }">
            <v-chip :color="item.agentColor" variant="flat">
              <p :style="{color: item.agentFontColor}">{{ item.agentName }}</p>
            </v-chip>
          </template>
          <template v-slot:item.agentIsActive="{ item }">
            <div v-if="item.agentLoader">
              <v-progress-circular :color="item.agentIsActive ? 'success' : 'error'" indeterminate></v-progress-circular>
              <br>
            </div>
            <v-chip v-else @click="updateAgentIsActive(item)" :color="item.agentIsActive ? 'success' : 'error'" variant="flat">
              <p>{{ item.agentIsActive ? 'Activo' : 'Inactivo' }}</p>
            </v-chip>
          </template>
          <template v-slot:item.agentCreationDatetime="{ item }">
            {{ $generalFunctions.default.methods.parseDatetimeToTextDate(item.agentCreationDatetime) }}
          </template>
          <template v-slot:item.agentInformationAction="{ item }">
            <v-btn @click="openAgentDialog(item)" dark block height="38" color="info">
              <h3>ACTUALIZAR</h3>
              <v-icon style="margin-left: 10px;" dark right>mdi-update</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.agentAccessCredentialAction="{ item }">
            <v-btn @click="openAccessCredentialDialog(item)" dark block height="38" color="warning">
              <h3>CREDENCIALES</h3>
              <v-icon style="margin-left: 10px;" dark right>mdi-security</v-icon>
            </v-btn>
          </template>
        </v-data-table-virtual>
      </div>  
    </v-card>
    <br><br>
  </div>
</template>

<style scoped>
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AgentView',

  inject: ['$generalFunctions'],

  data: () => ({
    input: 
    {
      selectedLocalityID: null,
    },

    display: 
    {
      localityOptions: [],
      agentRows: [],
      agentHeaders: [],
    },

    navigation: 
    {
    },

    loader:
    {
      selectLocalityIDAndLocalityName: false,
      selectAgentFromAgentView: false,
      selectAccessCredentialByAccessCredentialAgentID: false,
    },

    localStorage: 
    {
      localityID: null,
      agentID: null
    },

    accessCredential: {}
  }),

  watch: {
    'input.selectedLocalityID': function() {
      this.selectAgentFromAgentView();
    },
  },

  methods: {
    async updateAgentIsActive(agent){
      agent.agentLoader = true;
      const agentID = agent.agentID;
      const agentIsActive = !agent.agentIsActive;
      const updateAgentIsActiveRequestQuery = 
      {
        'agentID': agentID,
        'agentIsActive': agentIsActive
      };
      const updateAgentIsActiveResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/update/agentIsActive', updateAgentIsActiveRequestQuery);
      if (updateAgentIsActiveResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': `Agente ${agentIsActive ? 'activado' : 'desactivado'} exitosamente`,
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        this.selectAgentFromAgentView();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': `Ha ocurrido un error inesperado al ${agentIsActive ? 'activar' : 'desactivar'} al agente, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema`,
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      agent.agentLoader = false;
    },

    openAgentDialog(agent){
      const agentID = agent.agentID;
      const openAgentDialogRequestQuery =
      {
        'agentID': agentID
      };
      this.$root.agentDialog.openAgentDialog(openAgentDialogRequestQuery);
    },

    openAccessCredentialDialog(agent){
      const accessCredentialAgentID = agent.agentID;
      const accessCredentialDialogRequestQuery = 
      {
        'accessCredentialAgentID': accessCredentialAgentID
      };
      this.$root.accessCredentialDialog.openAccessCredentialDialog(accessCredentialDialogRequestQuery);
    },

    async selectAgentFromAgentView(){
      this.loader.selectAgentFromAgentView = true;
      const selectAgentFromAgentViewRequestQuery = 
      {
        'agentLocalityID': this.accessCredential['/agent/selectLocality'] ? this.input.selectedLocalityID : this.localStorage.localityID, 
        'agentIsActive': true
      };
      const selectAgentFromAgentViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/agent/select/byAgentLocalityIDAndByAgentIsActive', selectAgentFromAgentViewRequestQuery);
      if (selectAgentFromAgentViewResult.success){
        this.display.agentRows = selectAgentFromAgentViewResult.result.map(agentRow => ({
          ...agentRow,
          'agentLoader': false
        }));
        this.loader.selectAgentFromAgentView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los agentes, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },
    
    async selectLocalityIDAndLocalityName(){
      this.loader.selectLocalityIDAndLocalityName = true;
      const selectLocalityIDAndLocalityNameResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityName');
      if (selectLocalityIDAndLocalityNameResult.success){
        this.display.localityOptions = [{localityID: null, localityName: 'Todas las localidades'}, ...selectLocalityIDAndLocalityNameResult.result];
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectLocalityIDAndLocalityName = false;
    },

    async getLocalStorageData(){
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    },

    prepareBasedOnAccessCredential(){
      this.display.agentHeaders = [
        {key: 'agentID', title: 'ID DEL AGENTE'},
        {key: 'agentSSN', title: 'CÉDULA DEL AGENTE'},
        {key: 'agentName', title: 'NOMBRE DEL AGENTE'},
        {key: 'agentPhoneNumber', title: 'TELÉFONO DEL AGENTE'},
        {key: 'agentEmail', title: 'CORREO DEL AGENTE'},
        {key: 'agentCreationDatetime', title: 'FECHA DE CREACIÓN'},
        {key: 'agentIsActive', title: 'ESTADO'},
        {key: 'agentInformationAction', title: ''},
        ...(this.accessCredential['/agent/showAccessCredential'] ? [{key: 'agentAccessCredentialAction', title: ''}] : [])
      ];
    },
  },

  async mounted(){
    await this.getLocalStorageData();
    this.prepareBasedOnAccessCredential();
    await this.selectLocalityIDAndLocalityName();
    await this.selectAgentFromAgentView();
  }
});
</script>
