<template>
  <div style="margin: 2% 3% 0% 3%;">
    <div style="display: flex; justify-content: space-between;">
      <div style="width: 70%;">
        <v-card elevation="3" style="padding: 20px 20px 0px 20px; background-color: #e9e8e8; width: 100%;">
          <v-autocomplete 
            @click="clearSelectProductIDInput()"
            @keydown.enter.prevent="selectProductID()" 
            v-model:search="input.searchedProductID"
            v-model="input.selectedProductID" 
            :items="display.productOptions" 
            :loading="loader.selectProductBasicBasicInformationForBillsView" 
            :disabled="loader.selectProductBasicBasicInformationForBillsView" 
            ref="selectedProductIDInput"
            item-title="productAutocompleteTitle" 
            item-value="productID" 
            append-inner-icon="mdi-magnify" 
            label="Código o nombre del producto" 
            variant="solo-filled"
          ></v-autocomplete>
          <div v-if="display.product" style="display: flex;">
            <v-text-field v-model="display.startDateFormatted" @click="navigation.startDatePickerIsVisible = true" readonly label="Filtrar por fecha inicial" append-inner-icon="mdi-calendar" variant="solo-filled"></v-text-field>
            <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
              <v-card style="display: flex;">
                <v-date-picker v-model="input.startDate" hide-header color="primary" width="100%"></v-date-picker>
                <v-chip @click="input.startDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;"  color="primary" variant="flat">Hoy</v-chip>
              </v-card>
            </v-dialog>
            <v-text-field v-model="display.endDateFormatted" @click="navigation.endDatePickerIsVisible = true" readonly label="Filtrar por fecha final" append-inner-icon="mdi-calendar" style="margin-left: 5%;" variant="solo-filled"></v-text-field>
            <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
              <v-card>
                <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
                <v-chip @click="input.endDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;"  color="primary" variant="flat">Hoy</v-chip>
              </v-card>
            </v-dialog>
          </div>
        </v-card>
      </div>
      <div style="width: 28%; margin-left: 2%;">
        <div v-if="display.product && display.product.productImage" style="width: 75%; display: flex; justify-content: center; align-items: center;">
          <img :src="`data:image/jpeg;base64,${display.product.productImage}`" style="width: 70%;">
        </div> 
      </div>
    </div>
    <br><br>
    <v-card v-if="display.product || loader.selectProductFromProductView" elevation="3" style="padding: 30px 30px 30px 30px; background-color: #ededed;">
      <div v-if="loader.selectProductFromProductView" style="text-align: center;">
        <v-progress-circular color="info" indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <div style="display: flex;">
          <div style="width: 40%;">
            <div style="display: flex;">
              <h1>{{ display.product.productName }}</h1>
              <v-icon style="font-size: 30px; margin-left: 40px; margin-top: 5px;" @click="openUpdateProduct()" color="success" center>mdi-pencil</v-icon>
              <v-tooltip v-if="display.product.productStock.filter(productStockByLocality => (productStockByLocality.localityType == 'Bodega') && (productStockByLocality.productStockGoalAmount > productStockByLocality.productStockAmount)).length > 0" location="top">
                <div>
                  <h3>Stock bajo:</h3>
                  <div v-for="locality in display.product.productStock.filter(productStockByLocality => (productStockByLocality.localityType == 'Bodega') && (productStockByLocality.productStockGoalAmount > productStockByLocality.productStockAmount))" v-bind:key="locality">
                    <v-chip :color="locality.localityColor" variant="flat" style="margin-right: 10px;">
                      <p :style="{color: locality.localityFontColor}">{{ locality.productStockAmount }}</p>
                    </v-chip>
                  </div>
                </div>
                <template v-slot:activator="{ props }">
                  <v-chip v-bind="props" color="error" label class="productLowStockAlert">
                    <v-icon icon="mdi-trending-down" start></v-icon>
                  </v-chip>
                </template>
              </v-tooltip>
            </div>
            <p style="font-size: larger;">{{ display.product.productSKU }}</p>
            <p style="font-size: larger;"><strong>Proveedor: </strong> {{ display.product.productProviderName || 'No asignado' }}</p>
          </div>
          <div style="width: 60%;">
            <div style="display: flex; width: 100%; flex-direction: row-reverse;">
              <v-tooltip text="Tracking de producto" location="top">
                <template v-slot:activator="{ props }">
                  <v-chip @click="openProductHistory()" v-bind="props" color="warning" label style="font-size: larger; cursor: pointer;">
                    <v-icon icon="mdi-update" start></v-icon>
                  </v-chip>
                </template>
              </v-tooltip>
              <v-tooltip text="Unidades dañadas" location="top">
                <template v-slot:activator="{ props }">
                  <v-chip v-bind="props" color="error" label style="margin-right: 20px; font-size: larger; cursor: pointer;">
                    <v-icon icon="mdi-alert" start></v-icon>
                    {{ display.product.productChange }}
                  </v-chip>
                </template>
              </v-tooltip>
              <v-tooltip location="top">
                <div>
                  <h3>Unidades vendidas:</h3>
                  <div style="display: flex; margin-top: 10px; margin-bottom: 10px;">
                    <div v-for="locality in display.product.productSale" v-bind:key="locality">
                      <v-chip :color="locality.localityColor" variant="flat" style="margin-right: 10px;">
                        <p :style="{color: locality.localityFontColor}">{{ locality.billProductAmount }}</p>
                      </v-chip>
                    </div>
                  </div>
                </div>
                <template v-slot:activator="{ props }">
                  <v-chip v-bind="props" color="success" label style="margin-right: 20px; font-size: larger; cursor: pointer;">
                    <v-icon icon="mdi-cash-register" start></v-icon>
                      {{ display.product.productSale.reduce((productSaleAmount, product) => productSaleAmount + product.billProductAmount, 0) }}
                  </v-chip>
                </template>
              </v-tooltip>


              <v-chip v-if="navigation.showHiddenAmount == false" color="gray" label style="margin-right: 20px; font-size: larger; cursor: pointer;">
                <v-icon @click="showHiddenAmount()" icon="mdi-eye" start></v-icon>
              </v-chip>

              <v-tooltip location="top">
                <div>
                  <h3>Cantidad invertida:</h3>
                  <div style="display: flex; margin-top: 10px; margin-bottom: 10px;">
                    <div v-for="locality in display.product.productStock" v-bind:key="locality">
                      <v-chip :color="locality.localityColor" variant="flat" style="margin-right: 10px;">
                        <p :style="{color: locality.localityFontColor}">₡ {{ locality.productInvestmentAmount.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
                      </v-chip>
                    </div>
                  </div>
                </div>
                <template v-slot:activator="{ props }">
                  <v-chip v-if="navigation.showHiddenAmount == true" v-bind="props" color="gray" label style="margin-right: 20px; font-size: larger; cursor: pointer;">
                    <v-icon icon="mdi-cash-clock" start></v-icon>
                    ₡ {{ (display.product.productStock.reduce((productInvestmentAmount, product) => productInvestmentAmount + product.productInvestmentAmount, 0)).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}
                  </v-chip>
                </template>
              </v-tooltip>
              <v-tooltip location="top">
                <div>
                  <h3>Stock (localidades):</h3>
                  <div style="display: flex; margin-top: 10px; margin-bottom: 10px;">
                    <div v-for="locality in display.product.productStock.filter(productStockByLocality => productStockByLocality.localityType == 'Tienda')" v-bind:key="locality">
                      <v-chip :color="locality.localityColor" variant="flat" style="margin-right: 10px;">
                        <p :style="{color: locality.localityFontColor}">{{ locality.productStockAmount }}</p>
                      </v-chip>
                    </div>
                  </div>
                </div>
                <template v-slot:activator="{ props }">
                  <v-chip v-bind="props" color="info" label style="margin-right: 20px; font-size: larger; cursor: pointer;">
                    <v-icon icon="mdi-store" start></v-icon>
                    {{ display.product.productStock.filter(productStockByLocality => productStockByLocality.localityType == 'Tienda').reduce((productStockAmount, product) => productStockAmount + product.productStockAmount, 0) }}
                  </v-chip>
                </template>
              </v-tooltip>
              <v-tooltip location="top">
                <div>
                  <h3>Stock (bodegas):</h3>
                  <div style="display: flex; margin-top: 10px; margin-bottom: 10px;">
                    <div v-for="locality in display.product.productStock.filter(productStockByLocality => productStockByLocality.localityType == 'Bodega')" v-bind:key="locality">
                      <v-chip :color="locality.localityColor" variant="flat" style="margin-right: 10px;">
                        <p :style="{color: locality.localityFontColor}">{{ locality.productStockAmount }}</p>
                      </v-chip>
                    </div>
                  </div>
                </div>
                <template v-slot:activator="{ props }">
                  <v-chip v-bind="props" color="indigo" label style="margin-right: 20px; font-size: larger; cursor: pointer;">
                    <v-icon icon="mdi-warehouse" start></v-icon>
                    {{ display.product.productStock.filter(productStockByLocality => productStockByLocality.localityType == 'Bodega').reduce((productStockAmount, product) => productStockAmount + product.productStockAmount, 0) }}
                  </v-chip>
                </template>
              </v-tooltip>
              <v-tooltip text="Ranking del producto" location="top">
                <template v-slot:activator="{ props }">
                  <v-chip v-bind="props" color="purple" label style="margin-right: 20px; font-size: larger; cursor: pointer;">
                    <v-icon icon="mdi-medal" start></v-icon>
                    {{ display.product.productRanking }}
                  </v-chip>
                </template>
              </v-tooltip>
            </div>
          </div>
        </div>
        <v-divider style="margin-top: 20px; margin-bottom: 40px;"></v-divider>
        <div style="display: flex; justify-content: space-between;">
          <div style="flex-grow: 1;">
            <div style="display: flex;">
              <v-card elevation="3" style="width: 49%; margin-right: 1%; padding: 20px;">
                <div style="display: flex;">
                  <h2>Ventas por localidad:</h2>
                  <h1 style="margin-left: auto;">{{ display.product.productSale.reduce((productSaleAmount, productSaleByLocality) => productSaleAmount + productSaleByLocality.billProductAmount, 0) }}</h1>
                </div>
                <br>
                <apexchart type="pie" width="500" :options="display.productSaleChartOptions" :series="display.productSaleChartSeries"></apexchart>
              </v-card>
              <v-card elevation="3" style="width: 49%; margin-left: 1%; padding: 20px;">
                <div style="display: flex;">
                  <h2>Ventas por contacto:</h2>
                  <h1 style="margin-left: auto;">{{ display.product.productWholesale.reduce((productWholesaleAmount, productWholesaleByContact) => productWholesaleAmount + productWholesaleByContact.billProductAmount, 0) }}</h1>
                </div>                
                <br>
                <apexchart type="pie" width="500" :options="display.productWholesaleChartOptions" :series="display.productWholesaleChartSeries"></apexchart>
              </v-card>
            </div>
            <br>
             
            <v-card elevation="3" style="padding: 20px;">
              <h2>Contactos al por mayor:</h2>
              <br>
              <v-data-table-virtual :headers="display.wholesaleHeaders" :items="display.product.productWholesale">
                <template v-slot:item.contactName="{ item }">
                  <div style="cursor: pointer;" @click="$generalFunctions.default.methods.navigateTo(`contact/${item.contactID}`)">
                    {{ item.contactName }}
                  </div>
                </template>
                <template v-slot:item.lastSale="{ item }">
                  {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.lastSale) }}
                </template>
              </v-data-table-virtual> 
            </v-card>
          </div>
        </div>
        <br><br>
        <v-card elevation="3" style="padding: 20px 20px 0px 20px;">
          <h2>Comentarios del producto: </h2>
          <div v-if="display.product.productComment.length != 0">
            <br><br>
            <div style="max-height: 400px; overflow-y: auto;">
              <div v-for="productComment in display.product.productComment" v-bind:key="productComment">
                <div style="background-color: #ededed; border-radius: 20px; padding: 15px;">
                  <div style="display: flex;">
                    <v-chip :color="productComment.agentColor" variant="flat">
                      <p :style="{color: productComment.agentFontColor}">{{ productComment.agentName }}</p>
                    </v-chip>
                    <p style="margin-left: auto;">{{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(productComment.productCommentDatetime) }}</p>
                  </div>
                  <br>
                  <p v-html="productComment.productCommentText.replace(/\n/g, '<br>')"></p>
                </div>
                <br>
              </div>
            </div>
          </div>
          <br>
          <v-textarea @click:append-inner="insertProductComment()" v-model="input.productCommentText" rows="2" append-inner-icon="mdi-send" label="Escribe un comentario" variant="solo-filled"></v-textarea>
        </v-card>
      </div>
    </v-card>
    <br><br>
  </div>
</template>

<style scoped>
  .productLowStockAlert{
    margin-left: 20px; 
    margin-top: 5px; 
    font-size: larger; 
    cursor: pointer;
    animation: productLowStockAlertAnimation 2s infinite;
  }

  @keyframes productLowStockAlertAnimation {
    0% {transform: scale(1)}
    50% {transform: scale(1.4)}
    100% {transform: scale(1)}
  }
</style>


<script>
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'ProductView',
  inject: ['$generalFunctions'],

  data: () => ({
    input: 
    {
      selectedProductID: null,
      searchedProductID: null,
      startDate: null,
      endDate: null,
      productCommentText: null
    },

    display: 
    {
      product: null,
      startDateFormatted: null,
      endDateFormatted: null,

      productSaleChartSeries: [],
      productSaleChartOptions: {},
      productWholesaleChartSeries: [],
      productWholesaleChartOptions: {},

      wholesaleHeaders:
      [
        {key: 'contactName', title: 'NOMBRE'},
        {key: 'lastSale', title: 'ÚLTIMA COMPRA'},
        {key: 'billProductAmount', title: 'CANTIDAD'}
      ],
    },

    navigation: 
    {
      showHiddenAmount: false,
      showHiddenAmountInterval: null
    },

    loader:
    {
      selectProductBasicBasicInformationForBillsView: false,
      selectProductFromProductView: false
    },

    localStorage:
    {
      agentID: null
    },

    accessCredential: {}
  }),

  watch: {
    'input.selectedProductID'(){
      this.input.selectedProductID != null && this.selectProductFromProductView();
    },
    'input.startDate': async function() {
      if (this.input.startDate){
        this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB');
        this.navigation.startDatePickerIsVisible = false;
        this.selectProductFromProductView();
      } else {
        this.display.startDateFormatted = null;
      }
    },
    'input.endDate': async function() {
      if (this.input.endDate){
        this.display.endDateFormatted = new Date(this.input.endDate).toLocaleDateString('en-GB');
        this.navigation.endDatePickerIsVisible = false;
        this.selectProductFromProductView();
      } else {
        this.display.endDateFormatted = null;
      }
    }
  },

  methods: {
    showHiddenAmount(){
      this.navigation.showHiddenAmount = true;
      this.navigation.showHiddenAmountInterval = setInterval(async () => {
        this.navigation.showHiddenAmount = false;
      }, 15000);
    },


    async insertProductComment(){
      const insertProductCommentRequestQuery = 
      {
        'productCommentProductID': this.input.selectedProductID,
        'productCommentAgentID': this.localStorage.agentID,
        'productCommentText': this.input.productCommentText
      };
      const insertProductCommentResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productComment/insert', insertProductCommentRequestQuery);
      if (insertProductCommentResult.success){
        this.selectProductFromProductView();
        this.input.productCommentText = null;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al crear el comentario, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async generateChart(){
      const productSale = JSON.parse(JSON.stringify(this.display.product.productSale)).sort((a, b) => b.billProductAmount - a.billProductAmount);
      this.display.productSaleChartSeries = productSale.map(productSaleByLocality => productSaleByLocality.billProductAmount);
      this.display.productSaleChartOptions = 
      {
        chart: 
        {
          width: 500,
          type: 'pie',
        },
        labels: productSale.map(productSaleByLocality => productSaleByLocality.localityName),
        colors: productSale.map(productSaleByLocality => productSaleByLocality.localityColor)
      };

      const productWholesale = JSON.parse(JSON.stringify(this.display.product.productWholesale));
      this.display.productWholesaleChartSeries = productWholesale.map(productSaleByLocality => productSaleByLocality.billProductAmount);
      this.display.productWholesaleChartOptions = 
      {
        chart: 
        {
          width: 500,
          type: 'pie',
        },
        labels: productWholesale.map(productSaleByLocality => productSaleByLocality.contactName)
      };
    },
    
    async selectProductFromProductView(){
      this.loader.selectProductFromProductView = true;
      this.input.showWarehouseStock = true;
      const selectProductFromProductViewRequestQuery = 
      {
        'productID': this.input.selectedProductID,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate
      };
      const selectProductFromProductViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductFromProductView', selectProductFromProductViewRequestQuery);
      if (selectProductFromProductViewResult.success){
        this.display.product = selectProductFromProductViewResult.result;
        await this.generateChart(); 
        this.$generalFunctions.default.methods.navigateTo(`/product/${this.input.selectedProductID}`);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la información del producto, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
        await this.setDefaultValues();
      }
      this.loader.selectProductFromProductView = false;
    },

    selectProductID(){
      const selectedProduct = this.display.productOptions.find(productOption => productOption.productSKU === this.input.searchedProductID);
      if (selectedProduct){
        const selectedProductID = selectedProduct.productID;
        this.input.selectedProductID = selectedProductID;
        this.blurInput('selectedProductIDInput');
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': null,
          'notificationDialogBody': 'El producto escaneado no ha sido encontrado, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
      }
    },
    
    async selectProductBasicBasicInformationForBillsView(){
      this.loader.selectProductBasicBasicInformationForBillsView = true;
      const selectProductBasicBasicInformationForBillsViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductBasicBasicInformationForBillsView');
      if (selectProductBasicBasicInformationForBillsViewResult.success){
        this.display.productOptions = selectProductBasicBasicInformationForBillsViewResult.result.map(productOption => ({
          'productID': productOption.i,
          'productSKU': productOption.s,
          'productName': productOption.n,
          'productAutocompleteTitle': productOption.s + ' - ' + productOption.n
        }));
        this.loader.selectProductBasicBasicInformationForBillsView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    openProductHistory(){
      this.$generalFunctions.default.methods.navigateTo(`/productHistory/${this.display.product.productID}`);
    },

    openUpdateProduct(){
      this.$root.insertProductDialog.openInsertProductDialog(this.display.product.productID);
    },

    blurInput(inputReference){
      const blurInputInterval = setInterval(() => {
        if (this.$refs[inputReference]) {
          this.$refs[inputReference].blur();
          clearInterval(blurInputInterval);
        }
      }, 1);
    },

    clearSelectProductIDInput(){
      this.input.selectedProductID = null;
      this.input.searchedProductID = null;
    },

    async parseURL(){
      const currentRoute = this.$route.fullPath;
      const productID = currentRoute.split('/')[currentRoute.split('/').length - 1];
      if (productID != 'product'){
        this.input.selectedProductID = productID;
      }
    },


    async setDefaultValues(){
      this.input.selectedLocalityID = null;
      this.input.selectedProductID = null;
      this.input.searchedProductID = null;
      this.input.showWarehouseStock = true;
      this.display.product = null;
      this.loader.selectProductBasicBasicInformationForBillsView = false;
      this.loader.selectProductHistory = false;
    },

    async getLocalStorageData(){
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    },

  },
  
  async mounted(){
    await this.setDefaultValues();
    await this.getLocalStorageData();
    await this.parseURL();
    await this.selectProductBasicBasicInformationForBillsView();
  },

  beforeUnmount(){
    if (this.navigation.showHiddenAmountInterval){
      clearInterval(this.navigation.showHiddenAmountInterval);
    }
  }
});
</script>
