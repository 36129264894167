<template>
  <div style="margin: 2% 3% 0% 3%;">
    <v-card style="padding: 1.5%; background-color: #e9e8e8;" elevation="3">
      <div style="display: flex;">
        <div style="width: 50%;">
          <v-select v-if="accessCredential['/productChange/selectLocality']" v-model="input.selectedLocalityID" :items="display.localityOptions" :loading="loader.selectLocalityIDAndLocalityNameByLocalityIsActive" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive" item-title="localityName" item-value="localityID" append-inner-icon="mdi-store" label="Filtrar por localidad" variant="solo-filled"></v-select>
        </div>
        <div style="width: 48%; margin-left: 2%;">
          <v-text-field v-model="display.startDateFormatted" @click="navigation.startDatePickerIsVisible = true" readonly label="Filtrar por fecha inicial" append-inner-icon="mdi-calendar" variant="solo-filled"></v-text-field>
          <v-dialog v-model="navigation.startDatePickerIsVisible" width="auto">
            <v-card style="display: flex;">
              <v-date-picker v-model="input.startDate" hide-header color="primary" width="100%"></v-date-picker>
              <v-chip @click="input.startDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
            </v-card>
          </v-dialog>
          <v-text-field v-model="display.endDateFormatted" @click="navigation.endDatePickerIsVisible = true" readonly label="Filtrar por fecha final" append-inner-icon="mdi-calendar" variant="solo-filled"></v-text-field>
          <v-dialog v-model="navigation.endDatePickerIsVisible" width="auto">
            <v-card>
              <v-date-picker v-model="input.endDate" hide-header color="primary" width="100%"></v-date-picker>
              <v-chip @click="input.endDate = new Date()" style="margin: 0% 5% 5% 5%; text-align: center; cursor: pointer;" color="primary" variant="flat">Hoy</v-chip>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <v-select v-model="input.selectedProductChangeState" :items="display.productChangeStateOptions" append-inner-icon="mdi-text-box-outline" label="Filtrar por estado del cambio de producto" variant="solo-filled"></v-select>
      <v-text-field v-model="input.productChangeBillID" label="Buscar por código de la factura" append-inner-icon="mdi-barcode" variant="solo-filled"></v-text-field>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-title>
            Búsqueda avanzada por producto
            <template v-slot:actions>
              <v-icon icon="mdi-magnify"></v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <br>
            <v-autocomplete 
              @keydown.enter.prevent="selectProductID()" 
              v-model="input.selectedProductIDS" 
              v-model:search="input.searchedProductID"
              :items="display.productOptions" 
              :loading="loader.selectProductBasicBasicInformationForBillsView" 
              :disabled="loader.selectProductBasicBasicInformationForBillsView" 
              multiple 
              chips 
              ref="selectedProductIDSInput"
              item-title="productAutocompleteTitle" 
              item-value="productID" 
              append-inner-icon="mdi-source-branch" 
              label="Filtrar por producto(s)" 
              variant="solo-filled"
            ></v-autocomplete>
            <v-autocomplete v-model="input.selectedProductBrand" :items="display.productBrandOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productBrandName" item-value="productBrandID" append-inner-icon="mdi-bookshelf" label="Filtrar por marca del producto" variant="solo-filled"></v-autocomplete>
            <v-autocomplete v-model="input.selectedProductFlavor" :items="display.productFlavorOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productFlavorName" item-value="productFlavorID" append-inner-icon="mdi-water" label="Filtrar por sabor del producto" variant="solo-filled"></v-autocomplete>
            <v-autocomplete v-model="input.selectedProductNicotineLevel" :items="display.productNicotineLevelOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productNicotineLevelName" item-value="productNicotineLevelID" append-inner-icon="mdi-weather-cloudy" label="Filtrar por graduación de nicotina del producto" variant="solo-filled"></v-autocomplete>
            <v-autocomplete v-model="input.selectedProductSize" :items="display.productSizeOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productSizeName" item-value="productSizeID" append-inner-icon="mdi-ruler" label="Filtrar por tamaño del producto" variant="solo-filled"></v-autocomplete>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <br><br>
      <v-btn @click="selectProductChangeOnProductChangeView()" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive || loader.selectProductBasicBasicInformationForBillsView || loader.selectProductChangeOnProductChangeView" dark block height="38" color="primary">
        <h3>BUSCAR</h3>
        <v-icon style="margin-left: 20px;" dark right>mdi-magnify</v-icon>
      </v-btn>
      <br>
      <v-btn @click="setDefaultValues()" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive || loader.selectProductBasicBasicInformationForBillsView || loader.selectProductChangeOnProductChangeView" dark block height="38" color="#ffd978">
        <h3>LIMPIAR</h3>
        <v-icon style="margin-left: 20px;" dark right>mdi-backspace</v-icon>
      </v-btn>
    </v-card>
    <br><br>
    <v-card v-if="loader.selectProductChangeOnProductChangeView || (display.productChangeRows.length > 0)" style="max-height: 1000px; overflow-y: auto; padding: 1%; background-color: #e9e8e8;" elevation="3">
      <div v-if="loader.selectBillOnBillsView" style="text-align: center;">
        <br>
        <v-progress-circular color="info" indeterminate></v-progress-circular>
        <br><br>
      </div>
      <v-data-table-virtual v-else :headers="display.productChangeHeaders" :items="display.productChangeRows">
        <template v-slot:item.productChangeState="{ item }">
          <div style="display: flex; margin-top: 10px; margin-bottom: 10px;">
            <v-chip :color="localStorage.productChangeStateColors[item.productChangeState].productChangeStateColor" variant="flat">
              <p :style="{color: localStorage.productChangeStateColors[item.productChangeState].productChangeStateFontColor}">{{ item.productChangeState }}</p>
            </v-chip>
            <p v-if="item.approverAgentName" style="margin-left: 10px; margin-top: 5px;">por</p>
            <v-chip v-if="item.approverAgentName" :color="item.approverAgentColor" variant="flat" style="margin-left: 10px;">
              <p :style="{color: item.approverAgentFontColor}">{{ item.approverAgentName }}</p>
            </v-chip>
          </div>
          <p v-if="item.productChangeApprovedDatetime" style="margin-bottom: 10px;"><strong>Fecha:</strong> {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.productChangeApprovedDatetime) }}</p>
        </template>
        <template v-slot:item.billLocalityName="{ item }">
          <p style="margin-top: 10px;"><strong>ID de la factura:</strong> {{ item.productChangeBillID }}</p>
          <div style="display: flex; margin-top: 10px; margin-bottom: 10px;">
            <v-chip :color="item.billLocalityColor" variant="flat">
              <p :style="{color: item.billLocalityFontColor}">{{ item.billLocalityName }}</p>
            </v-chip>
            <p style="margin-left: 10px; margin-top: 5px;">por</p>
            <v-chip :color="item.billAgentColor" variant="flat" style="margin-left: 10px;">
              <p :style="{color: item.billAgentFontColor}">{{ item.billAgentName }}</p>
            </v-chip>
          </div>
          <p style="margin-bottom: 10px;"><strong>Fecha:</strong> {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.billCreationDatetime) }}</p>
        </template>
        <template v-slot:item.productChangeLocalityName="{ item }">
          <p style="margin-top: 10px;"><strong>ID del cambio de producto:</strong> {{ item.productChangeID }}</p>
          <div style="display: flex; margin-top: 10px; margin-bottom: 10px;">
            <v-chip :color="item.productChangeLocalityColor" variant="flat">
              <p :style="{color: item.productChangeLocalityFontColor}">{{ item.productChangeLocalityName }}</p>
            </v-chip>
            <p style="margin-left: 10px; margin-top: 5px;">por</p>
            <v-chip :color="item.requesterAgentColor" variant="flat" style="margin-left: 10px;">
              <p :style="{color: item.requesterAgentFontColor}">{{ item.requesterAgentName }}</p>
            </v-chip>
          </div>
          <p style="margin-bottom: 10px;"><strong>Fecha:</strong> {{ $generalFunctions.default.methods.parseDatetimeToDDMMYYYHHMMSS(item.productChangeDatetime) }}</p>
        </template>
        <template v-slot:item.productChangeProducts="{ item }">
          <div style="display: flex;">
            <div style="max-width: 200px; margin-right: 10px;">
              <div v-for="productChangeOldProduct in item.productChangeProducts.filter(productChangeProduct => productChangeProduct.productChangeProductType === 'oldProduct')" :key="productChangeOldProduct">
                {{ productChangeOldProduct.productName }}
              </div>
            </div>
            <div style="display: flex; justify-content: center; align-items: center;">
              <v-icon color="info" icon="mdi-arrow-right-bold" size="large"></v-icon>
            </div>
            <div>
              </div>
              <div style="max-width: 200px; margin-left: 10px;">
              <div v-for="productChangeNewProduct in item.productChangeProducts.filter(productChangeProduct => productChangeProduct.productChangeProductType === 'newProduct')" :key="productChangeNewProduct">
                {{ productChangeNewProduct.productName }}
              </div>
            </div>
          </div>
        </template>
        <template v-slot:item.productChangeAction="{ item }">
          <div v-if="item.loader" style="text-align: center;">
            <v-progress-circular color="success" indeterminate></v-progress-circular>
          </div>
          <div v-else>
            <div style="display: flex;">
              <div style="display: flex;" v-if="item.productChangeState == 'Cambio solicitado'">
                <v-btn @click="updateProductChange(item.productChangeID, 'Aprobar cambio')" dark height="38" color="success">
                  <h3>APROBAR</h3>
                </v-btn>
                <v-btn @click="updateProductChange(item.productChangeID, 'Rechazar cambio')" dark height="38" color="error" style="margin-left: 10px;">
                  <h3>RECHAZAR</h3>
                </v-btn>
              </div>
              <v-icon @click="generateProductChangeApprovePDFDocument(item)" style="margin-left: 10px;" color="success">mdi-printer</v-icon>
            </div>            
          </div>
             
        </template>
      </v-data-table-virtual>
    </v-card>
    <br><br>
  </div>
</template>

<style scoped>
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProductChangeView',
  inject: ['$generalFunctions'],

  
  data: () => ({
    input: 
    {
      selectedLocalityID: null,
      startDate: null,
      endDate: null,
      selectedProductChangeState: null,
      productChangeBillID: null,
      selectedProductIDS: [],
      searchedProductID: null,
      selectedProductBrand: null,
      selectedProductFlavor: null,
      selectedProductNicotineLevel: null,
      selectedProductSize: null,
    },

    navigation: 
    {
      startDatePickerIsVisible: false,
      endDatePickerIsVisible: false
    },

    loader: 
    {
      selectLocalityIDAndLocalityNameByLocalityIsActive: false,
      selectProductBasicBasicInformationForBillsView: false,
      selectProductSearchParameters: false,
      selectProductChangeOnProductChangeView: false
    },

    display:
    {
      localityOptions: [],
      startDateFormatted: '',
      endDateFormatted: '',
      productChangeStateOptions: ['Cambio solicitado', 'Cambio aprobado', 'Cambio rechazado', 'Cambio en revisión'],
      productOptions: [],
      productBrandOptions: [],
      productFlavorOptions: [],
      productNicotineLevelOptions: [],
      productSizeOptions: [],
      
      productChangeRows: [],
      productChangeHeaders: 
      [
        {key: 'productChangeState', title: 'ESTADO DE LA SOLICITUD'},
        {key: 'billLocalityName', title: 'FACTURA'},
        {key: 'productChangeLocalityName', title: 'SOLICITUD DE CAMBIO'},
        {key: 'productChangeProducts', title: 'PRODUCTOS'},
        {key: 'productChangeAction', title: null},
      ],
      productChangeActionOptions: 
      [
        {title: 'Aprobar cambio', icon: 'mdi-check-circle-outline'},
        {title: 'Rechazar cambio', icon: 'mdi-close-circle-outline'}

      ],
    },

    accessCredential: {},

    localStorage: 
    {
      agentID: null,
      localityID: null,
      productChangeStateColors: null
    }

  }),

  watch: {
    'input.startDate': async function() {
      this.display.startDateFormatted = new Date(this.input.startDate).toLocaleDateString('en-GB');
      this.navigation.startDatePickerIsVisible = false;
    },
    'input.endDate': async function() {
      this.display.endDateFormatted = new Date(this.input.endDate).toLocaleDateString('en-GB');
      this.navigation.endDatePickerIsVisible = false;
    }
  },

  methods: {
    async updateProductChange(productChangeID, productChangeState){
      this.loader.selectProductChangeOnProductChangeView = true;
      const updateProductChangeRequestQuery = 
      {
        'productChangeID': productChangeID,
        'productChangeApproverAgentID': this.localStorage.agentID,
        'productChangeState': productChangeState == 'Aprobar cambio' ? 'Cambio aprobado' : 'Cambio rechazado',
      };
      const updateProductChangeResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productChange/functions/updateProductChange', updateProductChangeRequestQuery);
      if (updateProductChangeResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': `El cambio ha sido ${productChangeState == 'Aprobar cambio' ? 'aprobado' : 'rechazado'} exitosamente`,
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': updateProductChangeResult.result.PDFDocumentFile.data,
          'printablePDFDocumentName': updateProductChangeResult.result.PDFDocumentName
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
        await this.selectProductChangeOnProductChangeView();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': `Ha ocurrido un error inesperado al ${productChangeState == 'Aprobar cambio' ? 'aprobar' : 'rechazar'} el cambio de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema`,
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectProductChangeOnProductChangeView = false;
    },


    blurInput(inputReference){
      const blurInputInterval = setInterval(() => {
        if (this.$refs[inputReference]) {
          this.$refs[inputReference].blur();
          clearInterval(blurInputInterval);
        }
      }, 1);
    },

    selectProductID(){
      const selectedProduct = this.display.productOptions.find(productOption => productOption.productSKU === this.input.searchedProductID);
      if (selectedProduct){
        const selectedProductID = selectedProduct.productID;
        if (!this.input.selectedProductIDS.includes(selectedProductID)){
          this.input.selectedProductIDS.push(selectedProduct.productID);
          this.blurInput('selectedProductIDSInput');
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': null,
            'notificationDialogBody': 'El producto escaneado ya se encuentra en la lista de productos seleccionados',
            'notificationDialogColor': 'info',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': null,
          'notificationDialogBody': 'El producto escaneado no ha sido encontrado, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
      }
      this.input.searchedProductID = null;
    },

    async selectProductChangeOnProductChangeView(){
      this.loader.selectProductChangeOnProductChangeView = true;
      const selectProductChangeOnProductChangeViewRequestQuery = 
      {
        'productChangeBillID': this.input.productChangeBillID,
        'productChangeLocalityID': this.accessCredential['/productChange/selectLocality'] ? this.input.selectedLocalityID : this.localStorage.localityID,
        'productChangeState': this.input.selectedProductChangeState,
        'startDate': this.input.startDate,
        'endDate': this.input.endDate,
        'productChangeProducts': this.input.selectedProductIDS,
        'productBrandID': this.input.selectedProductBrand,
        'productFlavorID': this.input.selectedProductFlavor,
        'productNicotineLevelID': this.input.selectedProductNicotineLevel,
        'productSizeID': this.input.selectedProductSize
      };
      const selectProductChangeOnProductChangeViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productChange/functions/selectProductChangeOnProductChangeView', selectProductChangeOnProductChangeViewRequestQuery);
      if (selectProductChangeOnProductChangeViewResult.success){
        this.display.productChangeRows = selectProductChangeOnProductChangeViewResult.result;
        if (this.display.productChangeRows.length == 0){
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': null,
            'notificationDialogBody': 'No se ha encontrado ningun cambio de producto con las características especificadas, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar los cambios de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectProductChangeOnProductChangeView = false;
    },


    async selectLocalityIDAndLocalityNameByLocalityIsActive(){
      this.loader.selectLocalityIDAndLocalityNameByLocalityIsActive = true;
      const selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery = {'localityIsActive': true};
      const selectLocalityIDAndLocalityNameByLocalityIsActiveResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/locality/select/localityIDAndLocalityNameByLocalityIsActive', selectLocalityIDAndLocalityNameByLocalityIsActiveRequestQuery);
      if (selectLocalityIDAndLocalityNameByLocalityIsActiveResult.success){
        this.display.localityOptions = 
        [
          {'localityID': null, 'localityName': 'Todas las localidades'}, 
          ...selectLocalityIDAndLocalityNameByLocalityIsActiveResult.result
        ];
        this.loader.selectLocalityIDAndLocalityNameByLocalityIsActive = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar las localidades, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductBasicBasicInformationForBillsView(){
      this.loader.selectProductBasicBasicInformationForBillsView = true;
      const selectProductBasicBasicInformationForBillsViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductBasicBasicInformationForBillsView');
      if (selectProductBasicBasicInformationForBillsViewResult.success){
        this.display.productOptions = selectProductBasicBasicInformationForBillsViewResult.result.map(productOption => ({
          'productID': productOption.i,
          'productSKU': productOption.s,
          'productName': productOption.n,
          'productAutocompleteTitle': productOption.s + ' - ' + productOption.n
        }));
        this.loader.selectProductBasicBasicInformationForBillsView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductSearchParameters(){
      this.loader.selectProductSearchParameters = true;
      const selectProductSearchParametersResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductSearchParameters');
      if (selectProductSearchParametersResult.success){
        this.display.productBrandOptions = selectProductSearchParametersResult.result.productBrand;
        this.display.productFlavorOptions = selectProductSearchParametersResult.result.productFlavor;
        this.display.productNicotineLevelOptions = selectProductSearchParametersResult.result.productNicotineLevel;
        this.display.productSizeOptions = selectProductSearchParametersResult.result.productSize;
        this.loader.selectProductSearchParameters = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de características de los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async generateProductChangeApprovePDFDocument(productChange){
      productChange.loader = true;
      const generateProductChangeApprovePDFDocumentRequestQuery =
      {
        'productChangeID': productChange.productChangeID,
        'reprint': true
      };
      const generateProductChangeApprovePDFDocumentResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/productChange/functions/generateProductChangeApprovePDFDocument', generateProductChangeApprovePDFDocumentRequestQuery);
      if (generateProductChangeApprovePDFDocumentResult.success){
        const printablePDFExcelDocumentDialogRequestQuery = 
        {
          'printablePDFDocumentFile': generateProductChangeApprovePDFDocumentResult.result.PDFDocumentFile.data,
          'printablePDFDocumentName': generateProductChangeApprovePDFDocumentResult.result.PDFDocumentName
        };
        this.$root.printablePDFExcelDocumentDialog.openPrintablePDFExcelDocumentDialog(printablePDFExcelDocumentDialogRequestQuery);
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al generar el reporte del cambio de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      productChange.loader = false;
    },

    async setDefaultValues(){
      
    },

    async getLocalStorageData(){
      this.localStorage.agentID = localStorage.getItem('agentID');
      this.localStorage.localityID = localStorage.getItem('localityID');
      this.localStorage.productChangeStateColors = this.$generalFunctions.default.data.productChangeStateColors;
      this.accessCredential = await this.$generalFunctions.default.methods.validateCredentialAccess(this.localStorage.agentID);
    },

  },

  async mounted(){
    await this.getLocalStorageData();
    await this.selectLocalityIDAndLocalityNameByLocalityIsActive();
    await this.selectProductBasicBasicInformationForBillsView();
    await this.selectProductSearchParameters();
    this.setDefaultValues();
  }

});

</script>
