<template>
  <div style="margin: 2% 3% 0% 3%;">
    <v-dialog v-model="navigation.updateBunchProductDialogIsVisible" width="auto" :persistent="true">
      <v-card width="500" style="padding: 20px;">
        <div v-if="loader.updateBunchProduct" style="text-align: center;">
          <br>
          <v-progress-circular color="success" indeterminate></v-progress-circular>
          <br><br>
        </div>
        <div v-else>
          <div v-if="navigation.updatingBunchProductField == 'productCost' || navigation.updatingBunchProductField == 'productPrice' || navigation.updatingBunchProductField == 'productMaxDiscountMargin' || navigation.updatingBunchProductField == 'productWholesaleColonPrice' || navigation.updatingBunchProductField == 'productWholesaleDolarPrice'">
            <v-text-field 
              v-model="input.updatingProductBunchValue"
              :label="`Nuevo ${navigation.updatingBunchProductField == 'productCost' ? 'precio de costo de los productos' : (navigation.updatingBunchProductField == 'productPrice' ? 'precio de venta de los productos' : (navigation.updatingBunchProductField == 'productMaxDiscountMargin' ? 'descuento porcentual máximo de los productos' : (navigation.updatingBunchProductField == 'productWholesaleColonPrice' ? 'precio de venta al por mayor en colones' : 'precio de venta al por mayor en dólares')))}`"
              type="number" 
              variant="solo-filled" 
              hide-spin-buttons
            >
            </v-text-field>
          </div>
          <div v-else>
            <v-select 
              v-model="input.updatingProductBunchValue" 
              :items="display.updatingProductBunchOptions" 
              :label="`Los productos ${navigation.updatingBunchProductField == 'productIsReplacedDaily' ? 'se reponen de forma diaria' : (navigation.updatingBunchProductField == 'productIsVisible' ? 'son visibles en línea' : 'están activos')}`"
              item-title="title" 
              item-value="value" 
              variant="solo-filled"
            >
            </v-select>
          </div>
          <br>
          <v-btn @click="updateBunchProduct()" dark height="38" color="success" block>
            <h3>ACTUALIZAR</h3>
            <v-icon style="margin-left: 10px;" dark right>mdi-check-bold</v-icon>
          </v-btn>
          <v-btn @click="navigation.updateBunchProductDialogIsVisible = false" dark height="38" color="error" block style="margin-top: 10px;">
            <h3>CANCELAR</h3>
            <v-icon style="margin-left: 10px;" dark right>mdi-close-circle-outline</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-card style="padding: 1.5%; background-color: #e9e8e8;" elevation="3">
      <v-expansion-panels v-model="navigation.searchVisible">
        <v-expansion-panel value="searchVisible">
          <v-expansion-panel-title>
            <p style="font-size: large;">Búsqueda de productos</p>
            <template v-slot:actions>
              <v-icon icon="mdi-magnify"></v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <br>
            <div style="display: flex;">
              <div style="width: 69%; margin-right: 1%;">
                <v-autocomplete 
                  @keydown.enter.prevent="selectProductID" 
                  v-model="input.selectedProductID" 
                  v-model:search="input.searchedProductID"
                  :items="display.productOptions" 
                  :loading="loader.selectProductBasicBasicInformationForBillsView" 
                  :disabled="loader.selectProductBasicBasicInformationForBillsView" 
                  ref="selectedProductIDInput"
                  item-title="productAutocompleteTitle" 
                  item-value="productID" 
                  return-object
                  append-inner-icon="mdi-alpha-a-box-outline" 
                  label="Filtrar por producto" 
                  variant="solo-filled"
                ></v-autocomplete>
              </div>
              <div style="width: 29%; margin-left: 1%;">
                <div style="display: flex;">
                  <div style="width: 49%; margin-right: 1%;">
                    <v-switch v-model="input.showMainProduct" color="info" label="Mostrar productos madre" hide-details></v-switch>
                  </div>
                  <div style="width: 49%; margin-left: 1%;">
                    <v-switch v-model="input.showInactiveProduct" color="error" label="Mostrar productos inactivos" hide-details></v-switch>
                  </div>
                </div>
              </div>
            </div>
            <div style="display: flex;">
              <div style="width: 49.5%; margin-right: 0.5%;">
                <v-autocomplete v-model="input.selectedProductCategory" :items="display.productCategoryOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productCategoryName" item-value="productCategoryID" append-inner-icon="mdi-cog" label="Filtrar por categoría del producto" variant="solo-filled"></v-autocomplete>
                <v-autocomplete v-model="input.selectedProductBrand" :items="display.productBrandOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productBrandName" item-value="productBrandID" append-inner-icon="mdi-bookshelf" label="Filtrar por marca del producto" variant="solo-filled"></v-autocomplete>
                <v-autocomplete v-model="input.selectedProductModel" :items="display.productModelOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productModelName" item-value="productModelID" append-inner-icon="mdi-source-branch" label="Filtrar por modelo del producto" variant="solo-filled"></v-autocomplete>
              </div>
              <div style="width: 49.5%; margin-left: 0.5%;">
                <v-autocomplete v-model="input.selectedProductFlavor" :items="display.productFlavorOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productFlavorName" item-value="productFlavorID" append-inner-icon="mdi-water" label="Filtrar por sabor del producto" variant="solo-filled"></v-autocomplete>
                <v-autocomplete v-model="input.selectedProductNicotineLevel" :items="display.productNicotineLevelOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productNicotineLevelName" item-value="productNicotineLevelID" append-inner-icon="mdi-weather-cloudy" label="Filtrar por graduación de nicotina del producto" variant="solo-filled"></v-autocomplete>
                <v-autocomplete v-model="input.selectedProductSize" :items="display.productSizeOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" item-title="productSizeName" item-value="productSizeID" append-inner-icon="mdi-ruler" label="Filtrar por tamaño del producto" variant="solo-filled"></v-autocomplete>
              </div>
            </div>
            <v-autocomplete v-model="input.selectedProductTags" :items="display.productTagOptions" :loading="loader.selectProductSearchParameters" :disabled="loader.selectProductSearchParameters" multiple chips item-title="productTagName" item-value="productTagID" append-inner-icon="mdi-tag" label="Filtrar por tags del producto" variant="solo-filled"></v-autocomplete>
            <br>
            <div v-if="loader.selectProductForProductsView" style="text-align: center;">
              <br>
              <v-progress-circular color="info" indeterminate></v-progress-circular>
              <br>
            </div>
            <div v-else>
              <v-btn @click="selectProductForProductsView()" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive || loader.selectAgentByAgentLocalityIDAndByAgentIsActive || loader.selectProductBasicBasicInformationForBillsView || loader.selectProductInAndOutForProductInAndOutView" dark block height="38" color="primary">
                <h3>BUSCAR</h3>
                <v-icon style="margin-left: 20px;" dark right>mdi-magnify</v-icon>
              </v-btn>
              <br>
              <v-btn @click="setDefaultValues()" :disabled="loader.selectLocalityIDAndLocalityNameByLocalityIsActive || loader.selectAgentByAgentLocalityIDAndByAgentIsActive || loader.selectProductBasicBasicInformationForBillsView || loader.selectProductBasicBasicInformationForBillsView" dark block height="38" color="#ffd978">
                <h3>LIMPIAR</h3>
                <v-icon style="margin-left: 20px;" dark right>mdi-backspace</v-icon>
              </v-btn>
            </div>
            <br>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <br><br>
    <v-card v-if="display.productRows.length != 0" style="padding: 1.5%; background-color: #e9e8e8; overflow-y: auto;" elevation="3">
      <v-data-table :headers="display.productHeaders" :items="display.productRows" :items-per-page="-1" hide-default-footer>
        <template v-slot:item="row">
          <tr :style="row.item.productSelected ? 'background-color: #c7e8fc;' : ''" @contextmenu.prevent="updateProductIsSelected(row.item)">
            <td>
              <v-checkbox @change="updateProductIsSelected(null)" v-model="row.item.productSelected" color="info" style="margin-top: 20px;"></v-checkbox>
              <div v-if="row.item.mainProductID ? (display.productRows.findIndex(productRow => (productRow.mainProductID) == row.item.mainProductID) == 0) : (display.productRows.findIndex(productRow => (productRow.productID) == row.item.productID) == 0)">
                <v-checkbox v-model="input.allProductSelected" @click="selectAllProduct()" color="info" style="position: absolute; top: 25px;"></v-checkbox>
              </div>
            </td>
            <td>
              {{ row.item.mainProductID || row.item.productID }}
            </td>
            <td>
              {{ row.item.productType == 'mainProduct' ? 'Producto madre' : 'Producto individual' }}
            </td>
            <td>
              <div v-if="row.item.productName">
                {{ row.item.productName }}
                <br>
                <strong>{{ row.item.productSKU }}</strong>
              </div>
              <p v-else>{{ row.item.mainProductName }}</p>
            </td>
            <td>
              <p v-if="display.productRows.some(productRow => productRow.productSelected || (productRow.products?.some(product => product.productSelected)))" @click="openUpdateBunchProductDialog('productCost')" style="cursor: pointer;">₡ {{ row.item.mainProductCost ? $generalFunctions.default.methods.parseAmountToDisplay(row.item.mainProductCost) : $generalFunctions.default.methods.parseAmountToDisplay(row.item.productCost) }}</p>
              <p v-else>₡ {{ row.item.mainProductCost ? $generalFunctions.default.methods.parseAmountToDisplay(row.item.mainProductCost) : $generalFunctions.default.methods.parseAmountToDisplay(row.item.productCost) }}</p>
            </td>
            <td>
              <p v-if="display.productRows.some(productRow => productRow.productSelected || (productRow.products?.some(product => product.productSelected)))" @click="openUpdateBunchProductDialog('productPrice')" style="cursor: pointer;">₡ {{ row.item.mainProductPrice ? $generalFunctions.default.methods.parseAmountToDisplay(row.item.mainProductPrice) : $generalFunctions.default.methods.parseAmountToDisplay(row.item.productPrice) }}</p>
              <p v-else>₡ {{ row.item.mainProductPrice ? $generalFunctions.default.methods.parseAmountToDisplay(row.item.mainProductPrice) : $generalFunctions.default.methods.parseAmountToDisplay(row.item.productPrice) }}</p>
            </td>

            <td>
              <div v-if="row.item.productType != 'mainProduct'">
                <p v-if="display.productRows.some(productRow => productRow.productSelected || (productRow.products?.some(product => product.productSelected)))" @click="openUpdateBunchProductDialog('productWholesaleColonPrice')" style="cursor: pointer;">₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(row.item.productWholesaleColonPrice) }}</p>
                <p v-else>₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(row.item.productWholesaleColonPrice) }}</p>
              </div>
            </td>

            <td>
              <div v-if="row.item.productType != 'mainProduct'">
                <p v-if="display.productRows.some(productRow => productRow.productSelected || (productRow.products?.some(product => product.productSelected)))" @click="openUpdateBunchProductDialog('productWholesaleDolarPrice')" style="cursor: pointer;">{{ $generalFunctions.default.methods.parseAmountToDisplay(row.item.productWholesaleDolarPrice) }} $</p>
                <p v-else>{{ $generalFunctions.default.methods.parseAmountToDisplay(row.item.productWholesaleDolarPrice) }} $</p>
              </div>
            </td>
            
            <td>
              <p v-if="display.productRows.some(productRow => productRow.productSelected || (productRow.products?.some(product => product.productSelected)))" @click="openUpdateBunchProductDialog('productMaxDiscountMargin')" style="cursor: pointer;">{{ row.item.mainProductMaxDiscountMargin || row.item.productMaxDiscountMargin }} %</p>
              <p v-else>{{ row.item.mainProductMaxDiscountMargin || row.item.productMaxDiscountMargin }} %</p>
            </td>
            <td>
              <p v-if="display.productRows.some(productRow => productRow.productSelected || (productRow.products?.some(product => product.productSelected)))" style="cursor: pointer;">{{ row.item.mainProductLocation || row.item.productLocation }}</p>
              <p v-else>{{ row.item.mainProductLocation || row.item.productLocation }}</p>
            </td>
            <td>
              <v-icon 
                v-if="display.productRows.some(productRow => productRow.productSelected || (productRow.products?.some(product => product.productSelected)))" 
                @click="openUpdateBunchProductDialog('productIsReplacedDaily')" 
                :color="(row.item.mainProductIsReplacedDaily ? row.item.mainProductIsReplacedDaily : row.item.productIsReplacedDaily) ? 'success' : 'error'" 
                style="cursor: pointer;"
              >
                {{ (row.item.mainProductIsReplacedDaily ? row.item.mainProductIsReplacedDaily : row.item.productIsReplacedDaily) ? 'mdi-check-bold' : 'mdi-close-thick' }}
              </v-icon>
              <v-icon v-else :color="(row.item.mainProductIsReplacedDaily ? row.item.mainProductIsReplacedDaily : row.item.productIsReplacedDaily) ? 'success' : 'error'" center>{{ (row.item.mainProductIsReplacedDaily ? row.item.mainProductIsReplacedDaily : row.item.productIsReplacedDaily) ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
            </td>
            <td>
              <v-icon 
                v-if="display.productRows.some(productRow => productRow.productSelected || (productRow.products?.some(product => product.productSelected)))" 
                @click="openUpdateBunchProductDialog('productIsVisible')" 
                :color="(row.item.mainProductIsVisible ? row.item.mainProductIsVisible : row.item.productIsVisible) ? 'success' : 'error'" 
                style="cursor: pointer;"
              >
                {{ (row.item.mainProductIsVisible ? row.item.mainProductIsVisible : row.item.productIsVisible) ? 'mdi-check-bold' : 'mdi-close-thick' }}
              </v-icon>
              <v-icon v-else :color="(row.item.mainProductIsVisible ? row.item.mainProductIsVisible : row.item.productIsVisible) ? 'success' : 'error'" center>{{ (row.item.mainProductIsVisible ? row.item.mainProductIsVisible : row.item.productIsVisible) ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
            </td>
            <td>
              <v-icon 
                v-if="display.productRows.some(productRow => productRow.productSelected || (productRow.products?.some(product => product.productSelected)))" 
                @click="openUpdateBunchProductDialog('productIsActive')" 
                :color="(row.item.mainProductIsActive ? row.item.mainProductIsActive : row.item.productIsActive) ? 'success' : 'error'" 
                style="cursor: pointer;"
              >
                {{ (row.item.mainProductIsActive ? row.item.mainProductIsActive : row.item.productIsActive) ? 'mdi-check-bold' : 'mdi-close-thick' }}
              </v-icon>
              <v-icon v-else :color="(row.item.mainProductIsActive ? row.item.mainProductIsActive : row.item.productIsActive) ? 'success' : 'error'" center>{{ (row.item.mainProductIsActive ? row.item.mainProductIsActive : row.item.productIsActive) ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
            </td>
            <td>
              <v-icon @click="openUpdateProduct(row.item)" color="warning" center>mdi-pencil</v-icon>
            </td>
          </tr>
          <template v-if="row.item.products">
            <tr v-for="(product, productIndex) in row.item.products" v-bind:key="product" :style="product.productSelected ? 'background-color: #c7e8fc;' : ''" @contextmenu.prevent="updateProductIsSelected(product)">
              <td :style="getTableSeparatorStyle(productIndex, row.item.products.length)">
                <v-checkbox @change="updateProductIsSelected(null)" v-model="product.productSelected" color="info" style="margin-top: 20px;"></v-checkbox>
              </td>
              <td :style="getTableSeparatorStyle(productIndex, row.item.products.length)">
                {{ product.productID }}
              </td>
              <td :style="getTableSeparatorStyle(productIndex, row.item.products.length)">
                Producto hijo
              </td>
              <td :style="getTableSeparatorStyle(productIndex, row.item.products.length)">
                {{ product.productName }}
                <strong>{{ product.productSKU }}</strong>
              </td>
              <td :style="getTableSeparatorStyle(productIndex, row.item.products.length)">
                <p v-if="display.productRows.some(productRow => productRow.productSelected || (productRow.products?.some(product => product.productSelected)))" @click="openUpdateBunchProductDialog('productCost')" style="cursor: pointer;">₡ {{ product.mainProductCost ? $generalFunctions.default.methods.parseAmountToDisplay(product.mainProductCost) : $generalFunctions.default.methods.parseAmountToDisplay(product.productCost) }}</p>
                <p v-else>₡ {{ product.mainProductCost ? $generalFunctions.default.methods.parseAmountToDisplay(product.mainProductCost) : $generalFunctions.default.methods.parseAmountToDisplay(product.productCost) }}</p>
              </td>
              <td :style="getTableSeparatorStyle(productIndex, row.item.products.length)">
                <p v-if="display.productRows.some(productRow => productRow.productSelected || (productRow.products?.some(product => product.productSelected)))" @click="openUpdateBunchProductDialog('productPrice')" style="cursor: pointer;">₡ {{ product.mainProductPrice ? $generalFunctions.default.methods.parseAmountToDisplay(product.mainProductPrice) : $generalFunctions.default.methods.parseAmountToDisplay(product.productPrice) }}</p>
                <p v-else>₡ {{ product.mainProductPrice ? $generalFunctions.default.methods.parseAmountToDisplay(product.mainProductPrice) : $generalFunctions.default.methods.parseAmountToDisplay(product.productPrice) }}</p>
              </td>

              <td :style="getTableSeparatorStyle(productIndex, row.item.products.length)">
                <p v-if="display.productRows.some(productRow => productRow.productSelected || (productRow.products?.some(product => product.productSelected)))" @click="openUpdateBunchProductDialog('productWholesaleColonPrice')" style="cursor: pointer;">₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(product.productWholesaleColonPrice) }}</p>
                <p v-else>₡ {{ $generalFunctions.default.methods.parseAmountToDisplay(product.productWholesaleColonPrice) }}</p>
              </td>

              <td :style="getTableSeparatorStyle(productIndex, row.item.products.length)">
                <p v-if="display.productRows.some(productRow => productRow.productSelected || (productRow.products?.some(product => product.productSelected)))" @click="openUpdateBunchProductDialog('productWholesaleDolarPrice')" style="cursor: pointer;">{{ $generalFunctions.default.methods.parseAmountToDisplay(product.productWholesaleDolarPrice) }} $</p>
                <p v-else>{{ $generalFunctions.default.methods.parseAmountToDisplay(product.productWholesaleDolarPrice) }} $</p>
              </td>

              <td :style="getTableSeparatorStyle(productIndex, row.item.products.length)">
                <p v-if="display.productRows.some(productRow => productRow.productSelected || (productRow.products?.some(product => product.productSelected)))" @click="openUpdateBunchProductDialog('productMaxDiscountMargin')" style="cursor: pointer;">{{ product.mainProductMaxDiscountMargin || product.productMaxDiscountMargin }} %</p>
                <p v-else>{{ product.mainProductMaxDiscountMargin || product.productMaxDiscountMargin }} %</p>
              </td>
              <td :style="getTableSeparatorStyle(productIndex, row.item.products.length)">
                <p v-if="display.productRows.some(productRow => productRow.productSelected || (productRow.products?.some(product => product.productSelected)))" style="cursor: pointer;">{{ row.item.mainProductLocation || row.item.productLocation }}</p>
                <p v-else>{{ row.item.mainProductLocation || row.item.productLOcation }}</p>
              </td>
              <td :style="getTableSeparatorStyle(productIndex, row.item.products.length)">
                <v-icon 
                  v-if="display.productRows.some(productRow => productRow.productSelected || (productRow.products?.some(product => product.productSelected)))" 
                  @click="openUpdateBunchProductDialog('productIsReplacedDaily')" 
                  :color="(product.mainProductIsReplacedDaily ? product.mainProductIsReplacedDaily : product.productIsReplacedDaily) ? 'success' : 'error'" 
                  style="cursor: pointer;"
                >
                  {{ (product.mainProductIsReplacedDaily ? product.mainProductIsReplacedDaily : product.productIsReplacedDaily) ? 'mdi-check-bold' : 'mdi-close-thick' }}
                </v-icon>
                <v-icon v-else :color="(product.mainProductIsReplacedDaily ? product.mainProductIsReplacedDaily : product.productIsReplacedDaily) ? 'success' : 'error'" center>{{ (product.mainProductIsReplacedDaily ? product.mainProductIsReplacedDaily : product.productIsReplacedDaily) ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
              </td>
              <td :style="getTableSeparatorStyle(productIndex, row.item.products.length)">
                <v-icon 
                  v-if="display.productRows.some(productRow => productRow.productSelected || (productRow.products?.some(product => product.productSelected)))" 
                  @click="openUpdateBunchProductDialog('productIsVisible')" 
                  :color="(product.mainProductIsVisible ? product.mainProductIsVisible : product.productIsVisible) ? 'success' : 'error'" 
                  style="cursor: pointer;"
                >
                  {{ (product.mainProductIsVisible ? product.mainProductIsVisible : product.productIsVisible) ? 'mdi-check-bold' : 'mdi-close-thick' }}
                </v-icon>
                <v-icon v-else :color="(product.mainProductIsVisible ? product.mainProductIsVisible : product.productIsVisible) ? 'success' : 'error'" center>{{ (product.mainProductIsVisible ? product.mainProductIsVisible : product.productIsVisible) ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
              </td>
              <td :style="getTableSeparatorStyle(productIndex, row.item.products.length)">
                <v-icon 
                  v-if="display.productRows.some(productRow => productRow.productSelected || (productRow.products?.some(product => product.productSelected)))" 
                  @click="openUpdateBunchProductDialog('productIsActive')" 
                  :color="(product.mainProductIsActive ? product.mainProductIsActive : product.productIsActive) ? 'success' : 'error'" 
                  style="cursor: pointer;"
                >
                  {{ (product.mainProductIsActive ? product.mainProductIsActive : product.productIsActive) ? 'mdi-check-bold' : 'mdi-close-thick' }}
                </v-icon>
                <v-icon v-else :color="(product.mainProductIsActive ? product.mainProductIsActive : product.productIsActive) ? 'success' : 'error'" center>{{ (product.mainProductIsActive ? product.mainProductIsActive : product.productIsActive) ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
              </td>
              <td :style="getTableSeparatorStyle(productIndex, row.item.products.length)">
                <v-icon @click="openUpdateProduct(product)" color="warning" center>mdi-pencil</v-icon>
              </td>
            </tr>
          </template>
        </template>
        <template #bottom></template>
      </v-data-table>
    </v-card>
    <br><br>
  </div>
</template>

<style scoped>
</style>

<script>
import { defineComponent } from 'vue';
import { viewMethodCaller } from '../viewMethodCaller.js';

export default defineComponent({
  name: 'ProductsView',
  inject: ['$generalFunctions'],

  data: () => ({
    input: 
    {
      selectedProductID: null,
      searchedProductID: null,
      showMainProduct: true,
      showInactiveProduct: false,
      selectedProductCategory: null,
      selectedProductBrand: null,
      selectedProductModel: null,
      selectedProductFlavor: null,
      selectedProductNicotineLevel: null,
      selectedProductSize: null,
      selectedProductTags: [],
      allProductSelected: false,
      updatingProductBunchValue: null
    },

    display: 
    {
      productOptions: [],
      productCategoryOptions: [],
      productBrandOptions: [],
      productModelOptions: [],
      productFlavorOptions: [],
      productNicotineLevelOptions: [],
      productSizeOptions: [],
      productTagOptions: [],
      productHeaders: 
      [
        {key: 'productSelected', title: ''},
        {key: 'mainProductID', title: 'ID INTERNO'},
        {key: 'productType', title: 'TIPO'},
        {key: 'mainProductName', title: 'NOMBRE'},
        {key: 'mainProductCost', title: 'PRECIO DE COSTO'},
        {key: 'mainProductPrice', title: 'PRECIO DE VENTA (DETALLE)'},
        {key: 'mainProductWholesaleColonPrice', title: 'PRECIO DE VENTA (POR MAYOR) ₡'},
        {key: 'mainProductWholesaleDolarPrice', title: 'PRECIO DE VENTA (POR MAYOR) $'},

        {key: 'mainProductMaxDiscountMargin', title: 'DESCUENTO PORCENTUAL MÁXIMO'},
        {key: 'mainProductLocation', title: 'UBICACIÓN EN BODEGA'},
        {key: 'mainProductIsReplacedDaily', title: 'REPOSICIÓN DIARIA'},
        {key: 'mainProductIsVisible', title: 'VISIBLE EN LÍNEA'},
        {key: 'mainProductIsActive', title: 'ACTIVO (GLOBAL)'},
        {key: 'productAction', title: 'EDITAR'},
      ],
      productRows: [],
      updatingProductBunchOptions: 
      [
        {value: true, title: '✔️'},
        {value: false, title: '✖️'},
      ]
    },

    navigation: 
    {
      searchVisible: 'searchVisible',
      updatingBunchProductField: null,
      updateBunchProductDialogIsVisible: false
    },

    loader:
    {
      selectProductBasicBasicInformationForBillsView: false,
      selectProductSearchParameters: false,
      selectProductForProductsView: false,
      updateBunchProduct: false
    },

    accessCredential: {},

    localStorage: {}
  }),

  watch: {
    'input.selectedProductID'(){
      if (this.input.selectedProductID){
        this.input.showMainProduct = false;
      }
    }
  },

  methods: {
    getTableSeparatorStyle(index, length){
      if (index+1 == length){
        return 'border-bottom: 2px black solid';
      }
    },

    openUpdateProduct(product){
      product.mainProductID ? this.$root.insertMainProductDialog.openInsertMainProductDialog(product.mainProductID) : this.$root.insertProductDialog.openInsertProductDialog(product.productID);
    },

    async updateBunchProduct(){
      this.loader.updateBunchProduct = true;
      const products = this.display.productRows
        .filter(productRow => productRow.productSelected || (productRow.products?.some(product => product.productSelected)))
        .flatMap(productRow => 
          productRow.productSelected 
            ? [{ productID: productRow.productID || null, mainProductID: productRow.mainProductID || null }]
            : productRow.products?.filter(product => product.productSelected).map(product => ({
                productID: product.productID || null,
                mainProductID: product.mainProductID || null
              })) || []
      );
      const updateBunchProductRequestQuery = 
      {
        'productField': this.navigation.updatingBunchProductField,
        'productValue': this.input.updatingProductBunchValue,
        'products': products
      };
      const updateBunchProductResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/updateBunchProduct', updateBunchProductRequestQuery);
      if (updateBunchProductResult.success){
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ÉXITO',
          'notificationDialogBody': 'Se han actualizado los productos exitosamente',
          'notificationDialogColor': 'success',
          'notificationDialogIsPersistent': false
        });
        this.navigation.updateBunchProductDialogIsVisible = false;
        await this.selectProductForProductsView();
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado actualizar los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.updateBunchProduct = false;
    },

    async selectProductForProductsView(){
      this.loader.selectProductForProductsView = true;
      const selectProductForProductsViewRequestQuery = 
      {
        'productID': this.input.selectedProductID ? this.input.selectedProductID.productID : null,
        'productProductCategoryID': this.input.selectedProductCategory,
        'productProductBrandID': this.input.selectedProductBrand,
        'productProductModelID': this.input.selectedProductModel,
        'productProductFlavorID': this.input.selectedProductFlavor,
        'productProductNicotineLevelID': this.input.selectedProductNicotineLevel,
        'productProductSizeID': this.input.selectedProductSize,
        'productProductTagIDS': this.input.selectedProductTags,
        'showMainProduct': this.input.showMainProduct,
        'showInactiveProduct': this.input.showInactiveProduct
      };
      this.input.allProductSelected = false;
      this.display.productRows.length = 0;
      const selectProductForProductsViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductForProductsView', selectProductForProductsViewRequestQuery);
      if (selectProductForProductsViewResult.success){
        if (selectProductForProductsViewResult.result.length != 0){
          this.display.productRows = selectProductForProductsViewResult.result.map(productRow => 
          ({
            ...productRow, 
            'productType': productRow.mainProductID ? 'mainProduct' : 'product',
            'productSelected': false
          }));
          this.navigation.searchVisible = '';
        } else {
          this.$root.notificationDialog.showNotificationDialog({
            'notificationDialogTitle': 'ATENCIÓN',
            'notificationDialogBody': 'No se ha encontrado ningún producto con los filtros seleccionados, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
            'notificationDialogColor': 'warning',
            'notificationDialogIsPersistent': false
          });
        }
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
      this.loader.selectProductForProductsView = false;
    },

    openUpdateBunchProductDialog(updatingBunchProductField){
      this.navigation.updatingBunchProductField = updatingBunchProductField;
      this.navigation.updateBunchProductDialogIsVisible = true;
      this.input.updatingProductBunchValue = (this.navigation.updatingBunchProductField == 'productCost' || this.navigation.updatingBunchProductField == 'productPrice' || this.navigation.updatingBunchProductField == 'productMaxDiscountMargin' || this.navigation.updatingBunchProductField == 'productWholesaleColonPrice' || this.navigation.updatingBunchProductField == 'productWholesaleDolarPrice') ? null : true;
    },

    selectAllProduct(){
      this.input.allProductSelected = !this.input.allProductSelected;
      this.display.productRows.forEach(productRow => {
        productRow.productSelected = this.input.allProductSelected;
        if (productRow.products){
          productRow.products.forEach(product => {
            product.productSelected = this.input.allProductSelected;
          });
        }
      });
    },

    updateProductIsSelected(productRow){
      if (productRow){
        productRow.productSelected = !productRow.productSelected;
      }
      this.input.allProductSelected = this.display.productRows.every(productRow => 
        productRow.productSelected && 
        (!productRow.products || productRow.products.every(product => product.productSelected))
      );
    },

    blurInput(inputReference){
      const blurInputInterval = setInterval(() => {
        if (this.$refs[inputReference]) {
          this.$refs[inputReference].blur();
          clearInterval(blurInputInterval);
        }
      }, 1);
    },

    selectProductID(){
      const selectedProduct = this.display.productOptions.find(productOption => productOption.productSKU == this.input.searchedProductID);
      if (selectedProduct){
        this.input.searchedProductID = selectedProduct.productAutocompleteTitle;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': null,
          'notificationDialogBody': 'El producto escaneado no ha sido encontrado, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'warning',
          'notificationDialogIsPersistent': false
        });
      }      
    },

    async selectProductBasicBasicInformationForBillsView(){
      this.loader.selectProductBasicBasicInformationForBillsView = true;
      const selectProductBasicBasicInformationForBillsViewResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductBasicBasicInformationForBillsView');
      if (selectProductBasicBasicInformationForBillsViewResult.success){
        this.display.productOptions = selectProductBasicBasicInformationForBillsViewResult.result.map(productOption => ({
          'productID': productOption.i,
          'productSKU': productOption.s,
          'productName': productOption.n,
          'productAutocompleteTitle': productOption.s + ' - ' + productOption.n
        }));
        this.loader.selectProductBasicBasicInformationForBillsView = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    async selectProductSearchParameters(){
      this.loader.selectProductSearchParameters = true;
      const selectProductSearchParametersResult = await this.$generalFunctions.default.methods.executeHttpPostRequest('/product/functions/selectProductSearchParameters');
      if (selectProductSearchParametersResult.success){
        this.display.productCategoryOptions = [{'productCategoryName': 'Todas las categorías', 'productCategoryID': null}, ...selectProductSearchParametersResult.result.productCategory];
        this.display.productBrandOptions = [{'productBrandName': 'Todas las marcas', 'productBrandID': null}, ...selectProductSearchParametersResult.result.productBrand];
        this.display.productModelOptions = [{'productModelName': 'Todos los modelos', 'productModelID': null}, ...selectProductSearchParametersResult.result.productModel];
        this.display.productFlavorOptions = [{'productFlavorName': 'Todos los sabores', 'productFlavorID': null}, ...selectProductSearchParametersResult.result.productFlavor];
        this.display.productNicotineLevelOptions = [{'productNicotineLevelName': 'Todas las graduaciones de nicotina', 'productNicotineLevelID': null}, ...selectProductSearchParametersResult.result.productNicotineLevel];
        this.display.productSizeOptions = [{'productSizeName': 'Todos los tamaños', 'productSizeID': null}, ...selectProductSearchParametersResult.result.productSize];
        this.display.productTagOptions = [...selectProductSearchParametersResult.result.productTag];
        this.loader.selectProductSearchParameters = false;
      } else {
        this.$root.notificationDialog.showNotificationDialog({
          'notificationDialogTitle': 'ERROR',
          'notificationDialogBody': 'Ha ocurrido un error inesperado al consultar la lista de características de los productos, por favor intente nuevamente. Si el problema persiste consulte a su administrador de sistema',
          'notificationDialogColor': 'error',
          'notificationDialogIsPersistent': false
        });
      }
    },

    setDefaultValues(){
      this.input.selectedProductID = null;
      this.input.searchedProductID = null;
      this.input.showMainProduct = true;
      this.input.showInactiveProduct = false;
      this.input.selectedProductCategory = null;
      this.input.selectedProductBrand = null;
      this.input.selectedProductModel = null;
      this.input.selectedProductFlavor = null;
      this.input.selectedProductNicotineLevel = null;
      this.input.selectedProductSize = null;
      this.input.selectedProductTags.length = 0;
      this.display.productRows.length = 0;
    }
  },

  async mounted(){
    await this.selectProductBasicBasicInformationForBillsView();
    await this.selectProductSearchParameters();

    viewMethodCaller.on('InsertMainProductDialog/methods/insertMainProduct()', this.selectProductBasicBasicInformationForBillsView);
    viewMethodCaller.on('InsertMainProductDialog/methods/updateMainProduct()', this.selectProductBasicBasicInformationForBillsView);
    viewMethodCaller.on('InsertProductDialog/methods/insertProduct()', this.selectProductBasicBasicInformationForBillsView);
    viewMethodCaller.on('InsertProductDialog/methods/updateProduct()', this.selectProductBasicBasicInformationForBillsView);

  }
});
</script>